import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export function useStatisticsBalanca(productId, type) {
	const { data, error } = useSWR(`data/statistic/balanca?productId=${productId}&type=${type}`, fetcher, { refreshInterval: 5000 });

	return {
		count: data,
		isLoading: !error && (data == null || data == undefined),
		isError: error
	}
}

export async function getActionToken(actionType) {
	return (await backendApi.get(`data/token?reason=${actionType}`)).data;	
}

export async function getProjecaoDiaria(mes, ano) {
	return (await backendApi.get(`data/financeiro/projecao-diaria?mes=${mes}&ano=${ano}`)).data;	
}

export async function getSaldo() {
	return (await backendApi.get(`data/financeiro/saldo`)).data;	
}

export async function getProjecaoMensal(meses) {
	return (await backendApi.get(`data/financeiro/projecao-mensal?meses=${meses}`)).data;	
}

export async function getLancamentosAtrasados(type) {
	return (await backendApi.get(`data/financeiro/lancamentos-atrasados?type=${type}`)).data;	
}

export async function getPrincipaisCreditos() {
	return (await backendApi.get(`data/financeiro/principais-creditos`)).data;	
}

export async function getLastTransactions() {
	return (await backendApi.get(`data/financeiro/last-transactions?max=5`)).data;	
}