import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';

import GerenciamentoBalanca from '../../../components/Movimento/Balanca/GerenciamentoBalanca/GerenciamentoBalanca'

export default function Balanca() {
	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Movimento</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/movimento/balanca">Balança</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Divider />
			<GerenciamentoBalanca></GerenciamentoBalanca>
		</div>
	);
}