import React from 'react';
import { Col, Row, Card } from 'antd';
import SaldoTotal from './SaldoTotal';
import ProjecaoDiaria from './ProjecaoDiaria';
import Projecoes from './Projecoes';

export default function DashboardFinanceiro() {
    return (
        <div>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Card title="Projeção diária" bodyStyle={{ padding: 1 }} style={{ height: '500px' }}>
                        <ProjecaoDiaria />
                    </Card>
                </Col>
                <Col span={9}>
                    <SaldoTotal />
                </Col>
                <Col span={15}>
                    <Projecoes />
                </Col>
            </Row>
        </div>
    );
}
