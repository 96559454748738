import React, { useState, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Row, Col, Button, Modal, Select, Form, notification, InputNumber} from 'antd';
import { useOperacoesFiscaisGerenciamento, useEstoquesOperacoes, useClientesRomaneio, criarOperacaoPrestacaoServico } from '../../../../apis/backend';
import { ConfigProvider } from 'antd';
import ptBr from 'antd/lib/locale/pt_BR';

export default NiceModal.create(() =>  {
    const modal = useModal();
    const [form] = Form.useForm();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const {clientes, isLoading : isLoadingClientes} = useClientesRomaneio();
    const [clientesFiltered, setClientesFiltered] = useState([]);
    const {opFiscais : opFiscaisDb, isLoading} = useOperacoesFiscaisGerenciamento();
    const {estoques, isLoading : isLoadingEstoques} = useEstoquesOperacoes();
    const [estoquesFiltered, setEstoquesFiltered] = useState([]);
    const [opFiscais, setOpFiscais] = useState([]);
    const [opFiscaisFiltered, setOpFiscaisFiltered] = useState([]);
    const fieldsRules = [
		{
			required: true
		}
	];

    useEffect(() => {
        if(opFiscaisDb){
            const arrFiltered = opFiscaisDb.filter(x => x.tipo === "Prestação de Serviço");
            setOpFiscais(arrFiltered);
            setOpFiscaisFiltered(arrFiltered);
        }
    }, [opFiscaisDb, isLoading]);

    useEffect(() => {
        if(clientes){
            setClientesFiltered(clientes);
        }
    }, [clientes, isLoadingClientes]);

    function onSearchOpFiscal(searchText){
		setOpFiscaisFiltered(
			!searchText ? opFiscais : opFiscais.filter(x =>  x.label.toLowerCase().startsWith(searchText.toLowerCase()))
		);
	};

    function onSearchCliente(searchText){
		setClientesFiltered(
			!searchText ? clientes : clientes.filter(x =>  x.label.toLowerCase().startsWith(searchText.toLowerCase()))
		);
	};

    function onSelectCliente(clienteId){
        setEstoquesFiltered(estoques[clienteId] || []);
        form.setFieldsValue({
            quantidadeReferenciada : 0,
            estoqueId: null
        });
    }

    function onSelectEstoque(estoqueId){
        const estoque = estoquesFiltered.filter(x => x.value === estoqueId)[0].data;
        form.setFieldsValue({
            quantidadeReferenciada: estoque.pesoAtual,
            porcentagemPrestacao: null,
            quantidadeCalculada: null

        });
    }

    function onChangeQuantidadeReferenciada(quantidade){
        const porcentagem = form.getFieldValue("porcentagemPrestacao");
        
        if(!porcentagem)
            return;

        form.setFieldsValue({
            quantidadeCalculada: parseFloat(((porcentagem / 100) * quantidade).toFixed(4))
        });
    }

    function calcularPeso(porcentagem){
        const quantidadeReferenciada = form.getFieldValue("quantidadeReferenciada");
        form.setFieldsValue({
            quantidadeCalculada: parseFloat(((porcentagem / 100) * quantidadeReferenciada).toFixed(4))
        });
    }

    function cancelEdit(){
        form.resetFields();
        modal.hide();
    }

    async function createPrestacao(e){
        setIsLoadingButton(true);

        try{
            await criarOperacaoPrestacaoServico(e);
            modal.resolve();
            cancelEdit();
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao criar prestação de serviço.'
            });
        }catch(e){
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao criar prestação de serviço. Tente novamente',
            });
        }

        setIsLoadingButton(false);
    }

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Registrar Prestação de Serviço"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelEdit} loading={isLoadingButton}>
                        Cancelar
                    </Button>,
                    <Button key={2} type="primary" loading={isLoadingButton} form="criarForm" htmlType="submit">
                        Criar
                    </Button>
                ]}
            >
                <Form form={form} name="criarForm" onFinish={createPrestacao}>
                    <Row justify='space-between' gutter={4}>
                        <Col span={24}>
                            <Form.Item
                                label="Operação Fiscal"
                                name={"operacaoFiscalId"}
                                rules={fieldsRules}>
                                <Select loading={isLoading} showSearch filterOption={false} onSearch={onSearchOpFiscal} options={opFiscaisFiltered}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={12}>
                            <Form.Item
                                label="Cliente"
                                name={"clienteId"}
                                rules={fieldsRules}>
                                <Select loading={isLoadingClientes} showSearch filterOption={false} onSelect={onSelectCliente} onSearch={onSearchCliente} options={clientesFiltered}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Produto"
                                name={"estoqueId"}
                                rules={fieldsRules}>
                                <Select loading={isLoadingEstoques} filterOption={false} onSelect={onSelectEstoque} options={estoquesFiltered}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={12}>
                            <Form.Item
                                label="Quantidade Referenciada"
                                name={"quantidadeReferenciada"}
                                rules={fieldsRules}>
                                <InputNumber addonAfter={"KG"} style={{width: '100%'}} onChange={onChangeQuantidadeReferenciada}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Porcentagem P.S"
                                name={"porcentagemPrestacao"}
                                rules={fieldsRules}>
                                <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}} onChange={calcularPeso}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='center' gutter={4}>
                        <Col span={24}>
                            <Form.Item
                                label="Quantidade Calculada"
                                name={"quantidadeCalculada"}
                                rules={fieldsRules}>
                                <InputNumber disabled addonAfter="KG" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </ConfigProvider>
    )
});