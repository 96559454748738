import React, { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Row, Col, Button, Modal, Select, Form, InputNumber, Input, notification, DatePicker, Checkbox } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { filterSelect, reaisFormatter, reaisParser, transacaoTipo } from '../../Utils/Utils';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { criarTransacao } from '../../../apis/backend';
import { getCaixas } from '../../../apis/queries/graphQueries';

const { Option } = Select;

export default NiceModal.create(({ caixaId }) => {
    const { data: caixas, isLoading: loadingCaixas } = useGraphQL(getCaixas);
    const [isLoading, setIsLoading] = useState(false);
    const modal = useModal();
    const [form] = Form.useForm();
    const fieldsRules = [
        {
            required: true,
        },
    ];

    async function create(payload) {
        setIsLoading(true);

        if (payload.valor <= 0) {
            notification.error({
                message: 'Erro',
                description: 'Valor deve ser maior que 0.',
            });
            setIsLoading(false);
            return;
        }

        try {
            await criarTransacao(payload);
            notification.success({
                message: 'Sucesso',
                description: 'Transação criada com sucesso.',
            });
            modal.resolve();
            modal.hide();
        } catch (e) {
            setIsLoading(false);

            notification.error({
                message: 'Erro',
                description: 'Falha ao criar transacao. Tente novamente. ' + e,
            });
        }
    }

    function cancelCreation(shouldMutate) {
        if (shouldMutate) modal.resolve();

        modal.hide();
    }

    useEffect(() => {
        if (Array.isArray(caixas?.caixas) && caixas?.caixas?.length > 0) {
            const val = caixas.caixas.find((x) => x.id == `${caixaId}`);

            form.setFieldsValue({
                valor: 0,
                data: dayjs(),
                caixaId: val.id,
                tipo: transacaoTipo[0].value,
            });
        }
    }, [caixas, loadingCaixas, caixaId]);

    const CriarTransacaoForm = () => {
        return (
            <Form
                form={form}
                name="transacaoForm"
                onFinish={create}
                isLoading={isLoading}
                initialValues={{
                    valor: 0,
                    data: dayjs(),
                    caixaId: caixaId || null,
                }}
            >
                <Row justify="space-between" gutter={4}>
                    <Col span={24}>
                        <Form.Item label="Descrição" name={'descricao'} rules={fieldsRules}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={12}>
                        <Form.Item label="Caixa" name={'caixaId'}>
                            <Select loading={loadingCaixas} showSearch filterOption={filterSelect}>
                                {caixas?.caixas?.map((val) => {
                                    return <Option value={val.id}>{val.nome}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Tipo" name={'tipo'} rules={fieldsRules}>
                            <Select options={transacaoTipo} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Valor" name={'valor'} rules={fieldsRules}>
                            <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={8}>
                        <Form.Item label="Titular Cheque" name={'titularCheque'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="NR Cheque" name={'numeroCheque'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Bom para" name={'bomPara'}>
                            <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item label="Cheque Disponível" name={'chequeDisponivel'} valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title="Criar Transação"
            centered
            open={modal.visible}
            width="90%"
            destroyOnClose={true}
            closable={false}
            keyboard={false}
            maskClosable={false}
            afterClose={() => modal.remove()}
            footer={[
                <Button key={1} onClick={cancelCreation} isLoading={isLoading}>
                    Cancelar
                </Button>,
                <Button key={2} type="primary" form="transacaoForm" htmlType="submit" disabled={isLoading} isLoading={isLoading}>
                    Salvar
                </Button>,
            ]}
        >
            <CriarTransacaoForm />
        </Modal>
    );
});
