import React, { useState } from 'react';
import { Popconfirm, Tooltip, Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deletarPesagem } from '../../../../apis/backend';

export default function DeletarPesagem({ pesagemId, onAfterDelete }) {
	const [isLoading, setIsLoading] = useState(false);

	async function onConfirmDelete() {
		setIsLoading(true);
		try {
			await deletarPesagem(pesagemId);
			onAfterDelete()
			notification.success({
				message: 'Sucesso',
				description: 'Sucesso ao deletar pesagem.'
			});
		} catch (e) {
			console.error(e);
			notification.error({
				message: 'Erro',
				description: 'Falha ao deletar pesagem. Tente novamente',
			});
		}
		setIsLoading(false);
	}

	return (
		<Tooltip title="Deletar">
			<Popconfirm
				title={`Confirma que deseja deletar?`}
				placement={"left"}
				onConfirm={onConfirmDelete}
				okButtonProps={{
					loading: isLoading,
				}}>
				<Button shape="circle" icon={<DeleteOutlined />} loading={isLoading} />
			</Popconfirm>
		</Tooltip>
	);
}