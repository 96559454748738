import "./InformacoesGeraisOpFiscal.scoped.css";
import React, { useState } from 'react';
import { Form, Row, Col, Input, notification, InputNumber, Select } from 'antd';
import { atualizarOpFiscal, useCodigosCfop } from "../../../apis/backend";

import { filterSelect, operacoesFiscaisTipos } from '../../Utils/Utils';

const { TextArea } = Input;

export default function InformacoesGeraisOpFiscal(props) {
	const [opFiscal] = useState(props.opFiscal);
    const {cfops, isLoading : isLoadingCfops} = useCodigosCfop();
	const isLoading = props.isLoading
	const [form] = Form.useForm();
	const fieldsRules = [
        {
            required: true
        }
    ];

	async function updateOpFiscal(e){
        isLoading(true);

        e.id = opFiscal.id;

        try{
			await atualizarOpFiscal(e);
			await props.mutate(e.id);
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao atualizar operação fiscal.'
            });
        }catch(e){
            notification.error({
                message: 'Erro',
                description:
                  e,
            });
        }

		isLoading(false);
    }
   
	return (
		<div>
			<Form
				form={form}
				name="opFiscalForm"
				onFinish={updateOpFiscal}
				initialValues={opFiscal}
			>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Tipo"
                            name={"tipo"}
                            rules={fieldsRules}>
							<Select
								allowClear
								filterOption={filterSelect}
								options={operacoesFiscaisTipos}
							/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={8}>
                        <Form.Item
                        label="Nome"
                        name={"nome"}
                        rules={fieldsRules}>
                            <Input/>
                        </Form.Item>
					</Col>
					<Col span={16}>
						<Form.Item
							label="CFOP"
							name={"cfopId"}
							rules={fieldsRules}>
							<Select
								allowClear
								showSearch
								loading={isLoadingCfops}
								filterOption={filterSelect}
								options={cfops}
							/>
						</Form.Item>
					</Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={8}>
                        <Form.Item
                            label="CST. ICMS"
                            name={"csticms"}
                            rules={fieldsRules}>
                            <InputNumber style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="CST. PIS"
                            name={"cstpis"}
                            rules={fieldsRules}>
                            <InputNumber style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="CST. COFINS"
                            name={"cstcofins"}
                            rules={fieldsRules}>
                            <InputNumber style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={8}>
                        <Form.Item
                            label="Ali. Frural"
                            name={"aliquotaFRural"}
                            rules={fieldsRules}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Ali. ICMS"
                            name={"aliquotaICMS"}
                            rules={fieldsRules}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Ali. PIS"
                            name={"aliquotaPIS"}
                            rules={fieldsRules}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={6}>
                        <Form.Item
                            label="Ali. COFIS"
                            name={"aliquotaCOFINS"}
                            rules={fieldsRules}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Ali. ISS"
                            name={"aliquotaISS"}
                            rules={fieldsRules}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Ali. IRPJ"
                            name={"aliquotaIRPJ"}
                            rules={fieldsRules}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Ali. CSLL"
                            name={"aliquotaCSLL"}
                            rules={fieldsRules}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={24}>
                        <Form.Item
                            label="Observações"
                            name={"observacoes"}>
                            <TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
			</Form>
		</div>
	);
}
