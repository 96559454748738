export default function RelatorioEstoqueHistorico() {
    return (
        <div>
            <iframe
                title="Histórico de Estoque"
                src="https://metabase.limaetarifa.com.br/public/question/9abe0074-b1d2-4bf1-947b-32abf8b8d91f"
                frameborder="0"
                width="100%"
                height="1130px"
                allowtransparency
            ></iframe>
        </div>
    );
}
