import NavigationRouter from './navigationRouter';
import { ConfigProvider } from 'antd';
import ptBr from 'antd/lib/locale/pt_BR';

function App() {
	return (	
		<ConfigProvider locale={ptBr} theme={{
			token: {
				colorPrimary: '#067e14',
			},
		}}>
			<NavigationRouter/>
		</ConfigProvider>
	);
}

export default App;
