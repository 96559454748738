import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export function useContratosRelacionados() {
	const { data, error, mutate } = useSWR(`operacoes/contratos`, fetcher);

	return {
		contratos: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export function useEstoquesOperacoes() {
	const { data, error, mutate } = useSWR(`operacoes/estoques`, fetcher);

	return {
		estoques: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export async function criarOperacaoPrestacaoServico(prestacaoServico) {
	return await backendApi.post('operacoes/prestacao', JSON.stringify(prestacaoServico));
}

export async function criarOperacao(operacao) {
	return JSON.parse((await backendApi.post('operacoes', JSON.stringify(operacao))).data);
}