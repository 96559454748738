import { useEffect, useRef, useState } from 'react';
import { flushSync } from "react-dom";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Space, Modal, Table, Card, Col, Row } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { getCustomerName, formatPercentageConcat } from '../../../Utils/Utils'
import ReactToPrint from 'react-to-print';

export default NiceModal.create(({ filteredRomaneioData, data }) => {
    const [printData, setPrintData] = useState([]);
    const [romaneiosByMoega, setRomaneiosByMoega] = useState({});
    const modal = useModal();
    const componentRef = useRef();
    const columns = [
        {
            title: 'Posição',
            key: 'id',
            dataIndex: 'id',
            render: (item, record, index) => {
                const posicaoRelativa = printData.findIndex(x => x.id === record.id) + 1;
                const posicaoGeral = data?.romaneios.findIndex(x => x.id === record.id) + 1;

                return `${posicaoRelativa} (${posicaoGeral})`
            },
            width: '85px'
        },
        {
            title: 'Placa',
            dataIndex: 'placaVeiculo',
            ellipsis: true,
            width: '100px'
        },
        {
            title: 'Motorista',
            dataIndex: 'motoristaNome',
            ellipsis: true,
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            ellipsis: true,
            width: '83px'
        },
        {
            title: 'Cliente',
            render: (_, r) => getCustomerName(r.cliente),
            ellipsis: true,
        },
        {
            title: 'Produto',
            dataIndex: ["produto", "descricao"],
            ellipsis: true,
            width: '160px'
        },
        {
            title: 'Umidade',
            dataIndex: "umidade",
            render: (value) => formatPercentageConcat(value),
            ellipsis: true,
            width: '80px'
        },
        {
            title: 'Moega',
            dataIndex: "moega",
            render: value => value.replace('Moega ', ''),
            ellipsis: true,
            width: '80px'
        }
    ];

    useEffect(() => {
        let modifiedMoega = [];

        data?.romaneios?.forEach(x => {
            const moegaName = x?.moega?.trim();

            if(!moegaName)
                return;
            
            if (modifiedMoega[moegaName] != null) {
                modifiedMoega[moegaName] = modifiedMoega[moegaName].concat(x);
            } else {
                modifiedMoega[moegaName] = [x];
            }
        });

        setRomaneiosByMoega(modifiedMoega);
    }, []);

    function onBeforePrint(key) {
        flushSync(() => {
            setPrintData(key === 'Todas' ? filteredRomaneioData.filter(x => x.tipo === "Entrada") : romaneiosByMoega[key]);
        });
    }

    function DisplayMoegaPrint(props) {
        const awaiting = romaneiosByMoega[props.title]?.length;

        return <Col span={8}>
            <Card
                title={props.title}
                bordered={false}
                style={{
                    height: 210
                }}
                actions={[
                    <ReactToPrint
                        onBeforeGetContent={() => onBeforePrint(props.title)}
                        trigger={() => <Button size='small' shape="circle" icon={<PrinterOutlined />} />}
                        content={() => componentRef.current}
                    />,
                ]}
            >
                <p>{props.text ? props.text : `${awaiting} veículo${awaiting <= 1 ? '' : 's'} est${awaiting <= 1 ? 'á' : 'ão'} na fila.`}</p>
            </Card>
        </Col>
    }

    return (
        <Modal
            title="Imprimir Lista"
            centered
            open={modal.visible}
            width="90%"
            destroyOnClose={true}
            closable={false}
            keyboard={false}
            maskClosable={false}
            afterClose={() => modal.remove()}
            footer={
                <Button key={1} onClick={() => modal.hide()}>
                    Cancelar
                </Button>
            }
        >
            <Row gutter={[16, 16]}>
                {
                    Object.keys(romaneiosByMoega).map(x => {
                        return <DisplayMoegaPrint title={x} key={x}/>
                    })
                }
                <DisplayMoegaPrint title="Todas" text={`${data?.romaneios?.filter(x => x.tipo === "Entrada")?.length} veículos estão na fila.`} />
            </Row>

            <Space size="middle" style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <div>
                        <Table rowKey="id" style={{ margin: "0", padding: "0", fontSize: "12px" }} columns={columns} dataSource={printData} pagination={false} />
                    </div>
                </div>
            </Space>
        </Modal>
    )
});