import { GraphQLClient } from "graphql-request";
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable'

const graphqlClient = new GraphQLClient(
	window.location.hostname === 'localhost' ? `http://127.0.0.1:5001/graphql` : 'https://be-bo.limaetarifa.com.br/graphql',
	{
		headers: {
			Authorization: `Bearer ${localStorage.getItem("JWT_TOKEN")}`
		}
	}
);
const fetcher = (query, variables) => graphqlClient.request(query, variables);

export function useGraphQL(query, variables) {
	const { data, error, mutate } = useSWR([query, variables], ([query, variables]) => fetcher(query, variables));

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export function useGraphQLImmutable(query, variables) {
	const { data, error, mutate } = useSWRImmutable([query, variables], ([query, variables]) => fetcher(query, variables));

	return {
		data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}