import React, { useState } from 'react';
import { getRomaneioPdf } from '../../apis/backend';
import { Tooltip, Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { printBase64Pdf } from '../Utils/Utils';

export default function RomaneioPrint(props) {
    const [isLoading, setIsLoading] = useState(false);

    async function printRomaneio() {
        setIsLoading(true);
        const pdf = await getRomaneioPdf(props.id);
        setIsLoading(false);
        printBase64Pdf(pdf);
    }

    return (
        <Tooltip title="Imprimir">
            <Button size="small" loading={isLoading} shape="circle" icon={<PrinterOutlined />} onClick={printRomaneio} />
        </Tooltip>
    );
}
