import React from 'react';
import './Cliente.scoped.css';
import { useParams } from "react-router-dom";
import DetalheCliente from '../../../components/Cliente/DetalheCliente/DetalheCliente';

export default function ClienteView() {
	const { id } = useParams();

	return (
		<DetalheCliente id={id}></DetalheCliente>
	);
}