import React, { useState, useEffect } from 'react';
import { Input, Table, Space } from 'antd';
import { useClientes } from '../../../apis/backend';
import { Link } from 'react-router-dom';
import { getCustomerName, getCustomerIdentification, searchInArray } from '../../Utils/Utils';

const { Search } = Input;

export default function ListagemClientes() {
    const { clientes, isLoading } = useClientes();
    const [filteredData, setFilteredData] = useState([]);
    const columns = [
        {
            title: 'Nome / Razão',
            dataIndex: 'nomeRazao',
            key: 'nomeRazao',
            ellipsis: true,
            width: '65%',
            render: (_, r) => <Link to={`/cadastros/cliente/${r.id}`}>{getCustomerName(r)}</Link>,
        },
        {
            title: 'CNPJ / CPF',
            dataIndex: 'cnpjCpf',
            key: 'cnpjCpf',
            ellipsis: true,
        },
        {
            title: 'Tipo',
            dataIndex: 'tipoPessoa',
            key: 'tipoPessoa',
            ellipsis: true,
        },
    ];

    useEffect(() => {
        if (!clientes) return;

        const newList = [];

        clientes.forEach((x) => {
            x.cnpjCpf = getCustomerIdentification(x);
            x.cepLimpo = x.cep.replace(/\D/g, '');
            newList.push(x);
        });

        setFilteredData(newList);
    }, [clientes]);

    function onSearch(evt) {
        const searchTerm = evt.target.value;

        if (!searchTerm) return setFilteredData(clientes);

        setFilteredData(searchInArray(searchTerm, clientes));
    }

    return (
        <div>
            <Search placeholder="Busca" allowClear size="large" onChange={onSearch} style={{ width: '100%', marginBottom: '10px' }} />
            <Space size="middle">
                <Table
                    rowKey="id"
                    loading={isLoading}
                    columns={columns}
                    dataSource={filteredData}
                    pagination={{
                        simple: false,
                        hideOnSinglePage: true,
                        defaultPageSize: 7,
                        showSizeChanger: false,
                    }}
                />
            </Space>
        </div>
    );
}
