import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button } from 'antd';
import CriarCaixaModal from '../CriarCaixaModal/CriarCaixaModal';

export default function CriarCaixa() {
	const criarCaixaModal = useModal(CriarCaixaModal);

	function criarCaixa() {
		criarCaixaModal.show();
	}

	return (
		<div>
			<Button type="primary" size={"large"} onClick={criarCaixa}>Criar</Button>
		</div>
	);
}