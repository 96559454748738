import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Row, Col, Button, Modal, Select, Form, notification, Input, InputNumber, DatePicker, ConfigProvider, Space, Popover, Typography} from 'antd';
import { useOperacoesFiscaisGerenciamento, useContratosRelacionados, useProdutosRomaneio, criarOperacao } from '../../../../apis/backend';
import { Link } from 'react-router-dom';
import ptBr from 'antd/lib/locale/pt_BR';
import dayjs from 'dayjs';
import {filterSelect, getCustomerName, formatDayDate, formatPeso} from '../../../Utils/Utils';

const { Option } = Select;
const { Text } = Typography;

export default NiceModal.create(() =>  {
    const modal = useModal();
    const [form] = Form.useForm();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const {opFiscais, isLoading : isLoadingOperacoesFiscais} = useOperacoesFiscaisGerenciamento();
    const {contratos, isLoading : isLoadingContratosRelacionados} = useContratosRelacionados();
    const {produtos, isLoading : isLoadingProdutos} = useProdutosRomaneio();
    const [contratosByProduct, setContratosByProduct] = useState([]);
    const fieldsRules = [
		{
			required: true
		}
	];

    function cancelEdit(){
        form.resetFields();
        modal.hide();
    }

    async function createEntrada(e){
        setIsLoadingButton(true);
        
        e.contratoId = parseInt(e.contratoId.split("-")[0]);

        try{
            await criarOperacao(e);
            modal.resolve();
            cancelEdit();
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao criar operação.'
            });
        }catch(e){
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao criar operação. Tente novamente',
            });
        }

        setIsLoadingButton(false);
    }

    function changedProduto(produto){
        const contratosFiltered = contratos[produto];
        setContratosByProduct(contratosFiltered || []);
        form.setFieldsValue({
            contratoId: null
        });
        form.setFieldsValue(
            {
                compradorNome: null,
                vendedorNome: null,
                produtoNome: null,
                quantidadeTotal: null,
                precoContrato: null,
                numeroDeContrato: null,
                dataDePagamento: null,
                prazoDeEmbarque: null,
                observacoes: null,
                quantidadeEmbarcada: null,
                saldoAEmbarcar: null,
            });
    }

    function formatterNumber(val){
        if (!val) return 0;
		return parseFloat(val).toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 4 });
    }
      
    function parserNumber(val){
        if (!val) return 0;
        return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, "").replace(/(\,{1})/g, ".")).toFixed(4)
    }

    function onSelectContrato(e){
        const contrato = contratosByProduct.filter(x => x.value === e)[0];
        const fullData = contrato.data;
        form.setFieldsValue(
        {
            compradorNome: fullData.comprador.tipoPessoa === "Pessoa Jurídica" ? fullData.comprador.razaoSocial : fullData.comprador.nome,
            vendedorNome: fullData.vendedor.tipoPessoa === "Pessoa Jurídica" ? fullData.vendedor.razaoSocial : fullData.vendedor.nome,
            produtoNome: fullData.produto.descricao,
            quantidadeTotal: fullData.quantidadeTotal,
            precoContrato: fullData.preco,
            numeroDeContrato: fullData.numeroDeContrato,
			dataDePagamento: dayjs(fullData.dataDePagamento),
			prazoDeEmbarque: dayjs(fullData.prazoDeEmbarque),
            observacoes: fullData.observacoes,
            quantidadeEmbarcada: fullData.quantidadeEmbarcada,
			saldoAEmbarcar: fullData.saldoAEmbarcar,
			transportador: fullData.transportador,
			frete: fullData.frete,
			corretor: fullData.corretor,
			comissao: fullData.comissao,
        });
    }

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Registrar Entrada/Saída"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelEdit} loading={isLoadingButton}>
                        Cancelar
                    </Button>,
                    <Button key={2} type="primary" loading={isLoadingButton} form="criarForm" key="submit" htmlType="submit">
                        Criar
                    </Button>
                ]}
            >
                <Form form={form} name="criarForm" onFinish={createEntrada}>
                    <Row justify='space-between' gutter={4}>
                        <Col span={24}>
                            <Form.Item
                                label="Operação Fiscal"
                                name={"operacaoFiscalId"}
                                rules={fieldsRules}>
                                <Select showSearch filterOption={filterSelect} options={opFiscais} loading={isLoadingOperacoesFiscais}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={12}>
                            <Form.Item
                                label="Produto"
                                name={"produtoId"}
                                rules={fieldsRules}>
                               <Select showSearch filterOption={filterSelect} onChange={changedProduto} options={produtos} loading={isLoadingProdutos}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Contrato"
                                name={"contratoId"}
                                rules={fieldsRules}>
                               <Select loading={isLoadingContratosRelacionados} onSelect={onSelectContrato} showSearch filterOption={filterSelect}>
                                    {contratosByProduct.map(val => {
                                        return <Option value={val.value} label={val.label}>
                                            <Popover placement="left" title={<div>{val.data.numeroDeContrato} | <Link target="_blank" to={`/cadastros/contrato/${val.data.id}`}>Acessar</Link></div>} content={
                                                <div>
                                                    <Text type="secondary">Comprador: </Text><Text>{getCustomerName(val.data.comprador)}</Text><br/>
                                                    <Text type="secondary">Vendedor: </Text><Text>{getCustomerName(val.data.vendedor)}</Text><br/>
                                                    <Text type="secondary">Pagamento: </Text><Text>{formatDayDate(val.data.dataDePagamento, true)}</Text><br/>
                                                    <Text type="secondary">Prazo Embarque: </Text><Text>{formatDayDate(val.data.prazoDeEmbarque, true)}</Text><br/>
                                                    <Text type="secondary">Contrato Externo: </Text><Text>{val.data.contratoExterno}</Text><br/>
                                                    <Text type="secondary">Quantidade Total: </Text><Text>{formatPeso(val.data.quantidadeTotal)}</Text><br/>
                                                    <Text type="secondary">Quantidade Embarcada: </Text><Text>{formatPeso(val.data.quantidadeEmbarcada)}</Text><br/>
                                                    <Text type="secondary">Saldo Embarcar: </Text><Text>{formatPeso(val.data.saldoAEmbarcar)}</Text><br/>
                                                </div>
                                                }>
                                                <Space>
                                                    {val.label}
                                                </Space>
                                            </Popover>
                                        </Option>
                                    })}
                               </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row justify='space-between' gutter={4}>
                        <Col span={12}>
                            <Form.Item
                                label="Comprador"
                                name={"compradorNome"}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Vendedor"
                                name={"vendedorNome"}
                                >
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={12}>
                            <Form.Item
                                label="Produto"
                                name={"produtoNome"}
                                >
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Preço"
                                name={"precoContrato"}>
                                <InputNumber
                                    disabled
                                    style={{width: "100%"}}
                                    addonBefore={"R$ / KG"}
                                    formatter={value => formatterNumber(value)}
                                    parser={value => parserNumber(value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={8}>
                            <Form.Item
                                label="Número de Contrato"
                                name={"numeroDeContrato"}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Data de Pagamento"
                                name={"dataDePagamento"}>
                                <DatePicker disabled format={'DD/MM/YYYY'} style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Prazo de Embarque"
                                name={"prazoDeEmbarque"}>
                                <DatePicker disabled format={'DD/MM/YYYY'} style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
					</Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={24}>
                            <Form.Item
                                label="Observações"
                                name={"observacoes"} >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={8}>
                            <Form.Item
                                label="Quantidade Total"
                                name={"quantidadeTotal"}
                                >
                                <InputNumber disabled style={{width: "100%"}} addonAfter={"KG"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Quantidade Embarcada"
                                name={"quantidadeEmbarcada"}>
                                <InputNumber disabled style={{width: "100%"}} addonAfter={"KG"}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Saldo a Embarcar"
                                name={"saldoAEmbarcar"}>
                                <InputNumber disabled style={{width: "100%"}} addonAfter={"KG"}/>
                            </Form.Item>
                        </Col>
                    </Row>
					<Row justify='space-between' gutter={4}>
						<Col span={6}>
							<Form.Item
								label="Transportador"
								name={"transportador"}
							>
								<Input disabled style={{ width: "100%" }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Frete"
								name={"frete"}>
								<InputNumber
									disabled
									style={{ width: "100%" }}
									addonBefore={"R$ / KG"}
									formatter={value => formatterNumber(value)}
									parser={value => parserNumber(value)}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Corretor"
								name={"corretor"}
							>
								<Input disabled style={{ width: "100%" }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Comissão"
								name={"comissao"}>
								<InputNumber
									disabled
									style={{ width: "100%" }}
									addonBefore={"R$ / KG"}
									formatter={value => formatterNumber(value)}
									parser={value => parserNumber(value)}
								/>
							</Form.Item>
						</Col>
					</Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={12}>
                            <Form.Item
                                rules={fieldsRules}
                                label="Peso Embarcado"
                                name={"pesoEmbarcado"}
                                >
                                <InputNumber style={{width: "100%"}} addonAfter={"KG"}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Forma de Pagamento"
                                name={"formaDePagamento"} >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </ConfigProvider>
    )
});