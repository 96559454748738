import React from 'react';
import { Alert } from 'antd';
import { SingleTicker, MiniChart } from "react-tradingview-embed";
import dayjs from 'dayjs';
import './Home.scoped.css';

const FUTURES_CODES = {
	'january' : 'F',
	'february' : 'G',
	'march' : 'H',
	'april' : 'J',
	'may' : 'K',
	'june' : 'M',
	'july' : 'N',
	'august' : 'Q',
	'september' : 'U',
	'october' : 'V',
	'november' : 'X',
	'december' : 'Z',
};
const MONTHS_CODES = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december',
];
const SOY_FUTURES = ['january', 'march', 'may', 'july', 'september', 'august', 'november'];
const CORN_FUTURES = ['march', 'may', 'july', 'september', 'december'];

export default function Home() {
	const soyFuturesSet = new Set();
	const cornFuturesSet = new Set();

	for (let index = 1; index < 13; index++) {
		soyFuturesSet.add(getCode('ZS', SOY_FUTURES, addMonths(index)));
	}

	for (let index = 1; index < 13; index++) {
		cornFuturesSet.add(getCode('ZC', CORN_FUTURES, addMonths(index)));
	}

	const soyFuturesArr = Array.from(soyFuturesSet);
	const cornFuturesArr = Array.from(cornFuturesSet);

	function getCode(ticker, list, date){
		const currentDate = date;
		const positionMonth = currentDate.getMonth();
		const nextMonthName = getNextMonthName(list, positionMonth);

		const monthIndex = MONTHS_CODES.findIndex(x => x === nextMonthName);
		const year = monthIndex < currentDate.getMonth() ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

		const contractCode = `CBOT:${ticker}${FUTURES_CODES[nextMonthName]}${year}`;
		return contractCode;
	}

	function addMonths(months) {
		const dateCopy = new Date();
  		dateCopy.setMonth(dateCopy.getMonth() + months);
  		return dateCopy;
	}

	function getNextMonthName(futuresList, monthNumber){
		let nextMonthName = MONTHS_CODES[monthNumber];

		if(!futuresList.includes(nextMonthName)){
			nextMonthName = getNextMonthName(futuresList, monthNumber > 11 ? 0 : monthNumber + 1);
		}

		return nextMonthName;
	}

	const currentMonth = dayjs().format('M');
	const currentDayInt = parseInt(dayjs().format('D'));
	const isBetweenOneAndTen = currentDayInt > 0 && currentDayInt < 11;
	const shouldPsArmazenagem = isBetweenOneAndTen && (currentMonth === "1" || currentMonth === "6");
	const isLastDays = currentDayInt === 28 || currentDayInt === 29 || currentDayInt === 30 || currentDayInt === 30 || currentDayInt === 1 || currentDayInt === 2

	return (
		<div>
			{
				isLastDays ? 
				<Alert message="Último dia do mês. Ajustar saldo negativo." type="warning" style={{ marginBottom: '10px' }} showIcon />
				: <></>
			}
			{
				shouldPsArmazenagem ? 
				<Alert message="Cobrar prestação de serviço armazenagem." type="warning" style={{ marginBottom: '10px' }} showIcon />
				: <></>
			}
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
				<div style={{ width: '33%' }}>
					<SingleTicker widgetProps={{ symbol: 'FX_IDC:USDBRL', dateRange: '1D', colorTheme: "Light", width: '100%' }} />
				</div>
				<div style={{ width: '33%' }}>
					<SingleTicker widgetProps={{ symbol: 'FX_IDC:EURBRL', dateRange: '1D', colorTheme: "Light", width: '100%' }} />
				</div>
				<div style={{ width: '33%' }}>
					<SingleTicker widgetProps={{ symbol: 'FX_IDC:CNYBRL', dateRange: '1D', colorTheme: "Light", width: '100%' }} />
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: 0, marginTop: '10px' }}>
				<div style={{width: '33%'}}>
					<MiniChart widgetProps={{ symbol: soyFuturesArr[0], dateRange: '1M', colorTheme: "Light", width: '100%' }} />
				</div>
				<div style={{ width: '33%' }}>
					<MiniChart widgetProps={{ symbol: soyFuturesArr[1], dateRange: '1M', colorTheme: "Light", width: '100%' }} />
				</div>
				<div style={{ width: '33%' }}>
					<MiniChart widgetProps={{ symbol: soyFuturesArr[2], dateRange: '1M', colorTheme: "Light", width: '100%' }} />
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: 0, marginTop: '10px' }}>
				<div style={{ width: '33%' }}>
					<MiniChart widgetProps={{ symbol: cornFuturesArr[0], dateRange: '1M', colorTheme: "Light", width: '100%' }} />
				</div>
				<div style={{ width: '33%' }}>
					<MiniChart widgetProps={{ symbol: cornFuturesArr[1], dateRange: '1M', colorTheme: "Light", width: '100%' }} />
				</div>
				<div style={{ width: '33%' }}>
					<MiniChart widgetProps={{ symbol: cornFuturesArr[2], dateRange: '1M', colorTheme: "Light", width: '100%' }} />
				</div>
			</div>
		</div>
	);
}