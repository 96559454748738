import React from 'react';
import { useEstoques } from "../../../apis/backend";
import { Table } from 'antd';
import { formatPeso, formatSacos } from '../../Utils/Utils';

export default function EstoqueCliente(props) {
    const { estoques, isLoading } = useEstoques(props.cliente.id);

	const columns = [
		{ title: 'Produto', dataIndex: ['produto', 'descricao'], key: 'produto' },
		{ title: 'Peso', dataIndex: 'pesoAtual', key: 'peso', render: (val) => formatPeso(val) },
		{ title: 'Sacos', key: 'sacos', render: (_, record) => formatSacos(record.pesoAtual)},
	];

    return (
		<div>
            <Table
                rowKey="id"
                columns={columns}
                pagination={{
                    simple: false,
                    hideOnSinglePage: true,
                    defaultPageSize: 5,
                    showSizeChanger: false
                }}
                dataSource={estoques}
                loading={isLoading}
            />
        </div>
    )
}