import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export function useRomaneiosPendentesGerenciamento() {
	const { data, error, mutate } = useSWR(`gerenciamento/pendentes`, fetcher);

	return {
		romaneiosPendentes: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export function useOperacoesFiscaisGerenciamento() {
	const { data, error, mutate } = useSWR(`gerenciamento/operacoes-fiscais`, fetcher);

	return {
		opFiscais: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export async function aprovarRomaneio(romaneio) {
	return await backendApi.post(`gerenciamento/validar-romaneio`, JSON.stringify(romaneio));
}