import React, { useState, useRef } from 'react';
import { Select, Button, Table } from 'antd';
import { PrinterOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useRelatorioAssinaturas } from '../../apis/backend';
import ReactToPrint from 'react-to-print';

export default function RelatorioAssinaturas() {
	const [tipo, setTipo] = useState("pending");
	const { relatorio, isLoading: isFetching } = useRelatorioAssinaturas(tipo);
	const tableRef = useRef();
	const columns = [
		{
			title: 'Contrato',
			render: valor => <Link target="_blank" to={`/cadastros/contrato/${valor.id}`}>{valor.numeroDeContrato}</Link>,
			align: 'center',
			key: 1,
		},
		{
			title: 'Comprador',
			render: valor => valor.zapStatusComprador === "signed" ? <div>Assinado <CheckOutlined /></div> : <div>Não Assinado <CloseOutlined /></div>,
			align: 'center',
			key: 2,
		},
		{
			title: 'Vendedor',
			render: valor => valor.zapStatusVendedor === "signed" ? <div>Assinado <CheckOutlined /></div> : <div>Não Assinado <CloseOutlined /></div>,
			align: 'center',
			key: 3,
		},
        {
			title: 'Ação',
			render: valor => <Link target="_blank" to={`/cadastros/contrato/${valor.id}?assinatura=true`}>Assinar</Link>,
			align: 'center',
			key: 4,
		},
	];

	return (
		<div>
			<div style={{ display: "flex" }}>
				<Select
					size='medium'
					placeholder="Tipo"
					mode="single"
					allowClear
					style={{ width: '100%', paddingRight: '5px', height: '100%' }}
					onChange={setTipo}
                    defaultValue={{ label: "Pendentes", value: "pending" }}
					options={[{ label: "Pendentes", value: "pending" }, { label: "Finalizados", value: "closed" }]}
				/>
				<ReactToPrint
					trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio} />}
					content={() => tableRef.current}
					pageStyle={"padding: 1px;"}
				/>
			</div>
			<div style={{ marginTop: '10px' }}>
				<Table
					expandable={{
						defaultExpandAllRows: true,
					}}
					id="report-form"
					ref={tableRef}
					columns={columns}
					dataSource={relatorio || []}
					pagination={false}
					loading={isFetching}
					bordered
				/>
			</div>
		</div>
	);
}