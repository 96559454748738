import React, { useState, useEffect } from 'react';
import { buscarRomaneiosFechados } from '../../../../apis/backend';
import { Input, Table, Space, DatePicker, Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';
import { searchInArray, getCustomerName } from '../../../Utils/Utils';
import { ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import RomaneioActions from '../RomaneioActions/RomaneioActions';

const { Search } = Input;
const { RangePicker } = DatePicker;

export default function RomaneiosFechados() {
    const [filteredRomaneioData, setFilteredRomaneioData] = useState([]);
    const [romaneioData, setRomaneioData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [defaultStartDate] = useState([dayjs().subtract(1, 'months').startOf('day'), dayjs().endOf('day')]);
    const [currentDates, setCurrentDates] = useState([]);
    const columns = [
        {
            title: 'Placa',
            dataIndex: 'placaVeiculo',
            ellipsis: true,
            width: '120px',
        },
        {
            title: 'Motorista',
            dataIndex: 'motoristaNome',
            ellipsis: true,
        },
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            ellipsis: true,
            width: '120px',
        },
        {
            title: 'Cliente',
            render: (_, r) => (
                <Link target="_blank" to={`/cadastros/cliente/${r.clienteId}`}>
                    {getCustomerName(r.cliente)}
                </Link>
            ),
            ellipsis: true,
        },
        {
            title: 'Produto',
            dataIndex: ['produto', 'descricao'],
            ellipsis: true,
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_, record) => <RomaneioActions romaneio={record} onAfterAction={onAfterAction} />,
        },
    ];

    useEffect(() => {
        setCurrentDates(defaultStartDate);
        fetchRomaneios(defaultStartDate[0], defaultStartDate[1]).then((e) => {
            setFilteredRomaneioData(e);
            setRomaneioData(e);
        });
    }, []);

    function onSearch(evt) {
        const searchTerm = evt.target.value;

        if (!searchTerm) return setFilteredRomaneioData(romaneioData);

        setFilteredRomaneioData(searchInArray(searchTerm, romaneioData));
    }

    async function onChangeRange(event) {
        if (!event || !event[0] || !event[1]) return;

        setCurrentDates(event);
        const data = await fetchRomaneios(event[0], event[1]);

        setRomaneioData(data);
        setFilteredRomaneioData(data);
    }

    async function fetchRomaneios(startDate, endDate) {
        setIsLoading(true);
        const data = await buscarRomaneiosFechados(startDate.startOf('day').toISOString(), endDate.endOf('day').toISOString());
        setIsLoading(false);

        return data;
    }

    async function onAfterAction() {
        const start = currentDates[0];
        const end = currentDates[1];

        fetchRomaneios(start, end).then((e) => setFilteredRomaneioData(e));
    }

    return (
        <span>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ width: '30%', marginRight: '10px' }}>
                    <RangePicker
                        defaultPickerValue={defaultStartDate}
                        defaultValue={defaultStartDate}
                        size="large"
                        style={{ width: '100%' }}
                        onChange={onChangeRange}
                        format={'DD/MM/YYYY'}
                    />
                </div>
                <div style={{ width: '65%' }}>
                    <Search placeholder="Busca" allowClear size="large" onChange={onSearch} />
                </div>
                <div style={{ width: '5%', display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Atualizar">
                        <Button type="primary" size={'large'} shape="circle" onClick={() => onChangeRange(currentDates)} icon={<ReloadOutlined />} />
                    </Tooltip>
                </div>
            </div>
            <Space size="middle">
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={filteredRomaneioData}
                    loading={isLoading}
                    pagination={{
                        simple: false,
                        hideOnSinglePage: true,
                        defaultPageSize: 7,
                        showSizeChanger: false,
                    }}
                />
            </Space>
        </span>
    );
}
