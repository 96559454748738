import React from 'react';
import './OperacaoFiscal.scoped.css';
import { useParams } from "react-router-dom";
import DetalheOperacaoFiscal from '../../../components/OperacaoFiscal/DetalheOperacaoFiscal/DetalheOperacaoFiscal';

export default function OperacaoFiscalView() {
	const { id } = useParams();

	return (
		<DetalheOperacaoFiscal id={id}></DetalheOperacaoFiscal>
	);
}