import React, {useState, useEffect, useRef} from 'react';
import { Select, Button, Table } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useRelatorioEstoque, useClientesRomaneio, useProdutosRomaneio } from '../../apis/backend';
import { formatPeso, filterSelect } from '../Utils/Utils';
import ReactToPrint from 'react-to-print';

export default function RelatorioEstoque() {
    const { clientes : clientesDb } = useClientesRomaneio();
    const { produtos : produtosDb } = useProdutosRomaneio();
    const [clientes, setClientes] = useState([]);
    const [produto, setProduto] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
	const [fixedReport, setFixedReport] = useState([]);
	const [totalData, setTotalData] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
    const {relatorio, isLoading : isFetching} = useRelatorioEstoque(clientes, produto);
    const tableRef = useRef();
    const columns = [
        { 
            title: 'Cliente', 
            key: 'key',
            render: (fullData) => {
                return (<Link target="_blank" to={`/cadastros/cliente/${fullData.key}`}>{fullData.clienteNome}</Link>)
            },
			defaultExpandAllRows: true,
			align: 'center'
        },
        {
            title: "Estoque",
            children: [
                {
                    title: "Produto",
                    dataIndex: ['produto', 'descricao'],
					key: 2,
					align: 'center'
                },
                {
                    title: "Saldo",
                    dataIndex: "pesoAtual",
                    key: 3,
					render: valor => !!valor ? formatPeso(valor) : '',
					align: 'center'
                },
            ]
        }
	];

    const updateExpandedRowKeys = ({ record }) => {
        const rowKey = record.key;
        const isExpanded = expandedKeys.find(key => key === rowKey);
        let newExpandedRowKeys = [];
        if (isExpanded) {
            newExpandedRowKeys = expandedKeys.reduce((acc, key) => {
            if (key !== rowKey) acc.push(key);
            return acc;
            }, []);
        } else {
            newExpandedRowKeys = expandedKeys;
            newExpandedRowKeys.push(rowKey);
        }
        setExpandedKeys(newExpandedRowKeys);
    };

    useEffect(() => {
		if (Array.isArray(relatorio) && ('object' == typeof relatorio[0] || relatorio.length === 0)) {
            setExpandedKeys([]);
			setIsLoading(true);
            let exKeys = [];
			let objSum = {};

			relatorio.forEach(x => {
				x.children.forEach(x => {
					let sum = objSum[x.produto.descricao] || 0;
					sum += x.pesoAtual;
					objSum[x.produto.descricao] = sum;
				});
                exKeys.push(x.key);
			});

			setTotalData(objSum);

            setExpandedKeys(exKeys);
			setFixedReport(relatorio);
			setIsLoading(false);
        }
    }, [relatorio]);

	return (
        <div>
            <div style={{display: "flex"}}>
                <Select
                    size='medium'
                    placeholder="Cliente"
                    mode="multiple"
                    allowClear
					style={{ width: '50%', paddingRight: '5px', height: '100%'}}
                    onChange={setClientes}
					filterOption={filterSelect}
                    options={clientesDb}
                />
                <Select
                    size='medium'
                    placeholder="Produto"
                    allowClear
                    mode="multiple"
					style={{ width: '50%', paddingRight: '5px', height: '100%'}}
                    onChange={setProduto}
					filterOption={filterSelect}
                    options={produtosDb}
                />
				<ReactToPrint
                    trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio}/>}
                    content={() => tableRef.current}
                    pageStyle={"padding: 1px;"}
                />
            </div>
            <div style={{marginTop: '10px'}}>
                <Table
                    expandable={{
                        defaultExpandAllRows: true,
                        expandedRowKeys: expandedKeys,
                        onExpand: (_, record) => {
                            updateExpandedRowKeys({ record });
                        }
                    }}
					id="report-form"
                    ref={tableRef}
                    columns={columns}
                    dataSource={fixedReport}
                    pagination={false}
					loading={isLoading || isFetching}
					bordered
					summary={() => (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell>Total</Table.Summary.Cell>
								<Table.Summary.Cell colSpan={2}>
									<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'end' }}>
										<div>
											{Object.keys(totalData).map(x => {
												return <div>{`${x}: ${formatPeso(totalData[x])}`}</div>;
											})}
										</div>
									</div>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					)}
                />
            </div>
        </div>
	);
}