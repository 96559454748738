import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { notification } from 'antd';
import { fetcher } from '../backendFetcher';

export async function baixarDocumentoContrato(documentId) {
    return (await backendApi.get(`contratos/documentos/${documentId}`)).data;
}

export function useDocumentosContrato(contratoId) {
    const { data, error, mutate } = useSWR(`contratos/documentos/${contratoId}/all`, fetcher);

    return {
        documentos: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useContratos() {
    const { data, error, mutate } = useSWR(`contratos`, fetcher);

    return {
        contratos: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useContrato(contratoId) {
    const { data, error, mutate } = useSWR(`contratos/${contratoId}`, fetcher);

    return {
        contrato: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useContratosCliente(clienteId) {
    const { data, error, mutate } = useSWR(`contratos/cliente/${clienteId}`, fetcher);

    return {
        contratos: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useComentariosContrato(contratoId) {
    const { data, error, mutate } = useSWR(`contratos/comentario/${contratoId}`, fetcher);

    return {
        comentarios: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useEstoquesClientes() {
    const { data, error, mutate } = useSWR(`contratos/cliente/estoques`, fetcher);

    return {
        estoques: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export async function criarContrato(contrato) {
    return JSON.parse((await backendApi.post('contratos', JSON.stringify(contrato))).data);
}

export async function atualizarContrato(contrato) {
    return await backendApi.patch('contratos', JSON.stringify(contrato));
}

export async function deletarComentario(comentarioId) {
    return await backendApi.delete(`contratos/comentario/${comentarioId}`);
}

export async function criarComentario(comentario) {
    return JSON.parse((await backendApi.post('contratos/comentario', JSON.stringify(comentario))).data);
}

export async function criarContratoDocumento(document, description, contratoId) {
    try {
        const formData = new FormData();
        formData.append('arquivo', document);
        formData.append('descricao', description);

        return await backendApi.post(`contratos/documentos/${contratoId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (e) {
        notification.error({
            message: 'Falha',
            description: e,
        });
    }
}

export async function deletarDocumentoContrato(documentId) {
    return await backendApi.delete(`contratos/documentos/${documentId}`);
}

export async function cancelarTransacao(transacaoId, comentario) {
    const obj = {
        transacaoId,
        comentario,
    };

    return await backendApi.post('contratos/cancelar-transacao', JSON.stringify(obj));
}

export async function signContrato(contratoId, compradorId, vendedorId, sentBy) {
    return await backendApi.post(`contratos/sign?contratoId=${contratoId}&vendedorId=${vendedorId}&compradorId=${compradorId}&sentBy=${sentBy}`);
}

export async function rememberContract(contratoId, type) {
    return await backendApi.post(`contratos/sign/remember?contratoId=${contratoId}&parte=${type}`);
}
