import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import DashboardFinanceiro from '../../../components/Financeiro/DashboardFinanceiro/DashboardFinanceiro';
import { Link } from 'react-router-dom';

export default function Lancamentos() {
	return (
		<div >
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Financeiro</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/financeiro/dashboard">Dashboard</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Divider />
			<DashboardFinanceiro></DashboardFinanceiro>
		</div>
	);
}