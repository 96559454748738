import React, { useState, useEffect } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, LoadingOutlined } from '@ant-design/icons';
import { Card, Statistic, Tooltip, Divider, Typography, Spin } from 'antd';
import { formatCurrencyTwo } from '../../Utils/Utils';
import { getProjecaoMensal, getLancamentosAtrasados, getPrincipaisCreditos } from '../../../apis/controllers/data';

const { Text } = Typography;

export default function Projecoes() {
    const [activeTabKey, setActiveTabKey] = useState(1);
    const [dataDebitosAtrasados, setDataDebitos] = useState(0);
    const [dataCreditosAtrasados, setDataCreditos] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingPrincipais, setLoadingPrincipais] = useState(false);
    const [dadosPrincipais, setDadosPrincipais] = useState({});

    useEffect(() => {
        fetchDataAtrasados();
        fetchPrincipaisCreditos();
    }, []);

    async function fetchPrincipaisCreditos() {
        setLoadingPrincipais(true);

        setDadosPrincipais(await getPrincipaisCreditos());

        setLoadingPrincipais(false);
    }

    async function fetchDataAtrasados() {
        setIsLoading(true);

        const resultCreditos = getLancamentosAtrasados('creditos');
        const resultDebitos = await getLancamentosAtrasados('debitos');

        setDataCreditos(await resultCreditos);
        setDataDebitos(resultDebitos);

        setIsLoading(false);
    }

    const tabList = [
        {
            key: 1,
            tab: 'Atual',
        },
        {
            key: 2,
            tab: 'Próximo mês',
        },
        {
            key: 3,
            tab: '3 meses',
        },
        {
            key: 4,
            tab: '6 meses',
        },
        {
            key: 5,
            tab: '12 meses',
        },
    ];

    const styleReceita = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const styleMiniCard = {
        padding: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '130px'
    }

    function ComponentSkeleton(props) {
        return <div style={{ height: '100%', width: '100%' }}>
            <div style={{ display: 'flex', padding: 0, margin: 0, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ padding: '10px' }}>
                    <Statistic
                        title="A Receber"
                        value={formatCurrencyTwo(props?.data?.aReceber)}
                        valueStyle={{ color: '#3f8600', fontSize: '25px' }}
                        prefix={<ArrowDownOutlined />}
                    />
                </div>
                <Divider type='vertical' style={{ height: '50px' }} />
                <div style={{ padding: '10px' }}>
                    <Statistic
                        title="A Pagar"
                        value={formatCurrencyTwo(props?.data?.aPagar)}
                        valueStyle={{ color: '#cf1322', fontSize: '25px' }}
                        prefix={<ArrowUpOutlined />}
                    />
                </div>
                <Divider type='vertical' style={{ height: '50px' }} />
                <div style={{ padding: '10px' }}>
                    <Tooltip title={`Projeção baseada no saldo de ${formatCurrencyTwo(props?.data?.baseCalculo)}`}>
                        <Statistic
                            title="Projeção"
                            value={formatCurrencyTwo(props?.data?.projecao)}
                            valueStyle={{ color: props?.data?.projecao > 0 ? '#3f8600' : '#cf1322', fontSize: '25px' }}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    }

    function ComponentTab(props) {
        const [data, setData] = useState([]);
        const [isLoading, setIsLoading] = useState(null);

        useEffect(() => {
            fetchData();
        }, []);

        async function fetchData() {
            setIsLoading(true);
            const result = await getProjecaoMensal(props.meses);
            setData(result);
            setIsLoading(false);
        }

        return <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: '15px', color: '#067e14' }} />}>
            <ComponentSkeleton data={data} />
        </Spin>
    }

    const contentList = {
        1: <ComponentTab meses={1} />,
        2: <ComponentTab meses={2} />,
        3: <ComponentTab meses={3} />,
        4: <ComponentTab meses={6} />,
        5: <ComponentTab meses={12} />,
    };

    return (
        <div>
            <Card activeTabKey={activeTabKey} onTabChange={setActiveTabKey} tabList={tabList} title="Projeções mensais" bodyStyle={{ padding: 1, height: 100 }} style={{ height: '200px', width: '100%' }}>
                {contentList[activeTabKey]}
            </Card>
            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                <Card title="Recebimentos em atraso" bodyStyle={styleMiniCard} style={{ height: '190px', width: '33%', marginRight: 5 }}>
                    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: '15px', color: '#067e14' }} />}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Statistic
                                value={formatCurrencyTwo(dataCreditosAtrasados)}
                                valueStyle={{ color: '#EED202', fontSize: '30px' }}
                            />
                        </div>
                    </Spin>
                </Card>
                <Card title="Pagamentos em atraso" bodyStyle={styleMiniCard} style={{ height: '190px', width: '33%', marginRight: 5 }}>
                    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: '15px', color: '#067e14' }} />}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Statistic
                                value={formatCurrencyTwo(dataDebitosAtrasados)}
                                valueStyle={{ color: '#EED202', fontSize: '30px' }}
                            />
                        </div>
                    </Spin>
                </Card>
                <Card title="Créditos futuros" bodyStyle={styleMiniCard} style={{ height: '190px', width: '33%' }}>
                    <Spin spinning={loadingPrincipais} indicator={<LoadingOutlined style={{ fontSize: '15px', color: '#067e14' }} />}>
                        <Tooltip title={`Receita esperada nos próximos 12 meses para as principais categorias.`}>
                            <div style={styleReceita}>
                                <Text>Rec. com Soja</Text>
                                <Divider type='vertical' style={{ height: '15px' }} />
                                <Text strong>{formatCurrencyTwo(dadosPrincipais?.soja)}</Text>
                            </div>
                            <div style={styleReceita}>
                                <Text>Rec. com Milho</Text>
                                <Divider type='vertical' style={{ height: '15px' }} />
                                <Text strong>{formatCurrencyTwo(dadosPrincipais?.milho)}</Text>
                            </div>
                            <div style={styleReceita}>
                                <Text>Rec. com Sorgo</Text>
                                <Divider type='vertical' style={{ height: '15px' }} />
                                <Text strong>{formatCurrencyTwo(dadosPrincipais?.sorgo)}</Text>
                            </div>
                        </Tooltip>
                    </Spin>
                </Card>
            </div>
        </div>

    );
}