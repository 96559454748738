import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { atualizarContato } from "../../../apis/backend";
import { Row, Col, Input, Button, Modal, Form, notification } from 'antd';

export default NiceModal.create(({contato}) => {
	const modal = useModal();
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const fieldsRules = [
		{
			required: true
		}
	];

	async function updateContato(e) {
		setIsLoading(true);
		e.id = contato.id;

		try {
			await atualizarContato(e, contato.clienteId);
			notification.success({
				message: 'Sucesso',
				description: 'O contato foi atualizado.'
			});
		} catch (e) {
			console.error(e);
			notification.error({
				message: 'Erro',
				description: 'Falha ao atualizar contato, tente novamente.'
			});
		}

		setIsLoading(false);
		modal.resolve();
		modal.hide();
	}

	return <Modal
		title="Editar Contato"
		centered
		open={modal.visible}
		width="90%"
		destroyOnClose={true}
		closable={false}
		keyboard={false}
		maskClosable={false}
		afterClose={() => modal.remove()}
		footer={[
			<Button onClick={() => modal.hide()} loading={isLoading}>
				Cancelar
			</Button>,
			<Button type="primary" loading={isLoading} form="ContatoForm" key="submit" htmlType="submit">
				Atualizar
			</Button>
		]}
	>
		<Form form={form} name="ContatoForm" onFinish={updateContato} initialValues={contato}>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Nome"
						name={"nome"}
						rules={fieldsRules}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={8}>
					<Form.Item
						label="Celular Primário"
						name={"celularUm"}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Celular Secundário"
						name={"celularDois"}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Telefone Fixo"
						name={"telefoneFixo"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Email"
						name={"email"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	</Modal>
});