import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal, Descriptions, ConfigProvider } from 'antd';
import { formatPeso, getCustomerName } from '../Utils/Utils'
import ptBr from 'antd/lib/locale/pt_BR';

export default NiceModal.create(({ pesagem }) => {
	const modal = useModal();

	function cancelEdit() {
		modal.hide();
	}

	return (
		<ConfigProvider locale={ptBr}>
			<Modal
				title="Visualizar Pesagem"
				centered
				open={modal.visible}
				width="95%"
				destroyOnClose={true}
				closable={false}
				keyboard={false}
				maskClosable={false}
				afterClose={() => modal.remove()}
				footer={[
					<Button key={1} onClick={cancelEdit} >
						Fechar
					</Button>
				]}
			>
				<Descriptions bordered column={24} size="small">
					<Descriptions.Item label="Produto" span={12}>{pesagem.produto}</Descriptions.Item>
					<Descriptions.Item label="Cliente" span={12}>{getCustomerName(pesagem.cliente)}</Descriptions.Item>

					<Descriptions.Item label="Placa" span={4}>{pesagem.placaVeiculo}</Descriptions.Item>
					<Descriptions.Item label="Tipo" span={6}>{pesagem.tipoVeiculo}</Descriptions.Item>
					<Descriptions.Item label="Nome" span={6}>{pesagem.motoristaNome}</Descriptions.Item>
					<Descriptions.Item label="CPF" span={4}>{pesagem.motoristaCPF}</Descriptions.Item>
					<Descriptions.Item label="Celular" span={4}>{pesagem.motoristaCelular}</Descriptions.Item>

					<Descriptions.Item label="Umidade" span={6}>{pesagem.umidade} % {formatPeso(pesagem.umidadePeso)}</Descriptions.Item>
					<Descriptions.Item label="Impureza" span={6}>{pesagem.impureza} % / {formatPeso(pesagem.impurezaPeso)}</Descriptions.Item>
					<Descriptions.Item label="Avariados" span={6}>{pesagem.avariados} % / {formatPeso(pesagem.avariadosPeso)}</Descriptions.Item>
					<Descriptions.Item label="PS" span={6}>{pesagem.prestacaoServico} %</Descriptions.Item>

					<Descriptions.Item label="Peso Entrada" span={8}>{formatPeso(pesagem.pesoEntrada)}</Descriptions.Item>
					<Descriptions.Item label="Peso Saída" span={8}>{formatPeso(pesagem.pesoSaida)}</Descriptions.Item>
					<Descriptions.Item label="Peso Líquido" span={8}>{formatPeso(pesagem.pesoLiquido)}</Descriptions.Item>

					<Descriptions.Item label="Observações" span={24}>{pesagem.observacoes}</Descriptions.Item>

				</Descriptions>
			</Modal>
		</ConfigProvider>
	)
});