import React, { useState } from 'react';
import { Space, Tooltip, Button } from 'antd';
import { useModal } from '@ebay/nice-modal-react';
import { VerticalAlignTopOutlined, EditOutlined, EyeOutlined, BellOutlined } from '@ant-design/icons';
import { buscarRomaneio } from '../../../../apis/backend';
import NiceModal from '@ebay/nice-modal-react';
import RomaneioPrint from '../../../Utils/RomaneioPrint';
import EditarRomaneio from '../EditarRomaneio/EditarRomaneio';
import RomaneioDetalhes from '../../../Utils/RomaneioDetalhes';
import SaidaRomaneio from '../SaidaRomaneio/SaidaRomaneio';
import DeletarRomaneio from '../DeletarRomaneio/DeletarRomaneio';
import ChamadaRomaneio from '../ChamadaRomaneio/ChamadaRomaneio';
import EditarRomaneioComponent from '../../../Utils/EditarRomaneio';

export default function RomaneioActions({ romaneio, onAfterAction }) {
    const editRomaneioModal = useModal(EditarRomaneio);
    const saidaRomaneioModal = useModal(SaidaRomaneio);
    const editarRomaneioModal = useModal(EditarRomaneioComponent);
    const chamadaRomaneioModal = useModal(ChamadaRomaneio);

    const [isLoadingView, setIsLoadingView] = useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const [isLoadingSaida, setIsLoadingSaida] = useState(false);
    const [isLoadingChamada, setIsLoadingChamada] = useState(false);

    async function onClickEdit() {
        setIsLoadingEdit(true);
        const record = await buscarRomaneio(romaneio.id);
        editRomaneioModal.show({ record }).then(() => {
            onAfterAction();
        });
        setIsLoadingEdit(false);
    }

    async function onClickSaida() {
        setIsLoadingSaida(true);
        const record = await buscarRomaneio(romaneio.id);
        saidaRomaneioModal.show({ record }).then(() => {
            onAfterAction();
        });
        setIsLoadingSaida(false);
    }

    async function onClickView() {
        setIsLoadingView(true);
        const data = await buscarRomaneio(romaneio.id);
        NiceModal.show(RomaneioDetalhes, { romaneio: data });
        setIsLoadingView(false);
    }

    async function onClickChamada() {
        setIsLoadingChamada(true);
        const record = await buscarRomaneio(romaneio.id);
        chamadaRomaneioModal.show({ record });
        setIsLoadingChamada(false);
    }

    return (
        <Space size="small">
            <RomaneioPrint id={romaneio.id} />
            <Tooltip title="Visualizar">
                <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={onClickView} loading={isLoadingView} />
            </Tooltip>
            {romaneio.canClose ? (
                <Tooltip title="Saída">
                    <Button size="small" shape="circle" icon={<VerticalAlignTopOutlined />} onClick={onClickSaida} loading={isLoadingSaida} />
                </Tooltip>
            ) : (
                <></>
            )}
            <Tooltip title="Chamada">
                <Button
                    style={{ color: romaneio.pesoEntrada > 0 ? 'green' : 'red' }}
                    size="small"
                    shape="circle"
                    icon={<BellOutlined />}
                    onClick={onClickChamada}
                    loading={isLoadingChamada}
                />
            </Tooltip>
            {romaneio.canEdit ? (
                <Tooltip title="Editar">
                    <Button size="small" shape="circle" icon={<EditOutlined />} onClick={onClickEdit} loading={isLoadingEdit} />
                </Tooltip>
            ) : (
                <></>
            )}
            {!romaneio.canEdit ? (
                <Tooltip title="Editar informações básicas">
                    <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editarRomaneioModal.show({ record: romaneio })} />
                </Tooltip>
            ) : (
                <></>
            )}
            {romaneio.canDelete ? <DeletarRomaneio romaneioId={romaneio.id} onAfterDelete={onAfterAction} /> : <></>}
        </Space>
    );
}
