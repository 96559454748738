import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button, Tooltip } from 'antd';
import { ArrowsAltOutlined, SolutionOutlined} from '@ant-design/icons';

import CriarEntradaSaida from '../CriarEntradaSaida/CriarEntradaSaida';
import CriarPrestacaoServico from '../CriarPrestacaoServico/CriarPrestacaoServico';

export default function Operacoes() {
    const entradaModal = useModal(CriarEntradaSaida);
    const prestacaoModal = useModal(CriarPrestacaoServico);

    function onClickEntrada(){
        entradaModal.show().then(() => {
		});
    }

    function onClickPrestacao(){
        prestacaoModal.show().then(() => {
		});
    }

	return (
		<div>
            <div style={{display: 'flex', justifyContent: 'end'}}>
                <Tooltip title="Nova entrada" >
                    <Button type="primary" style={{margin: '0 5px 0 5px'}} size='large' onClick={onClickEntrada} icon={<ArrowsAltOutlined />}>
                        Entrada / Saída
                    </Button>
                </Tooltip>
                
                <Tooltip title="Nova prestação de serviço">
                    <Button type="primary" size='large' onClick={onClickPrestacao} icon={<SolutionOutlined />}>
                        Prestação de serviço
                    </Button>
                </Tooltip>
            </div>
		</div>
	);
}