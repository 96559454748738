import React from 'react';
import './ListagemProdutos.scoped.css';
import { useProdutos } from '../../../apis/backend';
import { Table, Space } from 'antd';
import { Link  } from 'react-router-dom';

export default function ListagemProdutos() {
	const {produtos, isLoading } = useProdutos();
	const columns = [
		{
			title: 'Descrição',
			dataIndex: 'descricao',
			key: 'descricao',
			ellipsis: true,
			width: '50%',
			render: (_, record) => (<Link to={`/cadastros/produto/${record.id}`}>{record.descricao}</Link>),
		},
		{
			title: 'NCM',
			dataIndex: 'ncmId',
			key: 'ncmId',
			ellipsis: true,
			width: '8%',
		},
		{
			title: 'CEST',
			dataIndex: 'cestId',
			key: 'cestId',
			ellipsis: true,
			width: '8%',
		},
		{
			title: 'Quebra Técnica (Dia %)',
			dataIndex: 'quebraTecnica',
			className: 'column-money',
			key: 'quebraTecnica',
			width: '13%',
		},
	];

	return (
		<div>
			<Space size="middle">
				<Table
					rowKey="id"
					columns={columns}
					dataSource={produtos}
					loading={isLoading}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 7,
						showSizeChanger: false
					}}
				/>
			</Space>
		</div>
	);
}