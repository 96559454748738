import React, {useRef } from 'react';
import { Table, Space, Tooltip, Button } from 'antd';
import { useRomaneiosPendentesGerenciamento } from '../../../../apis/backend';
import { CheckOutlined, PrinterOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';
import AprovarRegistro from '../AprovarRegistro/AprovarRegistro';
import dayjs from 'dayjs';
import { RomaneioImpressao } from '../../../Utils/RomaneioImpressao';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { getCustomerName } from '../../../Utils/Utils';
import DeletarPesagem from '../../../Movimento/Balanca/DeletarRomaneio/DeletarRomaneio';

export default function ListagemEntradaSaida() {
	const {romaneiosPendentes, isLoading, mutate} = useRomaneiosPendentesGerenciamento();
	const aprovacaoModal = useModal(AprovarRegistro);
	const compRef = useRef([]);

	const columns = [
		{
			title: 'Placa',
			dataIndex: 'placaVeiculo',
			key: 'placaVeiculo',
			ellipsis: true
		},
		{
			title: 'Cliente',
			render: (record) => (<Link to={`/cadastros/cliente/${record.clienteId}`}>{getCustomerName(record.cliente)}</Link>),
			key: 'cliente',
			ellipsis: true
		},
		{
			title: 'Produto',
			dataIndex: ['produto', 'descricao']
		},
		{
			title: 'Tipo',
			dataIndex: 'tipo',
			key: 'tipo',
		},
		{
			title: 'Saída',
			render: (value) => <div>{dayjs(value.dataSaida + 'Z').format('DD/MM/YYYY HH:mm')}</div>,
			key: 'tipo',
		},
		{
			title: 'Ações',
			key: 'action',
			render: (_, record, index) => (
				<Space size="middle">
					<Tooltip title="Verificar">
						<Button shape="circle" icon={<CheckOutlined />} onClick={() => onClickVerificar(record)} />
					</Tooltip>
					<Tooltip title="Imprimir">
						<ReactToPrint
							trigger={() => <Button shape="circle" icon={<PrinterOutlined />} />}
							content={() => compRef[index].current}
						/>
					</Tooltip>
					<DeletarPesagem romaneioId={record.id} onAfterDelete={mutate} />
					<div style={{display: 'none'}}>
						<RomaneioImpressao ref={el => compRef[index] = {current: el}} romaneio={record}/>
					</div>
				</Space>
			),
		},
	];

	function onClickVerificar(record){
		aprovacaoModal.show({ record: record }).then(() => {
			mutate();
		});
	}

	return (
		<div>
            <Space size="middle" style={{display: 'flex', flexDirection: 'column'}}>
				<Table rowKey="id" loading={isLoading} columns={columns} dataSource={romaneiosPendentes} pagination={{
					simple: false,
					hideOnSinglePage: true,
					defaultPageSize: 10,
					showSizeChanger: false
				}}/>
				
			</Space>
		</div>
	);
}