import React from 'react';
import { Descriptions } from 'antd';
import { formatDayDate, formatCurrency, formatPeso } from '../../Utils/Utils';

export const ContratoDetalhesPrint = React.forwardRef(({ contrato }, ref) => {
	const labelStyle = {
		fontWeight: 'bold',
		display: 'flex',
		justifyContent: 'center'
	}
	const contentStyle = {
		display: 'flex',
		justifyContent: 'center'
	}
	return (
		<div ref={ref} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
			<div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
				<img src="https://i.imgur.com/hGOeyaT.jpg" className="img-style" style={{width: '30%', margin: '10px'}} />
			</div>
			<Descriptions bordered column={12} layout="vertical" size={"middle"}>
				<Descriptions.Item label="Comprador" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{contrato?.comprador?.nomeCompleto}</Descriptions.Item>
				<Descriptions.Item label="Vendedor" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{contrato?.vendedor?.nomeCompleto}</Descriptions.Item>
				
				<Descriptions.Item label="Produto" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.produto?.descricao}</Descriptions.Item>
				<Descriptions.Item label="Quantidade Total" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{formatPeso(contrato?.quantidadeTotal)}</Descriptions.Item>
				<Descriptions.Item label="Preço R$/KG" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{formatCurrency(contrato?.preco)}</Descriptions.Item>

				<Descriptions.Item label="Número de Contrato" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.numeroDeContrato}</Descriptions.Item>
				<Descriptions.Item label="Data de Pagamento" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{formatDayDate(contrato?.dataDePagamento, true)}</Descriptions.Item>
				<Descriptions.Item label="Prazo de Embarque" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{formatDayDate(contrato?.prazoDeEmbarque, true)}</Descriptions.Item>
				
				<Descriptions.Item label="Observações" labelStyle={labelStyle} contentStyle={contentStyle} span={12}>{contrato?.observacoes || 'N/A'}</Descriptions.Item>

				<Descriptions.Item label="Transportador" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{contrato?.transportador || 'N/A'}</Descriptions.Item>
				<Descriptions.Item label="Frete" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{formatCurrency(contrato?.frete)}</Descriptions.Item>

				<Descriptions.Item label="Corretor" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{contrato?.corretor || 'N/A'}</Descriptions.Item>
				<Descriptions.Item label="Comissão" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{formatCurrency(contrato?.comissao)}</Descriptions.Item>

				<Descriptions.Item label="Banco" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.bancoConta || 'N/A'}</Descriptions.Item>
				<Descriptions.Item label="C.c" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.contaCorrente || 'N/A'}</Descriptions.Item>
				<Descriptions.Item label="Agência" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.agencia || 'N/A'}</Descriptions.Item>

				<Descriptions.Item label="CNPJ" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.cnpjConta || 'N/A'}</Descriptions.Item>
				<Descriptions.Item label="CPF" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.cpfConta || 'N/A'}</Descriptions.Item>
				<Descriptions.Item label="RG" labelStyle={labelStyle} contentStyle={contentStyle} span={4}>{contrato?.rgConta || 'N/A'}</Descriptions.Item>

				<Descriptions.Item label="Quantidade Embarcada" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{formatPeso(contrato?.quantidadeEmbarcada)}</Descriptions.Item>
				<Descriptions.Item label="Saldo a Embarcar" labelStyle={labelStyle} contentStyle={contentStyle} span={6}>{formatPeso(contrato?.saldoAEmbarcar)}</Descriptions.Item>

			</Descriptions>
			<div style={{ display: 'flex', position: 'fixed', bottom: '30px', justifyContent: 'space-between', width: '100%' }}>
				<div style={{ width: '100%', paddingLeft: '5%', textAlign: 'center' }}>
					<hr style={{width: '80%'}}/>
					{contrato?.comprador?.nomeCompleto}
				</div>
				<div style={{ width: '100%', paddingRight: '5%', textAlign: 'center'}}>
					<hr style={{ width: '80%' }} />
					{contrato?.vendedor?.nomeCompleto}
				</div>
			</div>
		</div>
	);
});