export default function RelatorioLancamentosV2() {
    return (
        <div>
            <iframe
                title="Relatório de Lançamentos"
                src="https://metabase.limaetarifa.com.br/public/dashboard/f94a836a-84e2-4bcb-86a7-56762b930b03"
                frameborder="0"
                width="100%"
                height="1100px"
                allowtransparency
            ></iframe>
        </div>
    );
}
