import React, { useEffect, useState } from 'react';
import { Breadcrumb, Divider, Spin, Button, Tabs } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from "react-router-dom";
import { useContrato } from '../../../apis/backend';
import { toUserTime } from '../../Utils/Utils';
import NiceModal from '@ebay/nice-modal-react';
import dayjs from 'dayjs';
import InformacoesGeraisContrato from '../InformacoesGeraisContrato/InformacoesGeraisContrato';
import DocumentosContrato from '../DocumentosContrato/DocumentosContrato';
import ImprimirContrato from '../ImprimirContrato/ImprimirContrato';
import AssinaturaContrato from '../AssinaturaContrato/AssinaturaContrato';

export default function DetalheContrato(props) {
    const navigate = useNavigate();
    const { contrato, isLoading, mutate } = useContrato(props.id);
    const [pageLoading, setPageLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [isContratoLoaded, setIsContratoLoaded] = useState(false);

    function onClickAssinatura() {
		NiceModal.show(AssinaturaContrato, { contrato });
	}

	useEffect(() => {
		if (contrato && 'object' == typeof contrato) {
			if (!dayjs.isDayjs(contrato.dataDePagamento))
				contrato.dataDePagamento = toUserTime(contrato?.dataDePagamento);
			
			if (!dayjs.isDayjs(contrato.prazoDeEmbarque))
            	contrato.prazoDeEmbarque = toUserTime(contrato?.prazoDeEmbarque);
            
            if (!dayjs.isDayjs(contrato.dataInicioEntrega))
				contrato.dataInicioEntrega = toUserTime(contrato?.dataInicioEntrega);
        }
		setIsContratoLoaded(!!contrato);
		setPageLoading(isLoading);
	}, [contrato, isLoading]);

    useEffect(() => {
        if(!isContratoLoaded)
            return;

        const params = new URLSearchParams(window.location.search)
        const assinatura = params.get("assinatura")

        if(assinatura === "true")
            onClickAssinatura();

        window.history.pushState({}, document.title, window.location.pathname);
    }, [isContratoLoaded]);

    const items = [
        {
            key: '1',
            label: 'Informações Gerais',
            children: isContratoLoaded ? <InformacoesGeraisContrato mutate={mutate} contrato={contrato} isLoading={setPageLoading} /> : <></>
        },
        {
            key: '2',
            label: 'Documentos',
            children: isContratoLoaded ? <DocumentosContrato contrato={contrato} isLoading={setPageLoading} /> : <></>
        }
    ];

    return (
        <div>
            <Spin tip="Carregando..." spinning={pageLoading}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Cadastros</Breadcrumb.Item>
                        <Breadcrumb.Item>
                        <Link to={`/cadastros/contrato/`}>Contrato</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/cadastros/contrato/${props.id}`}>{contrato?.numeroDeContrato}</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Divider />
				<ProPageHeader
                	style={{paddingLeft: 0, paddingRight: 0}}
                    title={contrato?.numeroDeContrato}
                    className="site-page-header"
                    onBack={() => navigate("/cadastros/contrato")}
					extra={[
                        <Button type="primary" onClick={onClickAssinatura}>Assinatura</Button>,
						<ImprimirContrato contrato={contrato} key={1}/>,
						<Button type="primary" form="contratoForm" htmlType="submit" disabled={activeTab !== "1"} key={2}>Salvar</Button>,
                    ]}
                >
				</ProPageHeader>
                <div>
                    <Tabs defaultActiveKey="1" onChange={setActiveTab} items={items} />
                </div>
            </Spin>
        </div>
    )
}