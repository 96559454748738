import React, { useState } from 'react';
import { useComentariosContrato, criarComentario, deletarComentario } from '../../../apis/backend';
import { List, Tooltip, Form, Input, Button, notification } from 'antd';
import { Comment } from '@ant-design/compatible';
import { useAuth0 } from "@auth0/auth0-react";
import "dayjs/locale/pt-br";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relative from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relative);

const { TextArea } = Input;

export default function ComentariosContrato(props) {
    const [form] = Form.useForm();
    const { user } = useAuth0();
    const [contratoId] = useState(props.contratoId);
    const {comentarios, isLoading, mutate} = useComentariosContrato(contratoId);
    const [loadingButton, isLoadingButton] = useState(false);
    const fieldsRules = [
        {
            required: true
        }
    ];

    const Actions = (item) => {
        return [<>
            <span key="comment-basic-reply-to" onClick={() => deleteComentario(item.id)}>Deletar</span>
        </>]
    };

    async function deleteComentario(id){
        isLoadingButton(true);

        try{
            await deletarComentario(id);
            mutate();
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao deletar comentário.'
            });
        }catch(e){
            notification.error({
                message: 'Erro',
                description:
                  e,
            });
        }

		isLoadingButton(false);
    }

    async function createComentario(e){
        isLoadingButton(true);

        e.contratoId = contratoId;
        e.autor = user.name;

        try{
            await criarComentario(e);
            form.resetFields();
            mutate();
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao criar comentário.'
            });
        }catch(e){
            notification.error({
                message: 'Erro',
                description:
                  e,
            });
        }

		isLoadingButton(false);
    }

    const Editor = () => (
        <>
            <Form form={form} name="comentarioForm" onFinish={createComentario}>
                <Form.Item name={"comentario"} rules={fieldsRules}> 
                    <TextArea rows={2}  />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" loading={loadingButton} type="primary">
                        Adicionar
                    </Button>
                </Form.Item>
            </Form>
        </>
    );

    const DateComment = (item) => {
		dayjs.locale('pt-br');
		const momentDate = dayjs(item.createdDate).utc('Z');

        return <>
            <Tooltip title={momentDate.local().format('DD/MM/YYYY HH:mm')}>
                <span>
                    { momentDate.toNow(dayjs().utc(), false) }
                </span>
            </Tooltip>
        </>
    };

    return (
		<div>
            <Comment
                content={
                    <Editor/>
                }
            />
			<List
                rowKey="id"
                itemLayout="horizontal"
                dataSource={comentarios}
                loading={isLoading}
                style={{marginTop: '-50px'}}
                pagination={{
                    simple: false,
                    hideOnSinglePage: true,
                    defaultPageSize: 3,
                    showSizeChanger: false
                }}
                renderItem={item => (
                    <List.Item id={item.id}>
                        <Comment
                            actions={Actions(item)}
                            author={item.autor}
                            content={
                                <p>
                                    {item.comentario}
                                </p>
                            }
                            datetime={DateComment(item)}
                        />
                    </List.Item>
                )}
            />
		</div>
	);
}