import React, {useState, useEffect, useRef} from 'react';
import { Select, DatePicker, Button, Table, Typography } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';
import 'dayjs/locale/pt-br'
import { formatPeso, formatDate, getCustomerName, formatCurrency, filterSelect } from '../Utils/Utils';
import locale from 'antd/lib/locale/pt_BR';
import { useRelatorioContratoMovimentacoes, useClientesRomaneio, useProdutosRomaneio, useOperacoesFiscaisGerenciamento } from '../../apis/backend';

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function RelatorioMovimentacoesContrato() {
    const { clientes : clientesDb } = useClientesRomaneio();
	const { produtos: produtosDb } = useProdutosRomaneio();
    const { opFiscais : operacoesDb } = useOperacoesFiscaisGerenciamento();
    const [clientes, setClientes] = useState([]);
    const [produto, setProduto] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
	const [opFiscal, setOpFiscal] = useState([]);
	const [isPrinting, setIsPrinting] = useState(false);
    const [fixedReport, setFixedReport] = useState([]);
	const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
    const {relatorio, isLoading : isFetching} = useRelatorioContratoMovimentacoes(range[0].startOf('day').toISOString(), range[1].endOf('day').toISOString(), clientes, produto, opFiscal);
	const [isLoading, setIsLoading] = useState([]);
	const tableRef = useRef();
    const columns = [
        { 
            title: 'Contrato', 
            key: 'idContrato',
            render: (fullData) => {
                return (<Link target="_blank" to={`/cadastros/contrato/${fullData.key}`}>{fullData.numeroContrato}</Link>)
            },
			defaultExpandAllRows: true,
			align: 'center',
			width: 150,
        },
        {
            title: "Operações",
            children: [
                {
                    title: "Operação Fiscal",
					render: valor => <GetText isCancelado={valor?.isCancelled}>
						{valor?.operacao?.operacaoFiscal?.nome}
					</GetText>,
					key: 2,
					align: 'center',
                    width: 170,
                },
                {
                    title: "Peso Embarcado",
                    key: 3,
					render: valor => <GetText isCancelado={valor?.isCancelled}>
						{!!valor?.operacao?.pesoEmbarcado ? formatPeso(valor.operacao?.pesoEmbarcado) : ''}
					</GetText>,
					align: 'center'
				},
				{
					title: "Preço",
					render: val => <GetText isCancelado={val?.isCancelled}>
						{val?.operacao?.contrato ? formatCurrency(val?.operacao?.contrato?.preco) : ''}
					</GetText>,
					key: 4,
					align: 'center'
				},
                {
                    title: "Comprador",
					render: valor => <GetText isCancelado={valor?.isCancelled}>
						<Link target="_blank" to={`/cadastros/cliente/${valor?.operacao?.contrato?.compradorId}`}>{getCustomerName(valor?.operacao?.contrato?.comprador)}</Link>
					</GetText>,
					key: 5,
					width: 170,
					align: 'center'
                },
                {
                    title: "Vendedor",
					render: valor => <GetText isCancelado={valor?.isCancelled}>
						<Link target="_blank" to={`/cadastros/cliente/${valor?.operacao?.contrato?.vendedorId}`}>{getCustomerName(valor?.operacao?.contrato?.vendedor)}</Link>
					</GetText>,
					key: 6,
					width: 170,
					align: 'center'
                },
                {
                    title: "Data",
                    key: 7,
					render: (val) => <GetText isCancelado={val?.isCancelled}>{!!val?.operacao?.createdDate ? `${formatDate(val?.operacao?.createdDate)}` : ''}</GetText>,
					align: 'center'
                }
            ]
        }
	];
	const isCanceladoStyle = {
		textDecoration: 'line-through',
		textDecorationThickness: '0.2rem'
	}

    const updateExpandedRowKeys = ({ record }) => {
        const rowKey = record.key;
        const isExpanded = expandedKeys.find(key => key === rowKey);
        let newExpandedRowKeys = [];
        if (isExpanded) {
            newExpandedRowKeys = expandedKeys.reduce((acc, key) => {
            if (key !== rowKey) acc.push(key);
            return acc;
            }, []);
        } else {
            newExpandedRowKeys = expandedKeys;
            newExpandedRowKeys.push(rowKey);
        }
        setExpandedKeys(newExpandedRowKeys);
    };

    useEffect(() => {
		if (Array.isArray(relatorio)) {
            setExpandedKeys([]);
			setIsLoading(true);
            let exKeys = [];

            relatorio.forEach(x => {
                exKeys.push(x.key);
            });

            setExpandedKeys(exKeys);

            setFixedReport(relatorio);
			setIsLoading(false);
        }
    }, [relatorio]);
	
	function getHeader() {
		const prod = produto.map(prod => ' ' + (produtosDb.find(x => x.value === prod).label));
		return `Produto(s):${produto.length > 0 ? prod : ' Todos'}, Gerado em: ${new Date().toLocaleDateString('pt-br')}`;
	}

	function GetText({children, isCancelado}) {
		return <Text style={isCancelado ? isCanceladoStyle : {}}>
			{children}
		</Text>
	}

	return (
        <div>
            <div style={{display: "flex"}}>
                <RangePicker
                    allowClear={false}
                    locale={locale}
                    format={"DD/MM/YYYY"}
					defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]} 
					style={{ width: '25%', height: '100%' }} 
                    size='medium'
                    onChange={setRange}/>
                <Select
                    size='medium'
                    placeholder="Cliente"
                    mode="multiple"
                    showSearch
                    allowClear
					style={{ width: '25%', paddingLeft: '5px', paddingRight: '5px', height: '100%' }}
                    onChange={setClientes}
					filterOption={filterSelect}
                    options={clientesDb}
                />
                <Select
                    size='medium'
                    placeholder="Produto"
                    allowClear
                    mode="multiple"
					style={{ width: '25%', paddingRight: '5px', height: '100%' }}
                    onChange={setProduto}
					filterOption={filterSelect}
                    options={produtosDb}
                />
                <Select
                    size='medium'
                    placeholder="Operação Fiscal"
                    allowClear
                    mode="multiple"
					style={{ width: '25%', paddingRight: '5px', height: '100%' }}
                    onChange={setOpFiscal}
					filterOption={filterSelect}
                    options={operacoesDb}
				/>
				<ReactToPrint
					onBeforeGetContent={() => {
						return new Promise((resolve) => {
							setIsPrinting(true);
							resolve();
						});
					}}
					onAfterPrint={() => setIsPrinting(false)}
					trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio} />}
					content={() => tableRef.current}
					pageStyle={"padding: 1px;"}
				/>
            </div>
            <div style={{marginTop: '10px'}}>
                <Table
                    expandable={{
                        defaultExpandAllRows: true,
                        expandedRowKeys: expandedKeys,
                        onExpand: (record) => {
                            updateExpandedRowKeys({ record });
                        }
                    }}
                    id="report-form" 
                    ref={tableRef}
					columns={columns}
					title={() => isPrinting ? getHeader() : null}
                    dataSource={fixedReport}
                    pagination={false}
					loading={isLoading || isFetching}
                    bordered
                />
            </div>
        </div>
	);
}