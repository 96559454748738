import backendApi from '../backendAxiosClient';

export async function criarTransacao(transacao) {
    return await backendApi.post(`/transacao`, JSON.stringify(transacao));
}

export async function patchLancamentoRelations(transacaoId, dtos) {
    return await backendApi.patch(`/transacao/relate?transactionId=${transacaoId}`, JSON.stringify(dtos));
}

export async function deleteTransacao(transacaoId) {
    return await backendApi.delete(`/transacao?transactionId=${transacaoId}`, JSON.stringify({}));
}

export async function disponivelCheque(transacaoId) {
    return await backendApi.post(`/transacao/cheque/disponivel?transactionId=${transacaoId}`);
}

export async function naoRelacionavel(transacaoId) {
    return await backendApi.post(`/transacao/relacionavel?transactionId=${transacaoId}`);
}
