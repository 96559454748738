import React, { useState } from 'react';
import { useGraphQL } from '../../../../apis/backendGraphQL';
import { getPesagemDetails } from '../../../../apis/queries/graphQueries';
import { Space, Tooltip, Button } from 'antd';
import { useModal } from '@ebay/nice-modal-react';
import { EditOutlined, EyeOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';

import NiceModal from '@ebay/nice-modal-react';
import PesagemPrint from '../../../Utils/PesagemPrint';
import PesagemDetalhes from '../../../Utils/PesagemDetalhes';
import DeletarPesagem from '../DeletarSimplesPesagem/DeletarSimplesPesagem';
import EditarPesagem from '../EditarPesagem/EditarPesagem';
import SaidaPesagem from '../SaidaPesagem/SaidaPesagem';

export default function PesagemActions({ pesagem, onAfterAction }) {
	const { data, mutate } = useGraphQL(getPesagemDetails, { pesagemId: pesagem.id });

	const editPesagemModal = useModal(EditarPesagem);
	const saidaPesagemModal = useModal(SaidaPesagem);

	const [isLoadingView, setIsLoadingView] = useState(false);
	const [isLoadingEdit, setIsLoadingEdit] = useState(false);

	async function onClickView() {
		setIsLoadingView(true);
		await mutate();
		NiceModal.show(PesagemDetalhes, { pesagem: data.pesagens[0] });
		setIsLoadingView(false);
	}

	async function onClickEdit() {
		setIsLoadingEdit(true);
		await mutate();
		editPesagemModal.show({ record: data.pesagens[0] }).then(() => {
			onAfterAction();
		});
		setIsLoadingEdit(false);
	}

	async function onClickSaida() {
		setIsLoadingEdit(true);
		await mutate();
		saidaPesagemModal.show({ record: data.pesagens[0] }).then(() => {
			onAfterAction();
		});
		setIsLoadingEdit(false);
	}

	return (
		<Space size="small">
			<PesagemPrint id={pesagem?.id} />
			<Tooltip title="Visualizar">
				<Button shape="circle" icon={<EyeOutlined />} onClick={onClickView} loading={isLoadingView} />
			</Tooltip>
			<Tooltip title="Editar">
				<Button shape="circle" icon={<EditOutlined />} onClick={onClickEdit} loading={isLoadingEdit} />
			</Tooltip>
			{
				!pesagem.pesoSaida ? 
					<Tooltip title="Saída">
						<Button shape="circle" icon={<VerticalAlignTopOutlined />} onClick={onClickSaida} />
					</Tooltip>
					: <></>
			}
			<DeletarPesagem pesagemId={pesagem?.id} onAfterDelete={onAfterAction} />
		</Space>
	);
}