import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export function useDescargas() {
	const { data, error, mutate } = useSWR(`pontos-descarga`, fetcher);

	return {
		descargas: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export async function criarDescarga(descarga) {
	return await backendApi.post('pontos-descarga', JSON.stringify(descarga));
}

export async function atualizarDescarga(descarga) {
	return await backendApi.patch(`pontos-descarga`, JSON.stringify(descarga));
}