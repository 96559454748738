import React, { useState } from 'react';
import { Table, Button, Typography, Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { getTransacoesEstoque } from '../../../apis/queries/graphQueries';
import { formatPeso, getCustomerName, formatDateWithTime } from '../../Utils/Utils';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { useModal } from '@ebay/nice-modal-react';
import CancelarTransacao from '../CancelarTransacao/CancelarTransacao';
import DetalheTransacao from '../DetalheTransacao/DetalheTransacao';
import { EyeOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
const { Text } = Typography;

export default function TransacoesContrato(props) {
	const [contratoId] = useState(props.contratoId);
	const modalCancelar = useModal(CancelarTransacao);
	const modalDetalhe = useModal(DetalheTransacao);
	const { data, isLoading, mutate } = useGraphQL(getTransacoesEstoque, { contratoId });
	const isCanceladoStyle = {
		textDecoration: 'line-through',
		textDecorationThickness: '0.2rem'
	}
	const columns = [
		{
			title: "Operação Fiscal",
			key: 2,
			align: 'center',
			render: (data) => {
				return (<Link style={data.isCancelado ? isCanceladoStyle : {}} target="_blank" to={`/cadastros/operacao-fiscal/${data.operacao.operacaoFiscal.id}`}>
					{data.isCancelado ? <Tooltip title="Operação Cancelada"><WarningOutlined style={{ fontSize: '20px', color: '#ffcc00', marginRight: '10px' }} /></Tooltip> : <></>} {data.operacao.operacaoFiscal.nome}
				</Link>)
			},
		},
		{
			title: "Cliente",
			key: 3,
			align: 'center',
			render: (data) => {
				return (<Link style={data.isCancelado ? isCanceladoStyle : {}} target="_blank" to={`/cadastros/cliente/${data.estoque.cliente.id}`}>{getCustomerName(data.estoque.cliente)}</Link>)
			},
		},
		{
			title: "Diferença",
			render: val => {
				const peso = formatPeso(Math.abs(val.novoValor - val.valorAnterior));
				return <Text style={val.isCancelado ? isCanceladoStyle : {}}>{val.isAdicao ? "+" : "-"}{peso}</Text>;
			},
			key: 4,
			align: 'center'
		},
		{
			title: "Data",
			dataIndex: "createdDate",
			key: 7,
			render: (val, rec) => !!val ? <Text style={rec.isCancelado ? isCanceladoStyle : {}}>{formatDateWithTime(val)}</Text> : '',
			align: 'center'
		},
		{
			title: "Ações",
			key: 8,
			render: (rec) => <AcoesTransacao rec={rec}/>,
			align: 'center'
		}
	];
	
	const AcoesTransacao = ({ rec }) => {
		return (
			<Space size="small">
				<Tooltip title="Cancelar">
					<Button shape="circle" icon={<CloseCircleOutlined />} onClick={() => modalCancelar.show({ rec }).then(() => mutate())} disabled={rec.isCancelado} />
				</Tooltip>
				<Tooltip title="Detalhes">
					<Button shape="circle" icon={<EyeOutlined />} onClick={() => modalDetalhe.show({ rec }).then(() => mutate())} />
				</Tooltip>
			</Space>
		)
	};

	return (
		<div>
			<Table
				rowKey="id"
				columns={columns}
				dataSource={data?.estoquesTransacoes}
				pagination={false}
				loading={isLoading}
				bordered
			/>
		</div>
	);
}