import React, { useState, useEffect, useRef } from 'react';
import { Select, DatePicker, Button, Table } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import locale from 'antd/lib/locale/pt_BR';
import { useRelatorioFretes, useClientesRomaneio, useProdutosRomaneio } from '../../apis/backend';
import ReactToPrint from 'react-to-print';
import { formatPeso, formatDate, filterSelect } from '../Utils/Utils';
import { useGraphQL } from '../../apis/backendGraphQL';
import { getMotoristas } from '../../apis/queries/graphQueries';

const { RangePicker } = DatePicker;

export default function RelatorioFrete() {
	const { clientes: clientesDb } = useClientesRomaneio();
	const { produtos: produtosDb } = useProdutosRomaneio();
	const { data: motoristasDb } = useGraphQL(getMotoristas);
	const [clientes, setClientes] = useState([]);
	const [somatoriaBruto, setSomatoriaBruto] = useState('');
	const [motorista, setMotorista] = useState([]);
	const [produto, setProduto] = useState(1);
	const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
	const { relatorio, isLoading } = useRelatorioFretes(range[0].startOf('day').toISOString(), range[1].endOf('day').toISOString(), clientes, produto, motorista);
	const [fixedReport, setFixedReport] = useState([]);
	const tableRef = useRef();
	const columns = [
		{
			title: "Produto",
			key: 1,
			dataIndex: ['produto', 'descricao'],
			align: 'center'
		},
		{
			title: "Data",
			key: 2,
			dataIndex: 'createdDate',
			render: (val) => formatDate(val),
			align: 'center'
		},
		{
			title: "Tipo",
			key: 3,
			dataIndex: 'tipo',
			align: 'center'
		},
		{
			title: "Peso Bruto",
			key: 4,
			render: valor => formatPeso(valor.pesoBruto),
			align: 'center'
		},
		{
			title: "Placa",
			key: 5,
			dataIndex: 'placaVeiculo',
			align: 'center'
		},
		{
			title: "Motorista",
			key: 6,
			dataIndex: 'motoristaNome',
			align: 'center'
		}
	];

	useEffect(() => {
		let bruto = 0;

		if (Array.isArray(relatorio)) {
			setFixedReport(relatorio);
			relatorio.forEach(x => {
				bruto += x.pesoBruto;
			});
		}

		setSomatoriaBruto(formatPeso(bruto));
	}, [relatorio]);

	return (
		<div>
			<div style={{ display: "flex" }}>
				<RangePicker
					allowClear={false}
					locale={locale}
					format={"DD/MM/YYYY"}
					defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
					style={{ width: '20%', height: '100%' }}
					size='medium'
					onChange={setRange} />
				<Select
					size='medium'
					placeholder="Cliente"
					showSearch
					style={{ width: '20%', height: '100%', paddingLeft: '5px' }}
					onChange={setClientes}
					filterOption={filterSelect}
					options={clientesDb}
					mode="multiple"
					defaultValue={[1]}
				/>
				<Select
					size='medium'
					placeholder="Motorista"
					showSearch
					allowClear={true}
					style={{ width: '20%', height: '100%', paddingLeft: '5px', paddingRight: '5px' }}
					onChange={setMotorista}
					filterOption={filterSelect}
					mode="multiple"
					options={motoristasDb?.motoristas}
				/>
				<Select
					size='medium'
					placeholder="Produto"
					mode="multiple"
					allowClear={false}
					style={{ width: '40%', height: '100%', paddingRight: '5px' }}
					onChange={setProduto}
					defaultValue={[1]}
					filterOption={filterSelect}
					options={produtosDb}
				/>
				<ReactToPrint
					trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio} />}
					content={() => tableRef.current}
					pageStyle={"*{font-size: 10px}"}
				/>
			</div>
			<div style={{ marginTop: '10px' }}>
				<Table
					id="report-form"
					ref={tableRef}
					columns={columns}
					dataSource={fixedReport}
					pagination={false}
					loading={isLoading}
					bordered
					summary={() => (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell>Total</Table.Summary.Cell>
								<Table.Summary.Cell colSpan={5}>
									<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'end' }}>
										<div>
											<div>{somatoriaBruto}</div>
										</div>
									</div>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					)}
				/>
			</div>
		</div>
	);
}