import React from 'react';
import { Table } from 'antd';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { Link } from 'react-router-dom';
import { getTransacoesRelacionadas } from '../../../apis/queries/graphQueries';
import { formatCurrencyTwo, formatDayDate } from '../../Utils/Utils';

export default function LancamentoTransacoesRelacionadas(props) {
    const { data, isLoading } = useGraphQL(getTransacoesRelacionadas, { lancamentoId: parseInt(props.lancamentoId) });

    console.log(data);

    const columns = [
        {
            title: 'Caixa',
            key: 'caixaTransaction',
            ellipsis: true,
            dataIndex: ['caixaTransaction', 'caixa'],
            render: (data) => (
                <Link to={`/financeiro/caixa/${data?.id}`} target="_blank">
                    {data?.nome}
                </Link>
            ),
        },
        {
            title: 'Data',
            key: 'data',
            ellipsis: true,
            dataIndex: 'createdDate',
            render: (data) => formatDayDate(data, true),
        },
        {
            title: 'Descrição',
            key: 'descricao',
            dataIndex: 'descricao',
            ellipsis: true,
        },
        {
            title: 'Valor',
            key: 'valor',
            dataIndex: 'valor',
            render: (value) => formatCurrencyTwo(value),
            ellipsis: true,
        },
    ];

    return (
        <div>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data?.transacaoLancamentoRelations}
                pagination={{
                    simple: false,
                    hideOnSinglePage: true,
                    defaultPageSize: 5,
                    showSizeChanger: false,
                }}
                loading={isLoading}
                bordered
            />
        </div>
    );
}
