import { gql } from 'graphql-request';

export const getTransacoesEstoque = gql`
    query GetTransacoesEstoque($contratoId: Int!) {
        estoquesTransacoes(where: { operacao: { contratoId: { eq: $contratoId } } }) {
            operacaoId
            id
            createdDate
            isAdicao
            valorAnterior
            novoValor
            motivo
            comentario
            isCancelado
            parentTransacao {
                operacaoId
                id
                createdDate
                isAdicao
                valorAnterior
                novoValor
                motivo
                comentario
                isCancelado
                estoque {
                    cliente {
                        id
                        tipoPessoa
                        nome
                        razaoSocial
                    }
                    produto {
                        descricao
                        id
                    }
                }
            }
            estoque {
                cliente {
                    id
                    tipoPessoa
                    nome
                    razaoSocial
                }
                produto {
                    descricao
                    id
                }
            }
            operacao {
                id
                operacaoFiscal {
                    nome
                    id
                }
                contrato {
                    preco
                }
            }
        }
    }
`;

export const getContratosCliente = gql`
    query GetContratosCliente($clienteId: Int!) {
        contratos(where: { or: [{ vendedorId: { eq: $clienteId } }, { compradorId: { eq: $clienteId } }] }, order: { createdDate: DESC }) {
            numeroDeContrato
            id
            produto {
                descricao
            }
            quantidadeTotal
            saldoAEmbarcar
        }
    }
`;

export const getRomaneiosPendentes = gql`
    query GetRomaneiosPendentes {
        romaneios(where: { dataSaida: { lt: "2010-10-10" } }, order: { createdDate: ASC }) {
            id
            placaVeiculo
            tipo
            moega
            motoristaNome
            motoristaCelular
            clienteId
            pesoEntrada
            dataSaida
            validado
            canDelete
            umidade
            canClose
            canEdit
            cliente {
                nome
                razaoSocial
                tipoPessoa
            }
            produto {
                descricao
            }
            moega
        }
    }
`;

export const getSelectFornecedores = gql`
    query getSelectFornecedores {
        clientes(where: { or: [{ relacionamento: { eq: "Ambos" } }, { relacionamento: { eq: "Fornecedor" } }] }) {
            value: id
            label: nomeCompleto
            razaoSocial
            tipoPessoa
            nome
        }
    }
`;

export const getSelectClientes = gql`
    query getSelectClientes {
        clientes {
            value: id
            label: nomeCompleto
            razaoSocial
            tipoPessoa
            nome
        }
    }
`;

export const getNotifications = gql`
    query getNotificacoes {
        notificacoes(order: { createdDate: DESC }) {
            id
            createdBy
            titulo
            descricao
            createdDate
        }
    }
`;

export const getPlacas = gql`
    query getPlacas {
        placas {
            label
            value
            fullData {
                tipoVeiculo
                motoristaNome
                motoristaCpf
            }
        }
    }
`;

export const getPesagens = gql`
    query getPesagens {
        pesagens(order: { createdDate: DESC }) {
            id
            placaVeiculo
            motoristaNome
            motoristaCPF
            clienteId
            pesoEntrada
            createdDate
            pesoSaida
            pesoLiquido
            avariados
            cliente {
                id
                nome
                razaoSocial
                tipoPessoa
            }
        }
    }
`;

export const getPesagemDetails = gql`
    query getPesagemDetails($pesagemId: Int!) {
        pesagens(where: { id: { eq: $pesagemId } }) {
            id
            placaVeiculo
            motoristaNome
            tipoVeiculo
            motoristaNome
            motoristaCPF
            clienteId
            cliente {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
            pesoEntrada
            pesoSaida
            pesoLiquido
            umidade
            avariados
            prestacaoServico
            impureza
            observacoes
            produto
            impurezaPeso
            avariadosPeso
            prestacaoServicoPeso
        }
    }
`;

export const getMotoristas = gql`
    query getMotoristas {
        motoristas {
            label
            value
        }
    }
`;

export const getContratos = gql`
    query getContratos {
        contratos {
            id
            numeroDeContrato
            dataDePagamento
            prazoDeEmbarque
            contratoExterno
            quantidadeTotal
            quantidadeEmbarcada
            saldoAEmbarcar
            comprador {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
            vendedor {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
        }
    }
`;

export const getCaixas = gql`
    query getCaixas {
        caixas {
            id
            banco
            codigoBancario
            agencia
            contaCorrente
            titular
            cpf
            cnpj
            rg
            nome
            isDesativada
            saldoAtual
            tipo
        }
    }
`;

export const getDetalheCaixa = gql`
    query getDetalheCaixa($caixaId: Int!) {
        caixas(where: { id: { eq: $caixaId } }) {
            id
            banco
            codigoBancario
            agencia
            contaCorrente
            titular
            cpf
            cnpj
            rg
            nome
            isDesativada
            saldoAtual
            tipo
            descricao
        }
    }
`;

export const getDetalheLancamento = gql`
    query getDetalheLancamento($lancamentoId: Int!) {
        lancamentos(where: { id: { eq: $lancamentoId } }) {
            id
            tipo
            categoria
            nf
            quantidade
            valorTotal
            juros
            multas
            acrescimos
            descontos
            descricao
            unidade
            status
            caixaId
            vencimento
            formaDePagamento
            clienteId
            fornecedorId
            contratoId
            valor
            cheque
            relatedValue
            missingRelatedValue
        }
    }
`;

export const getLancamentosCredito = gql`
    query getLancamentosCredito {
        lancamentos(order: { vencimento: ASC }, where: { tipo: { eq: "Crédito" } }) {
            id
            descricao
            status
            valor
            valorTotal
            juros
            multas
            acrescimos
            descontos
            tipo
            vencimento
            categoria
            relatedValue
            missingRelatedValue
            cliente {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
            fornecedor {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
            contrato {
                id
                numeroDeContrato
            }
        }
    }
`;

export const getLancamentosDebito = gql`
    query getLancamentosDebito {
        lancamentos(order: { vencimento: ASC }, where: { tipo: { eq: "Débito" } }) {
            id
            descricao
            status
            valor
            valorTotal
            juros
            nf
            multas
            acrescimos
            descontos
            tipo
            vencimento
            categoria
            missingRelatedValue
            cliente {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
            fornecedor {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
            contrato {
                id
                numeroDeContrato
            }
        }
    }
`;

export const getLancamentosNaoPagos = gql`
    query getLancamentos {
        lancamentos(order: { vencimento: ASC }, where: { status: { eq: "Não Pago" } }) {
            id
            descricao
            status
            valor
            valorTotal
            juros
            multas
            acrescimos
            descontos
            tipo
            vencimento
            categoria
            contrato {
                id
                numeroDeContrato
            }
            clienteId
            cliente {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
            fornecedorId
            fornecedor {
                id
                nome
                razaoSocial
                tipoPessoa
                nomeCompleto
            }
        }
    }
`;

export const getLancamentosRelacionadosCliente = gql`
    query getLancamentosRelacionadosCliente($id: Int!) {
        lancamentos(where: { or: [{ clienteId: { eq: $id } }, { fornecedorId: { eq: $id } }] }, order: { createdDate: DESC }) {
            id
            descricao
            status
            valorTotal
            juros
            multas
            acrescimos
            descontos
            valor
            tipo
            categoria
            fornecedorId
            clienteId
        }
    }
`;

export const getLancamentosRelacionadosContrato = gql`
    query getLancamentosRelacionadosContrato($id: Int!) {
        lancamentos(where: { contratoId: { eq: $id } }, order: { createdDate: DESC }) {
            id
            descricao
            status
            valor
            valorTotal
            juros
            multas
            acrescimos
            descontos
            tipo
            categoria
            fornecedorId
            clienteId
        }
    }
`;

export const getLancamentosRelacionadosCaixa = gql`
    query getLancamentosRelacionadosCaixa($id: Int!) {
        lancamentos(where: { caixaId: { eq: $id } }, order: { createdDate: DESC }) {
            id
            descricao
            status
            valor
            tipo
            categoria
            fornecedorId
            valorTotal
            juros
            multas
            acrescimos
            descontos
            clienteId
            caixaId
            missingRelatedValue
            relatedValue
        }
    }
`;

export const getTransacoesByDate = gql`
    query getTransacoesByDate($caixaId: Int!, $afterThen: DateTime!, $beforeThen: DateTime!) {
        caixaTransactions(where: { and: [{ data: { gt: $afterThen } }, { data: { ngt: $beforeThen } }, { caixaId: { eq: $caixaId } }] }, order: { data: DESC }) {
            id
            valor
            data
            tipo
            descricao
            dataLote
            numeroDocumento
            cpfCnpj
            infoComplementar
            relatedValue
            titularCheque
            numeroCheque
            bomPara
            chequeDisponivel
            naoRelacionavel
            caixa {
                tipo
            }
        }
    }
`;

export const getTransacoesRelacionadas = gql`
    query getTransacoesRelacionadas($lancamentoId: Int!) {
        transacaoLancamentoRelations(where: { lancamentoId: { eq: $lancamentoId } }, order: { createdDate: DESC }) {
            id
            valor
            descricao
            caixaTransaction {
                id
                descricao
                caixa {
                    id
                    descricao
                    nome
                }
            }
        }
    }
`;

export const getRelationsTransacoes = gql`
    query getRelationsTransacoes($transactionId: Int!) {
        transacaoLancamentoRelations(where: { caixaTransactionId: { eq: $transactionId } }, order: { createdDate: DESC }) {
            id
            lancamentoId
            caixaTransactionId
            valor
            descricao
        }
    }
`;

export const getContatosCliente = gql`
    query GetContatosCliente {
        clientesContatos {
            id
            celularUm
            email
            nome
            clienteId
            cliente {
                nomeCompleto
                id
                cpf
                cnpj
                name
                nome
                razaoSocial
                tipoPessoa
            }
        }
    }
`;
