import "./PrestacaoServicoProduto.scoped.css";
import React, { useEffect, useState } from 'react';
import { usePrestacoesServico, deletarPrestacaoServico, criarPrestacaoServico, atualizarPrestacaoServico} from "../../../apis/backend";
import { Table, Modal, Button, Form, Row, Col, Space, InputNumber } from 'antd';

export default function PrestacaoServicoProduto(props) {
    const [produto] = useState(props.produto);
	const [formCreation] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [editarVisible, setEditarVisible] = useState(false);
	const {prestacaoServicos, mutate} = usePrestacoesServico(produto.id);
	const [loadingModalButton, setLoadingModalButton] = useState(false);
	const [visibleCriar, setVisibleCriar] = useState(false);
    const [editRecord, setEditRecord] = useState({});
	const isLoading = props.isLoading
	const columns = [
		{ title: 'Umidade Inicial (%)', dataIndex: 'umidadeInicial', key: 'umidadeInicial' },
        { title: 'Umidade Final (%)', dataIndex: 'umidadeFinal', key: 'umidadeFinal' },
        { title: 'Desconto (%)', dataIndex: 'desconto', key: 'desconto' },
		{
		  title: 'Ações',
		  dataIndex: '',
		  key: '',
		  render: (_, a) => AcoesPrestacaoServico(a),
		},
	];
	const fieldsRules = [
        {
            required: true
        }
    ];

	useEffect(() => {
		getPrestacaoServicos();
	}, []);

	async function createPrestacaoServico(e){
		setLoadingModalButton(true);

        await criarPrestacaoServico(e, produto.id);

		setLoadingModalButton(false);
		setVisibleCriar(false);
		await getPrestacaoServicos();
	}

	async function getPrestacaoServicos(){
		isLoading(true);
		mutate();
		isLoading(false);
	}

	async function onClickDelete(record){
		isLoading(true);
		await deletarPrestacaoServico(record.id)
		await getPrestacaoServicos();
		isLoading(false);
	}

    async function visibleEditar(record){
		formEdit.resetFields();
		formEdit.setFieldsValue(record)
		setEditRecord(record);

		setTimeout(() => {
			setEditarVisible(true);
		}, 0);
    }

    async function editPrestacaoServico(e){
        setLoadingModalButton(true);
        e.id = editRecord.id;
        await atualizarPrestacaoServico(e, produto.id);
        await getPrestacaoServicos();
        setLoadingModalButton(false);
        setEditarVisible(false);
    }

	const AcoesPrestacaoServico = (record) => (
        <div>
            <Button type="link" onClick={() => visibleEditar(record)}>Editar</Button>
            <Button type="link" onClick={() => onClickDelete(record)}>Deletar</Button>
            <Modal
					title="Editar Prestação Serviço"
					centered
					open={editarVisible}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
					footer={[
						<Button key={1} onClick={() => setEditarVisible(false)} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="prestacaoServicoEditForm" htmlType="submit">
							Atualizar
						</Button>
					]}
				>
					<Form form={formEdit} name="prestacaoServicoEditForm" onFinish={editPrestacaoServico} initialValues={editRecord}>
						<Row justify='space-between' gutter={4}>
							<Col span={8}>
								<Form.Item
                                label="Umidade Inicial"
                                name={"umidadeInicial"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={8}>
								<Form.Item
                                label="Umidade Final"
                                name={"umidadeFinal"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={8}>
								<Form.Item
                                label="Desconto"
                                name={"desconto"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
        </div>
	);

    return (
		<div>
			<Space size={"small"} direction="vertical" style={{width: "100%"}}>
				<div style={{width: "100%", display: "flex", justifyContent: 'end'}}>
				    <Button type="primary" onClick={() => setVisibleCriar(true)}>Adicionar</Button>
				</div>
			
				<Table
					rowKey={"id"}
					columns={columns}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 5,
						showSizeChanger: false
					}}
					dataSource={prestacaoServicos}
				/>

				<Modal
					title="Criar Prestação Serviço"
					centered
					open={visibleCriar}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
                    afterClose={() => formCreation.resetFields()}
					footer={[
						<Button key={1} onClick={() => setVisibleCriar(false)} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="prestacaoServicoCreationForm" htmlType="submit">
							Criar
						</Button>
					]}
				>
					<Form form={formCreation} name="prestacaoServicoCreationForm" onFinish={createPrestacaoServico}>
                        <Row justify='space-between' gutter={4}>
							<Col span={8}>
								<Form.Item
                                label="Umidade Inicial"
                                name={"umidadeInicial"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={8}>
								<Form.Item
                                label="Umidade Final"
                                name={"umidadeFinal"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={8}>
								<Form.Item
                                label="Desconto"
                                name={"desconto"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
			</Space>
        </div>
    )
}