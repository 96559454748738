import React, { useState, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Row, Col, Input, Button, InputNumber, Modal, Select, Form, AutoComplete, notification} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { usePlacasRomaneio, useProdutosRomaneio, useClientesRomaneio, useOperacoesFiscaisGerenciamento, aprovarRomaneio} from '../../../../apis/backend';
import { ConfigProvider } from 'antd';
import ptBr from 'antd/lib/locale/pt_BR';
import { listaVeiculos, transgeniaOptions } from '../../../Utils/Utils';

const { Option } = Select;

export default NiceModal.create(({ record }) =>  {
    const modal = useModal();
    const [form] = Form.useForm();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const fieldsRules = [
		{
			required: true
		}
	];
    const tipoOptions = [
        {
            label: "Entrada",
            value: "Entrada"
        },
        {
            label: "Saída",
            value: "Saída"
        },
    ];
    const {placas, isLoading : loadingPlacas} = usePlacasRomaneio();
    const [placaOptionsFiltered, setPlacaOptionsFiltered] = useState([]);
    const {produtos, isLoading : loadingProdutos} = useProdutosRomaneio();
    const [produtoOptionsFiltered, setProdutoOptionsFiltered] = useState([]);
    const {clientes, isLoading : loadingClientes} = useClientesRomaneio();
    const [clienteOptionsFiltered, setClienteOptionsFiltered] = useState([]);
    const {opFiscais} = useOperacoesFiscaisGerenciamento();
    const [opFiscaisFiltered, setOpFiscaisFiltered] = useState([]);
    const [opFiscaisType, setOpFiscaisType] = useState([]);

    useEffect(() => {
        form.setFieldsValue(record);
    }, []);

    useEffect(() => {
        if(clientes){
            setClienteOptionsFiltered(clientes);
        }
    }, [clientes, loadingClientes]);

    useEffect(() => {
        if(placas){
            setPlacaOptionsFiltered(placas);
        }
    }, [placas, loadingPlacas]);

    useEffect(() => {
        if(produtos){
            setProdutoOptionsFiltered(produtos);
        }
    }, [produtos, loadingProdutos]);

    function onSearchPlaca(searchText){
		setPlacaOptionsFiltered(
			!searchText ? placas : placas.filter(x =>  x.label.toLowerCase().startsWith(searchText.toLowerCase()))
		);
	};

    function onSearchProduto(searchText){
		setProdutoOptionsFiltered(
			!searchText ? produtos : produtos.filter(x =>  x.label.toLowerCase().startsWith(searchText.toLowerCase()))
		);
	};

    function onSearchCliente(searchText){
		setClienteOptionsFiltered(
			!searchText ? clientes : clientes.filter(x =>  x.label.toLowerCase().startsWith(searchText.toLowerCase()))
		);
	};

    function onSearchOpFiscal(searchText){
		setOpFiscaisFiltered(
			!searchText ? opFiscaisType : opFiscaisType.filter(x =>  x.label.toLowerCase().startsWith(searchText.toLowerCase()))
		);
	};

    function onSelectTipo(tipo){
        const filtered = opFiscais.filter(x => x.tipo == tipo);

        setOpFiscaisType(filtered);
        setOpFiscaisFiltered(filtered);
    }

    function onSelectPlaca(placa){
        const placaFull = placas.find(x => x.value === placa).fullData;

        Object.keys(placaFull).forEach(x => {
            form.setFields([
                {
                    name: x,
                    value: placaFull[x]
                }
            ])
        });
    };

    function cancelEdit(){
        form.resetFields();
        modal.hide();
    }

    function calculatePs(e){
        const keys = Object.keys(e);

        if(!keys.includes("umidade") && !keys.includes("produtoId") && !keys.includes("clienteId"))
            return;

        const valueForm = form.getFieldsValue();

        if(!valueForm.produtoId || !valueForm.clienteId || !valueForm.umidade)
            return;
        
        const fullProduct = produtos.find(x => x.value === valueForm.produtoId);
        const fullClient = clientes.find(x => x.value === valueForm.clienteId);
        const umidade = valueForm.umidade;
        let prestacaoValue = 0;

        fullProduct.tabelaPs.forEach(x => {
            if(umidade >= x.umidadeInicial && umidade <= x.umidadeFinal){
                prestacaoValue = x.desconto;
                return;
            }
        });

        fullClient.ps.forEach(x => {
            if(umidade >= x.umidadeInicial && umidade <= x.umidadeFinal && x.produtoId === valueForm.produtoId){
                prestacaoValue = x.desconto;
                return;
            }
        });

        form.setFields([
            {
                name: 'prestacaoServico',
                value: prestacaoValue
            }
        ]);
    }

    async function approveRecord(e){
        e.id = record.id;

		if (e.produtoId === 2) {
			if (e.transgenia === transgeniaOptions[0].value || e.transgenia === "Pendente") {
				return form.setFields([{
					name: 'transgenia',
					errors: ["Insira a opção correta"]
				}]);
			}
		}

        setIsLoadingButton(true);

        try{
            await aprovarRomaneio(e);
            modal.resolve();
            cancelEdit();
            
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao aprovar romaneio.'
            });
        }catch(e){
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao aprovar romaneio. Tente novamente',
            });
        }

        setIsLoadingButton(false);
    }

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Aprovar Registro"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelEdit} loading={isLoadingButton}>
                        Cancelar
                    </Button>,
                    <Button key={2} type="primary" loading={isLoadingButton} form="aprovacaoForm" key="submit" htmlType="submit">
                        Aprovar
                    </Button>
                ]}
            >
                <Form form={form} name="aprovacaoForm" onFinish={approveRecord} onValuesChange={calculatePs}>
                    <Row justify='space-between' gutter={4}>
                        <Col span={24}>
                            <Form.Item
                                label="Tipo"
                                name={"tipo"}
                                rules={fieldsRules}>
                                <Select options={tipoOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={6}>
                            <Form.Item
                                label="Tipo"
                                name={"operacaoFiscalTipo"}>
                                <Select onSelect={onSelectTipo}>
                                    <Option value="Entrada">Entrada</Option>
                                    <Option value="Saída">Saída</Option>
                                    <Option value="Prestação de Serviço">Prestação de Serviço</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                label="Operação Fiscal"
                                name={"operacaoFiscalId"}
                                rules={fieldsRules}>
                                <Select showSearch filterOption={false} onSearch={onSearchOpFiscal} options={opFiscaisFiltered}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={12}>
                            <Form.Item
                            label="Produto"
                            name={"produtoId"}
                            rules={fieldsRules}>
                                <Select showSearch filterOption={false} onSearch={onSearchProduto} options={produtoOptionsFiltered}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Cliente"
                                name={"clienteId"}
                                rules={fieldsRules}>
                                <Select showSearch filterOption={false} onSearch={onSearchCliente} options={clienteOptionsFiltered}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={4}>
                            <Form.Item
                                label="Placa"
                                name={"placaVeiculo"}
                                rules={fieldsRules}>
                                <AutoComplete
                                    options={placaOptionsFiltered}
                                    onSearch={onSearchPlaca}
                                    onSelect={onSelectPlaca}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Tipo Veículo"
                                name={"tipoVeiculo"}
                                rules={fieldsRules}>
								<Select options={listaVeiculos} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Nome"
                                name={"motoristaNome"}
                                rules={fieldsRules}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="CPF"
                                name={"motoristaCPF"}
                                rules={fieldsRules}>
                                <MaskedInput mask={'000.000.000-00'}></MaskedInput>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Celular"
                                name={"motoristaCelular"}>
                                <MaskedInput mask={'(00) 000000000'}></MaskedInput>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={5}>
                            <Form.Item
                                label="Umidade"
                                name={"umidade"}
                                rules={fieldsRules}>
                                <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="Impureza"
                                name={"impureza"}
                                rules={fieldsRules}>
                                <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="Avariados"
                                name={"avariados"}
                                rules={fieldsRules}>
                                <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                label="PS"
                                name={"prestacaoServico"}
                                rules={fieldsRules}>
                                <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Transgenia"
                                name={"transgenia"}
                                rules={fieldsRules}
                                >
								<Select options={transgeniaOptions}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={8}>
                            <Form.Item
                                label="Peso Entrada"
                                name={"pesoEntrada"}
                                rules={fieldsRules}
                                >
                                <InputNumber addonAfter={'KG'} style={{width: '100%'}}></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Peso Saída"
                                name={"pesoSaida"}
                                rules={fieldsRules}
                                >
                                <InputNumber addonAfter={'KG'} style={{width: '100%'}}></InputNumber>
                            </Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Peso Líquido"
								name={"pesoLiquido"}
								rules={fieldsRules}
							>
								<InputNumber disabled addonAfter={'KG'} style={{ width: '100%' }}></InputNumber>
							</Form.Item>
						</Col>
                    </Row>
                    <Row justify='space-between' gutter={4}>
                        <Col span={24}>
                            <Form.Item
                            label="Observações"
                            name={"observacoes"}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </ConfigProvider>
    )
});