import React, {useState, useEffect, useRef} from 'react';
import { Select, DatePicker, Button, Table } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import locale from 'antd/lib/locale/pt_BR';
import { useRelatorioArmazenamentoExpedicao, useClientesRomaneio, useProdutosRomaneio } from '../../apis/backend';
import { formatPeso, formatDate, fromDateOnlyToLocal, getCustomerName, formatPercentageConcat, filterSelect } from '../Utils/Utils';
import ReactToPrint from 'react-to-print';

const { RangePicker } = DatePicker;

export default function RelatorioArmazenamentoExpedicao() {
    const [clientes, setClientes] = useState([]);
    const { produtos : produtosDb } = useProdutosRomaneio();
	const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
    const {clientes : clientesDb} = useClientesRomaneio();
    const [isLoading, setIsLoading] = useState(false);
    const [fixedData, setFixedData] = useState([]);
	const [expandedKeys, setExpandedKeys] = useState([]);
	const [isPrinting, setIsPrinting] = useState(false);
    const [totalLiquido, setTotalLiquido] = useState(0);
    const [totalBruto, setTotalBruto] = useState(0);
    const [totalProducao, setTotalProducao] = useState(0);
    const [produto, setProduto] = useState(1);
    const [typeSelected, setTypeSelected] = useState('Entrada');
    const types = [{
        label: 'Entrada',
        value: 'Entrada'
    },
    {
        label: 'Saída',
        value: 'Saída'
    }];
    const {relatorio, isLoading : isFetching} = useRelatorioArmazenamentoExpedicao(range[0].startOf('day').toISOString(), range[1].endOf('day').toISOString(), clientes, produto, typeSelected);
    const tableRef = useRef();
    const columns = [
        { 
            title: 'Data', 
            dataIndex: 'key',
			render: valor => !!valor ? `${fromDateOnlyToLocal(valor)}` : '',
			width: 140,
			align: 'center'
        },
        {
			title: typeSelected === "Entrada" ? "Armazenamentos" : "Expedições",
			children: [
				{
					title: "Entrada",
					dataIndex: "createdDate",
					key: 1,
					render: valor => !!valor ? formatDate(valor) : '',
					width: 155,
					align: 'center'
				},
                {
                    title: "Saida",
                    dataIndex: "dataSaida",
                    key: 1,
					render: valor => !!valor ? formatDate(valor) : '',
					width: 155,
					align: 'center'
                },
                {
                    title: "Placa",
                    dataIndex: "placaVeiculo",
					key: 2,
					align: 'center'
                },
                {
                    title: "Cliente",
                    dataIndex: ["cliente"],
                    key: 3,
					render: valor => !!valor ? getCustomerName(valor) : '',
					align: 'center'
                },
                {
                    title: "Peso Bruto",
                    dataIndex: "pesoBruto",
                    key: 4,
					render: valor => !!valor ? formatPeso(valor) : '',
					align: 'center'
                },
                {
                    title: "Umidade",
                    dataIndex: "umidade",
                    key: 5,
					render: valor => !!valor ? formatPercentageConcat(valor) : '',
					align: 'center'
                },
                {
                    title: "Impureza",
                    dataIndex: "impureza",
                    key: 6,
					render: valor => !!valor ? formatPercentageConcat(valor) : '',
					align: 'center'
                },
                {
                    title: "Avariados",
                    dataIndex: "avariados",
                    key: 7,
					render: valor => !!valor ? formatPercentageConcat(valor) : '',
					align: 'center'
                },
                {
                    title: "P.S",
                    dataIndex: "prestacaoServico",
                    key: 8,
                    width: 98,
					render: valor => !!valor ? formatPercentageConcat(valor) : '',
					align: 'center'
                },
                {
                    title: "Peso Liquido",
                    dataIndex: "pesoLiquido",
                    key: 9,
					render: valor => !!valor ? formatPeso(valor) : '',
					align: 'center'
                }
            ]
        }
	];

    const updateExpandedRowKeys = ({ record }) => {
        setExpandedKeys([]);
        const rowKey = record.key;
        const isExpanded = expandedKeys.find(key => key === rowKey);
        let newExpandedRowKeys = [];
        if (isExpanded) {
            newExpandedRowKeys = expandedKeys.reduce((acc, key) => {
            if (key !== rowKey) acc.push(key);
            return acc;
            }, []);
        } else {
            newExpandedRowKeys = expandedKeys;
            newExpandedRowKeys.push(rowKey);
        }
        setExpandedKeys(newExpandedRowKeys);
	};
	
	function getHeader() {
		if (!produto)
			return null;

		const prod = (produtosDb.find(x => x.value === produto)).label;
		return `Produto: ${prod}, Gerado em: ${new Date().toLocaleDateString('pt-br')}`;

		/*
		if (!produto[0])
			return null;

		const prod = produto.map(prod => ' ' + (produtosDb.find(x => x.value === prod).label));
		return `Produto(s):${prod}`;
		*/
	}

    useEffect(() => {
        if(Array.isArray(relatorio)){
            setIsLoading(true);
            let valorLiquido = 0;
            let valorBruto = 0;
            let valorProducao = 0;
            let exKeys = [];

            relatorio.forEach(x => {
                exKeys.push(x.key);
                x.children.forEach(y => {
                    valorLiquido += y.pesoLiquido;
                    valorBruto += y.pesoBruto;
                    valorProducao += y.pesoBruto - (y.umidadePeso + y.impurezaPeso)
                });
            });

            setTotalProducao(valorProducao);
            setTotalLiquido(valorLiquido);
            setTotalBruto(valorBruto);
            setFixedData(relatorio);
            setExpandedKeys(exKeys);
            setIsLoading(false);
        }
    }, [relatorio]);

	return (
        <div>
            <div style={{display: "flex"}}>
                <RangePicker
                    allowClear={false}
                    locale={locale}
                    format={"DD/MM/YYYY"}
					defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]} 
					style={{ width: '25%', height: '100%' }} 
                    size='medium'
                    onChange={setRange}/>
                <Select
                    size='medium'
                    placeholder="Cliente"
                    mode="multiple"
                    showSearch
                    allowClear
					style={{ width: '35%', paddingLeft: '5px', paddingRight: '5px', height: '100%' }}
                    onChange={setClientes}
					filterOption={filterSelect}
                    options={clientesDb}
                />
                <Select
                    size='medium'
                    placeholder="Produto"
                    allowClear={false}
					style={{ width: '20%', paddingRight: '5px', height: '100%' }}
                    onChange={setProduto}
                    defaultValue={1}
					filterOption={filterSelect}
                    options={produtosDb}
                />
                <Select
                    size='medium'
                    placeholder="Tipo"
                    allowClear={false}
					style={{ width: '20%', paddingRight: '5px', height: '100%' }}
                    defaultValue={'Entrada'}
                    options={types}
                    onChange={setTypeSelected}
                />
				<ReactToPrint
					onBeforeGetContent={() => {
						return new Promise((resolve) => {
							setIsPrinting(true);
							resolve();
						});
					}}
					onAfterPrint={() => setIsPrinting(false)}
                    trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio}/>}
                    content={() => tableRef.current}
                    pageStyle={"padding: 1px;"}
                />
            </div>
            <div style={{marginTop: '10px'}}>
                <Table
                    id="report-form"
                    expandable={{
                        defaultExpandAllRows: true,
                        expandedRowKeys: expandedKeys,
                        onExpand: (expanded, record) => {
                            updateExpandedRowKeys({ record });
                        }
                    }}
                    ref={tableRef}
					columns={columns}
					title={() => isPrinting ? getHeader() : null}
                    dataSource={fixedData}
                    pagination={false}
                    loading={isLoading || isFetching}
                    bordered
                    summary={() => (
                        <Table.Summary fixed>
                        <Table.Summary.Row>
                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={10}>
                                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column',textAlign: 'end'}}>
                                    <div>Bruto: {formatPeso(totalBruto)}</div>
                                    <div>Produção: {formatPeso(totalProducao)}</div>
									<div>Líquido: {formatPeso(totalLiquido)}</div>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </div>
        </div>
	);
}