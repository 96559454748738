export default function RelatorioMediaContratoV2() {
    return (
        <div>
            <iframe
                title="Relatório de Média de Contratos"
                src="https://metabase.limaetarifa.com.br/public/dashboard/bb5716e1-a4ef-4eda-8139-c0e17b96b6cc"
                frameborder="0"
                width="100%"
                height="650px"
                allowtransparency
            ></iframe>
        </div>
    );
}
