import React, { useState, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { MaskedInput } from 'antd-mask-input';
import { Row, Col, Input, Button, InputNumber, Modal, Select, Form, AutoComplete, Result } from 'antd';
import { criarPesagem, getPesoPdf } from '../../../../apis/backend';
import { listaVeiculos, isValidCPF, filterSelect, printBase64Pdf } from '../../../Utils/Utils';
import { LoadingOutlined, StopOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useGraphQL } from '../../../../apis/backendGraphQL';
import { getSelectClientes, getPlacas } from '../../../../apis/queries/graphQueries';
import BalancasTooltip from '../../../Utils/BalancasTooltip';

export default NiceModal.create(() => {
	const { data: clientes, isLoading: loadingClientes } = useGraphQL(getSelectClientes);
	const { data: placas, isLoading: loadingPlacas } = useGraphQL(getPlacas);

	const modal = useModal();
	const [form] = Form.useForm();
	const [placaOptionsFiltered, setPlacaOptionsFiltered] = useState([]);
	const [creationStatus, setCreationStatus] = useState({
		inProgress: false,
		title: 'Registrando pesagem',
		subtitle: null,
		icon: <LoadingOutlined />,
		extra: null
	});
	const fieldsRules = [
		{
			required: true
		}
	];

	useEffect(() => {
		if (placas && Array.isArray(placas.placas))
			setPlacaOptionsFiltered(placas.placas);
	}, [placas, loadingPlacas]);

	async function create(payload) {
		if (!isValidCPF(payload.motoristaCpf)) {
			return form.setFields([{
				name: 'motoristaCpf',
				errors: ["Insira um CPF válido"]
			}]);
		}

		setCreationStatus({ inProgress: true, title: 'Registrando pesagem', subtitle: null, icon: <LoadingOutlined />, extra: null });

		let pesagem;

		try {
			pesagem = await criarPesagem(payload);
		} catch (e) {
			return setCreationStatus({
				inProgress: true,
				title: 'Falha ao criar pesagem!',
				subtitle: `Ocorreu uma falha ao criar a pesagem. (${e})`,
				icon: <StopOutlined style={{ color: '#C41E3A' }} />,
				extra: [
					<Button onClick={() => cancelCreation(true)}>Cancelar</Button>,
					<Button type="primary" onClick={() => create(payload)}>Tentar novamente</Button>
				]
			});
		}

		try {
			setCreationStatus({ inProgress: true, title: 'Gerando impressão', subtitle: null, icon: <LoadingOutlined />, extra: null });
			const pdf = await getPesoPdf(pesagem.id);
			printBase64Pdf(pdf, onPrintClose);
		} catch (e) {
			setCreationStatus({
				inProgress: true,
				title: 'Falha ao imprimir!',
				subtitle: `A pesagem foi criada, mas não foi possível imprimir. Imprima utilizando os registros na balança. (${e})`,
				icon: <WarningOutlined style={{ color: '#D8BA23' }} />,
				extra: <Button type="primary" onClick={() => cancelCreation(true)}>Fechar</Button>,
			});
		}
	}

	function onPrintClose() {
		setCreationStatus({
			inProgress: true,
			title: 'Pesagem registrada',
			subtitle: null,
			icon: <CheckCircleOutlined style={{ color: '#5CB914' }} />,
			extra: <Button type="primary" onClick={() => cancelCreation(true)}>Fechar</Button>
		});
	}

	function onSelectPlaca(placa) {
		const placaFull = placas.placas.find(x => x.value === placa).fullData;

		Object.keys(placaFull).forEach(x => {
			form.setFields([
				{
					name: x,
					value: placaFull[x]
				}
			])
		});
	};

	function cancelCreation(shouldMutate) {
		if (shouldMutate)
			modal.resolve();
		modal.hide();
	}

	function onClickPatioEntrada(e){
		form.setFields([{
			name: "pesoEntrada",
			value: e.peso
		}]);
	}

	function onClickEscritorioEntrada(e){
		form.setFields([{
			name: "pesoEntrada",
			value: e.peso
		}]);
	}

	const PesagemCreationForm = () => {
		return <Form form={form} name="pesagemForm" onFinish={create}>
			<Row justify='space-between' gutter={4}>
				<Col span={12}>
					<Form.Item
						label="Produto"
						name={"produto"}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cliente"
						name={"clienteId"}
						rules={fieldsRules}>
						<Select
							allowClear
							showSearch
							loading={loadingClientes}
							filterOption={filterSelect}
							options={clientes?.clientes}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={6}>
					<Form.Item
						label="Placa"
						name={"placaVeiculo"}
						rules={fieldsRules}>
						<AutoComplete
							options={placaOptionsFiltered}
							filterOption={filterSelect}
							onSelect={onSelectPlaca}
						/>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Tipo Veículo"
						name={"tipoVeiculo"}
						rules={fieldsRules}>
						<Select options={listaVeiculos} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Nome"
						name={"motoristaNome"}
						rules={fieldsRules}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="CPF"
						name={"motoristaCpf"}
						rules={fieldsRules}>
						<MaskedInput mask={'000.000.000-00'}></MaskedInput>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={6}>
					<Form.Item
						label="Umidade"
						name={"umidade"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Impureza"
						name={"impureza"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Avariados"
						name={"avariados"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="PS"
						name={"prestacaoServico"}
						rules={fieldsRules}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<BalancasTooltip onClickEscritorio={onClickEscritorioEntrada} onClickPatio={onClickPatioEntrada}>
						<Form.Item
						label="Peso Entrada"
						name={"pesoEntrada"}
						rules={fieldsRules}>
							<InputNumber addonAfter={'KG'} style={{ width: '100%' }}></InputNumber>
						</Form.Item>
					</BalancasTooltip>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Observações"
						name={"observacoes"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	}

	const PesagemCreationProgress = () => {
		return <Result
			icon={creationStatus.icon}
			subTitle={creationStatus.subtitle}
			title={creationStatus.title}
			extra={creationStatus.extra}
		/>
	}

	return (
		<Modal
			title="Registrar Pesagem"
			centered
			open={modal.visible}
			width="90%"
			destroyOnClose={true}
			closable={false}
			keyboard={false}
			maskClosable={false}
			afterClose={() => modal.remove()}
			footer={!creationStatus.inProgress ? [
				<Button key={1} onClick={cancelCreation}>
					Cancelar
				</Button>,
				<Button key={2} type="primary" form="pesagemForm" htmlType="submit">
					Salvar e Imprimir
				</Button>
			] : <></>}
		>
			{!creationStatus.inProgress ? <PesagemCreationForm /> : <PesagemCreationProgress />}
		</Modal>
	)
});