import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal, Descriptions, ConfigProvider, Tooltip } from 'antd';
import { formatCurrencyTwo, formatDayDate, formatDate } from '../Utils/Utils';
import ptBr from 'antd/lib/locale/pt_BR';
import { InfoCircleOutlined } from '@ant-design/icons';

export default NiceModal.create(({ transacao }) => {
    const modal = useModal();

    function cancelEdit() {
        modal.hide();
    }

    function InnerTooltip({ titulo, descricao }) {
        return (
            <div>
                <Tooltip title={descricao}>
                    {titulo} <InfoCircleOutlined />
                </Tooltip>
            </div>
        );
    }

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Visualizar Transação"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelEdit}>
                        Fechar
                    </Button>,
                ]}
            >
                <Descriptions bordered column={24} size="small">
                    <Descriptions.Item label={<InnerTooltip titulo="Tipo" descricao="Tipo da transação: crédito e débito." />} span={12}>
                        {transacao.tipo}
                    </Descriptions.Item>
                    <Descriptions.Item label={<InnerTooltip titulo="Valor" descricao="Valor do transação na conta corrente." />} span={12}>
                        {formatCurrencyTwo(transacao.valor)}
                    </Descriptions.Item>

                    <Descriptions.Item label={<InnerTooltip titulo="Data" descricao="Data em que a transação foi processada pelo sistema financeiro." />} span={12}>
                        {formatDayDate(transacao.data)}
                    </Descriptions.Item>
                    <Descriptions.Item label={<InnerTooltip titulo="Data Lote" descricao="Data do lançamento do lote." />} span={12}>
                        {formatDayDate(transacao.dataLote)}
                    </Descriptions.Item>

                    <Descriptions.Item label={<InnerTooltip titulo="Descrição" descricao="Informação adicional inserida pelo usuário." />} span={24}>
                        {transacao.descricao}
                    </Descriptions.Item>

                    <Descriptions.Item label={<InnerTooltip titulo="Número Documento" descricao="Número do documento da transação." />} span={12}>
                        {transacao.numeroDocumento}
                    </Descriptions.Item>
                    <Descriptions.Item label={<InnerTooltip titulo="CPF/CNPJ" descricao="Número do CPF ou CNPJ do remetente" />} span={12}>
                        {transacao.cpfCnpj}
                    </Descriptions.Item>

                    <Descriptions.Item label={<InnerTooltip titulo="Titular" />} span={24}>
                        {transacao.titularCheque}
                    </Descriptions.Item>
                    <Descriptions.Item label={<InnerTooltip titulo="Bom Para" />} span={8}>
                        {!transacao.bomPara.startsWith('0001') ? formatDayDate(transacao.bomPara, true) : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label={<InnerTooltip titulo="Número Cheque" />} span={8}>
                        {transacao.numeroCheque}
                    </Descriptions.Item>
                    <Descriptions.Item label={<InnerTooltip titulo="Cheque Disponível" />} span={4}>
                        {transacao.chequeDisponivel ? 'Sim' : 'Não'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<InnerTooltip titulo="Não Relacionavel" />} span={4}>
                        {transacao.naoRelacionavel ? 'Sim' : 'Não'}
                    </Descriptions.Item>

                    <Descriptions.Item label={<InnerTooltip titulo="Informação Complementar" descricao="Informação complementar do lançamento" />} span={24}>
                        {transacao.infoComplementar}
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </ConfigProvider>
    );
});
