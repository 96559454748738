import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export function useOperacoesFiscais() {
	const { data, error } = useSWR(`operacoes-fiscais`, fetcher);

	return {
		operacoes: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useOperacaoFiscal(id) {
	const { data, error, mutate } = useSWR(`operacoes-fiscais/${id}`, fetcher);

	return {
		operacao: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export async function criarOpFiscal(operacao) {
	return JSON.parse((await backendApi.post('operacoes-fiscais', JSON.stringify(operacao))).data);
}

export async function atualizarOpFiscal(opFiscal) {
	return await backendApi.patch(`operacoes-fiscais`, JSON.stringify(opFiscal));
}