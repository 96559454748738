import React, { useState, useEffect } from 'react';
import { useContratos } from '../../../apis/backend';
import { Table, Space, Input, Select } from 'antd';
import { Link } from 'react-router-dom';
import { formatPeso, getCustomerName, contratosTipos, searchInArray } from '../../Utils/Utils';

const { Search } = Input;

export default function ListagemContratos() {
    const { contratos, isLoading } = useContratos();
    const columns = [
        {
            title: 'Contrato',
            dataIndex: 'numeroDeContrato',
            key: 'numeroDeContrato',
            ellipsis: true,
            render: (_, record) => <Link to={`/cadastros/contrato/${record.id}`}>{record.numeroDeContrato}</Link>,
        },
        {
            title: 'Produto',
            dataIndex: ['produto', 'descricao'],
            key: 'ncmId',
        },
        {
            title: 'Comprador',
            render: (_, record) => <Link to={`/cadastros/cliente/${record.compradorId}`}>{getCustomerName(record.comprador)}</Link>,
            key: 'comprador',
        },
        {
            title: 'Vendedor',
            render: (_, record) => <Link to={`/cadastros/cliente/${record.vendedorId}`}>{getCustomerName(record.vendedor)}</Link>,
            key: 'vendedor',
        },
        {
            title: 'Saldo a Embarcar',
            dataIndex: 'saldoAEmbarcar',
            render: (_, record) => formatPeso(record.saldoAEmbarcar),
        },
    ];
    const [type, setType] = useState('vigentes');
    const [contractsByType, setContractsByType] = useState([]);
    const [filteredContratos, setFilteredContratos] = useState([]);

    function onSearch(evt) {
        const searchTerm = evt.target.value;

        if (!searchTerm) return setFilteredContratos(contractsByType);

        setFilteredContratos(searchInArray(searchTerm, contractsByType));
    }

    function onChangeSelect(value) {
        setType(value);
        setContractsByType(value === 'vigentes' ? contratos.vigentes : contratos.finalizados);
        setFilteredContratos(value === 'vigentes' ? contratos.vigentes : contratos.finalizados);
    }

    useEffect(() => {
        if (!contratos) return;

        setContractsByType(type === 'vigentes' ? contratos.vigentes : contratos.finalizados);
        setFilteredContratos(type === 'vigentes' ? contratos.vigentes : contratos.finalizados);
    }, [contratos, isLoading]);

    return (
        <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ width: '30%', paddingRight: '10px' }}>
                    <Select placeholder="Tipos" onChange={onChangeSelect} options={contratosTipos} style={{ width: '100%' }} defaultValue="Vigentes" size="large" />
                </div>
                <div style={{ width: '70%' }}>
                    <Search placeholder="Busca" allowClear size="large" onChange={onSearch} />
                </div>
            </div>
            <Space size="middle">
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={filteredContratos}
                    loading={isLoading}
                    pagination={{
                        simple: false,
                        hideOnSinglePage: true,
                        defaultPageSize: 7,
                        showSizeChanger: false,
                    }}
                />
            </Space>
        </div>
    );
}
