import React from 'react';
import ChamadaApp from '../components/Utils/ChamadaApp';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

export default function Chamada() {
    const handle = useFullScreenHandle();

    return (
        <div>
            <FullScreen handle={handle}>
                <div onClick={() => (handle.active ? handle.exit() : handle.enter())}>
                    <ChamadaApp></ChamadaApp>
                </div>
            </FullScreen>
        </div>
    );
}
