import React from 'react';
import { Row, Col, Tabs } from 'antd';
import EstatisticasRomaneio from '../EstatisticasRomaneio/EstatisticasRomaneio';
import RomaneiosAbertos from '../RomaneiosAbertos/RomaneiosAbertos';
import RomaneiosFechados from '../RomaneiosFechados/RomaneiosFechados';

export default function Balanca() {

	const items = [{
		key: '1',
		label: 'Logística de Veículos',
		children: <RomaneiosAbertos />
	},
	{
		key: '2',
		label: 'Histórico',
		children: <RomaneiosFechados />
	}];

	return (
		<div>
			<div>
				<Row gutter={16}>
					<Col span={12}>
						<EstatisticasRomaneio productId={1} type="Entrada" />
					</Col>
					<Col span={12}>
						<EstatisticasRomaneio productId={1} type="Saída" />
					</Col>
				</Row>
			</div>
			<Tabs defaultActiveKey="1" items={items} />
		</div>
	);
}