import React, { useEffect, useState } from "react";
import { Breadcrumb, Divider, Spin, Button, Tabs } from "antd";
import { ProPageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from "react-router-dom";
import { useCliente } from "../../../apis/backend";
import InformacoesGeraisCliente from "../InformacoesGeraisCliente/InformacoesGeraisCliente";
import PropriedadesCliente from "../PropriedadesCliente/PropriedadesCliente";
import ContatosCliente from "../ContatosCliente/ContatosCliente";
import DocumentosCliente from "../DocumentosCliente/DocumentosCliente";
import dayjs from "dayjs";
import ContasBancariasCliente from "../ContasBancariasCliente/ContasBancariasCliente";
import PrestacoesServicoCliente from "../PrestacoesServicoCliente/PrestacoesServicoCliente";
import { getCustomerName } from '../../Utils/Utils';

export default function DetalheCliente(props) {
	const navigate = useNavigate();
	const { cliente, isLoading, mutate } = useCliente(props.id);
	const [pageLoading, setPageLoading] = useState(false);
	const [activeTab, setActiveTab] = useState("1");
	const [isClienteLoaded, setIsClienteLoaded] = useState(false);

	useEffect(() => {
		if (!cliente || 'object' != typeof cliente) return;

		setPageLoading(true);

		cliente.nascimento = dayjs(new Date(cliente.nascimento));

		setPageLoading(false);
		setIsClienteLoaded(true);
	}, [cliente, isLoading]);

	const items = [{
		key: '1',
		label: 'Informações Gerais',
		children: isClienteLoaded ? <InformacoesGeraisCliente mutate={mutate} cliente={cliente} isLoading={setPageLoading} /> : <></>
	},
	{
		key: '2',
		label: 'Propriedades',
		children: isClienteLoaded ? <PropriedadesCliente cliente={cliente} isLoading={setPageLoading} /> : <></>
	},
	{
		key: '3',
		label: 'Contatos',
		children: isClienteLoaded ? <ContatosCliente cliente={cliente} isLoading={setPageLoading} /> : <></>
	},
	{
		key: '4',
		label: 'Contas Bancárias',
		children: isClienteLoaded ? <ContasBancariasCliente cliente={cliente} isLoading={setPageLoading} /> : <></>
	},
	{
		key: '5',
		label: 'Documentos',
		children: isClienteLoaded ? <DocumentosCliente cliente={cliente} isLoading={setPageLoading} /> : <></>
	},
	{
		key: '6',
		label: 'Prestação Serviço',
		children: isClienteLoaded ? <PrestacoesServicoCliente cliente={cliente} isLoading={setPageLoading} /> : <></>
	}];

	return (
		<div>
			<Spin tip="Carregando..." spinning={isLoading || pageLoading}>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<Breadcrumb>
						<Breadcrumb.Item>Cadastros</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to={`/cadastros/cliente/`}>Cliente</Link>
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							<Link to={`/cadastros/cliente/${props.id}`}>
								{cliente?.tipoPessoa === "Pessoa Jurídica"
									? cliente?.razaoSocial
									: cliente?.nome}
							</Link>
						</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<Divider />
				<ProPageHeader
					style={{ paddingLeft: 0, paddingRight: 0 }}
					title={getCustomerName(cliente)}
					className="site-page-header"
					onBack={() => navigate("/cadastros/cliente")}
					extra={[
						<Button
							type="primary"
							form="clienteForm"
							key="1"
							htmlType="submit"
							disabled={activeTab !== "1"}
						>
							Salvar
						</Button>,
					]}
				></ProPageHeader>
				<div>
					<Tabs defaultActiveKey="1" onChange={setActiveTab} items={items}/>
				</div>
			</Spin>
		</div>
	);
}
