import React, {useState, useEffect, useRef} from 'react';
import { Select, DatePicker, Button, Table } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { formatPeso, formatDate, formatCurrency, getCustomerName, formatPercentageConcat, movimentacoesEstoqueTipos, filterSelect } from '../Utils/Utils';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import locale from 'antd/lib/locale/pt_BR';
import { Link } from 'react-router-dom';
import { useRelatorioMovimentacoesEstoque, useClientesRomaneio, useProdutosRomaneio } from '../../apis/backend';
import ReactToPrint from 'react-to-print';

const { RangePicker } = DatePicker;

export default function RelatorioMovimentacoesEstoque() {
    const { clientes : clientesDb } = useClientesRomaneio();
	const { produtos: produtosDb } = useProdutosRomaneio();
	const [isPrinting, setIsPrinting] = useState(false);
    const [clientes, setClientes] = useState([1]);
    const [produto, setProduto] = useState(1);
	const [tiposSelected, setTipo] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
    const [resumo, setResumo] = useState({
        entradaBruta: 0,
        entradaLiquida: 0,
        contratos: 0,
        ps: 0,
        quebraTecnica: 0,
        saldoAtual: 0,
    });
    const [fixedReport, setFixedReport] = useState([]);
	const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
	const { relatorio, isLoading: isFetching } = useRelatorioMovimentacoesEstoque(range[0].startOf('day').toISOString(), range[1].endOf('day').toISOString(), clientes, produto, tiposSelected);
	const stylePeso = {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center'
	};
    const tableRef = useRef();
    const columns = [
		{
			title: "Data",
			dataIndex: "createdDate",
			key: 1,
			render: (val) => !!val ? formatDate(val) : '',
			fixed: 'left',
			width: 100,
			align: 'center'
		},
		{
			title: "Tipo",
			render: (val) => {
				if (val.motivo !== 'Operação')
					return val.motivo;
				
				const clienteId = val.estoque.clienteId;
				const contratoId = val.operacao.contrato.id;
				let text = val.operacao.contrato.vendedorId === clienteId ? 'Operação | Venda' : 'Operação | Compra' ;

				return <Link target="_blank" to={`/cadastros/contrato/${contratoId}`}>{text}</Link>
			},
			key: 2,
			fixed: 'left',
			width: 120,
			align: 'center'
		},
		{
			title: "Peso Bruto",
			dataIndex: ["romaneio", "pesoBruto"],
			key: 3,
			render: valor => !!valor ? formatPeso(valor) : '',
			width: 130,
			align: 'center'
		},
		{
			title: "Umid.",
			key: 4,
			render: valor => !!valor.romaneio ? <div style={stylePeso}><span>{formatPercentageConcat(valor.romaneio.umidade)}</span><span>{formatPeso(valor.romaneio.umidadePeso)}</span></div> : '',
			width: 120,
			align: 'center'
		},
		{
			title: "Impur.",
			key: 5,
			render: valor => !!valor.romaneio ? <div style={stylePeso}><span>{formatPercentageConcat(valor.romaneio.impureza)}</span><span>{formatPeso(valor.romaneio.impurezaPeso)}</span></div> : '',
			width: 120,
			align: 'center'
		},
		{
			title: "Avar.",
			key: 6,
			render: valor => !!valor.romaneio ? <div style={stylePeso}><span>{formatPercentageConcat(valor.romaneio.avariados)}</span><span>{formatPeso(valor.romaneio.avariadosPeso)}</span></div> : '',
			width: 120,
			align: 'center'
		},
		{
			title: "P.S",
			key: 7,
			render: valor => !!valor.romaneio ? <div style={stylePeso}><span>{formatPercentageConcat(valor.romaneio.prestacaoServico)}</span><span>{formatPeso(valor.romaneio.prestacaoServicoPeso)}</span></div> : '',
			width: 120,
			align: 'center'
		},
		{
			title: "P.S Arm.",
			key: 8,
			dataIndex: ["prestacaoServico", "quantidadeCalculada"],
			render: valor => !!valor ? formatPeso(valor) : '',
			width: 110,
			align: 'center'
		},
		{
			title: "P. Líquido",
			dataIndex: ["romaneio", "pesoLiquido"],
			key: 9,
			render: valor => !!valor ? formatPeso(valor) : '',
			width: 130,
			align: 'center'
		},
		{
			title: "Q.T",
			key: 10,
			render: valor => valor.motivo === "Quebra Técnica" ? formatPeso(valor.novoValor - valor.valorAnterior) : '',
			width: 110,
			align: 'center'
		},
		{
			title: "Operação Peso",
			key: 11,
			render: valor => valor.motivo === "Operação" ? formatPeso(valor.novoValor - valor.valorAnterior) : '',
			width: 145,
			align: 'center'
		},
		{
			title: "Preço",
			dataIndex: ["operacao", "contrato", "preco"],
			key: 12,
			render: valor => valor ? formatCurrency(valor) : '',
			width: 86,
			align: 'center'
		},
		{
			title: "Parte",
			key: 13,
			render: valor => {
				if (valor.motivo === "Prestação de Serviço") {
					const nome = valor?.prestacaoServico?.estoque?.cliente?.nomeCompleto;
					if (nome)
						return nome;
					
					return valor.romaneio.cliente.nomeCompleto;
				}
				
				if(valor.motivo !== "Operação")
					return '';
				
				const clienteId = valor.estoque.clienteId;

				if(valor.operacao.contrato.vendedorId === clienteId)
					return getCustomerName(valor.operacao.contrato.comprador);
				
				if(valor.operacao.contrato.compradorId === clienteId)
					return getCustomerName(valor.operacao.contrato.vendedor);
			},
			width: 120,
			align: 'center'
		},
		{
			title: "Saldo",
			dataIndex: "novoValor",
			key: 14,
			render: valor => !!valor ? formatPeso(valor) : '',
			width: 136,
			align: 'center'
		}
	];
	const pageStyle = '.ant-table *, .ant-table *::after, .ant-table *::before {box-shadow: none !important;} *::before{font-size: 16px;}';

    useEffect(() => {
		if (Array.isArray(relatorio)) {
			setIsLoading(true);
            const initial = {
                entradaBruta: 0,
				entradaLiquida: 0,
				saida: 0,
				contratos: 0,
				psRomaneio: 0,
                ps: 0,
                quebraTecnica: 0,
                saldoAtual: 0,
				producao: 0,
				psPorcentagem: 0
            };

            relatorio.forEach(y => {
                initial.entradaBruta += y.motivo === "Entrada" ? y.romaneio.pesoBruto : 0;
                initial.entradaLiquida += y.motivo === "Entrada" ? y.romaneio.pesoLiquido : 0;
				initial.contratos += y.motivo === "Operação" ? y.operacao?.pesoEmbarcado : 0;
				initial.producao += y.motivo === "Entrada" ? y.romaneio.pesoBruto - (y.romaneio.umidadePeso + y.romaneio.impurezaPeso + y.romaneio.avariadosPeso) : 0

				if (y.motivo === "Entrada" && y.romaneio?.prestacaoServicoPeso) {
					initial.psRomaneio += y.romaneio?.prestacaoServicoPeso;
				}

				if (y.motivo === "Prestação de Serviço") {
					if (y.romaneio) {
						initial.psRomaneio += y.romaneio?.prestacaoServicoPeso;
					} else {
						initial.ps += y.prestacaoServico?.quantidadeCalculada;
					}
				}
                initial.quebraTecnica += y.motivo === "Quebra Técnica" ? y.novoValor - y.valorAnterior : 0;
                if(y.estoque){
                    initial.saldoAtual = y.estoque.pesoAtual
				}

				if (y.motivo === 'Saída') {
					initial.saida += y.romaneio.pesoLiquido;
				}

				initial.psPorcentagem = (initial.psRomaneio / initial.entradaBruta) * 100;
            });
            setResumo(initial);
			setFixedReport(relatorio);
			setIsLoading(false);
        }
    }, [relatorio]);
	
	function getHeader() {
		if (!produto)
			return null;

		const prod = (produtosDb.find(x => x.value === produto)).label;
		return `Produto: ${prod}, Gerado em: ${new Date().toLocaleDateString('pt-br')}`;
	}

	return (
        <div>
            <div style={{display: "flex"}}>
                <RangePicker
                    allowClear={false}
                    locale={locale}
                    format={"DD/MM/YYYY"}
					defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]} 
					style={{ width: '25%', height: '100%' }} 
                    size='medium'
                    onChange={setRange}/>
                <Select
                    size='medium'
                    placeholder="Cliente"
                    allowClear={false}
                    defaultValue={1}
                    showSearch
					style={{ width: '25%', paddingLeft: '5px', paddingRight: '5px', height: '100%' }}
                    onChange={setClientes}
					filterOption={filterSelect}
                    options={clientesDb}
                />
                <Select
                    size='medium'
                    placeholder="Produto"
                    allowClear={false}
                    defaultValue={1} 
					style={{ width: '25%', paddingRight: '5px', height: '100%' }}
                    onChange={setProduto}
					filterOption={filterSelect}
                    options={produtosDb}
                />
                <Select
                    size='medium'
                    placeholder="Tipo"
                    allowClear
                    mode="multiple"
					style={{ width: '25%', paddingRight: '5px', height: '100%' }}
                    onChange={setTipo}
					options={movimentacoesEstoqueTipos}
				/>
				<ReactToPrint
					onBeforeGetContent={() => {
						return new Promise((resolve) => {
							setIsPrinting(true);
							resolve();
						});
					}}
					onAfterPrint={() => setIsPrinting(false)}
					trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio} />}
					content={() => tableRef.current}
					pageStyle={pageStyle}
					bodyClass={'testeee'}
				/>
            </div>
			<div style={{ marginTop: '10px' }} ref={tableRef}>
                <Table
                    rowKey="createdDate"
					bordered={true}
					size="small"
                    columns={columns}
					dataSource={fixedReport}
					title={() => isPrinting ? getHeader() : null}
					pagination={false}
                    loading={isLoading || isFetching}
                    scroll={{
                        x: 1300,
                    }}
				/>
				<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', textAlign: 'end' }}>
					<div>
						Entrada Bruta: {formatPeso(resumo.entradaBruta)}
					</div>
					<div>
						Produção: {formatPeso(resumo.producao)}
					</div>
					<div>
						Entrada Liquida: {formatPeso(resumo.entradaLiquida)}
					</div>
					<div>
						Saída: {formatPeso(resumo.saida)}
					</div>
					<div>
						Contratos: {formatPeso(resumo.contratos)}
					</div>
					<div>
						Prestações de Serviço: {formatPeso(resumo.psRomaneio)} ({formatPercentageConcat(resumo.psPorcentagem)})
					</div>
					<div>
						Prestações de Serviço Armazenagem: {formatPeso(resumo.ps)}
					</div>
					<div>
						Quebra Técnica: {formatPeso(resumo.quebraTecnica)}
					</div>
					<div>
						Saldo Atual: {formatPeso(resumo.saldoAtual)}
					</div>
				</div>
            </div>
        </div>
	);
}