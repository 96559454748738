import React from 'react';
import { Tabs } from 'antd';
import Pesagens from '../Pesagens/Pesagens';

export default function GerenciamentoPesagemSimples() {

	const items = [
		{
			key: '1',
			label: 'Logística de Veículos',
			children: <Pesagens />
		}
	];

	return (
		<div>
			<Tabs defaultActiveKey="1" items={items} />
		</div>
	);
}