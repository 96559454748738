import React, { useState } from 'react';
import './CriarOperacaoFiscal.scoped.css';
import { Button, Modal, Input, Form, Row, Col, InputNumber, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import { criarOpFiscal, useCodigosCfop } from '../../../apis/backend';
import { filterSelect, operacoesFiscaisTipos } from '../../Utils/Utils';

const { TextArea } = Input;

export default function CriarOperacaoFiscal() {
	const {cfops, isLoading : isLoadingCfops} = useCodigosCfop();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [visible, setVisible] = useState(false);
	const [secondaryLoading, setSecondaryLoading] = useState(false);
	const [primaryLoading, setPrimaryLoading] = useState(false);
	const fieldsRules = [
		{
			required: true
		}
	];

	function cancelCreation(){
		form.resetFields();
		setVisible(false);
	}

	async function createOp(e){
		setPrimaryLoading(true);
		setSecondaryLoading(true);

		navigate(`/cadastros/operacao-fiscal/${await criarOpFiscal(e)}`);
	}

	return (
		<div>
			<Button type="primary" size="large" onClick={() => setVisible(true)}>
				Criar
			</Button>
			<Modal
				title="Criar Operação Fiscal"
				centered
				open={visible}
				width="90%"
				destroyOnClose={true}
				closable={false}
				keyboard={false}
				maskClosable={false}
				footer={[
					<Button loading={secondaryLoading} onClick={cancelCreation} >
						Cancelar
					</Button>,
					<Button type="primary" loading={primaryLoading} form="opFicalForm" key="submit" htmlType="submit">
						Criar
					</Button>
				]}
			>
				<div>
					<Form form={form} name="opFicalForm" onFinish={createOp}>
						<Row>
							<Col span={24}>
								<Form.Item
									label="Tipo"
									name={"Tipo"}
									rules={fieldsRules}>
										<Select
											allowClear
											filterOption={filterSelect}
											options={operacoesFiscaisTipos}
										/>
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={8}>
								<Form.Item
								label="Nome"
								name={"Nome"}
								rules={fieldsRules}>
									<Input/>
								</Form.Item>
							</Col>
							<Col span={16}>
								<Form.Item
								label="CFOP"
								name={"CFOPId"}
								rules={fieldsRules}>
									<Select
										allowClear
										showSearch
										loading={isLoadingCfops}
										filterOption={filterSelect}
										options={cfops}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={8}>
								<Form.Item
									label="CST. ICMS"
									name={"CSTICMS"}
									rules={fieldsRules}>
									<InputNumber style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="CST. PIS"
									name={"CSTPIS"}
									rules={fieldsRules}>
									<InputNumber style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="CST. COFINS"
									name={"CSTCOFINS"}
									rules={fieldsRules}>
									<InputNumber style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={8}>
								<Form.Item
									label="Ali. Frural"
									name={"AliquotaFRural"}
									rules={fieldsRules}>
									<InputNumber  max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="Ali. ICMS"
									name={"AliquotaICMS"}
									rules={fieldsRules}>
									<InputNumber  max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="Ali. PIS"
									name={"AliquotaPIS"}
									rules={fieldsRules}>
									<InputNumber  max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={6}>
								<Form.Item
									label="Ali. COFIS"
									name={"AliquotaCOFINS"}
									rules={fieldsRules}>
									<InputNumber  max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label="Ali. ISS"
									name={"AliquotaISS"}
									rules={fieldsRules}>
									<InputNumber  max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label="Ali. IRPJ"
									name={"AliquotaIRPJ"}
									rules={fieldsRules}>
									<InputNumber  max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label="Ali. CSLL"
									name={"AliquotaCSLL"}
									rules={fieldsRules}>
									<InputNumber  max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={24}>
								<Form.Item
									label="Observações"
									name={"Observacoes"}>
									<TextArea rows={3} />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>	
		</div>
	);
}