import React, { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useGraphQLImmutable } from '../../../apis/backendGraphQL';
import { getContatosCliente } from '../../../apis/queries/graphQueries';
import { Button, Modal, ConfigProvider, Row, Col, Typography, Select, Popover, Space, Form, Input, Popconfirm, Result, Descriptions, Divider, notification } from 'antd';
import { filterSelect, getCustomerName, isNonMinDate, formatDayDateFromUTC } from '../../Utils/Utils';
import { signContrato, rememberContract } from '../../../apis/controllers/contrato';
import { useAuth0 } from "@auth0/auth0-react";
import { CheckCircleOutlined, CheckOutlined, StopOutlined, PushpinFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

export default NiceModal.create(({ contrato }) => {
	const modal = useModal();
    const [form] = Form.useForm();
    const { user } = useAuth0();
    const [api, contextHolder] = notification.useNotification();
    const { data: contatoClientes, isLoading : isLoadingClientes, mutate } = useGraphQLImmutable(getContatosCliente);
    const [compradoresContatos, setCompradoresContatos] = useState([]);
    const [vendedoresContatos, setVendedoresContatos] = useState([]);
    const [selectedComprador, setSelectedComprador] = useState();
    const [selectedVendedor, setSelectedVendedor] = useState();
    const [sent, setSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openVendedor, setOpenVendedor] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const fieldsRules = [
		{
			required: true
		}
	];

	function cancelEdit() {
		modal.hide();
	}

    useEffect(() => {
        if(!contatoClientes || !contatoClientes.clientesContatos)
            return;
        
        setCompradoresContatos(contatoClientes.clientesContatos.filter(x => x.clienteId === contrato.compradorId));
        setVendedoresContatos(contatoClientes.clientesContatos.filter(x => x.clienteId === contrato.vendedorId));
    }, [contatoClientes]);

    useEffect(() => {
        mutate();
    }, []);
    
    const handleOk = async (type) => {
        setConfirmLoading(true);

        try {
			await rememberContract(contrato.id, type);
			api.success({
				message: 'Sucesso',
				description: `O lembrete será enviado para o ${type} em alguns segundos.`,
			});
		} catch (e) {
			api.error({
				message: 'Erro',
				description: e?.response?.data?.message || 'Falha interna. Tente novamente.',
			});
			console.error(e);
		}

        setConfirmLoading(false);
        setOpenVendedor(false);
        setOpen(false);
    };

    async function create(eData){
        setIsLoading(true);

        try {
			await signContrato(contrato.id, eData.contatoComprador, eData.contatoVendedor, user?.name);
			api.success({
				message: 'Sucesso',
				description: 'Contrato enviado para assinatura',
			});
            setSent(true);
		} catch (e) {
			api.error({
				message: 'Erro',
				description: e?.response?.data?.message || 'Falha interna. Tente novamente.',
			});
			console.error(e);
		}

		setIsLoading(false);
    }

    function onChangeForm(data, allFields){
        const compradorId = allFields.find(x => x.name[0] === 'contatoComprador').value
        const vendedorId = allFields.find(x => x.name[0] === 'contatoVendedor').value

        setSelectedComprador(compradoresContatos.find(x => x.id === compradorId));
        setSelectedVendedor(vendedoresContatos.find(x => x.id === vendedorId));
    }

    function ContactDetail({contact}){
        return <div>
            <Space direction="vertical" style={{width: '100%'}}>
                <Input addonBefore="Nome" disabled value={contact.nome} />
                <Input addonBefore="Celular" disabled value={contact.celularUm} />
                <Input addonBefore="Email" disabled value={contact.email} />
            </Space>
        </div>
    }

    function NaoEnviado(){
        return <div>
            <Form form={form} name="form" onFinish={create} onFieldsChange={onChangeForm}>
                <Row justify="center" gutter={4}>
                    <Col span={12} style={{textAlign: 'center'}}>
                        <Form.Item
                            label="Comprador"
                            name={"contatoComprador"}
                            rules={fieldsRules}>     
                            <Select loading={isLoadingClientes} showSearch filterOption={filterSelect} style={{width: '100%'}}>
                                {!!compradoresContatos ? compradoresContatos.map(val => {
                                    return <Option value={val.id} label={val.nome} width="100%" style={{zIndex: 100}}>
                                        <Popover placement="top" style={{zIndex: 999}}  title={<div>{val.nome} | <Link target="_blank" to={`/cadastros/cliente/${val.cliente.id}`}>Acessar</Link></div>} content={
                                            <div>
                                                <Text type="secondary">Cliente: </Text><Text>{getCustomerName(val.cliente)}</Text><br/>
                                                <Text type="secondary">Contato: </Text><Text>{val.nome}</Text><br/>
                                                <Text type="secondary">Celular: </Text><Text>{val.celularUm}</Text><br/>
                                                <Text type="secondary">Email: </Text><Text>{val.email}</Text><br/>
                                            </div>
                                            }>
                                            <Space >
                                                {val.nome}
                                            </Space>
                                        </Popover>
                                    </Option>
                                }) : []}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{textAlign: 'center'}}>
                        <Form.Item
                            label="Vendedor"
                            name={"contatoVendedor"}
                            rules={fieldsRules}>
                            <Select loading={isLoadingClientes} showSearch filterOption={filterSelect} style={{width: '100%'}}>
                                {!!vendedoresContatos ? vendedoresContatos.map(val => {
                                    return <Option value={val.id} label={val.nome} width="100%" style={{zIndex: 100}}>
                                        <Popover placement="top" style={{zIndex: 999}}  title={<div>{val.nome} | <Link target="_blank" to={`/cadastros/cliente/${val.cliente.id}`}>Acessar</Link></div>} content={
                                            <div>
                                                <Text type="secondary">Cliente: </Text><Text>{getCustomerName(val.cliente)}</Text><br/>
                                                <Text type="secondary">Contato: </Text><Text>{val.nome}</Text><br/>
                                                <Text type="secondary">Celular: </Text><Text>{val.celularUm}</Text><br/>
                                                <Text type="secondary">Email: </Text><Text>{val.email}</Text><br/>
                                            </div>
                                            }>
                                            <Space >
                                                {val.nome}
                                            </Space>
                                        </Popover>
                                    </Option>
                                }) : []}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center" gutter={4}>
                    {!!selectedComprador ? 
                        <Col span={12} style={{textAlign: 'center'}}>
                            <ContactDetail contact={selectedComprador} />
                        </Col>
                    : []}
                    {!!selectedVendedor ? 
                        <Col span={12} style={{textAlign: 'center'}}>
                            <ContactDetail contact={selectedVendedor} />
                        </Col>
                    : []}
                </Row>
                <Row justify="center" gutter={4}>
                    <Title level={4}>Certifique-se que o celular está correto e também cadastrado no WhatsApp. Após o envio não será possível alterar o número de celular.</Title>
                </Row>
            </Form>
        </div>
    }

    function DescriptionAssinador({title, nome, celular, email, dataAssinatura, notificado, primeiraNotificacao, ultimaNotificacao, status, ultimaVisualizacao, numeroViews, lat, lon}){
        const center = {
            lat: lat ? parseFloat(lat) : 0,
            lng: lon ? parseFloat(lon) : 0,
        };
        const zoom = 16;
        
        return <div>
            <Descriptions title={title}>
                <Descriptions.Item label="Nome" span={3}>{nome}</Descriptions.Item>
                <Descriptions.Item label="Celular" span={3}>{celular}</Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>{email}</Descriptions.Item>
                <Descriptions.Item label="Data Assinatura" span={3}>{isNonMinDate(dataAssinatura) ? formatDayDateFromUTC(dataAssinatura) : 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Notificado" span={3}>{notificado ? <CheckOutlined /> : <StopOutlined />}</Descriptions.Item>
                <Descriptions.Item label="Primeira Notificação" span={3}>{isNonMinDate(primeiraNotificacao) ? formatDayDateFromUTC(primeiraNotificacao) : 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Última Notificação" span={3}>{isNonMinDate(ultimaNotificacao) ? formatDayDateFromUTC(ultimaNotificacao) : 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Última Visualização" span={3}>{isNonMinDate(ultimaVisualizacao) ? formatDayDateFromUTC(ultimaVisualizacao) : 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Vezes que Visualizou" span={3}>{numeroViews}</Descriptions.Item>
                <Descriptions.Item label="Status">{status}</Descriptions.Item>
            </Descriptions>
            {center.lat ? 
            <div style={{ height: '200px', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAqvg1qj3l5VcR61s6Wbr9ONHYnBW21qOk" }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    language={"pt-BR"}
                    region={"BR"}
                    options={{
                        disableDefaultUI: true,
                        disableDoubleClickZoom: true,
                        draggable: false,
                        draggableCursor: false
                    }}
                    yesIWantToUseGoogleMapApiInternals
                >
                    <PushpinFilled 
                        lat={center.lat}
                        lng={center.lng}
                        style={{fontSize: '20px', color: '#067e14'}}
                    />
                </GoogleMapReact>
             </div>
            : <div></div>}
        </div>
    }

    function DescriptionAssinatura({contrato}){
        return <Descriptions>
            <Descriptions.Item label="Concluído" span={2}>{contrato.zapContratoConcluido ? 'Sim' : 'Não'}</Descriptions.Item>
            <Descriptions.Item label="Status" span={2}>{contrato.zapStatus}</Descriptions.Item>
            <Descriptions.Item label="Contrato ZAP" span={2}><a target="_blank" href={`https://app.zapsign.com.br/conta/documentos/${contrato.zapToken}`}>Acessar</a></Descriptions.Item>
            <Descriptions.Item label="Enviado em" span={2}>{formatDayDateFromUTC(contrato.zapDataEnviado)}</Descriptions.Item>
            <Descriptions.Item label="Enviado Por" span={2}>{contrato.zapSentByName}</Descriptions.Item>
            <Descriptions.Item label="Documento Assinado" span={2}>{contrato.zapDocumentoAssinado ? <a target="_blank" href={contrato.zapDocumentoAssinado}>Acessar</a> : 'N/A'}</Descriptions.Item>
        </Descriptions>
    }

    function Enviado(){
        const signedComprador = contrato.zapStatusComprador === 'signed' ? 1 : 0;
        const signedVendedor = contrato.zapStatusVendedor === 'signed' ? 1 : 0;

        return <div>
            <Divider orientation="left" plain>Informações gerais</Divider>
            <Row gutter={4}>
                <Space direction="vertical" style={{width: '100%'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '50%', display: 'flex', justifyContent: 'start'}}>
                            Comprador: <Paragraph code copyable={{ text: `https://app.zapsign.com.br/verificar/${contrato.zapCompradorToken}` }}>{`https://app.zapsign.com.br/verificar/${contrato.zapCompradorToken}`}</Paragraph>
                        </div>
                        <div style={{width: '50%', display: 'flex', justifyContent: 'start'}}>
                            Vendedor: <Paragraph code copyable={{ text: `https://app.zapsign.com.br/verificar/${contrato.zapVendedorToken}` }}>{`https://app.zapsign.com.br/verificar/${contrato.zapVendedorToken}`}</Paragraph>
                        </div>
                    </div>
                    <Text><Text mark>{signedComprador + signedVendedor}/2 Assinaturas</Text>. Os links já foram enviados via WhatsApp, caso queira, compartilhe novamente os links de assinatura.</Text>
                </Space>
            </Row>
            <Divider orientation="left" plain>Informações dos contatos</Divider>
            <Row gutter={4}>
                <Col span={12} style={{textAlign: 'center'}}>
                    <DescriptionAssinador 
                        title={"Comprador"} 
                        nome={contrato.zapCompradorNome}
                        celular={contrato.zapCelularComprador}
                        dataAssinatura={contrato.zapCompradorAssinadoData}
                        notificado={contrato.zapNotificadoComprador}
                        primeiraNotificacao={contrato.zapNotificadoCompradorPrimeiraData}
                        ultimaNotificacao={contrato.zapNotificadoCompradorUltimaData}
                        status={contrato.zapStatusComprador}
                        email={contrato.zapEmailComprador}
                        ultimaVisualizacao={contrato.zapCompradorUltimaVisualizacaoData}
                        numeroViews={contrato.zapCompradorNumeroVisualizacoes}
                        lat={contrato.zapCompradorLatAssinatura}
                        lon={contrato.zapCompradorLonAssinatura} />
                        <Popconfirm
                            title="Você realmente deseja enviar um lembrete via WhatsApp para o comprador?"
                            description=""
                            open={open}
                            onConfirm={() => handleOk('comprador')}
                            okButtonProps={{ loading: confirmLoading }}
                            onCancel={() => setOpen(false)}
                        >
                            <Button type="primary" onClick={() => setOpen(true)} disabled={contrato.zapStatusComprador !== 'pending'}>
                                Enviar lembrete ao comprador
                            </Button>
                        </Popconfirm>
                </Col>
                <Col span={12} style={{textAlign: 'center'}}>
                    <DescriptionAssinador 
                        title={"Vendedor"} 
                        nome={contrato.zapVendedorNome}
                        celular={contrato.zapCelularVendedor}
                        dataAssinatura={contrato.zapVendedorAssinadoData}
                        notificado={contrato.zapNotificadoVendedor}
                        primeiraNotificacao={contrato.zapNotificadoVendedorPrimeiraData}
                        ultimaNotificacao={contrato.zapNotificadoVendedorUltimaData}
                        status={contrato.zapStatusVendedor}
                        email={contrato.zapEmailVendedor}
                        ultimaVisualizacao={contrato.zapVendedorUltimaVisualizacaoData}
                        numeroViews={contrato.zapVendedorNumeroVisualizacoes}
                        lat={contrato.zapVendedorLatAssinatura}
                        lon={contrato.zapVendedorLonAssinatura} />
                        <Popconfirm
                            title="Você realmente deseja enviar um lembrete via WhatsApp para o vendedor?"
                            description=""
                            open={openVendedor}
                            onConfirm={() => handleOk('vendedor')}
                            okButtonProps={{ loading: confirmLoading }}
                            onCancel={() => setOpenVendedor(false)}
                        >
                            <Button type="primary" onClick={() => setOpenVendedor(true)} disabled={contrato.zapStatusVendedor !== 'pending'}>
                                Enviar lembrete ao vendedor
                            </Button>
                        </Popconfirm>
                </Col>
            </Row>
            <Divider orientation="left" plain>Informações do contrato</Divider>
            <DescriptionAssinatura contrato={contrato}></DescriptionAssinatura>
        </div>
    }

    function ContratoEnviado(){
        return <Result
            icon={<CheckCircleOutlined style={{ color: '#5CB914' }} />}
            subTitle={'Atualize a página para visualizar as informações atualizadas sobre o envio.'}
            title={'Contrato enviado'}
        />
    }

	return (
		<ConfigProvider>
            {contextHolder}
			<Modal
				title="Assinatura de Contrato"
				centered
				open={modal.visible}
				width="95%"
				destroyOnClose={true}
				closable={false}
				keyboard={false}
				maskClosable={false}
				afterClose={() => modal.remove()}
				footer={[
                    <Button key={2} type="primary" form="form" htmlType="submit" disabled={sent || contrato.zapEnviadoParaAssinar || isLoading} isLoading={isLoading}>
                        Enviar contrato 
                    </Button>,
					<Button key={1} onClick={cancelEdit} >
						Fechar
					</Button>
				]}
			>
                {sent ? <ContratoEnviado /> : contrato.zapEnviadoParaAssinar ? <Enviado /> : <NaoEnviado />} 
			</Modal>
		</ConfigProvider>
	)
});