import React, { useEffect, useState } from 'react';
import { usePropriedades, atualizarPropriedade, buscarCep, deletarPropriedade, criarPropriedade} from "../../../apis/backend";
import { Table, Modal, Button, Spin, Form, Row, Col, Input, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MaskedInput } from 'antd-mask-input';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function PropriedadesCliente(props) {
    const [cliente] = useState(props.cliente);
	const [form] = Form.useForm();
	const {propriedades, mutate} = usePropriedades(cliente.id);
	const [formCreation] = Form.useForm();
	const [loadingModalButton, setLoadingModalButton] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
	const [visible, setVisible] = useState(false);
	const [visibleCriar, setVisibleCriar] = useState(false);
	const [currentPropriedade, setCurrentPropriedade] = useState({});
	const isLoading = props.isLoading
	const columns = [
		{ title: 'Razao Social', dataIndex: 'razaoSocial', key: 'razaoSocial' },
		{ title: 'CNPJ', dataIndex: 'cnpj', key: 'cnpj' },
		{ title: 'Cidade', dataIndex: 'cidade', key: 'cidade' },
		{
		  title: 'Ações',
		  dataIndex: '',
		  key: '',
		  render: (_, a) => AcoesPropriedade(a),
		},
	];
	const fieldsRules = [
        {
            required: true
        }
    ];

	useEffect(() => {
		isLoading(true);
		getPropriedades();
	}, []);
	
	async function updatePropriedade(e){
		setLoadingModalButton(true);
        e.id = currentPropriedade.id;

        await atualizarPropriedade(e, cliente.id);

		setLoadingModalButton(false);
		setVisible(false);
		await getPropriedades();
	}

	async function createPropriedade(e){
		setLoadingModalButton(true);

        await criarPropriedade(e, cliente.id);
		formCreation.resetFields();
		setLoadingModalButton(false);
		setVisibleCriar(false);
		await getPropriedades();
	}

	async function cancelCreation(){
		formCreation.resetFields();
		setVisibleCriar(false);
	}

	async function findCepUpdate(e){
        if(e.unmaskedValue.length !== 8)
            return;

		setLoadingModal(true);
        
        const endereco = await buscarCep(e.unmaskedValue);

        form.setFieldsValue({
            endereco: endereco.address,
            bairro: endereco.district,
            cidade: endereco.city,
            estado: endereco.state
        });

        setLoadingModal(false);
    }

	async function findCepCreate(e){
		if(e.unmaskedValue.length !== 8)
            return;

		setLoadingModal(true);
        
        const endereco = await buscarCep(e.unmaskedValue);

        formCreation.setFieldsValue({
            endereco: endereco.address,
            bairro: endereco.district,
            cidade: endereco.city,
            estado: endereco.state
        });

        setLoadingModal(false);
	}

	async function getPropriedades(){
		isLoading(true);
		await mutate()
		isLoading(false);
	}

	function onClickEdit(record){
		setVisible(true);
		setCurrentPropriedade(record);

		setTimeout(() => {
			form.resetFields();
		}, 0);
	}

	async function onClickDelete(record){
		isLoading(true);
		await deletarPropriedade(record.id)
		await getPropriedades();
		isLoading(false);
	}

	const ExpandedPropriedade = (record) => (
		<div>{record.endereco} {record.complemento}, Nr {record.numero} - {record.bairro}, {record.cidade} - {record.estado}, {record.cep}. IE: {record.ie}</div>
	);

	const AcoesPropriedade = (record) => (
		<>
			<div>
				<Button type="link" onClick={() => onClickEdit(record)}>Editar</Button>
				<Button type="link" onClick={() => onClickDelete(record)}>Deletar</Button>
			</div>
			<Modal
				title="Editar Propriedade"
				centered
				open={visible}
				width="90%"
				destroyOnClose={true}
				closable={false}
				keyboard={false}
				maskClosable={false}
				footer={[
					<Button key={1} onClick={() => setVisible(false)} loading={loadingModalButton}>
					  	Cancelar
					</Button>,
					<Button key={2} type="primary" loading={loadingModalButton} form="propriedadeForm" key="submit" htmlType="submit">
					  	Atualizar
					</Button>
				]}
			>
                <Spin indicator={antIcon} spinning={loadingModal} size="large" tip="Carregando...">
					<Form form={form} name="propriedadeForm" onFinish={updatePropriedade} initialValues={currentPropriedade}>
						<Row justify='space-between' gutter={4}>
							<Col span={10}>
								<Form.Item
								label="Razão Social"
								name={"razaoSocial"}
								rules={fieldsRules}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="CNPJ"
									name={"cnpj"}
									rules={fieldsRules}>
									<MaskedInput mask={'00.000.000/0000-00'}></MaskedInput>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label="IE"
									name={"ie"}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={5}>
								<Form.Item
								label="CEP"
								name={"cep"}
								rules={fieldsRules}>
									<MaskedInput mask={'00000-000'} onBlur={findCepUpdate}></MaskedInput>
								</Form.Item>
							</Col>
							<Col span={14}>
								<Form.Item
									label="Endereço"
									name={"endereco"}
									rules={fieldsRules}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={5}>
								<Form.Item
									label="Número"
									name={"numero"}>
								<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={24}>
								<Form.Item
									label="Complemento"
									name={"complemento"}>
								<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={8}>
								<Form.Item
									label="Bairro"
									name={"bairro"}
									rules={fieldsRules}>
								<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="Cidade"
									name={"cidade"}
									rules={fieldsRules}>
								<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="Estado"
									name={"estado"}
									rules={fieldsRules}>
								<Input />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		</>
	);

    return (
		<div>
			<Space size={"small"} direction="vertical" style={{width: "100%"}}>
				<div style={{width: "100%", display: "flex", justifyContent: 'end'}}>
				<Button type="primary" onClick={() => setVisibleCriar(true)}>Adicionar</Button>
				</div>
			
				<Table
					rowKey={"id"}
					columns={columns}
					expandable={{
						expandedRowRender: record => ExpandedPropriedade(record),
						rowExpandable: () => true,
					}}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 5,
						showSizeChanger: false
					}}
					dataSource={propriedades}
				/>
				<Modal
					title="Criar Propriedade"
					centered
					open={visibleCriar}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
					footer={[
						<Button key={1} onClick={cancelCreation} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="propriedadeCreationForm" key="submit" htmlType="submit">
							Criar
						</Button>
					]}
				>
					<Spin indicator={antIcon} spinning={loadingModal} size="large" tip="Carregando...">
						<Form form={formCreation} name="propriedadeCreationForm" onFinish={createPropriedade}>
							<Row justify='space-between' gutter={4}>
								<Col span={10}>
									<Form.Item
									label="Razão Social"
									name={"razaoSocial"}
									rules={fieldsRules}>
										<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label="CNPJ"
										name={"cnpj"}
										rules={fieldsRules}>
										<MaskedInput mask={'00.000.000/0000-00'}></MaskedInput>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										label="IE"
										name={"IE"}>
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row justify='space-between' gutter={4}>
								<Col span={5}>
									<Form.Item
									label="CEP"
									name={"cep"}
									rules={fieldsRules}>
										<MaskedInput mask={'00000-000'} onBlur={findCepCreate}></MaskedInput>
									</Form.Item>
								</Col>
								<Col span={14}>
									<Form.Item
										label="Endereço"
										name={"endereco"}
										rules={fieldsRules}>
										<Input />
									</Form.Item>
								</Col>
								<Col span={5}>
									<Form.Item
										label="Número"
										name={"numero"}>
									<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row justify='space-between' gutter={4}>
								<Col span={24}>
									<Form.Item
										label="Complemento"
										name={"complemento"}>
									<Input />
									</Form.Item>
								</Col>
							</Row>
							<Row justify='space-between' gutter={4}>
								<Col span={8}>
									<Form.Item
										label="Bairro"
										name={"bairro"}
										rules={fieldsRules}>
									<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label="Cidade"
										name={"cidade"}
										rules={fieldsRules}>
									<Input />
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label="Estado"
										name={"estado"}
										rules={fieldsRules}>
									<Input />
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Spin>
				</Modal>
			</Space>
        </div>
    )
}