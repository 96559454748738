import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Row, Col, Button, Modal, Form, notification, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { criarCaixa } from '../../../apis/backend';
import { MaskedInput } from 'antd-mask-input';
import { tiposCaixa } from '../../Utils/Utils';

export default NiceModal.create(() => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const modal = useModal();
    const [form] = Form.useForm();
    const fieldsRules = [
        {
            required: true,
        },
    ];

    async function create(payload) {
        setIsLoading(true);

        try {
            navigate(`/financeiro/caixa/${await criarCaixa(payload)}`);
            modal.hide();
        } catch (e) {
            setIsLoading(false);

            notification.error({
                message: 'Erro',
                description: 'Falha ao criar caixa. Tente novamente. ' + e,
            });
        }
    }

    function cancelCreation(shouldMutate) {
        if (shouldMutate) modal.resolve();

        modal.hide();
    }

    const CriarCaixaForm = () => {
        return (
            <Form form={form} name="caixaForm" onFinish={create}>
                <Row justify="space-between" gutter={4}>
                    <Col span={24}>
                        <Form.Item label="Nome" name={'nome'} rules={fieldsRules}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={6}>
                        <Form.Item label="Tipo" name={'tipo'} rules={fieldsRules}>
                            <Select options={tiposCaixa} />
                        </Form.Item>
                    </Col>
                    <Col span={18}>
                        <Form.Item label="Descrição" name={'descricao'}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={6}>
                        <Form.Item label="Titular" name={'titular'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="CPF" name={'cpf'}>
                            <MaskedInput mask={'000.000.000-00'}></MaskedInput>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="CNPJ" name={'cnpj'}>
                            <MaskedInput mask={'00.000.000/0000-00'}></MaskedInput>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="RG" name={'rg'}>
                            <MaskedInput mask={'00.000.000-[*]'}></MaskedInput>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-between" gutter={4}>
                    <Col span={7}>
                        <Form.Item label="Banco" name={'banco'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Código Bancário" name={'codigoBancario'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Conta Corrente" name={'contaCorrente'}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Agência" name={'agencia'}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title="Criar Caixa"
            centered
            open={modal.visible}
            width="90%"
            destroyOnClose={true}
            closable={false}
            keyboard={false}
            maskClosable={false}
            afterClose={() => modal.remove()}
            footer={[
                <Button key={1} onClick={cancelCreation} isLoading={isLoading}>
                    Cancelar
                </Button>,
                <Button key={2} type="primary" form="caixaForm" htmlType="submit" isLoading={isLoading}>
                    Salvar
                </Button>,
            ]}
        >
            <CriarCaixaForm />
        </Modal>
    );
});
