import "./InformacoesGeraisProduto.scoped.css";
import React, { useState } from 'react';
import { Form, Row, Col, Input, notification, InputNumber, Select } from 'antd';
import { atualizarProduto, useCodigosNcm, useCodigosCest } from "../../../apis/backend";
import { filterSelect } from '../../Utils/Utils';

export default function InformacoesGeraisProduto(props) {
    const {ncms, isLoading : isLoadingNcms} = useCodigosNcm();
	const {cests, isLoading : isLoadingCests} = useCodigosCest();
	const [produto] = useState(props.produto);
	const isLoading = props.isLoading
	const [form] = Form.useForm();
	const fieldsRules = [
        {
            required: true
        }
    ];

	async function updateProduto(e){
		isLoading(true);
		
        e.id = produto.id;

        try{
			await atualizarProduto(e);
			await props.mutate(e.id);
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao atualizar produto.'
            });
        }catch(e){
            notification.error({
                message: 'Erro',
                description:
                  e,
            });
        }

		isLoading(false);
    }
   
	return (
		<div>
			<Form
				form={form}
				name="produtoForm"
				onFinish={updateProduto}
				initialValues={produto}
			>
                <Row justify='space-between' gutter={4}>
                    <Col span={24}>
                        <Form.Item
                        label="Descrição"
                        name={"descricao"}
                        rules={fieldsRules}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={8}>
                        <Form.Item
                            label="NCM"
                            name={"ncmId"}>
							<Select
								allowClear
								showSearch
								loading={isLoadingNcms}
								filterOption={filterSelect}
								options={ncms}
							/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="CEST"
                            name={"cestId"}>
							<Select
								allowClear
								showSearch
								loading={isLoadingCests}
								filterOption={filterSelect}
								options={cests}
							/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Quebra Técnica (Dia)"
                            name={"quebraTecnica"}>
                            <InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
			</Form>
		</div>
	);
}
