import React, { useState } from 'react';
import { getTransacoesByDate } from '../../../apis/queries/graphQueries';
import { Table, Space, DatePicker, Button } from 'antd';
import { useModal } from '@ebay/nice-modal-react';
import dayjs from 'dayjs';
import locale from 'antd/lib/locale/pt_BR';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { formatCurrencyTwo, formatDateWithTime } from '../../Utils/Utils';
import TransacoesActions from '../TransacoesActions/TransacoesActions';
import CriarTransacaoModal from '../CriarTransacaoModal/CriarTransacaoModal';

const { RangePicker } = DatePicker;

export default function TransacoesCaixa(props) {
    const criarTransacaoModal = useModal(CriarTransacaoModal);

    function criarTransacao() {
        criarTransacaoModal
            .show({
                caixaId: props?.caixaId || null,
            })
            .then(() => {
                mutate();
            });
    }
    const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
    const { data, isLoading, mutate } = useGraphQL(getTransacoesByDate, {
        caixaId: parseInt(props.caixaId),
        afterThen: range[0].startOf('day').toISOString(),
        beforeThen: range[1].endOf('day').toISOString(),
    });

    const columns = [
        {
            title: 'Tipo',
            dataIndex: 'tipo',
            key: 'id',
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            render: (value) => formatCurrencyTwo(value),
            key: 'id',
        },
        {
            title: 'Relacionado',
            dataIndex: 'relatedValue',
            render: (value) => formatCurrencyTwo(value),
            key: 'id',
        },
        {
            title: 'Data',
            dataIndex: 'data',
            render: (value) => formatDateWithTime(value),
            key: 'id',
        },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            key: 'id',
            ellipsis: true,
        },
        {
            title: 'Ações',
            key: 'action',
            width: '180px',
            render: (_, record) => <TransacoesActions transacao={record} mutate={mutate} />,
        },
    ];

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                <RangePicker
                    allowClear={false}
                    locale={locale}
                    format={'DD/MM/YYYY'}
                    style={{ width: '94%', height: '100%' }}
                    defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                    size="medium"
                    onChange={setRange}
                />
                <Button type="primary" style={{ width: '5%' }} size={'medium'} onClick={criarTransacao}>
                    Criar
                </Button>
            </div>
            <Space size="middle">
                <Table
                    rowKey="id"
                    loading={isLoading}
                    columns={columns}
                    dataSource={data?.caixaTransactions}
                    pagination={{
                        simple: false,
                        hideOnSinglePage: true,
                        defaultPageSize: 7,
                        showSizeChanger: false,
                    }}
                />
            </Space>
        </div>
    );
}
