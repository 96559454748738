import React, { useState } from 'react';
import { Button, notification, Modal, Select, Checkbox, Descriptions } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { tiposChamada } from '../../../Utils/Utils';
import { notificarChamada } from '../../../../apis/backend';

export default NiceModal.create(({ record }) => {
    const modal = useModal();
    const [isProcessing, setIsProcessing] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [chamada, setChamada] = useState(tiposChamada[0].value);

    function onClose(shouldMutate) {
        if (shouldMutate) modal.resolve();
        modal.hide();
    }

    async function onNotificar() {
        setIsProcessing(true);
        try {
            await notificarChamada(record.id, chamada, whatsapp);
            notification.success({
                message: 'Sucesso',
                description: `Motorista notificado.`,
            });
            onClose(true);
        } catch (e) {
            notification.error({
                message: 'Erro',
                description: `${e.response.data.message} Motorista não notificado.`,
            });
            console.error(e);
        }
        setIsProcessing(false);
    }

    const ModalContent = () => {
        return (
            <span>
                <Descriptions bordered column={24} size="small">
                    <Descriptions.Item label="Placa" span={4}>
                        {record.placaVeiculo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nome" span={6}>
                        {record.motoristaNome}
                    </Descriptions.Item>
                    <Descriptions.Item label="CPF" span={4}>
                        {record.motoristaCPF}
                    </Descriptions.Item>
                    <Descriptions.Item label="Celular" span={4}>
                        {record.motoristaCelular}
                    </Descriptions.Item>
                </Descriptions>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                    <div>
                        Tipo de chamada:
                        <Select style={{ width: '100%' }} options={tiposChamada} value={chamada} onChange={setChamada} />
                    </div>
                    <Checkbox onChange={(e) => setWhatsapp(e.target.checked)} checked={whatsapp}>
                        Notificar também via Whatsapp
                    </Checkbox>
                </div>
            </span>
        );
    };

    return (
        <Modal
            title={`Chamada`}
            centered
            open={modal.visible}
            width="90%"
            destroyOnClose={true}
            closable={false}
            keyboard={false}
            maskClosable={false}
            afterClose={() => modal.remove()}
            footer={[
                <Button key={1} onClick={onClose} disabled={isProcessing} loading={isProcessing}>
                    Fechar
                </Button>,
                <Button key={2} type="primary" onClick={onNotificar} disabled={isProcessing} loading={isProcessing}>
                    Notificar
                </Button>,
            ]}
        >
            <ModalContent />
        </Modal>
    );
});
