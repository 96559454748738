import React, { useEffect, useState } from 'react';
import { Popover, Divider, Typography } from 'antd';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { formatRoundedPeso } from './Utils';

const { Text } = Typography;

export default function BalancasTooltip(props) {
	const [connection, setConnection] = useState(null);
	const [connectionStatus, setConnectionStatus] = useState('Conectando');
	const [latestEscritorio, setLatestEscritorio] = useState({
		local: 'escritorio',
		stable: getStable('ST'),
		peso: 0
	});
	const [latestPatio, setLatestPatio] = useState({
		local: 'patio',
		stable: getStable('ST'),
		peso: 0
	});

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl('https://be-bo.limaetarifa.com.br/balanca-hub')
			.withAutomaticReconnect()
			.build();
		
		newConnection.onclose(() => setConnectionStatus('Desconectado'));
		newConnection.onreconnecting(() => setConnectionStatus('Reconectando'));

		setConnection(newConnection);

		return() => {
			newConnection.stop();
		};
	}, []);

	useEffect(() => {
		if (connection) {
			connection.start()
				.then(result => {
					setConnectionStatus('Conectado')

					connection.on('BalancaUpdate', message => {
						const msgArr = message.split(',');

						if(msgArr[0].toLowerCase() === 'escritorio'){
							setLatestEscritorio({
								local: msgArr[0],
								stable: getStable(msgArr[1]),
								peso: parseFloat(msgArr[3].replace('+', '').replace('kg', '') || 0)
							});
						}

						if(msgArr[0].toLowerCase() === 'patio'){
							setLatestPatio({
								local: msgArr[0],
								stable: getStable(msgArr[1]),
								peso: parseFloat(msgArr[3].replace('+', '').replace('kg', '').replace('\r', '') || 0)
							});
						}
						
					});
					
				})
				.catch(e => {
					setConnectionStatus('Desconectado');
				});
		}
	}, [connection]);

	function getStable(strType){
		const types = {
			'ST': 'success',
			'US': 'warning',
			'OL': 'danger',
		}

		return types[strType];
	}

	function onClickEscritorio(){
		if(props.onClickEscritorio)
			props.onClickEscritorio(latestEscritorio);
	}

	function onClickPatio(){
		if(props.onClickPatio)
			props.onClickPatio(latestPatio);
	}

	function ContentBalanca() {
		return <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', userSelect: 'none' }}>
			<div onClick={onClickEscritorio} style={{cursor: 'pointer'}}>Escritório: <Text type={latestEscritorio.stable} strong>{formatRoundedPeso(latestEscritorio.peso)}</Text></div>
			<hr style={{width: '100%'}}></hr>
			<div onClick={onClickPatio} style={{cursor: 'pointer'}}>Pátio: <Text type={latestPatio.stable} strong >{formatRoundedPeso(latestPatio.peso)}</Text></div>
		</div>
	}

	return (
		<Popover placement="topLeft" content={ContentBalanca}>
			{props.children}
		</Popover>
	);
}