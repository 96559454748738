import React, { useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal, ConfigProvider, Input, Card, Col, Row, InputNumber, Select, Form, Descriptions, notification, Popover } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import ptBr from 'antd/lib/locale/pt_BR';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { getLancamentosNaoPagos, getRelationsTransacoes } from '../../../apis/queries/graphQueries';
import { reaisFormatter, reaisParser, filterSelect, formatCurrency, formatDayDate } from '../../Utils/Utils';
import { patchLancamentoRelations } from '../../../apis/backend';
import { Link } from 'react-router-dom';

const { Option } = Select;

export default NiceModal.create(({ transacao }) => {
    const { data, isLoading: isLoadingLancamentos } = useGraphQL(getLancamentosNaoPagos);
    const { data: lancamentoRelations } = useGraphQL(getRelationsTransacoes, { transactionId: transacao.id });
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLancamentos, setSelectedLancamentos] = useState([]);
    const [selectedLancamentosValues, setSelectedLancamentosValues] = useState([]);
    const modal = useModal();
    const fieldsRules = [
        {
            required: true,
        },
    ];

    function cancelEdit() {
        modal.hide();
    }

    async function onSave() {
        setIsLoading(true);
        const filteredLancamentos = selectedLancamentosValues.filter((x) => x !== undefined && x !== null);

        if (filteredLancamentos.filter((x) => x.lancamentoId == null || x.valor <= 0).length > 0) {
            notification.error({
                message: 'Erro',
                description: 'O campo lançamento e valor são obrigatórios.',
            });
            setIsLoading(false);
            return;
        }

        if (filteredLancamentos.reduce((acc, currVal) => acc + currVal.valor, 0) > transacao.valor) {
            notification.error({
                message: 'Erro',
                description: 'O valor total dos lançamentos relacionados não pode ser maior que o valor da transação.',
            });
            setIsLoading(false);
            return;
        }

        try {
            await patchLancamentoRelations(transacao.id, filteredLancamentos);
            notification.success({
                message: 'Sucesso',
                description: 'Os relacionamentos foram atualizados',
            });
            modal.resolve();
            modal.hide();
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao atualizar. Tente novamente.',
            });
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (Array.isArray(lancamentoRelations?.transacaoLancamentoRelations)) {
            const novoArr = [];

            lancamentoRelations?.transacaoLancamentoRelations.forEach((x) => {
                novoArr.push({
                    idJs: crypto.randomUUID(),
                    ...x,
                });
            });

            setSelectedLancamentosValues([...novoArr]);
            setSelectedLancamentos([...novoArr]);
        }
    }, [lancamentoRelations]);

    function onUpdateValue(value) {
        let index = selectedLancamentosValues.findIndex((x) => x?.idJs === value.idJs);
        index = index === -1 ? selectedLancamentosValues.length + 1 : index;
        selectedLancamentosValues[index] = value;

        setSelectedLancamentosValues(selectedLancamentosValues);
    }

    const InformacoesLancamento = ({ lancamento }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Tipo: {lancamento.tipo}</span>
                <span>Categoria: {lancamento.categoria}</span>
                <span>
                    Descrição:
                    {
                        <Link to={`/financeiro/lancamento/${lancamento.id}`} target={'_blank'}>
                            {lancamento.descricao}
                        </Link>
                    }
                </span>
                <span>Vencimento: {formatDayDate(lancamento.vencimento, true)}</span>
                <span>Valor: {reaisFormatter(lancamento.valorTotal)}</span>
                <span>
                    Cliente:{' '}
                    {
                        <Link to={`/cadastros/cliente/${lancamento.clienteId}`} target={'_blank'}>
                            {lancamento?.cliente?.nomeCompleto}
                        </Link>
                    }
                </span>
                <span>
                    Fornecedor:{' '}
                    {
                        <Link to={`/cadastros/cliente/${lancamento.fornecedorId}`} target={'_blank'}>
                            {lancamento?.fornecedor?.nomeCompleto}
                        </Link>
                    }
                </span>
                <span>
                    Contrato:{' '}
                    {
                        <Link to={`/cadastros/contrato/${lancamento.contratoId}`} target={'_blank'}>
                            {lancamento?.contrato?.numeroDeContrato}
                        </Link>
                    }
                </span>
            </div>
        );
    };

    const RelateComponent = ({ value, onUpdateValue }) => {
        const [form] = Form.useForm();

        const [newValue, setNewValue] = useState(Object.assign({}, value));
        const initialValue = selectedLancamentosValues.find((x) => x?.idJs === value.idJs);

        function onDelete() {
            setSelectedLancamentosValues(selectedLancamentosValues.filter((item) => item.idJs !== value.idJs));
            setSelectedLancamentos(selectedLancamentos.filter((item) => item.idJs !== value.idJs));
        }

        function onFormChange(evt) {
            newValue[evt[0].name[0]] = evt[0].value;
            setNewValue(newValue);
            onUpdateValue(newValue);
        }

        return (
            <Card actions={[<DeleteOutlined key="ellipsis" onClick={onDelete} />]} style={{ height: '267px' }} key={value.idJs}>
                <Form initialValues={initialValue} onFieldsChange={onFormChange} form={form}>
                    <Row justify="space-between" gutter={4}>
                        <Col span={24}>
                            <Form.Item label="Lançamento" name={'lancamentoId'} rules={fieldsRules}>
                                <Select loading={isLoadingLancamentos} showSearch filterOption={filterSelect}>
                                    {data?.lancamentos?.map((val) => {
                                        return (
                                            <Option key={val.id} value={val.id} label={val.descricao} style={{ width: '100%' }}>
                                                <Popover placement="right" style={{ width: '100%' }} title={val.tipo} content={<InformacoesLancamento lancamento={val} />}>
                                                    <div>{val.descricao}</div>
                                                </Popover>
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={24}>
                            <Form.Item label="Valor" name={'valor'} rules={fieldsRules}>
                                <InputNumber style={{ width: '100%' }} addonBefore={'R$'} formatter={(value) => reaisFormatter(value)} parser={(value) => reaisParser(value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={24}>
                            <Form.Item label="Descrição" name={'descricao'}>
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    };

    const RelateContainerComponent = () => {
        return (
            <div>
                <Row gutter={[24, 24]}>
                    {selectedLancamentos?.map((value) => {
                        return (
                            <Col span={8} key={value.idJs} style={{ width: '100%' }}>
                                <RelateComponent value={value} onUpdateValue={onUpdateValue} />
                            </Col>
                        );
                    })}
                    <Col span={8}>
                        <Card title="Adicionar lançamento" style={{ height: '267px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setSelectedLancamentos([
                                        ...selectedLancamentos,
                                        {
                                            idJs: crypto.randomUUID(),
                                            descricao: '',
                                            lancamentoId: 0,
                                            valor: 0,
                                        },
                                    ])
                                }
                            >
                                <PlusCircleOutlined
                                    style={{
                                        color: 'black',
                                        fontSize: '80px',
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Relacionar Transação"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelEdit} loading={isLoading} disabled={isLoading}>
                        Fechar
                    </Button>,
                    <Button key={2} type="primary" htmlType="submit" loading={isLoading} disabled={isLoading} onClick={onSave}>
                        Salvar
                    </Button>,
                ]}
            >
                <div>
                    <Descriptions title="">
                        <Descriptions.Item label="Descrição">{transacao.descricao}</Descriptions.Item>
                        <Descriptions.Item label="Valor">{formatCurrency(transacao.valor)}</Descriptions.Item>
                    </Descriptions>
                    <RelateContainerComponent />
                </div>
            </Modal>
        </ConfigProvider>
    );
});
