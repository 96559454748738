import backendApi from '../backendAxiosClient';

export async function criarLancamento(payload) {
	return (await backendApi.post('lancamento', JSON.stringify(payload))).data;
}

export async function updateLancamento(payload) {
	return await backendApi.patch('lancamento', JSON.stringify(payload));
}

export async function relateTransacao(lancamentosIds, transacaoId) {
	return await backendApi.post(`lancamento/relate?lancamentosIds=${lancamentosIds}&transacaoId=${transacaoId}`);
}