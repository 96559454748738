import React, { useState } from 'react';
import { Form, Row, Col, Select, DatePicker, Input, notification, InputNumber, Divider } from 'antd';
import { useClientesRomaneio, useProdutosRomaneio, atualizarContrato } from "../../../apis/backend";
import { reaisFormatter, reaisParser, filterSelect, modalidadeOptions, tipoFretes } from '../../Utils/Utils';
import ComentariosContrato from '../ComentariosContrato/ComentariosContrato';
import TransacoesContrato from '../TransacoesContrato/TransacoesContrato';
import dayjs from 'dayjs';
import LancamentosRelacionados from '../../Financeiro/LancamentosRelacionados/LancamentosRelacionados';

export default function InformacoesGeraisCliente({ isLoading, contrato : contratoProps, mutate}) {
	const [contrato] = useState(contratoProps);
	const [form] = Form.useForm();
    const {produtos, isLoading : isLoadingProdutos} = useProdutosRomaneio();
    const {clientes, isLoading : isLoadingClientes} = useClientesRomaneio();
	const fieldsRules = [
		{
			required: true
		}
	];

    async function updateContrato(e){
		isLoading(true);

        e.id = contrato.id;
		e.dataDePagamento = dayjs(e.dataDePagamento).toDate();
		e.prazoDeEmbarque = dayjs(e.prazoDeEmbarque).toDate();
		e.dataInicioEntrega = dayjs(e.dataInicioEntrega).toDate();

		if(e.tipoFrete === tipoFretes[1].value && (!e.transportador || !e.frete)) {
			isLoading(false);

			return form.setFields([{
				name: 'transportador',
				errors: ["Preencha o transportador e frete"]
			},
			{
				name: 'frete',
				errors: ["Preencha o transportador e frete"]
			}]);
		}

        try{
			await atualizarContrato(e);
			const res = await mutate(e.id);
			res.dataDePagamento = dayjs(res?.dataDePagamento);
			res.prazoDeEmbarque = dayjs(res?.prazoDeEmbarque);
			res.dataInicioEntrega = dayjs(res?.dataInicioEntrega);
			form.setFieldsValue(res);
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao atualizar contrato.'
            });
        }catch(e){
            notification.error({
                message: 'Erro',
                description: 'Falha ao atualizar contrato, tente novamente.'
            });
        }

		isLoading(false);
    }

	return (
		<div>
			<Form form={form} name="contratoForm" initialValues={contrato} onFinish={updateContrato}>
				<Row justify='space-between' gutter={4}>
					<Col span={24}>
						<Form.Item
							label="Modalidade"
							name={"modalidade"}
							rules={fieldsRules}>
							<Select options={modalidadeOptions}/>
						</Form.Item>
					</Col>
				</Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={12}>
                        <Form.Item
                            label="Comprador"
                            name={"compradorId"}
                            rules={fieldsRules}>
							<Select loading={isLoadingClientes} showSearch filterOption={filterSelect} options={clientes} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Vendedor"
                            name={"vendedorId"}
                            rules={fieldsRules}>
							<Select loading={isLoadingClientes} showSearch filterOption={filterSelect} options={clientes} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={8}>
                        <Form.Item
                            label="Produto"
                            name={"produtoId"}
                            rules={fieldsRules}>
							<Select loading={isLoadingProdutos} showSearch filterOption={filterSelect} options={produtos} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Quantidade Total"
                            name={"quantidadeTotal"}
                            rules={fieldsRules}>
                            <InputNumber style={{width: "100%"}} addonAfter={"KG"}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Preço"
                            name={"preco"}
                            rules={fieldsRules}>
                            <InputNumber 
                                style={{width: "100%"}}
                                addonBefore={"R$ / KG"}
                                formatter={value => reaisFormatter(value)}
                                parser={value => reaisParser(value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={6}>
                        <Form.Item
                            label="Número de Contrato"
							name={"numeroDeContrato"}
                            rules={fieldsRules}>
							<Input disabled />
                        </Form.Item>
                    </Col>
					<Col span={6}>
						<Form.Item
							label="Data Início Entrega"
							name={"dataInicioEntrega"}>
							<DatePicker format={'DD/MM/YYYY'} style={{width: "100%"}}/>
						</Form.Item>
					</Col>
                    <Col span={6}>
                        <Form.Item
                            label="Data de Pagamento"
                            name={"dataDePagamento"}
                            rules={fieldsRules}>
                            <DatePicker format={'DD/MM/YYYY'} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Prazo de Embarque"
                            name={"prazoDeEmbarque"}
                            rules={fieldsRules}>
                            <DatePicker format={'DD/MM/YYYY'} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                </Row>
				<Row justify='space-between' gutter={4}>
					<Col span={3}>
						<Form.Item
							label="T. Frete"
							name={"tipoFrete"}
							rules={fieldsRules}>
							<Select options={tipoFretes}/>
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							label="Transportador"
							name={"transportador"}
						>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="Frete"
							name={"frete"}>
							<InputNumber
								style={{ width: "100%" }}
								addonBefore={"R$/KG"}
								formatter={value => reaisFormatter(value)}
								parser={value => reaisParser(value)}
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="Corretor"
							name={"corretor"}
						>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="Comissão"
							name={"comissao"}>
							<InputNumber
								style={{ width: "100%" }}
								addonBefore={"R$/KG"}
								formatter={value => reaisFormatter(value)}
								parser={value => reaisParser(value)}
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="CTR Externo"
							name={"contratoExterno"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				</Row>
				<Row justify='space-between' gutter={4}>
                    <Col span={24}>
                        <Form.Item
                            label="Local de Entrega"
                            name={"localEntrega"}>
                            <Input />
                        </Form.Item>
                    </Col>
				</Row>
				<Row justify='space-between' gutter={4}>
					<Col span={5}>
						<Form.Item
							label="Banco"
							name={"bancoConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item
							label="C.c"
							name={"contaCorrente"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item
							label="Agência"
							name={"agencia"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							label="CNPJ"
							name={"cnpjConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="CPF"
							name={"cpfConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="RG"
							name={"rgConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				</Row>
                <Row justify='space-between' gutter={4}>
                    <Col span={12}>
                        <Form.Item
                            label="Quantidade Embarcada"
                            name={"quantidadeEmbarcada"}
                            rules={fieldsRules}>
                            <InputNumber disabled style={{width: "100%"}} addonAfter={"KG"}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Saldo a Embarcar"
                            name={"saldoAEmbarcar"}
                            rules={fieldsRules}>
                            <InputNumber disabled style={{width: "100%"}} addonAfter={"KG"}/>
                        </Form.Item>
                    </Col>
                </Row>
				<Row justify='space-between' gutter={4}>
                    <Col span={24}>
                        <Form.Item
                            label="Observações"
                            name={"observacoes"}>
                            <Input />
                        </Form.Item>
                    </Col>
				</Row>
            </Form>
			<Divider orientation="left">Transações</Divider>
			<TransacoesContrato contratoId={contrato.id}></TransacoesContrato>
			<Divider orientation="left">Lançamentos</Divider>
            <LancamentosRelacionados context={'contrato'} id={contrato.id}></LancamentosRelacionados>
			<Divider orientation="left">Comentários</Divider>
            <ComentariosContrato contratoId={contrato.id}></ComentariosContrato>
		</div>
	);
}
