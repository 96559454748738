import React, { useEffect, useState } from 'react';
import { Breadcrumb, Divider, Spin, Button, Tabs } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from "react-router-dom";
import { useProduto } from '../../../apis/backend';

import InformacoesGeraisProduto from '../InformacoesGeraisProduto/InformacoesGeraisProduto'
import UmidadeProduto from "../UmidadeProduto/UmidadeProduto";
import PrestacaoServicoProduto from "../PrestacaoServicoProduto/PrestacaoServicoProduto";

export default function DetalheProduto(props) {
	const navigate = useNavigate();
    const {produto, isLoading, mutate } = useProduto(props.id);
    const [pageLoading, setPageLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [isProdutoLoaded, setIsProdutoLoaded] = useState(false);

    useEffect(() => {
        setIsProdutoLoaded(produto != null);
        setPageLoading(isLoading);
    }, [produto, isLoading]);


    const items = [{
        key: '1',
        label: 'Informações Gerais',
        children: isProdutoLoaded ? <InformacoesGeraisProduto mutate={mutate} produto={produto} isLoading={setPageLoading} /> : <></>
    },{
        key: '2',
        label: 'Umidade',
        children: isProdutoLoaded ? <UmidadeProduto produto={produto} isLoading={setPageLoading} /> : <></>
    },{
        key: '3',
        label: 'Prestação de Serviço',
        children: isProdutoLoaded ? <PrestacaoServicoProduto produto={produto} isLoading={setPageLoading} /> : <></>
    }];

    return (
        <div>
            <Spin tip="Carregando..." spinning={pageLoading || isLoading}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Cadastros</Breadcrumb.Item>
                        <Breadcrumb.Item>
                        <Link to={`/cadastros/produto/`}>Produto</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/cadastros/produto/${props.id}`}>{produto?.descricao}</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Divider />
				<ProPageHeader
					style={{paddingLeft: 0, paddingRight: 0}}
					title={produto?.descricao}
					className="site-page-header"
					onBack={() => navigate("/cadastros/produto")}
					extra={[
						<Button type="primary" form="produtoForm" key="1" htmlType="submit" disabled={activeTab !== "1"}>Salvar</Button>,
					]}
                >
				</ProPageHeader>
                <div>
					<Tabs defaultActiveKey="1" onChange={setActiveTab} items={items} />
                </div>
            </Spin>
        </div>
    )
}