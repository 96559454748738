import React from 'react';
import './ListarDescargas.scoped.css';
import { Table, Space, Button } from 'antd';
import { useDescargas } from '../../../apis/backend';
import { useModal } from '@ebay/nice-modal-react';
import EditarDescarga from '../EditarDescarga/EditarDescarga';

export default function ListarDescargas() {
	const { descargas, isLoading: isLoadingDescargas, mutate: mutateDescargas } = useDescargas();
	const aprovacaoModal = useModal(EditarDescarga);
	const columns = [
		{
		  title: 'Moega',
		  dataIndex: 'moega',
		  key: 'moega',
		  ellipsis: true,
		},
		{
			title: 'Produto',
			dataIndex: ["produto", "descricao"],
			key: "produtoDescricao"
		},
		{
			title: 'Umidade Inicial',
			dataIndex: "umidadeInicial",
			key: "umidadeInicial"
		},
		{
			title: 'Umidade Final',
			dataIndex: "umidadeFinal",
			key: "umidadeFinal"
		},
		{
			title: 'Ações',
			dataIndex: '',
			key: '',
			render: (_, a) => <Button type="link" onClick={() => showEdit(a)}>Editar</Button>
		}
	];

	function showEdit(e){
		aprovacaoModal.show({ descarga: e }).then(() => {
			mutateDescargas();
		});
	}

	return (
		<div>
			<Space size="middle">
				<Table rowKey="id" columns={columns} dataSource={descargas} loading={isLoadingDescargas} pagination={{
					simple: false,
					hideOnSinglePage: true,
					defaultPageSize: 7,
					showSizeChanger: false
				}}/>
			</Space>
		</div>
	);
}