import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeGrid as Grid } from 'react-window';
import { Select, DatePicker, Button, Table, Typography, theme, Tooltip } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';
import { useRelatorioContratos, useClientesRomaneio, useProdutosRomaneio } from '../../apis/backend';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { formatPeso, formatCurrency, getCustomerName, filterSelect, formatDate } from '../Utils/Utils';

const { Text } = Typography;

const { RangePicker } = DatePicker;

export default function RelatorioContratos() {
	const { clientes: clientesDb } = useClientesRomaneio();
	const { produtos: produtosDb } = useProdutosRomaneio();
	const [compradores, setCompradores] = useState([]);
	const [vendedores, setVendedores] = useState([]);
	const [produto, setProduto] = useState();
	const [tipos, setTipos] = useState("");
	const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
	const [rangePagamento, setRangePagamento] = useState([dayjs().startOf('year'), dayjs().endOf('year')]);
	const { relatorio, isLoading: isFetching } = useRelatorioContratos(!!range ? range[0]?.startOf('day').toISOString() : '', !!range ? range[1]?.endOf('day').toISOString() : '', tipos, compradores, vendedores, produto, rangePagamento[0].startOf('month').toISOString(), rangePagamento[1].endOf('month').toISOString());
	const [fixedReport, setFixedReport] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [resumo, setResumo] = useState([]);
	const tableRef = useRef();
	const columns = [
		{
			title: 'Contrato',
			render: valor => <Link target="_blank" to={`/cadastros/contrato/${valor.id}`}>{valor.numeroDeContrato}</Link>,
			align: 'center',
			key: 1,
		},
		{
			title: 'Comprador',
			render: valor => <Link target="_blank" to={`/cadastros/cliente/${valor.compradorId}`}>{getCustomerName(valor.comprador)}</Link>,
			align: 'center',
			key: 2,
		},
		{
			title: 'Vendedor',
			render: valor => <Link target="_blank" to={`/cadastros/cliente/${valor.vendedorId}`}>{getCustomerName(valor.vendedor)}</Link>,
			align: 'center',
			key: 3,
		},
		{
			title: "Total",
			dataIndex: "quantidadeTotal",
			render: valor => formatPeso(valor),
			key: 4,
			align: 'center'
		},
		{
			title: "Embarcado",
			dataIndex: "quantidadeEmbarcada",
			render: valor => formatPeso(valor),
			key: 5,
			align: 'center'
		},
		{
			title: "Saldo",
			dataIndex: "saldoAEmbarcar",
			render: valor => formatPeso(valor),
			key: 6,
			align: 'center'
		},
		{
			title: "Produto",
			render: valor => <Link target="_blank" to={`/cadastros/produto/${valor.produtoId}`}>{valor.produto.descricao}</Link>,
			key: 7,
			align: 'center'
		},
		{
			title: "Preço",
			dataIndex: "preco",
			render: valor => !!valor ? formatCurrency(valor) : '',
			key: 8,
			align: 'center'
		},
		{
			title: "Pagamento",
			dataIndex: "dataDePagamento",
			render: valor => !!valor ? formatDate(valor, true) : '',
			key: 8,
			align: 'center'
		}
	];

	useEffect(() => {
		if (Array.isArray(relatorio)) {
			setIsLoading(true);

			const tableResumo = [];

			relatorio.forEach(x => {
				let objArr = tableResumo[x.produtoId];
				if (objArr == null)
					objArr = {};

				objArr.nome = x.produto.descricao;

				objArr.quantidadeEmbarcada = objArr.quantidadeEmbarcada ? objArr.quantidadeEmbarcada + x.quantidadeEmbarcada : x.quantidadeEmbarcada;
				if (x.saldoAEmbarcar > 0) {
					objArr.saldoAEmbarcar = objArr.saldoAEmbarcar ? objArr.saldoAEmbarcar + x.saldoAEmbarcar : x.saldoAEmbarcar;
					objArr.vigente = objArr.vigente ? objArr.vigente + x.quantidadeTotal : x.quantidadeTotal;
				} else {
					objArr.finalizado = objArr.finalizado ? objArr.finalizado + x.quantidadeTotal : x.quantidadeTotal;
				}
				tableResumo[x.produtoId] = objArr;
			});

			setResumo(tableResumo);
			setFixedReport(relatorio);
			setIsLoading(false);
		}
	}, [relatorio]);

	const VirtualTable = (props) => {
		const { columns, scroll } = props;
		const [tableWidth, setTableWidth] = useState(0);
		const { token } = theme.useToken();
		const widthColumnCount = columns.filter(({ width }) => !width).length;
		const mergedColumns = columns.map((column) => {
		  if (column.width) {
			return column;
		  }
		  return {
			...column,
			width: Math.floor(tableWidth / widthColumnCount),
		  };
		});
		const gridRef = useRef();
		const [connectObject] = useState(() => {
		  const obj = {};
		  Object.defineProperty(obj, 'scrollLeft', {
			get: () => {
			  if (gridRef.current) {
				return gridRef.current?.state?.scrollLeft;
			  }
			  return null;
			},
			set: (scrollLeft) => {
			  if (gridRef.current) {
				gridRef.current.scrollTo({
				  scrollLeft,
				});
			  }
			},
		  });
		  return obj;
		});
		const resetVirtualGrid = () => {
		  gridRef.current?.resetAfterIndices({
			columnIndex: 0,
			shouldForceUpdate: true,
		  });
		};
		useEffect(() => resetVirtualGrid, [tableWidth]);
		const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
		  ref.current = connectObject;
		  const totalHeight = rawData.length * 54;
		  return (
			<Grid
			  ref={gridRef}
			  className="virtual-grid"
			  columnCount={mergedColumns.length}
			  columnWidth={(index) => {
				const { width } = mergedColumns[index];
				return totalHeight > scroll?.y && index === mergedColumns.length - 1
				  ? width - scrollbarSize - 1
				  : width;
			  }}
			  height={scroll.y}
			  rowCount={rawData.length}
			  rowHeight={() => 54}
			  width={tableWidth}
			  onScroll={({ scrollLeft }) => {
				onScroll({
				  scrollLeft,
				});
			  }}
			>
			  {({ columnIndex, rowIndex, style }) => (
				<div
				  className={classNames('virtual-table-cell', {
					'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
				  })}
				  style={{
					...style,
					boxSizing: 'border-box',
					padding: token.padding,
					borderBottom: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
					background: token.colorBgContainer,
				  }}
				>
				  {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]}
				</div>
			  )}
			</Grid>
		  );
		};
		return (
			<ResizeObserver
			  onResize={({ width }) => {
				setTableWidth(width);
			  }}
			>
			  <Table
			  	ref={tableRef}
				{...props}
				className="virtual-table"
				columns={mergedColumns}
				pagination={false}
				components={{
				  body: renderVirtualList,
				}}
			  />
			</ResizeObserver>
		  );
	};

	return (
		<div>
			<div style={{ display: "flex" }}>
				<Tooltip title="Data de Criação">
					<RangePicker
						allowClear={true}
						locale={locale}
						format={"DD/MM/YYYY"}
						defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
						style={{ width: '16.5%', height: '100%', paddingLeft: '5px' }}
						size='medium'
						onChange={setRange} />
				</Tooltip>
				<div style={{ width: '16.5%', height: '100%', paddingLeft: '5px' }}>
					<Tooltip title="Data de Pagamento" >
						<RangePicker
							allowClear={false}
							locale={locale}
							format={"MM/YYYY"}
							defaultValue={[dayjs().startOf('year'), dayjs().endOf('year')]}
							style={{ height: '100%' }}
							size='medium'
							picker="month"
							onChange={setRangePagamento} />
					</Tooltip>
				</div>
				<Select
					size='medium'
					placeholder="Tipos"
					mode="multiple"
					allowClear
					style={{ width: '16.5%', paddingLeft: '5px', height: '100%' }}
					onChange={setTipos}
					filterOption={filterSelect}
					options={[{ label: "Vigentes", value: "Vigentes" }, { label: "Finalizados", value: "Finalizados" }]}
				/>
				<Select
					size='medium'
					placeholder="Comprador"
					mode="multiple"
					showSearch
					allowClear
					style={{ width: '16.5%', paddingLeft: '5px', height: '100%' }}
					onChange={setCompradores}
					filterOption={filterSelect}
					options={clientesDb}
				/>
				<Select
					size='medium'
					placeholder="Vendedor"
					mode="multiple"
					showSearch
					allowClear
					style={{ width: '16.5%', paddingLeft: '5px', height: '100%' }}
					onChange={setVendedores}
					filterOption={filterSelect}
					options={clientesDb}
				/>
				<Select
					size='medium'
					placeholder="Produto"
					allowClear={true}
					mode="multiple"
					style={{ width: '16.5%', paddingLeft: '5px', paddingRight: '5px', height: '100%' }}
					onChange={setProduto}
					filterOption={filterSelect}
					options={produtosDb}
				/>
				<ReactToPrint
					trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio} />}
					content={() => tableRef.current}
					pageStyle={"padding: 1px;"}
				/>
			</div>

			{/*<div style={{ marginTop: '10px' }}>
				<VirtualTable
					columns={columns}
					dataSource={fixedReport}
					expandable={{
						defaultExpandAllRows: true,
					}}
					id="report-form"
					loading={isLoading || isFetching}
					bordered
					summary={() => (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell>Total</Table.Summary.Cell>
								<Table.Summary.Cell colSpan={8}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'space-between' }}>
										{
											resumo.map(x => {
												return <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', width: '100%' }}>
													<span style={{ marginBottom: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '140px'}}>{x.nome}</span>
													<Text>Vigente:<br />{formatPeso(x.vigente)}</Text>
													<span>Finalizado:<br />{formatPeso(x.finalizado)}</span>
													<span>Saldo:<br />{formatPeso(x.saldoAEmbarcar)}</span>
													<span>Embarcado:<br />{formatPeso(x.quantidadeEmbarcada)}</span>
												</div>
											})
										}
									</div>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					)}
				/>
			</div>*/}
		

			<div style={{ marginTop: '10px' }}>
				<Table
					expandable={{
						defaultExpandAllRows: true,
					}}
					id="report-form"
					ref={tableRef}
					columns={columns}
					dataSource={fixedReport}
					pagination={false}
					loading={isLoading || isFetching}
					bordered
					summary={() => (
						<Table.Summary fixed>
							<Table.Summary.Row>
								<Table.Summary.Cell>Total</Table.Summary.Cell>
								<Table.Summary.Cell colSpan={8}>
									<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'space-between' }}>
										{
											resumo.map(x => {
												return <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', width: '100%' }}>
													<span style={{ marginBottom: '10px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '140px'}}>{x.nome}</span>
													<Text>Vigente:<br />{formatPeso(x.vigente)}</Text>
													<span>Finalizado:<br />{formatPeso(x.finalizado)}</span>
													<span>Saldo:<br />{formatPeso(x.saldoAEmbarcar)}</span>
													<span>Embarcado:<br />{formatPeso(x.quantidadeEmbarcada)}</span>
												</div>
											})
										}
									</div>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</Table.Summary>
					)}
				/>
			</div>
		</div>
	);
}