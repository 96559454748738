import React from 'react';
import { Breadcrumb, Divider, Button } from 'antd';
import { Link } from 'react-router-dom';
import ListagemContratos from '../../../components/Contrato/ListagemContratos/ListagemContratos';
import NiceModal from '@ebay/nice-modal-react';
import NovoContrato from '../../../components/Contrato/NovoContrato/NovoContrato';

export default function Contrato() {
	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Cadastros</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/cadastros/contrato">Contrato</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<Button type="primary" size="large" onClick={() => NiceModal.show(NovoContrato)} role="primary">Criar</Button>
			</div>
			<Divider />
			
			<ListagemContratos></ListagemContratos>
		</div>
	);
}