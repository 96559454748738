import axios from 'axios';
import { getActionToken } from './controllers/data';

const interceptedRequests = ['post', 'patch', 'put', 'delete'];

const customAxios = axios.create({
    baseURL: window.location.hostname === 'localhost' ? `http://127.0.0.1:5001/api/` : 'https://be-bo.limaetarifa.com.br/api/',
});

const requestHandler = async (request) => {
    request.headers['Accept'] = 'application/json';

    if (!request.headers['Content-Type']) {
        request.headers['Content-Type'] = 'application/json';
    }
    if (interceptedRequests.includes(request.method.toLowerCase())) {
        try {
            request.headers['actionToken'] = await getActionToken(request.method.toLowerCase());
        } catch (e) {
            throw new axios.Cancel('Falha ao obter token');
        }
    }
    request.headers.Authorization = `Bearer ${localStorage.getItem('JWT_TOKEN')}`;
    return request;
};

const responseHandler = (response) => {
    if (response.status === 401) {
        window.location = '/';
    }
    return response;
};

const errorHandler = (error) => {
    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export default customAxios;
