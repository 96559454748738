import React, { useState } from 'react';
import './CriarProduto.scoped.css';
import { Button, Modal, Input, Form, Row, Col, InputNumber, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import { criarProduto, useCodigosNcm, useCodigosCest } from '../../../apis/backend';
import { filterSelect } from '../../Utils/Utils';

export default function CriarProduto() {
	const {ncms, isLoading : isLoadingNcms} = useCodigosNcm();
	const {cests, isLoading : isLoadingCests} = useCodigosCest();
	const navigate = useNavigate();
    const [form] = Form.useForm();
	const [visible, setVisible] = useState(false);
	const [secondaryLoading, setSecondaryLoading] = useState(false);
	const [primaryLoading, setPrimaryLoading] = useState(false);
	const fieldsRules = [
        {
            required: true
        }
    ];

	function cancelCreation(){
        form.resetFields();
        setVisible(false);
    }

	async function createProduct(e) {
		setPrimaryLoading(true);
        setSecondaryLoading(true);
        
        navigate(`/cadastros/produto/${await criarProduto(e)}`);
	}

	return (
		<div>
			<Button type="primary" size="large" onClick={() => setVisible(true)}>
				Criar
			</Button>
			<Modal
				title="Criar Produto"
				centered
				open={visible}
				width="90%"
				destroyOnClose={true}
				closable={false}
				keyboard={false}
				maskClosable={false}
				footer={[
					<Button loading={secondaryLoading} onClick={cancelCreation} >
					  	Cancelar
					</Button>,
					<Button type="primary" loading={primaryLoading} form="clienteForm" key="submit" htmlType="submit">
					  	Criar
					</Button>
				]}
			>
				<div>
					<Form form={form} name="clienteForm" onFinish={createProduct}>
						<Row justify='space-between' gutter={4}>
							<Col span={24}>
								<Form.Item
								label="Descrição"
								name={"Descricao"}
								rules={fieldsRules}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={8}>
								<Form.Item
									label="NCM"
									name={"NCMId"}
									rules={fieldsRules}>
									<Select
										allowClear
										showSearch
										loading={isLoadingNcms}
										filterOption={filterSelect}
										options={ncms}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="CEST"
									name={"CESTId"}>
									<Select
										allowClear
										showSearch
										loading={isLoadingCests}
										filterOption={filterSelect}
										options={cests}
									/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item
									label="Quebra Técnica (Dia)"
									name={"QuebraTecnica"}
									rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
                </div>
			</Modal>	
		</div>
	);
}