export default function RelatorioTransgeniaV2() {
    return (
        <div>
            <iframe
                title="Relatório de Transgenia"
                src="https://metabase.limaetarifa.com.br/public/dashboard/3a63ad81-5852-403f-a5e7-5aa64b2a8f03"
                frameborder="0"
                width="100%"
                height="1560px"
                allowtransparency
            ></iframe>
        </div>
    );
}
