import React from 'react';
import './OperacaoFiscal.scoped.css';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';
import CriarOperacaoFiscal from '../../../components/OperacaoFiscal/CriarOperacaoFiscal/CriarOperacaoFiscal';
import ListagemOperacoesFiscais from '../../../components/OperacaoFiscal/ListagemOperacoesFiscais/ListagemOperacoesFiscais';

export default function OperacaoFiscal() {
	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Cadastros</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/cadastros/operacao-fiscal">Operação Fiscal</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<CriarOperacaoFiscal></CriarOperacaoFiscal>
			</div>
			<Divider />
			<ListagemOperacoesFiscais></ListagemOperacoesFiscais>
		</div>
	);
}