import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';

import CriarCaixa from '../../../components/Financeiro/CriarCaixa/CriarCaixa';
import ListagemCaixas from '../../../components/Financeiro/ListagemCaixas/ListagemCaixas';

export default function Caixas() {
	return (
		<div >
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Financeiro</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/financeiro/caixas">Caixas</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<CriarCaixa />
			</div>
			<Divider />
			<ListagemCaixas />
		</div>
	);
}