import React, { useState, useEffect } from 'react';
import { useGraphQL } from '../../../../apis/backendGraphQL';
import { getPesagens } from '../../../../apis/queries/graphQueries';
import { Input, Table, Space, Tooltip, Button } from 'antd';
import { searchInArray, getCustomerName, formatDateWithTime } from '../../../Utils/Utils';
import { useModal } from '@ebay/nice-modal-react';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import CriarPesagem from '../CriarSimplesPesagem/CriarSimplesPesagem';
import PesagemActions from '../PesagemActions/PesagemActions';

const { Search } = Input;

export default function PesagensAbertas() {
    const { data, isLoading: isLoadingPesagens, mutate } = useGraphQL(getPesagens);
    const [filteredPesagemData, setFilteredPesagemData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const criarPesagemModal = useModal(CriarPesagem);
    const columns = [
        {
            title: 'Data',
            dataIndex: 'createdDate',
            width: '160px',
            render: (v) => formatDateWithTime(v, true),
        },
        {
            title: 'Placa',
            dataIndex: 'placaVeiculo',
            ellipsis: true,
            width: '120px',
        },
        {
            title: 'Motorista',
            dataIndex: 'motoristaNome',
            ellipsis: true,
        },
        {
            title: 'Cliente',
            render: (_, r) => (
                <Link target="_blank" to={`/cadastros/cliente/${r.clienteId}`}>
                    {getCustomerName(r.cliente)}
                </Link>
            ),
            ellipsis: true,
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_, record) => <PesagemActions pesagem={record} onAfterAction={mutateData} />,
        },
    ];

    useEffect(() => {
        setFilteredPesagemData(data?.pesagens);
    }, [data]);

    function onSearch(evt) {
        const searchTerm = evt.target.value;

        if (!searchTerm) return setFilteredPesagemData(data?.pesagens);

        setFilteredPesagemData(searchInArray(searchTerm, data?.pesagens));
    }

    function criarRomaneio() {
        criarPesagemModal.show().then(async () => {
            mutateData();
        });
    }

    async function mutateData() {
        setIsLoading(true);
        await mutate();
        setIsLoading(false);
    }

    return (
        <span>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ width: '95%' }}>
                    <Search placeholder="Busca" allowClear size="large" onChange={onSearch} />
                </div>
                <div style={{ width: '5%', display: 'flex', justifyContent: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                    <Tooltip title="Criar Pesagem">
                        <Button type="primary" size={'large'} shape="circle" onClick={criarRomaneio} icon={<PlusOutlined />} />
                    </Tooltip>
                </div>
            </div>
            <Space size="middle">
                <Table rowKey="id" columns={columns} dataSource={filteredPesagemData} pagination={false} loading={isLoadingPesagens || isLoading} />
            </Space>
        </span>
    );
}
