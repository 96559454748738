import React, { useState } from 'react';
import { Popconfirm, Tooltip, Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deletarRomaneio } from '../../../../apis/backend';

export default function DeletarRomaneio({ romaneioId, onAfterDelete }) {
    const [isLoading, setIsLoading] = useState(false);

    async function onConfirmDelete() {
        setIsLoading(true);
        try {
            await deletarRomaneio(romaneioId);
            onAfterDelete();
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao deletar romaneio.',
            });
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao deletar romaneio. Tente novamente',
            });
        }
        setIsLoading(false);
    }

    return (
        <Tooltip title="Deletar">
            <Popconfirm title={`Confirma que deseja deletar?`} placement={'left'} onConfirm={onConfirmDelete}>
                <Button size="small" shape="circle" icon={<DeleteOutlined />} loading={isLoading} />
            </Popconfirm>
        </Tooltip>
    );
}
