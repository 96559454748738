import React from 'react';
import './Produto.scoped.css';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';
import ListagemProdutos from '../../../components/Produto/ListagemProdutos/ListagemProdutos';
import CriarProduto from '../../../components/Produto/CriarProduto/CriarProduto';

export default function Produtos() {
	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Cadastros</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/cadastros/produto">Produto</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<CriarProduto></CriarProduto>
			</div>
			<Divider />
			
			<ListagemProdutos></ListagemProdutos>
		</div>
	);
}