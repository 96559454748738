import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { notification } from 'antd';
import { fetcher } from '../backendFetcher';

export function useRomaneiosCliente(clienteId) {
    const { data, error, mutate } = useSWR(`clientes/romaneios/${clienteId}`, fetcher);

    return {
        romaneios: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useCep(cep) {
    const { data, error } = useSWR(`clientes/cep/${cep}`, fetcher);

    return {
        cep: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export function useClientes() {
    const { data, error } = useSWR(`clientes`, fetcher);

    return {
        clientes: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export function useCliente(id) {
    const { data, error, mutate } = useSWR(`clientes/${id}`, fetcher);

    return {
        cliente: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useEstoques(clienteId) {
    const { data, error } = useSWR(`clientes/estoque/consolidado?clienteId=${clienteId}`, fetcher);

    return {
        estoques: data,
        isLoading: !error && !data,
        isError: error,
    };
}

export function useContas(clienteId) {
    const { data, error, mutate } = useSWR(`clientes/contas/${clienteId}`, fetcher);

    return {
        contas: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function usePropriedades(clienteId) {
    const { data, error, mutate } = useSWR(`clientes/propriedades/${clienteId}`, fetcher);

    return {
        propriedades: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function usePrestacoesServicoCliente(clienteId) {
    const { data, error, mutate } = useSWR(`clientes/prestacoes/${clienteId}`, fetcher);

    return {
        prestacoes: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useContatos(clienteId) {
    const { data, error, mutate } = useSWR(`clientes/contatos/${clienteId}`, fetcher);

    return {
        contatos: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useDocumentos(clienteId) {
    const { data, error, mutate } = useSWR(`clientes/documentos/${clienteId}/all`, fetcher);

    return {
        documentos: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export async function criarPortalUser(contactId) {
    return await backendApi.post(`clientes/contato/${contactId}/portal/ativar`, null);
}

export async function deletarPortalUser(contactId) {
    return await backendApi.delete(`clientes/contato/${contactId}/portal/desativar`, null);
}

export async function criarPrestacaoServicoCliente(prestacao) {
    return await backendApi.post(`clientes/prestacoes`, JSON.stringify(prestacao));
}

export async function deletarPrestacaoServicoCliente(prestacaoId) {
    return (await backendApi.delete(`clientes/prestacoes/${prestacaoId}`)).data;
}

export async function atualizarPrestacaoServicoCliente(prestacao) {
    return await backendApi.patch('clientes/prestacoes', JSON.stringify(prestacao));
}

export async function atualizarPropriedade(propriedade, clienteId) {
    return await backendApi.patch(`clientes/propriedades/${clienteId}`, JSON.stringify(propriedade));
}

export async function deletarPropriedade(propriedadeId) {
    return await backendApi.delete(`clientes/propriedades/${propriedadeId}`);
}

export async function criarPropriedade(propriedade, clienteId) {
    return await backendApi.post(`clientes/propriedades/${clienteId}`, JSON.stringify(propriedade));
}

export async function atualizarContato(contato, clienteId) {
    return await backendApi.patch(`clientes/contatos/${clienteId}`, JSON.stringify(contato));
}

export async function deletarContato(contatoId) {
    return await backendApi.delete(`clientes/contatos/${contatoId}`);
}

export async function criarContato(contato, clienteId) {
    return await backendApi.post(`clientes/contatos/${clienteId}`, JSON.stringify(contato));
}

export async function baixarDocumento(documentId) {
    return (await backendApi.get(`clientes/documentos/${documentId}`)).data;
}

export async function buscarCep(cep) {
    return JSON.parse((await backendApi.get(`clientes/cep/${cep}`)).data);
}

export async function criarDocumento(document, description, clienteId) {
    try {
        const formData = new FormData();

        formData.append('arquivo', document);
        formData.append('descricao', description);

        return await backendApi.post(`clientes/documentos/${clienteId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (e) {
        notification.error({
            message: 'Falha',
            description: e,
        });
    }
}

export async function deletarDocumento(documentId) {
    return await backendApi.delete(`clientes/documentos/${documentId}`);
}

export async function atualizarConta(conta, clienteId) {
    return await backendApi.patch(`clientes/contas/${clienteId}`, JSON.stringify(conta));
}

export async function deletarConta(contaId) {
    return await backendApi.delete(`clientes/contas/${contaId}`);
}

export async function criarConta(conta, clienteId) {
    return await backendApi.post(`clientes/contas/${clienteId}`, JSON.stringify(conta));
}

export async function criarCliente(cliente) {
    return JSON.parse((await backendApi.post('clientes', JSON.stringify(cliente))).data);
}

export async function atualizarCliente(cliente) {
    return await backendApi.patch('clientes', JSON.stringify(cliente));
}

export async function tornarContaPrincipal(contaId) {
    return await backendApi.post(`clientes/contas?contaId=${contaId}`);
}
