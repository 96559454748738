import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal, ConfigProvider, Input, notification } from 'antd';
import { deleteTransacao } from '../../../apis/backend';
import ptBr from 'antd/lib/locale/pt_BR';

export default NiceModal.create(({ transacao }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState();
    const [password, setPassword] = useState();
    const modal = useModal();

    function cancelDelete() {
        modal.hide();
    }

    async function onSave() {
        setIsLoading(true);

        if (transacao.caixa.tipo === 'Conta Bancária') {
            notification.error({
                message: 'Erro',
                description: 'Não é possível excluir uma transação bancária.',
            });
            setIsLoading(false);
            return;
        }

        if (!comment) {
            notification.error({
                message: 'Erro',
                description: 'O campo comentário é obrigatório.',
            });
            setIsLoading(false);
            return;
        }

        if (!password || password !== 'vzt123') {
            notification.error({
                message: 'Erro',
                description: 'Insira a senha correta.',
            });
            setIsLoading(false);
            return;
        }

        try {
            await deleteTransacao(transacao.id);
            notification.success({
                message: 'Sucesso',
                description: 'A transação foi deletada',
            });
            modal.resolve();
            modal.hide();
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao deletar. Tente novamente.',
            });
        }

        setIsLoading(false);
    }

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Deletar Transação"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelDelete} loading={isLoading} disabled={isLoading}>
                        Fechar
                    </Button>,
                    <Button key={2} type="primary" htmlType="submit" loading={isLoading} disabled={isLoading} onClick={onSave}>
                        Deletar
                    </Button>,
                ]}
            >
                <div>
                    <p>Comentário:</p>
                    <Input onChange={(e) => setComment(e.target.value)}></Input>
                    <p>Senha:</p>
                    <Input type="password" onChange={(e) => setPassword(e.target.value)}></Input>
                </div>
            </Modal>
        </ConfigProvider>
    );
});
