import React from 'react';
import './ListagemOperacoesFiscais.scoped.css';
import { useOperacoesFiscais } from '../../../apis/backend';
import { Table, Space } from 'antd';
import { Link  } from 'react-router-dom';

export default function ListagemOperacoesFiscais() {
	const { operacoes, isLoading } = useOperacoesFiscais();
	const columns = [
		{
			title: 'Nome',
			dataIndex: 'nome',
			key: 'nome',
			ellipsis: true,
			render: (_, record) => (<Link to={`/cadastros/operacao-fiscal/${record.id}`}>{record.nome}</Link>),
			width: '90%'
		},
		{
			title: 'CFOP',
			dataIndex: 'cfopId',
			key: 'cfopId',
		}		
	];

	return (
		<div>
			<Space size="middle">
				<Table
					rowKey="id"
					columns={columns}
					dataSource={operacoes}
					loading={isLoading}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 7,
						showSizeChanger: false
					}}
				/>
			</Space>
		</div>
	);
}