import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal, Select, Form, Row, Col, notification, InputNumber, Input, DatePicker, Popover } from 'antd';
import { useClientesRomaneio, useProdutosRomaneio, criarContrato, useEstoquesClientes } from '../../../apis/backend';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { formatPeso, reaisFormatter, reaisParser, filterSelect, modalidadeOptions, toUserTime, tipoFretes } from '../../Utils/Utils';

export default NiceModal.create(() =>  {
    const navigate = useNavigate();
    const modal = useModal();
    const [form] = Form.useForm();
	const { produtos, isLoading: isLoadingProdutos } = useProdutosRomaneio();
	const { estoques, isLoading: isLoadingEstoques } = useEstoquesClientes();
	const { clientes, isLoading } = useClientesRomaneio();
	const [ estoquePeso, setEstoquePeso ] = useState(0);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const fieldsRules = [
		{
			required: true
		}
	];
	
	function onChangeForm(e) {
		const formValues = form.getFieldValue();

		if (formValues.vendedorId && formValues.produtoId) {
			if (!estoques)
				return;

			const estoqueSelect = estoques.find(x => x.clienteId === formValues.vendedorId && 
				x.produtoId === formValues.produtoId	
			);

			setEstoquePeso(estoqueSelect ? estoqueSelect.peso : 0);
		}

		if (formValues.vendedorId && e.vendedorId) {
			const vendedorObj = clientes.find(x => x.value === formValues.vendedorId);

			if (vendedorObj && vendedorObj.contas) {
				const contaPrincipal = vendedorObj.contas.find(x => x.principal === true);

				if (!contaPrincipal) return; 

				form.setFieldsValue({
					"bancoConta": contaPrincipal.banco,
					"contaCorrente": contaPrincipal.contaCorrente,
					"agencia": contaPrincipal.agencia,
					"cnpjConta": contaPrincipal.cnpj,
					"cpfConta": contaPrincipal.cpf,
					"rgConta": contaPrincipal.rg
				});
			}
		}
	}

    function cancelEdit(){
        form.resetFields();
        modal.hide();
    }

    async function criarContratoAction(e){

		if(e.tipoFrete === tipoFretes[1].value && (!e.transportador || !e.frete)) {
			return form.setFields([{
				name: 'transportador',
				errors: ["Preencha o transportador e frete"]
			},
			{
				name: 'frete',
				errors: ["Preencha o transportador e frete"]
			}]);
		}

		e.dataDePagamento = dayjs(e.dataDePagamento).toDate();
		e.prazoDeEmbarque = dayjs(e.prazoDeEmbarque).toDate();
		
        try{
            setIsLoadingButton(true);
            const contractId = await criarContrato(e);
            setIsLoadingButton(false);
            cancelEdit();
            navigate(`/cadastros/contrato/${contractId}`);
        }catch(e){
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao criar contrato. Tente novamente',
            });
            setIsLoadingButton(false);
        }
    }

    return (
		<Modal
			title="Criar Contrato"
			centered
			open={modal.visible}
			width="95%"
			destroyOnClose={true}
			closable={false}
			keyboard={false}
			maskClosable={false}
			afterClose={() => modal.remove()}
			footer={[
				<Button onClick={cancelEdit} loading={isLoadingButton}>
					Cancelar
				</Button>,
				<Button type="primary" loading={isLoadingButton} form="criarForm" key="submit" htmlType="submit">
					Criar
				</Button>
			]}
		>
			<Form form={form} name="criarForm" onFinish={criarContratoAction} onValuesChange={onChangeForm} initialValues={{modalidade:modalidadeOptions[0].value, dataInicioEntrega: toUserTime(), tipoFrete: tipoFretes[0].value }}>
				<Row justify='space-between' gutter={4}>
					<Col span={24}>
						<Form.Item
							label="Modalidade"
							name={"modalidade"}
							rules={fieldsRules}>
							<Select options={modalidadeOptions}/>
						</Form.Item>
					</Col>
				</Row>
				<Row justify='space-between' gutter={4}>
					<Col span={12}>
						<Form.Item
							label="Comprador"
							name={"compradorId"}
							rules={fieldsRules}>
							<Select loading={isLoading} showSearch filterOption={filterSelect} options={clientes} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Vendedor"
							name={"vendedorId"}
							rules={fieldsRules}>
							<Select loading={isLoading} showSearch filterOption={filterSelect} options={clientes} />
						</Form.Item>
					</Col>
				</Row>
				<Row justify='space-between' gutter={4}>
					<Col span={8}>
						<Form.Item
							label="Produto"
							name={"produtoId"}
							rules={fieldsRules}>
							<Select loading={isLoadingProdutos} showSearch filterOption={filterSelect} options={produtos} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Popover content={() => <div style={{ textAlign: 'center' }}>{isLoadingEstoques ? 'Carregando...' : formatPeso(estoquePeso)}</div>} title={() => <div style={{ textAlign: 'center' }}>{isLoadingEstoques ? `Carregando...` : 'Estoque do Vendedor'}</div>}>
							<Form.Item
								label="Quantidade Total"
								name={"quantidadeTotal"}
								rules={fieldsRules}>
								<InputNumber style={{width: "100%"}} addonAfter={"KG"}/>
							</Form.Item>
						</Popover>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Preço"
							name={"preco"}
							rules={fieldsRules}>
							<InputNumber 
								style={{width: "100%"}}
								addonBefore={"R$/KG"}
								formatter={value => reaisFormatter(value)}
								parser={value => reaisParser(value)}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row justify='space-between' gutter={4}>
					<Col span={8}>
						<Form.Item
							label="Data Início Entrega"
							name={"dataInicioEntrega"}
							rules={fieldsRules}>
							<DatePicker format={'DD/MM/YYYY'} style={{width: "100%"}}/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Data de Pagamento"
							name={"dataDePagamento"}
							rules={fieldsRules}>
							<DatePicker format={'DD/MM/YYYY'} style={{width: "100%"}}/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Prazo de Embarque"
							name={"prazoDeEmbarque"}
							rules={fieldsRules}>
							<DatePicker format={'DD/MM/YYYY'} style={{width: "100%"}}/>
						</Form.Item>
					</Col>
				</Row>
				<Row justify='space-between' gutter={4}>
					<Col span={3}>
						<Form.Item
							label="T. Frete"
							name={"tipoFrete"}
							rules={fieldsRules}>
							<Select options={tipoFretes}/>
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							label="Transportador"
							name={"transportador"}
						>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="Frete"
							name={"frete"}>
							<InputNumber
								style={{ width: "100%" }}
								addonBefore={"R$ / KG"}
								formatter={value => reaisFormatter(value)}
								parser={value => reaisParser(value)}
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="Corretor"
							name={"corretor"}
						>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="Comissão"
							name={"comissao"}>
							<InputNumber
								style={{ width: "100%" }}
								addonBefore={"R$/KG"}
								formatter={value => reaisFormatter(value)}
								parser={value => reaisParser(value)}
							/>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="CTR Externo"
							name={"contratoExterno"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				</Row>
				<Row justify='space-between' gutter={4}>
					<Col span={5}>
						<Form.Item
							label="Banco"
							name={"bancoConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item
							label="C.c"
							name={"contaCorrente"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={3}>
						<Form.Item
							label="Agência"
							name={"agencia"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item
							label="CNPJ"
							name={"cnpjConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="CPF"
							name={"cpfConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item
							label="RG"
							name={"rgConta"}>
							<Input style={{ width: "100%" }} />
						</Form.Item>
					</Col>
				</Row>
				<Row justify='space-between' gutter={4}>
					<Col span={24}>
						<Form.Item
							label="Observações"
							name={"observacoes"}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
    )
});