import React, { useState, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Sector, ResponsiveContainer } from 'recharts';
import { formatCurrencyTwo } from '../../Utils/Utils';
import { Spin, Card } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getSaldo } from '../../../apis/controllers/data';

export default function SaldoTotal() {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
        fetchData();
    }, []);

    async function fetchData(){
        setIsLoading(true);
        const result = await getSaldo();
        setData(result);
        setIsLoading(false);
    }

	return (
		<Card title={`Saldo total - ${formatCurrencyTwo(data?.saldoTotal)}`} style={{height: '400px'}}>
			<div style={{height: '400px', width: '100%'}}>
				<Spin size="large" spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: '50px', color: '#067e14' }}/>}>
					<ResponsiveContainer height={330} >
					<BarChart data={data?.contas}>
						<XAxis dataKey="name" />
						<YAxis hide />
						<Tooltip formatter={(v, n, p) => formatCurrencyTwo(v)}/>
						<Bar dataKey="value" fill="#82ca9d" />
						</BarChart>
					</ResponsiveContainer>
				</Spin>
			</div>
		</Card>
	);
}
