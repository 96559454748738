import React from 'react';
import { useParams } from "react-router-dom";
import DetalheCaixa from '../../../components/Financeiro/DetalheCaixa/DetalheCaixa';

export default function ContaBancaria() {
	const { id } = useParams();

	return (
		<DetalheCaixa id={id}></DetalheCaixa>
	);
}