import React, { useState } from 'react';
import { Select, Image } from 'antd';
import background from '../../imgs/relatorios.png';

import RelatorioTransgenia from './RelatorioTransgenia';
import RelatorioTransgeniaV2 from './RelatorioTransgeniaV2';
import RelatorioMovimentacoesContrato from './RelatorioMovimentacoesContrato';
import RelatorioMovimentacoesEstoque from './RelatorioMovimentacoesEstoque';
import RelatorioEstoque from './RelatorioEstoque';
import RelatorioArmazenamentoExpedicao from './RelatorioArmazenamentoExpedicao';
import RelatorioDescontos from './RelatorioDescontos';
import RelatorioFrete from './RelatorioFrete';
import RelatorioContratos from './RelatorioContratos';
import RelatorioClientes from './RelatorioClientes';
import RelatorioAssinaturas from './RelatorioAssinaturas';
import RelatorioLancamentosV2 from './RelatorioLancamentosV2';
import RelatorioMediaContratoV2 from './RelatorioMediaContratoV2';
import RelatorioEstoqueHistorico from './RelatorioEstoqueHistorico';
import RelatorioTransacoesNaoRelacionadas from './RelatorioTransacoesNaoRelacionadas';
import RelatorioPrestacoesServico from './RelatorioPrestacoesServico';

export default function RelatoriosHome() {
    const [currentReport, setCurrentReport] = useState();
    const reportOptions = [
        {
            label: 'Estoque',
            options: [
                {
                    label: 'Movimentações de estoque',
                    value: 1,
                },
                {
                    label: 'Movimentações de contrato',
                    value: 2,
                },
                {
                    label: 'Estoque',
                    value: 3,
                },
                {
                    label: 'Transgenia',
                    value: 5,
                },
                {
                    label: 'Armazenamentos e expedições',
                    value: 6,
                },
                {
                    label: 'Descontos',
                    value: 7,
                },
                {
                    label: 'Fretes',
                    value: 8,
                },
                {
                    label: 'Histórico de Estoque',
                    value: 15,
                },
                {
                    label: 'Prestações de Serviço',
                    value: 17,
                },
            ],
        },
        {
            label: 'Cadastro',
            options: [
                {
                    label: 'Média de preços de contrato',
                    value: 13,
                },
                {
                    label: 'Contratos',
                    value: 9,
                },
                {
                    label: 'Clientes',
                    value: 10,
                },
                {
                    label: 'Assinaturas',
                    value: 11,
                },
            ],
        },
        {
            label: 'Financeiro',
            options: [
                {
                    label: 'Lançamentos',
                    value: 12,
                },
                {
                    label: 'Transações a Relacionar',
                    value: 16,
                },
            ],
        },
        {
            label: 'NF',
            options: [],
        },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Select style={{ width: '100%', marginBottom: '10px' }} placeholder="Relatório" onChange={setCurrentReport} options={reportOptions} />

            {!currentReport ? (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Image preview={false} src={background} width="80%" />
                </div>
            ) : (
                <></>
            )}
            {currentReport === 1 ? <RelatorioMovimentacoesEstoque /> : <></>}
            {currentReport === 2 ? <RelatorioMovimentacoesContrato /> : <></>}
            {currentReport === 3 ? <RelatorioEstoque /> : <></>}
            {currentReport === 5 ? <RelatorioTransgenia /> : <></>}
            {currentReport === 6 ? <RelatorioArmazenamentoExpedicao /> : <></>}
            {currentReport === 7 ? <RelatorioDescontos /> : <></>}
            {currentReport === 8 ? <RelatorioFrete /> : <></>}
            {currentReport === 9 ? <RelatorioContratos /> : <></>}
            {currentReport === 10 ? <RelatorioClientes /> : <></>}
            {currentReport === 11 ? <RelatorioAssinaturas /> : <></>}
            {currentReport === 12 ? <RelatorioLancamentosV2 /> : <></>}
            {currentReport === 13 ? <RelatorioMediaContratoV2 /> : <></>}
            {currentReport === 14 ? <RelatorioTransgeniaV2 /> : <></>}
            {currentReport === 15 ? <RelatorioEstoqueHistorico /> : <></>}
            {currentReport === 16 ? <RelatorioTransacoesNaoRelacionadas /> : <></>}
            {currentReport === 17 ? <RelatorioPrestacoesServico /> : <></>}
        </div>
    );
}
