import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';
import ListagemEntradaSaida from '../../../components/Gerenciamento/EntradaSaida/ListagemEntradaSaida/ListagemEntradaSaida';

export default function GestaoBalanca() {
	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Gerenciamento</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/gerenciamento/gestao-balanca">Gestão de Balança</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Divider />
			
            <ListagemEntradaSaida></ListagemEntradaSaida>
		</div>
	);
}