import React from 'react';
import './Cliente.scoped.css';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';
import CriarCliente from '../../../components/Cliente/CriarCliente/CriarCliente';
import ListagemClientes from '../../../components/Cliente/ListagemClientes/ListagemClientes';

export default function Cliente() {
	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Cadastros</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/cadastros/cliente">Cliente</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<CriarCliente></CriarCliente>
			</div>
			<Divider />
			
			<ListagemClientes></ListagemClientes>
		</div>
	);
}