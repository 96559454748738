import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export function useProdutos() {
	const { data, error } = useSWR(`produtos`, fetcher);

	return {
		produtos: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useProduto(id) {
	const { data, error, mutate } = useSWR(`produtos/${id}`, fetcher);

	return {
		produto: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export function useUmidades(produtoId) {
	const { data, error, mutate } = useSWR(`produtos/umidades/${produtoId}`, fetcher);

	return {
		umidades: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export function usePrestacoesServico(produtoId) {
	const { data, error, mutate } = useSWR(`produtos/prestacoes/${produtoId}`, fetcher);

	return {
		prestacaoServicos: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}

export async function criarProduto(produto) {
	return JSON.parse((await backendApi.post('produtos', JSON.stringify(produto))).data);
}

export async function atualizarProduto(produto) {
	return await backendApi.patch(`produtos/${produto.id}`, JSON.stringify(produto));
}

export async function criarUmidade(umidade, produtoId) {
	return await backendApi.post(`produtos/umidades/${produtoId}`, JSON.stringify(umidade));
}

export async function deletarUmidade(umidadeId) {
	return await backendApi.delete(`produtos/umidades/${umidadeId}`);
}

export async function atualizarUmidade(umidade, produtoId) {
	return await backendApi.patch(`produtos/umidades/${produtoId}`, JSON.stringify(umidade));
}

export async function criarPrestacaoServico(prestacao, produtoId) {
	return await backendApi.post(`produtos/prestacoes/${produtoId}`, JSON.stringify(prestacao));
}

export async function deletarPrestacaoServico(prestacaoId) {
	return await backendApi.delete(`produtos/prestacoes/${prestacaoId}`);
}

export async function atualizarPrestacaoServico(prestacao, produtoId) {
	return await backendApi.patch(`produtos/prestacoes/${produtoId}`, JSON.stringify(prestacao));
}