import React, { useState } from 'react';
import { useDocumentosContrato, deletarDocumentoContrato, criarContratoDocumento, baixarDocumentoContrato} from "../../../apis/backend";
import { Table, Modal, Button, Form, Row, Col, Input, Space, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export default function DocumentosContrato(props) {
    const [contrato] = useState(props.contrato);
	const [formCreation] = Form.useForm();
	const {documentos, mutate} = useDocumentosContrato(contrato.id);
    const [file, setFile] = useState(null);
	const [loadingModalButton, setLoadingModalButton] = useState(false);
	const [visibleCriar, setVisibleCriar] = useState(false);
	const isLoading = props.isLoading
	const columns = [
		{ title: 'Descrição', dataIndex: 'descricao', key: 'descricao' },
		{
		  title: 'Ações',
		  dataIndex: '',
		  key: '',
		  render: (_, a) => AcoesDocumento(a),
		},
	];
	const fieldsRules = [
        {
            required: true
        }
    ];

	async function createDocumento(e){
		setLoadingModalButton(true);

        if(!file){
            setLoadingModalButton(false);
            return;
        }

        await criarContratoDocumento(file, e.descricao, contrato.id);

		setLoadingModalButton(false);
		setVisibleCriar(false);
		await getDocumentos();
	}

	async function getDocumentos(){
		isLoading(true);
		mutate();
		isLoading(false);
	}

	async function onClickDownload(record){
		isLoading(true);
		window.open(await baixarDocumentoContrato(record.id), '_blank').focus();
		isLoading(false);
	}

	async function onClickDelete(record){
		isLoading(true);
		await deletarDocumentoContrato(record.id)
		await getDocumentos();
		isLoading(false);
	}

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            setFile(file);
            onSuccess("ok");
        }, 0);
    };

	const AcoesDocumento = (record) => (
        <div>
            <Button type="link" onClick={() => onClickDownload(record)}>Baixar</Button>
            <Button type="link" onClick={() => onClickDelete(record)}>Deletar</Button>
        </div>
	);

    return (
		<div>
			<Space size={"small"} direction="vertical" style={{width: "100%"}}>
				<div style={{width: "100%", display: "flex", justifyContent: 'end'}}>
				    <Button type="primary" onClick={() => setVisibleCriar(true)}>Adicionar</Button>
				</div>
			
				<Table
					rowKey={"id"}
					columns={columns}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 5,
						showSizeChanger: false
					}}
					dataSource={documentos}
				/>

				<Modal
					title="Criar Documento"
					centered
					open={visibleCriar}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
					footer={[
						<Button key={1} onClick={() => setVisibleCriar(false)} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="DocumentoCreationForm" htmlType="submit">
							Criar
						</Button>
					]}
				>
					<Form form={formCreation} name="DocumentoCreationForm" onFinish={createDocumento}>
						<Row justify='space-between' gutter={4}>
							<Col span={24}>
								<Form.Item
								label="Descrição"
								name={"descricao"}
								rules={fieldsRules}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
						<Row justify='space-between' gutter={4}>
							<Col span={24}>
								<Form.Item>
									<Dragger maxCount={1} customRequest={dummyRequest}>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-text">Clique ou arraste algum arquivo para enviar.</p>
									</Dragger>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
			</Space>
        </div>
    )
}