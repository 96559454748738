import React from 'react';
import { useParams } from "react-router-dom";
import DetalheContrato from '../../../components/Contrato/DetalheContrato/DetalheContrato';

export default function ContratoView() {
	const { id } = useParams();

	return (
		<DetalheContrato id={id}></DetalheContrato>
	);
}