import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import RelatoriosHome from '../components/Relatorios/RelatoriosHome';

export default function Relatorios() {
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Divider />

            <RelatoriosHome></RelatoriosHome>
        </div>
    );
}
