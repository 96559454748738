import React, { useEffect, useState } from 'react';
import { criarPrestacaoServicoCliente, useProdutosRomaneio, usePrestacoesServicoCliente, deletarPrestacaoServicoCliente, atualizarPrestacaoServicoCliente} from "../../../apis/backend";
import { Table, Modal, Button, Form, Row, Col, Space, InputNumber, Select } from 'antd';

export default function PrestacoesServicoCliente(props) {
    const [cliente] = useState(props.cliente);
	const [formCreation] = Form.useForm();
    const [formEdit] = Form.useForm();
    const [editarVisible, setEditarVisible] = useState(false);
	const [loadingModalButton, setLoadingModalButton] = useState(false);
    const [produtoOptionsFiltered, setProdutoOptionsFiltered] = useState([]);
	const [visibleCriar, setVisibleCriar] = useState(false);
    const [editRecord, setEditRecord] = useState({});
	const {prestacoes, mutate} = usePrestacoesServicoCliente(cliente.id);
	const {produtos, isLoading : loadingProdutos} = useProdutosRomaneio();
	const isLoading = props.isLoading
	const columns = [
		{ title: 'Umidade Inicial (%)', dataIndex: 'umidadeInicial', key: 'umidadeInicial' },
        { title: 'Umidade Final (%)', dataIndex: 'umidadeFinal', key: 'umidadeFinal' },
        { title: 'Desconto (%)', dataIndex: 'desconto', key: 'desconto' },
        { title: 'Produto', dataIndex: ['produto', 'descricao'], key: 'produtoNome' },
		{
		  title: 'Ações',
		  dataIndex: '',
		  key: '',
		  render: (_, a) => AcoesPrestacaoServico(a),
		},
	];
	const fieldsRules = [
        {
            required: true
        }
    ];

	useEffect(() => {
		getPrestacaoServicos();
	}, []);

	useEffect(() => {
		if(produtos){
			setProdutoOptionsFiltered(produtos);
		}
	}, [produtos, loadingProdutos]);

	async function createPrestacaoServico(e){
		setLoadingModalButton(true);

		e.clienteId = cliente.id;
        await criarPrestacaoServicoCliente(e);

		setLoadingModalButton(false);
		setVisibleCriar(false);
		await getPrestacaoServicos();
	}

	async function getPrestacaoServicos(){
		isLoading(true);
		await mutate()
		isLoading(false);
	}

	async function onClickDelete(record){
		isLoading(true);
		await deletarPrestacaoServicoCliente(record.id)
		await getPrestacaoServicos();
		isLoading(false);
	}

	async function visibleEditar(record) {
		formEdit.resetFields();
		formEdit.setFieldsValue(record)
		setEditRecord(record);

		setTimeout(() => {
			setEditarVisible(true);
		}, 0);
    }

	function onSearchProduto(searchText){
		setProdutoOptionsFiltered(
			!searchText ? produtos : produtos.filter(x =>  x.label.toLowerCase().startsWith(searchText.toLowerCase()))
		);
	};

    async function editPrestacaoServico(e){
        setLoadingModalButton(true);
        e.id = editRecord.id;
		e.clienteId = cliente.id;
        await atualizarPrestacaoServicoCliente(e);
        await getPrestacaoServicos();
        setLoadingModalButton(false);
        setEditarVisible(false);
    }

	const AcoesPrestacaoServico = (record) => (
        <div>
            <Button type="link" onClick={() => visibleEditar(record)}>Editar</Button>
            <Button type="link" onClick={() => onClickDelete(record)}>Deletar</Button>
            <Modal
					title="Editar Prestação Serviço"
					centered
					open={editarVisible}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
					footer={[
						<Button key={1} onClick={() => setEditarVisible(false)} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="prestacaoServicoEditForm" htmlType="submit">
							Atualizar
						</Button>
					]}
				>
					<Form form={formEdit} name="prestacaoServicoEditForm" onFinish={editPrestacaoServico} initialValues={editRecord}>
						<Row justify='space-between' gutter={4}>
							<Col span={6}>
								<Form.Item
                                label="Umi. Inicial"
                                name={"umidadeInicial"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={6}>
								<Form.Item
                                label="Umi. Final"
                                name={"umidadeFinal"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={6}>
								<Form.Item
                                label="Desconto"
                                name={"desconto"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
								label="Produto"
								name={"produtoId"}
								rules={fieldsRules}>
									<Select showSearch filterOption={false} onSearch={onSearchProduto} options={produtoOptionsFiltered}/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
        </div>
	);

    return (
		<div>
			<Space size={"small"} direction="vertical" style={{width: "100%"}}>
				<div style={{width: "100%", display: "flex", justifyContent: 'end'}}>
				    <Button type="primary" onClick={() => setVisibleCriar(true)}>Adicionar</Button>
				</div>
			
				<Table
					rowKey={"id"}
					columns={columns}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 5,
						showSizeChanger: false
					}}
					dataSource={prestacoes}
				/>

				<Modal
					title="Criar Prestação Serviço"
					centered
					open={visibleCriar}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
                    afterClose={() => formCreation.resetFields()}
					footer={[
						<Button key={1} onClick={() => setVisibleCriar(false)} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="prestacaoServicoCreationForm" htmlType="submit">
							Criar
						</Button>
					]}
				>
					<Form form={formCreation} name="prestacaoServicoCreationForm" onFinish={createPrestacaoServico}>
                        <Row justify='space-between' gutter={4}>
							<Col span={6}>
								<Form.Item
                                label="Umi. Inicial"
                                name={"umidadeInicial"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={6}>
								<Form.Item
                                label="Umi. Final"
                                name={"umidadeFinal"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={6}>
								<Form.Item
                                label="Desconto"
                                name={"desconto"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.0001" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
								label="Produto"
								name={"produtoId"}
								rules={fieldsRules}>
									<Select showSearch filterOption={false} onSearch={onSearchProduto} options={produtoOptionsFiltered}/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
			</Space>
        </div>
    )
}