import React, { useState, useEffect, useRef } from 'react';
import { Select, DatePicker, Button, Table } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import locale from 'antd/lib/locale/pt_BR';
import { useRelatorioPropriedadesoMedia, useClientesRomaneio, useProdutosRomaneio } from '../../apis/backend';
import ReactToPrint from 'react-to-print';
import { formatPeso, formatPercentageConcat, filterSelect } from '../Utils/Utils';

const { RangePicker } = DatePicker;

export default function RelatorioDescontos() {
    const { clientes: clientesDb } = useClientesRomaneio();
    const { produtos: produtosDb } = useProdutosRomaneio();
    const [clientes, setClientes] = useState([]);
    const [somatorias, setSomatorias] = useState({ pesoBruto: 0, producao: 0, umidade: 0, impureza: 0, avariados: 0 });
    const [isPrinting, setIsPrinting] = useState(false);
    const [produto, setProduto] = useState(1);
    const [range, setRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
    const { relatorio, isLoading } = useRelatorioPropriedadesoMedia(range[0].startOf('day').toISOString(), range[1].endOf('day').toISOString(), clientes, produto);
    const [fixedReport, setFixedReport] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const tableRef = useRef();
    const columns = [
        {
            title: 'Cliente',
            key: 'clienteId',
            render: (fullData) => {
                return (
                    <Link target="_blank" to={`/cadastros/cliente/${fullData.key}`}>
                        {fullData.clienteNome}
                    </Link>
                );
            },
            align: 'center',
        },
        {
            title: 'Romaneios',
            children: [
                {
                    title: 'Peso Bruto',
                    key: 1,
                    render: (valor) => formatPeso(valor.pesoBruto),
                    align: 'center',
                },
                {
                    title: 'Umidade',
                    dataIndex: 'umidade',
                    key: 2,
                    render: (valor) => formatPercentageConcat(valor),
                    align: 'center',
                },
                {
                    title: 'Impureza',
                    dataIndex: 'impureza',
                    key: 3,
                    render: (valor) => formatPercentageConcat(valor),
                    align: 'center',
                },
                {
                    title: 'Avariados',
                    dataIndex: 'avariados',
                    key: 4,
                    render: (valor) => formatPercentageConcat(valor),
                    align: 'center',
                },
                {
                    title: 'Produção',
                    dataIndex: 'producao',
                    key: 5,
                    render: (valor, rec) => formatPeso(rec.producao ? rec.producao : rec.pesoBruto - (rec.umidadePeso + rec.impurezaPeso + rec.avariadosPeso)),
                    align: 'center',
                },
            ],
        },
    ];

    useEffect(() => {
        if (Array.isArray(relatorio)) {
            setExpandedKeys([]);
            setFixedReport(relatorio);

            const somatoriasInner = { pesoBruto: 0, producao: 0, umidade: 0, impureza: 0, avariados: 0 };

            relatorio.forEach((x) => {
                somatoriasInner.pesoBruto += x.pesoBruto;
                somatoriasInner.producao += x.producao;
            });

            relatorio.forEach((linha) => {
                linha?.children?.forEach((x) => {
                    somatoriasInner.umidade += x.umidadePeso;
                    somatoriasInner.impureza += x.impurezaPeso;
                    somatoriasInner.avariados += x.avariadosPeso;
                });
            });
            setSomatorias(somatoriasInner);
        }
    }, [relatorio]);

    const updateExpandedRowKeys = ({ record }) => {
        const rowKey = record.key;
        const isExpanded = expandedKeys.find((key) => key === rowKey);
        let newExpandedRowKeys = [];
        if (isExpanded) {
            newExpandedRowKeys = expandedKeys.reduce((acc, key) => {
                if (key !== rowKey) acc.push(key);
                return acc;
            }, []);
        } else {
            newExpandedRowKeys = expandedKeys;
            newExpandedRowKeys.push(rowKey);
        }
        setExpandedKeys(newExpandedRowKeys);
    };

    function getHeader() {
        if (!produto) return null;

        const prod = produtosDb.find((x) => x.value === produto).label;
        return `Produto: ${prod}, Gerado em: ${new Date().toLocaleDateString('pt-br')}`;
    }

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <RangePicker
                    allowClear={false}
                    locale={locale}
                    format={'DD/MM/YYYY'}
                    defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                    style={{ width: '30%', height: '100%' }}
                    size="medium"
                    onChange={setRange}
                />
                <Select
                    size="medium"
                    placeholder="Cliente"
                    mode="multiple"
                    showSearch
                    allowClear
                    style={{ width: '35%', paddingLeft: '5px', paddingRight: '5px', height: '100%' }}
                    onChange={setClientes}
                    filterOption={filterSelect}
                    options={clientesDb}
                />
                <Select
                    size="medium"
                    placeholder="Produto"
                    allowClear={false}
                    style={{ width: '35%', paddingRight: '5px', height: '100%' }}
                    onChange={setProduto}
                    defaultValue={1}
                    filterOption={filterSelect}
                    options={produtosDb}
                />
                <ReactToPrint
                    onBeforeGetContent={() => {
                        return new Promise((resolve) => {
                            setIsPrinting(true);
                            resolve();
                        });
                    }}
                    onAfterPrint={() => setIsPrinting(false)}
                    trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio} />}
                    content={() => tableRef.current}
                    pageStyle={'padding: 1px;'}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                <Table
                    expandable={{
                        defaultExpandAllRows: true,
                        expandedRowKeys: expandedKeys,
                        onExpand: (_, record) => {
                            updateExpandedRowKeys({ record });
                        },
                    }}
                    id="report-form"
                    ref={tableRef}
                    title={() => (isPrinting ? getHeader() : null)}
                    columns={columns}
                    dataSource={fixedReport}
                    pagination={false}
                    loading={isLoading}
                    bordered
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={6}>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        <span>Peso Bruto: {formatPeso(somatorias.pesoBruto)}</span>
                                        <span>Umidade: {formatPeso(somatorias.umidade)}</span>
                                        <span>Impureza: {formatPeso(somatorias.impureza)}</span>
                                        <span>Avariados: {formatPeso(somatorias.avariados)}</span>
                                        <span>Produção: {formatPeso(somatorias.producao)}</span>
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </div>
        </div>
    );
}
