import React from 'react';
import { Breadcrumb, Divider, Image } from 'antd';
import { Link } from 'react-router-dom';
import OperacoesResumo from '../../../components/Gerenciamento/Operacoes/OperacoesResumo/OperacoesResumo';

import background from '../../../imgs/produtividade.png'

export default function Operacoes() {
	return (
		<div >
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Gerenciamento</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/gerenciamento/operacoes">Operações</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<OperacoesResumo></OperacoesResumo>
			</div>
			<Divider />
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<Image preview={false} src={background} width="80%" />
			</div>
		</div>
	);
}