import React, { useState } from 'react';
import { getPesoPdf } from "../../apis/backend";
import { Tooltip, Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { printBase64Pdf } from '../Utils/Utils';

export default function PesagemPrint(props) {
	const [isLoading, setIsLoading] = useState(false);

	async function printPesagem() {
		setIsLoading(true);
		const pdf = await getPesoPdf(props.id);
		setIsLoading(false);
		printBase64Pdf(pdf);
	}

	return (
		<Tooltip title="Imprimir">
			<Button loading={isLoading} shape="circle" icon={<PrinterOutlined />} onClick={printPesagem} />
		</Tooltip>
	)
}