import "./InformacoesGeraisCliente.scoped.css";
import React, { useState } from 'react';
import { Form, Row, Col, Select, DatePicker, Input, notification, Divider } from 'antd';
import { buscarCep, atualizarCliente } from "../../../apis/backend";
import { MaskedInput } from 'antd-mask-input';
import { tiposCliente, relacionamentoCliente, isClienteFisico, atividadesCliente } from '../../Utils/Utils';

import ContratosCliente from '../ContratosCliente/ContratosCliente';
import EstoqueCliente from '../EstoqueCliente/EstoqueCliente';
import RomaneiosCliente from "../RomaneiosCliente/RomaneiosCliente";
import LancamentosRelacionados from "../../Financeiro/LancamentosRelacionados/LancamentosRelacionados";

const { TextArea } = Input;

export default function InformacoesGeraisCliente(props) {
	const [cliente, setCliente] = useState(props.cliente);
	const isLoading = props.isLoading
	const [form] = Form.useForm();
	const fieldsRules = [
		{
			required: true
		}
	];

	async function updateCustomer(e) {
		await form.validateFields();

		isLoading(true);

		e.id = cliente.id;

		try {
			await atualizarCliente(e);
			await props.mutate(e.id);
			notification.success({
				message: 'Sucesso',
				description: 'Sucesso ao atualizar cliente.'
			});
		} catch (e) {
			notification.error({
				message: 'Erro',
				description: e,
			});
		}

		isLoading(false);
	}

	async function findCep(e) {
		if (e.unmaskedValue.length !== 8)
			return;

		isLoading(true);

		const endereco = await buscarCep(e.unmaskedValue);

		form.setFieldsValue({
			endereco: endereco.address,
			bairro: endereco.district,
			cidade: endereco.city,
			estado: endereco.state
		});

		isLoading(false);
	}

	return (
		<div>
			<div>
				<Form
					form={form}
					name="clienteForm"
					onFinish={updateCustomer}
					initialValues={cliente}>
					<Row justify="space-between" gutter={4}>
						<Col span={6}>
							<Form.Item label="Tipo" name={"tipoPessoa"} rules={fieldsRules}>
								<Select
									options={tiposCliente}
									onSelect={(e) => setCliente({ ...cliente, tipoPessoa: e })}
								></Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Relacionamento"
								name={"relacionamento"}
								rules={fieldsRules}>
									<Select options={relacionamentoCliente}></Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Atividade"
								name={"atividade"}
								rules={fieldsRules}>
									<Select options={atividadesCliente}></Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Ocorre Q.T"
								name={"ocorreQt"}
								rules={fieldsRules}>
									<Select
										options={[{ value: "Sim", label: "Sim" }, { value: "Não", label: "Não" }]}>
									</Select>
							</Form.Item>
						</Col>
					</Row>
					{!isClienteFisico(cliente) ? (
						<Row justify="space-between" gutter={4}>
							<Col span={12}>
								<Form.Item
									label="Razão Social"
									name={"razaoSocial"}
									rules={fieldsRules}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="CNPJ" name={"cnpj"} rules={fieldsRules}>
									<MaskedInput mask={"00.000.000/0000-00"}></MaskedInput>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item label="IE" name={"ie"}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					) : (
						<Row justify="space-between" gutter={4}>
							<Col span={10}>
								<Form.Item label="Nome" name={"nome"} rules={fieldsRules}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item
									label="Nascimento"
									name={"nascimento"}
									rules={fieldsRules}
								>
									<DatePicker style={{ width: "100%" }} format="DD/MM/YYYY" />
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item label="CPF" name={"cpf"} rules={fieldsRules}>
									<MaskedInput mask={"000.000.000-00"}></MaskedInput>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item label="RG" name={"rg"}>
									<MaskedInput mask={"00.000.000-[*]"}></MaskedInput>
								</Form.Item>
							</Col>
						</Row>
					)}
					<Row justify="space-between" gutter={4}>
						<Col span={5}>
							<Form.Item label="CEP" name={"cep"} rules={fieldsRules}>
								<MaskedInput mask={"00000-000"} onBlur={findCep}></MaskedInput>
							</Form.Item>
						</Col>
						<Col span={14}>
							<Form.Item label="Endereço" name={"endereco"} rules={fieldsRules}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={5}>
							<Form.Item label="Número" name={"numero"}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="space-between" gutter={4}>
						<Col span={24}>
							<Form.Item label="Complemento" name={"complemento"}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="space-between" gutter={4}>
						<Col span={8}>
							<Form.Item label="Bairro" name={"bairro"} rules={fieldsRules}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Cidade" name={"cidade"} rules={fieldsRules}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Estado" name={"estado"} rules={fieldsRules}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="space-between" gutter={4}>
						<Col span={24}>
							<Form.Item label="Observações" name={"observacoes"}>
								<TextArea rows={3} />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
			<Divider orientation="left">Contratos</Divider>
			<ContratosCliente cliente={cliente}></ContratosCliente>
			<Divider orientation="left">Estoques</Divider>
			<EstoqueCliente cliente={cliente}></EstoqueCliente>
			<Divider orientation="left">Romaneios</Divider>
			<RomaneiosCliente cliente={cliente}></RomaneiosCliente>
			<Divider orientation="left">Lançamentos</Divider>
			<LancamentosRelacionados id={cliente.id} context={'cliente'}></LancamentosRelacionados>
		</div>
	);
}
