export default function RelatorioLancamentosV2() {
    return (
        <div>
            <iframe
                title="Relatório de Transações Não Relacionadas"
                src="https://metabase.limaetarifa.com.br/public/question/d9c2664b-e306-4053-8627-ed491fdb7387"
                frameborder="0"
                width="100%"
                height="1130px"
                allowtransparency
            ></iframe>
        </div>
    );
}
