import React, {useState, useEffect, useRef} from 'react';
import { Select, Button, Table } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import 'dayjs/locale/pt-br'
import { filterSelect } from '../Utils/Utils';
import { useClientesRomaneio , useRelatorioClientes} from '../../apis/backend';

export default function RelatorioClientes() {
    const tableRef = useRef();
    const [clientes, setClientes] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [fixedReport, setFixedReport] = useState([]);
	const [isLoading, setIsLoading] = useState([]);
    const { clientes : clientesDb } = useClientesRomaneio();
    const {relatorio, isLoading : isFetching} = useRelatorioClientes(clientes);

    const columns = [
        { 
            title: 'Cliente', 
            key: 'clienteId',
            render: (fullData) => <Link target="_blank" to={`/cadastros/cliente/${fullData.key}`}>{fullData.nomeCliente}</Link>,
			defaultExpandAllRows: true,
			align: 'center',
			width: 300,
        },
        {
            title: "Contatos",
            children: [
                {
                    title: "Nome",
					dataIndex: "nome",
					key: 2,
					align: 'center',
                    width: 400,
                },
                {
                    title: "Celular",
                    key: 3,
					dataIndex: "celular",
					align: 'center'
				},
				{
					title: "Email",
					dataIndex: "email",
					key: 4,
					align: 'center'
				}
            ]
        }
	];

    const updateExpandedRowKeys = ({ record }) => {
        const rowKey = record.key;
        const isExpanded = expandedKeys.find(key => key === rowKey);
        let newExpandedRowKeys = [];
        if (isExpanded) {
            newExpandedRowKeys = expandedKeys.reduce((acc, key) => {
            if (key !== rowKey) acc.push(key);
            return acc;
            }, []);
        } else {
            newExpandedRowKeys = expandedKeys;
            newExpandedRowKeys.push(rowKey);
        }
        setExpandedKeys(newExpandedRowKeys);
    };

    useEffect(() => {
		if (Array.isArray(relatorio)) {
            setExpandedKeys([]);
			setIsLoading(true);
            let exKeys = [];

            relatorio.forEach(x => {
                exKeys.push(x.key);
            });

            setExpandedKeys(exKeys);

            setFixedReport(relatorio);
			setIsLoading(false);
        }
    }, [relatorio]);

	return (
        <div>
            <div style={{display: "flex"}}>
                <Select
                    size='medium'
                    placeholder="Cliente"
                    mode="multiple"
                    showSearch
                    allowClear
					style={{ width: '100%', paddingRight: '5px', height: '100%' }}
                    onChange={setClientes}
					filterOption={filterSelect}
                    options={clientesDb}
                />
				<ReactToPrint
					trigger={() => <Button type="primary" icon={<PrinterOutlined />} disabled={!relatorio} />}
					content={() => tableRef.current}
					pageStyle={"padding: 1px;"}
				/>
            </div>
            <div style={{marginTop: '10px'}}>
                <Table
                    expandable={{
                        defaultExpandAllRows: true,
                        expandedRowKeys: expandedKeys,
                        onExpand: (record) => {
                            updateExpandedRowKeys({ record });
                        }
                    }}
                    id="report-form" 
                    ref={tableRef}
					columns={columns}
                    dataSource={fixedReport}
                    pagination={false}
					loading={isLoading || isFetching}
                    bordered
                />
            </div>
        </div>
	);
}