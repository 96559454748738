import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';
import { ContratoDetalhesPrint } from '../DetalheContratoPrint/DetalheContratoPrint'

export default function ImprimirContrato({ contrato }) {
	const componentRef = useRef();
	
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return <div>
		<Button type="primary" onClick={handlePrint}>Imprimir</Button>
		<div style={{ display: 'none' }}>
			<ContratoDetalhesPrint contrato={contrato} ref={componentRef} />
		</div>
	</div>

}