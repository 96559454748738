import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { DatePicker, Spin } from 'antd';
import { formatCurrencyTwo } from '../../Utils/Utils';
import { getProjecaoDiaria } from '../../../apis/controllers/data';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

export default function Projecoes(){
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(null);
    const [dateRange, setDateRange] = useState([new Date().getMonth() + 1, new Date().getFullYear()]);

    useEffect(() => {
        fetchData();
    }, [dateRange]);

    async function fetchData(){
        setIsLoading(true);
        const result = await getProjecaoDiaria(dateRange[0], dateRange[1]);
        
        setData(result.sort((a,b) => (parseInt(a.date) > parseInt(b.date)) ? 1 : ((parseInt(b.date) > parseInt(a.date)) ? -1 : 0)));
        setIsLoading(false);
    }

    function onChangeMonth(date){
        setDateRange([date.month() + 1, dateRange[1]]);
    }

    function onChangeYear(date){
        setDateRange([dateRange[0], date.year()]);
    }
      
    return (
        <Spin size="large" spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: '100px', color: '#067e14' }}/>}>
            <div style={{height: '400px', width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <DatePicker format={"MMMM"} onChange={onChangeMonth} defaultValue={dayjs(new Date())} placeholder="Mês" picker="month" style={{width: '50%', margin: '10px'}} />
                    <DatePicker format={"YYYY"} onChange={onChangeYear} defaultValue={dayjs(new Date())} placeholder="Ano" picker="year" style={{width: '50%', margin: '10px'}}/>
                </div>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={data}
                        width={'100%'}
                        margin={{
                            top: 10,
                            right: 10,
                            left: 60,
                            bottom: 10,
                        }}
                    >
                    <XAxis dataKey="date" />
                    <YAxis tickFormatter={formatCurrencyTwo}/>
                    <Tooltip formatter={formatCurrencyTwo} labelFormatter={(v) => `Dia ${v}`}/>
                        <Bar dataKey="entrada" name={"Entrada"} fill="#3f8600" />
                        <Bar dataKey="saida" name={"Saída"} fill="#cf1322" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Spin>
    );
}