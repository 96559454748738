import React from "react";
import * as ReactDOMClient from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Auth0Provider
        domain="lima-e-tarifa.us.auth0.com"
        clientId="DuYDuIup5QVMSIRxzS3a0FagfexnDcm5"
        audience="backoffice.limaetarifa.com.br"
        scope="openid email profile"
        cacheLocation="localstorage"
        redirectUri={window.location.origin}
      >
        <App />
      </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
