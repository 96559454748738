import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, InputNumber, Modal, Form, Result } from 'antd';
import { saidaPesagem } from '../../../../apis/backend';
import { LoadingOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import BalancasTooltip from '../../../Utils/BalancasTooltip';

export default NiceModal.create(({ record }) => {
	const modal = useModal();
	const [form] = Form.useForm();
	const [updateStatus, setUpdateStatus] = useState({
		inProgress: false,
		title: 'Registrando saída',
		subtitle: null,
		icon: <LoadingOutlined />,
		extra: null
	});
	const fieldsRules = [
		{
			required: true
		}
	];

	useEffect(() => {
		form.setFieldsValue(record);
	}, []);

	async function update(payload) {
		payload.id = record.id;

		setUpdateStatus({ inProgress: true, title: 'Registrando saída', subtitle: null, icon: <LoadingOutlined />, extra: null });

		try {
			await saidaPesagem(payload);
			setUpdateStatus({
				inProgress: true,
				title: 'Saída concluída',
				subtitle: null,
				icon: <CheckCircleOutlined style={{ color: '#5CB914' }} />,
				extra: <Button type="primary" onClick={() => closeEdit(true)}>Fechar</Button>
			});
		} catch (e) {
			console.error(e);
			return setUpdateStatus({
				inProgress: true,
				title: 'Falha ao registrar saída pesagem!',
				subtitle: `Ocorreu uma falha ao registrar saída da pesagem. (${e})`,
				icon: <StopOutlined style={{ color: '#C41E3A' }} />,
				extra: [
					<Button onClick={() => closeEdit(true)}>Cancelar</Button>,
					<Button type="primary" onClick={() => update(payload)}>Tentar novamente</Button>
				]
			});
		}
	}

	function closeEdit(shouldMutate) {
		if (shouldMutate)
			modal.resolve();
		modal.hide();
	}

	function onClickEscritorioSaida(e){
		form.setFields([{
			name: "pesoSaida",
			value: e.peso
		}]);
	}

	function onClickPatioSaida(e){
		form.setFields([{
			name: "pesoSaida",
			value: e.peso
		}]);
	}

	const RomaneioUpdateForm = () => {
		return <Form form={form} name="romaneioForm" onFinish={update}>
			<Row justify='space-between' gutter={4}>
				<Col span={6}>
					<Form.Item
						label="Umidade"
						name={"umidade"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Impureza"
						name={"impureza"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Avariados"
						name={"avariados"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="PS"
						name={"prestacaoServico"}
						rules={fieldsRules}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<BalancasTooltip onClickEscritorio={onClickEscritorioSaida} onClickPatio={onClickPatioSaida}>
						<Form.Item
							label="Peso Saída"
							name={"pesoSaida"}>
							<InputNumber addonAfter={'KG'} style={{ width: '100%' }}></InputNumber>
						</Form.Item>
					</BalancasTooltip>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Observações"
						name={"observacoes"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	}

	const PeasagemUpdateProgress = () => {
		return <Result
			icon={updateStatus.icon}
			subTitle={updateStatus.subtitle}
			title={updateStatus.title}
			extra={updateStatus.extra}
		/>
	}

	return (
		<Modal
			title="Saída Pesagem"
			centered
			open={modal.visible}
			width="90%"
			destroyOnClose={true}
			closable={false}
			keyboard={false}
			maskClosable={false}
			afterClose={() => modal.remove()}
			footer={!updateStatus.inProgress ? [
				<Button onClick={closeEdit}>
					Cancelar
				</Button>,
				<Button type="primary" form="romaneioForm" key="submit" htmlType="submit">
					Salvar
				</Button>
			] : <></>}>
			{!updateStatus.inProgress ? <RomaneioUpdateForm /> : <PeasagemUpdateProgress />}
		</Modal>
	);
});