import React, { useState, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { MaskedInput } from 'antd-mask-input';
import {Row, Col, Input, Button, InputNumber, Modal, Select, Form, AutoComplete, Result} from 'antd';
import {usePlacasRomaneio, useProdutosRomaneio, usePontosDescarga, useClientesRomaneio, criarRomaneio, getRomaneioPdf} from '../../../../apis/backend';
import { listaVeiculos, isValidCPF, transgeniaOptions, romaneioTipos, filterSelect, printBase64Pdf} from '../../../Utils/Utils';
import { LoadingOutlined, StopOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import BalancasTooltip from '../../../Utils/BalancasTooltip';

export default NiceModal.create(() => {
	const modal = useModal();
	const [form] = Form.useForm();
	const [placaOptionsFiltered, setPlacaOptionsFiltered] = useState([]);
	const [creationStatus, setCreationStatus] = useState({
		inProgress: false,
		title: 'Criando romaneio',
		subtitle: null,
		icon: <LoadingOutlined />,
		extra: null
	});
	const { placas, isLoading: loadingPlacas } = usePlacasRomaneio();
	const { produtos, isLoading: loadingProdutos } = useProdutosRomaneio();
	const { clientes, isLoading: loadingClientes } = useClientesRomaneio();
	const { pontosDescarga } = usePontosDescarga();
	const fieldsRules = [
		{
			required: true
		}
	];
	const [disabledTrans, setDisabledTrans] = useState(true);
	const [psDisabled, setPsDisabled] = useState(false);

	useEffect(() => {
		if (placas)
			setPlacaOptionsFiltered(placas);
	}, [placas, loadingPlacas]);

	async function create(payload) {
		if (!isValidCPF(payload.motoristaCpf)) {
			return form.setFields([{
				name: 'motoristaCpf',
				errors: ["Insira um CPF válido"]
			}]);
		}

		if (payload.produtoId === 2 && payload.tipo === 'Entrada') {
			if (payload.transgenia === transgeniaOptions[0].value) {
				return form.setFields([{
					name: 'transgenia',
					errors: ["Insira a opção correta"]
				}]);
			}
		}

		setCreationStatus({ inProgress: true, title: 'Criando romaneio', subtitle: null, icon: <LoadingOutlined />, extra: null });

		let romaneio;

		try {
			romaneio = await criarRomaneio(payload);
		} catch (e) {
			return setCreationStatus({
				inProgress: true,
				title: 'Falha ao criar romaneio!',
				subtitle: `Ocorreu uma falha ao criar o romaneio. (${e})`,
				icon: <StopOutlined style={{ color: '#C41E3A' }} />,
				extra: [
					<Button onClick={() => cancelCreation(true)}>Cancelar</Button>,
					<Button type="primary" onClick={() => create(payload)}>Tentar novamente</Button>
				]
			});
		}

		try {
			setCreationStatus({ inProgress: true, title: 'Gerando impressão', subtitle: null, icon: <LoadingOutlined />, extra: null});
			const pdf = await getRomaneioPdf(romaneio.id);
			printBase64Pdf(pdf, onPrintClose);
		} catch (e) {
			setCreationStatus({
				inProgress: true,
				title: 'Falha ao imprimir!',
				subtitle: `O romaneio foi criado, mas não foi possível imprimir. Imprima utilizando a lista de romaneios. (${e})`,
				icon: <WarningOutlined style={{ color: '#D8BA23' }} />,
				extra: <Button type="primary" onClick={() => cancelCreation(true)}>Fechar</Button>,
			});
		}
	}

	function onPrintClose() {
		setCreationStatus({
			inProgress: true,
			title: 'Romaneio criado',
			subtitle: null,
			icon: <CheckCircleOutlined style={{ color: '#5CB914' }} />,
			extra: <Button type="primary" onClick={() => cancelCreation(true)}>Fechar</Button>
		});
	}

	function onSelectPlaca(placa) {
		const placaFull = placas.find(x => x.value === placa).fullData;

		Object.keys(placaFull).forEach(x => {
			form.setFields([
				{
					name: x,
					value: placaFull[x]
				}
			])
		});
	};

	function cancelCreation(shouldMutate) {
		if (shouldMutate)
			modal.resolve();
		modal.hide();
	}

	function calculatePs(e) {
		const keys = Object.keys(e);
		const valueForm = form.getFieldsValue();

		if(keys.includes('tipo')){
			if(valueForm.tipo === 'Saída'){
				form.setFields([{
					name: "prestacaoServico",
					value: 0
				}]);
				setPsDisabled(true);
			}else{
				form.setFields([{
					name: "prestacaoServico"
				}]);
				setPsDisabled(false);
			}
		}

		if (!keys.includes("umidade") && !keys.includes("produtoId") && !keys.includes("clienteId"))
			return;

		if (!valueForm.produtoId || !valueForm.clienteId || !valueForm.umidade)
			return;
        
		const fullProduct = produtos.find(x => x.value === valueForm.produtoId);
		const fullClient = clientes.find(x => x.value === valueForm.clienteId);
		const umidade = valueForm.umidade;
		let prestacaoValue = 0;

		fullProduct.tabelaPs.forEach(x => {
			if (umidade >= x.umidadeInicial && umidade <= x.umidadeFinal) {
				prestacaoValue = x.desconto;
				return;
			}
		});

		fullClient.ps.forEach(x => {
			if (umidade >= x.umidadeInicial && umidade <= x.umidadeFinal && x.produtoId === valueForm.produtoId) {
				prestacaoValue = x.desconto;
				return;
			}
		});

		form.setFields([
			{
				name: 'prestacaoServico',
				value: prestacaoValue
			}
		]);
	}

	function calculateMoega() {
		const values = form.getFieldsValue();

		if (values.produtoId && values.umidade) {
			const umidade = values.umidade;
			const produtoId = values.produtoId;

			let found = false;

			pontosDescarga.forEach(ponto => {
				if (ponto.produtoId !== produtoId || found)
					return;

				if (umidade >= ponto.umidadeInicial && umidade <= ponto.umidadeFinal) {
					form.setFields([{
						name: "moega",
						value: ponto.moega
					}]);
					found = true;
				}
			});

			if (!found) {
				form.setFields([{
					name: "moega",
					value: null
				}]);
			}

		}
	}

	function calculateTrans() {
		if(form.getFieldValue("produtoId") === 2 && form.getFieldValue("tipo") === "Saída"){
			form.setFields([{
				name: "transgenia",
				value: "Baixada"
			}]);
		}
		
		setDisabledTrans(form.getFieldValue("produtoId") !== 2 || form.getFieldValue("tipo") === "Saída")
	}

	function onChangeForm(e) {
		calculatePs(e);
		calculateMoega(e);
		calculateTrans(e);
	}

	function onClickEscritorio(e){
		form.setFields([{
			name: "pesoEntrada",
			value: e.peso
		}]);
	}

	function onClickPatio(e){
		form.setFields([{
			name: "pesoEntrada",
			value: e.peso
		}]);
	}

	const RomaneioCreationForm = () => {
		return <Form
			form={form}
			name="romaneioForm"
			onFinish={create}
			initialValues={{
				"transgenia": transgeniaOptions[0].value
			}}
			onValuesChange={onChangeForm}>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Tipo"
						name={"tipo"}
						rules={fieldsRules}>
						<Select options={romaneioTipos} />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={12}>
					<Form.Item
						label="Produto"
						name={"produtoId"}
						rules={fieldsRules}>
						<Select
							allowClear
							showSearch
							loading={loadingProdutos}
							filterOption={filterSelect}
							options={produtos}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cliente"
						name={"clienteId"}
						rules={fieldsRules}>
						<Select
							allowClear
							showSearch
							loading={loadingClientes}
							filterOption={filterSelect}
							options={clientes}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={4}>
					<Form.Item
						label="Placa"
						name={"placaVeiculo"}
						rules={fieldsRules}>
						<AutoComplete
							options={placaOptionsFiltered}
							filterOption={filterSelect}
							onSelect={onSelectPlaca}
						/>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Tipo Veículo"
						name={"tipoVeiculo"}
						rules={fieldsRules}>
						<Select options={listaVeiculos} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Nome"
						name={"motoristaNome"}
						rules={fieldsRules}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="CPF"
						name={"motoristaCpf"}
						rules={fieldsRules}>
						<MaskedInput mask={'000.000.000-00'}></MaskedInput>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Celular"
						name={"motoristaCelular"}>
						<MaskedInput mask={'(00) 000000000'}></MaskedInput>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={4}>
					<Form.Item
						label="Umidade"
						name={"umidade"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Impureza"
						name={"impureza"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Avariados"
						name={"avariados"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="PS"
						name={"prestacaoServico"}
						rules={fieldsRules}>
						<InputNumber disabled={psDisabled} max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Transgenia"
						name={"transgenia"}>
						<Select options={transgeniaOptions} disabled={disabledTrans}/>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={6}>
					<BalancasTooltip onClickEscritorio={onClickEscritorio} onClickPatio={onClickPatio}>
						<Form.Item
						label="Peso Entrada"
						name={"pesoEntrada"}
						rules={fieldsRules}>
							<InputNumber addonAfter={'KG'} style={{ width: '100%' }} />
						</Form.Item>
					</BalancasTooltip>
				</Col>
				<Col span={18}>
					<Form.Item
						label="Moega"
						name={"moega"}>
						<Input disabled />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Observações"
						name={"observacoes"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	}

	const RomaneioCreationProgress = () => {
		return <Result
			icon={creationStatus.icon}
			subTitle={creationStatus.subtitle}
			title={creationStatus.title}
			extra={creationStatus.extra}
		/>
	}

	return ( 
		<Modal
			title="Criar Romaneio"
			centered
			open={modal.visible}
			width="90%"
			destroyOnClose={true}
			closable={false}
			keyboard={false}
			maskClosable={false}
			afterClose={() => modal.remove()}
			footer={!creationStatus.inProgress ? [
				<Button key={1} onClick={cancelCreation}>
					Cancelar
				</Button>,
				<Button key={2} type="primary" form="romaneioForm" htmlType="submit">
					Salvar e Imprimir
				</Button>
			] : <></>}
		>
			{!creationStatus.inProgress ? <RomaneioCreationForm /> : <RomaneioCreationProgress />}
		</Modal>
	)
});