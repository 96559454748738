import React, { useState } from 'react';
import { useContatos } from "../../../apis/backend";
import { Table, Button, Space, Tooltip } from 'antd';
import { useModal } from '@ebay/nice-modal-react';
import { StarFilled } from '@ant-design/icons';

import ContatosClienteActions from '../ContatosClienteActions/ContatosClienteActions';
import ContatoClienteCriar from '../ContatoClienteCriar/ContatoClienteCriar';

export default function ContatosCliente(props) {
    const [cliente] = useState(props.cliente);
	const criarContatoModal = useModal(ContatoClienteCriar);
	const {contatos, mutate} = useContatos(cliente.id);
	const isLoading = props.isLoading
	const columns = [
		{
			title: 'Nome', key: 'nome', render: (obj) =>
				<span>{obj.isPortalEnabled ?
					<Tooltip title="Portal Ativado">
						<StarFilled style={{ color: '#FFD700', fontSize: '17px' }} />
					</Tooltip> : <></>} {obj.nome}
				</span>,
		},
		{ title: 'Celular', dataIndex: 'celularUm', key: 'celular' },
		{ title: 'Email', dataIndex: 'email', key: 'email' },
		{
		  title: 'Ações',
		  dataIndex: '',
		  key: '',
			render: (_, a) => <ContatosClienteActions contato={a} />,
		},
	];

	async function getContatos(){
		isLoading(true);
		await mutate();
		isLoading(false);
	}

	function onClickAdd() {
		criarContatoModal.show({ cliente: cliente }).then(async () => {
			getContatos();
		});
	}

	const ExpandedContato = (record) => (
		<div>Celular Secundário: {record.celularDois} | Fixo: {record.telefoneFixo}</div>
	);

    return (
		<div>
			<Space size={"small"} direction="vertical" style={{ width: "100%" }}>
				
				<div style={{width: "100%", display: "flex", justifyContent: 'end'}}>
					<Button type="primary" onClick={() => onClickAdd()}>Adicionar</Button>
				</div>
			
				<Table
					rowKey={"id"}
					columns={columns}
					expandable={{
						expandedRowRender: record => ExpandedContato(record),
						rowExpandable: () => true,
					}}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 5,
						showSizeChanger: false
					}}
					dataSource={contatos}
					loading={isLoading}
				/>
			</Space>
        </div>
    )
}