import React from 'react';
import { useParams } from "react-router-dom";
import DetalheLancamento from '../../../components/Financeiro/DetalheLancamento/DetalheLancamento';

export default function Lancamento() {
	const { id } = useParams();

	return (
		<DetalheLancamento id={id}></DetalheLancamento>
	);
}