import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Button } from 'antd';
import CriarLancamentoModal from '../CriarLancamentoModal/CriarLancamentoModal';

export default function CriarLancamento() {
	const criarLancamentoModal = useModal(CriarLancamentoModal);

	function criarLancamento() {
		criarLancamentoModal.show();
	}

	return (
		<div>
			<Button type="primary" size={"large"} onClick={criarLancamento}>Criar</Button>
		</div>
	);
}