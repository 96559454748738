import React, { useState } from 'react';
import './CriarCliente.scoped.css';
import {
  Button,
  Modal,
  Input,
  Form,
  Spin,
  Row,
  Col,
  Select,
  DatePicker,
  notification,
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { MaskedInput } from 'antd-mask-input';
import { buscarCep, criarCliente } from '../../../apis/backend';
import { tiposCliente, relacionamentoCliente, atividadesCliente, simNao } from '../../Utils/Utils';

const { TextArea } = Input;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function CriarCliente() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [secondaryIsLoading, setSecondaryIsLoading] = useState(false);
    const [primaryIsLoading, setPrimaryIsLoading] = useState(false);
    const [personType, setPersonType] = useState("Pessoa Jurídica");
    const fieldsRules = [
        {
            required: true
        }
    ];

    function cancelCreation(){
        form.resetFields();
        setVisible(false);
    }

    async function createCustomer(e){
        setPrimaryIsLoading(true);
        setSecondaryIsLoading(true);
        
        try {
            navigate(`/cadastros/cliente/${await criarCliente(e)}`);
        } catch (e) {
            setPrimaryIsLoading(false);
			setSecondaryIsLoading(false);
			
            notification.error({
              message: "Erro",
              description: "Falha ao criar cliente. Tente novamente. " + e,
            });
        }
        
    }

    async function findCep(e){
        if(e.unmaskedValue.length !== 8)
            return;
        setIsLoadingModal(true);
        
        try{
            const endereco = await buscarCep(e.unmaskedValue);

            form.setFieldsValue({
                Endereco: endereco.address,
                Bairro: endereco.district,
                Cidade: endereco.city,
                Estado: endereco.state
            });            
        }catch(e){
            console.error(e);
        }
        
        setIsLoadingModal(false);
    }

    return (
		<div>
            <Button type="primary" size="large" onClick={() => setVisible(true)}>
				Criar
			</Button>
            <Modal
				title="Criar Cliente"
				centered
				open={visible}
				width="90%"
				destroyOnClose={true}
				closable={false}
				keyboard={false}
				maskClosable={false}
				footer={[
					<Button onClick={cancelCreation} loading={secondaryIsLoading}>
					  	Cancelar
					</Button>,
					<Button type="primary" loading={primaryIsLoading} form="clienteForm" key="submit" htmlType="submit">
					  	Criar
					</Button>
				]}
			>
                <Spin indicator={antIcon} spinning={isLoadingModal} size="large" tip="Carregando...">
                    <div>
						<Form initialValues={{ OcorreQt: 'Sim' }} form={form} name="clienteForm" onFinish={createCustomer}>
                            <Row justify='space-between' gutter={4}>
								<Col span={6}>
									<Form.Item
									label="Tipo"
									name={"TipoPessoa"}
									rules={fieldsRules}>
										<Select
											options={tiposCliente}
											onSelect={setPersonType}
											optionFilterProp="children">
										</Select>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										label="Relacionamento"
										name={"Relacionamento"}
										rules={fieldsRules}>
										<Select
											options={relacionamentoCliente}>
										</Select>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										label="Atividade"
										name={"Atividade"}
										rules={fieldsRules}>
										<Select
											options={atividadesCliente}>
										</Select>
									</Form.Item>
								</Col>
								<Col span={6}>
									<Form.Item
										label="Ocorre Q.T"
										name={"OcorreQt"}
										rules={fieldsRules}>
										<Select
											options={simNao}>
										</Select>
									</Form.Item>
								</Col>
                            </Row>
                            {
                                personType === "Pessoa Jurídica" ? 
                                    <Row justify='space-between' gutter={4}>
                                        <Col span={12}>
                                            <Form.Item
                                            label="Razão Social"
                                            name={"RazaoSocial"}
                                            rules={fieldsRules}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="CNPJ"
                                                name={"CNPJ"}
                                                rules={fieldsRules}>
                                                <MaskedInput mask={'00.000.000/0000-00'}></MaskedInput>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="IE"
                                                name={"IE"}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row> : 
                                    <Row justify='space-between' gutter={4}>
                                        <Col span={10}>
                                            <Form.Item
                                            label="Nome"
                                            name={"Nome"}
                                            rules={fieldsRules}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Nascimento"
                                                name={"Nascimento"}
                                                rules={fieldsRules}>
                                                    <DatePicker style={{width: "100%"}} format="DD/MM/YYYY"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="CPF"
                                                name={"CPF"}
                                                rules={fieldsRules}>
                                                <MaskedInput mask={'000.000.000-00'}></MaskedInput>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                label="RG"
                                                name={"RG"}>
                                            <MaskedInput mask={'00.000.000-[*]'}></MaskedInput>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                            }
                            <Row justify='space-between' gutter={4}>
                                <Col span={5}>
                                    <Form.Item
                                    label="CEP"
                                    name={"CEP"}
                                    rules={fieldsRules}>
                                        <MaskedInput mask={'00000-000'} onBlur={findCep}></MaskedInput>
                                    </Form.Item>
                                </Col>
                                <Col span={14}>
                                    <Form.Item
                                        label="Endereço"
                                        name={"Endereco"}
                                        rules={fieldsRules}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item
                                        label="Número"
                                        name={"Numero"}>
                                    <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between' gutter={4}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Complemento"
                                        name={"Complemento"}>
                                    <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between' gutter={4}>
                                <Col span={8}>
                                    <Form.Item
                                        label="Bairro"
                                        name={"Bairro"}
                                        rules={fieldsRules}>
                                    <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Cidade"
                                        name={"Cidade"}
                                        rules={fieldsRules}>
                                    <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="Estado"
                                        name={"Estado"}
                                        rules={fieldsRules}>
                                    <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-between' gutter={4}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Observações"
                                        name={"Observacoes"}>
                                        <TextArea rows={3} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Spin>
            </Modal>
        </div>
    );
}