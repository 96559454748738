import { Button, Modal, Descriptions, ConfigProvider } from 'antd';
import ptBr from 'antd/lib/locale/pt_BR';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useContrato } from '../../apis/backend';
import { Link } from 'react-router-dom';
import { formatDate, getCustomerName, formatCurrencyTwo } from '../Utils/Utils';

export default NiceModal.create(({ contratoId }) => {
    const { contrato } = useContrato(contratoId);

    const modal = useModal();

    function cancelEdit() {
        modal.hide();
    }

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Visualizar Contrato"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelEdit}>
                        Fechar
                    </Button>,
                ]}
            >
                <Descriptions bordered column={24} size="small">
                    <Descriptions.Item label="Tipo" span={24}>
                        {contrato?.modalidade}
                    </Descriptions.Item>

                    <Descriptions.Item label="Comprador" span={12}>
                        <Link to={`/cadastros/cliente/${contrato?.compradorId}`} target="_blank">
                            {getCustomerName(contrato?.comprador)}
                        </Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="Vendedor" span={12}>
                        <Link to={`/cadastros/cliente/${contrato?.vendedorId}`} target="_blank">
                            {getCustomerName(contrato?.vendedor)}
                        </Link>
                    </Descriptions.Item>

                    <Descriptions.Item label="Produto" span={8}>
                        {contrato?.produto?.descricao}
                    </Descriptions.Item>
                    <Descriptions.Item label="Quantidade Total" span={8}>
                        {contrato?.quantidadeTotal} KG
                    </Descriptions.Item>
                    <Descriptions.Item label="Preço (R$/KG)" span={8}>
                        {formatCurrencyTwo(contrato?.preco)}
                    </Descriptions.Item>

                    <Descriptions.Item label="Numero" span={6}>
                        <Link to={`/cadastros/contrato/${contrato?.id}`} target="_blank">
                            {contrato?.numeroDeContrato}
                        </Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="Início Entrega" span={6}>
                        {formatDate(contrato?.dataInicioEntrega, true)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Data de Pagamento" span={6}>
                        {formatDate(contrato?.dataDePagamento, true)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Prazo de Embarque" span={6}>
                        {formatDate(contrato?.prazoDeEmbarque, true)}
                    </Descriptions.Item>

                    <Descriptions.Item label="T. Frete" span={8}>
                        {contrato?.tipoFrete}
                    </Descriptions.Item>
                    <Descriptions.Item label="Transportador" span={8}>
                        {contrato?.transportador}
                    </Descriptions.Item>
                    <Descriptions.Item label="Frete (R$/KG)" span={8}>
                        {formatCurrencyTwo(contrato?.frete)}
                    </Descriptions.Item>

                    <Descriptions.Item label="Corretor" span={8}>
                        {contrato?.corretor}
                    </Descriptions.Item>
                    <Descriptions.Item label="Comissao (R$/KG)" span={8}>
                        {formatCurrencyTwo(contrato?.comissao)}
                    </Descriptions.Item>
                    <Descriptions.Item label="CTR Externo" span={8}>
                        {contrato?.contratoExterno}
                    </Descriptions.Item>

                    <Descriptions.Item label="Local entrega" span={24}>
                        {contrato?.localEntrega}
                    </Descriptions.Item>

                    <Descriptions.Item label="Banco" span={8}>
                        {contrato?.bancoConta}
                    </Descriptions.Item>
                    <Descriptions.Item label="C.c" span={8}>
                        {contrato?.contaCorrent}
                    </Descriptions.Item>
                    <Descriptions.Item label="Agência" span={8}>
                        {contrato?.agencia}
                    </Descriptions.Item>

                    <Descriptions.Item label="CNPJ" span={8}>
                        {contrato?.cnpjConta}
                    </Descriptions.Item>
                    <Descriptions.Item label="CPF" span={8}>
                        {contrato?.cpfConta}
                    </Descriptions.Item>
                    <Descriptions.Item label="RG" span={8}>
                        {contrato?.rgConta}
                    </Descriptions.Item>

                    <Descriptions.Item label="Quantidade Embarcada" span={12}>
                        {contrato?.quantidadeEmbarcada}
                    </Descriptions.Item>
                    <Descriptions.Item label="Saldo a Embarcar" span={12}>
                        {contrato?.saldoAEmbarcar}
                    </Descriptions.Item>

                    <Descriptions.Item label="Observações" span={24}>
                        {contrato?.observacoes}
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </ConfigProvider>
    );
});
