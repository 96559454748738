import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Modal, Select, Form, AutoComplete, notification } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { usePlacasRomaneio, atualizarRomaneioBasico } from '../../apis/backend';
import { listaVeiculos, isValidCPF, transgeniaOptions, filterSelect } from '../Utils/Utils';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default NiceModal.create(({ record }) => {
	const modal = useModal();
	const [form] = Form.useForm();
	const [placaOptionsFiltered, setPlacaOptionsFiltered] = useState([]);
	const { placas, isLoading: loadingPlacas } = usePlacasRomaneio();
	const fieldsRules = [
		{
			required: true
		}
	];

	useEffect(() => {
		form.setFieldsValue(record);
	}, []);

	useEffect(() => {
		if (placas) {
			setPlacaOptionsFiltered(placas);
		}
	}, [placas, loadingPlacas]);

	async function update(payload) {
		payload.id = record.id;

		if (!isValidCPF(payload.motoristaCPF)) {
			return form.setFields([{
				name: 'motoristaCPF',
				errors: ["Insira um CPF válido"]
			}]);
		}

		try {
			await atualizarRomaneioBasico(payload);
            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao editar romaneio.'
            });
            modal.hide();
		} catch (e) {
			console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao editar romaneio. Tente novamente',
            });
		}
	}

	function onSelectPlaca(placa) {
		const placaFull = placas.find(x => x.value === placa).fullData;

		Object.keys(placaFull).forEach(x => {
			form.setFields([
				{
					name: x,
					value: placaFull[x]
				}
			])
		});
	}

	function closeEdit(shouldMutate) {
		if (shouldMutate)
			modal.resolve();
		modal.hide();
	}

	const RomaneioUpdateForm = () => {
		return <Form form={form} name="romaneioForm" onFinish={update}>
			<Row justify='space-between' gutter={4}>
				<Col span={4}>
					<Form.Item
						label="Placa"
						name={"placaVeiculo"}
						rules={fieldsRules}>
						<AutoComplete
							options={placaOptionsFiltered}
							filterOption={filterSelect}
							onSelect={onSelectPlaca}
						/>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Tipo Veículo"
						name={"tipoVeiculo"}
						rules={fieldsRules}>
						<Select options={listaVeiculos} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Nome"
						name={"motoristaNome"}
						rules={fieldsRules}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="CPF"
						name={"motoristaCPF"}
						rules={fieldsRules}>
						<MaskedInput mask={'000.000.000-00'}></MaskedInput>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Celular"
						name={"motoristaCelular"}>
						<MaskedInput mask={'(00) 000000000'}></MaskedInput>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Transgenia"
						name={"transgenia"}
					>
						<Select options={transgeniaOptions} />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Observações"
						name={"observacoes"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	}

	return (
		<Modal
			title="Editar Romaneio"
			centered
			open={modal.visible}
			width="90%"
			destroyOnClose={true}
			closable={false}
			keyboard={false}
			maskClosable={false}
			afterClose={() => modal.remove()}
			footer={[
				<Button key={1} onClick={closeEdit}>
					Cancelar
				</Button>,
				<Button key={2} type="primary" form="romaneioForm" htmlType="submit">
					Salvar
				</Button>
            ]}>
				<RomaneioUpdateForm />
		</Modal>
	);
});