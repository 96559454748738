import React from 'react';
import './Descarga.scoped.css';
import { Breadcrumb, Divider, Button } from 'antd';
import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import CriarDescarga from '../../../components/Expediente/CriarDescarga/CriarDescarga';
import ListarDescargas from '../../../components/Expediente/ListarDescargas/ListarDescargas';

export default function Descarga() {
	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Expediente</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/expediente/descarga">Descarga</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<Button type="primary" size="large" onClick={() => NiceModal.show(CriarDescarga)} role="primary">Criar</Button>
			</div>
			<Divider />
			<ListarDescargas></ListarDescargas>
		</div>
	);
}