import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';

import GerenciamentoSimplesPesagem from '../../../components/Movimento/SimplesPesagem/GerenciamentoSimplesPesagem/GerenciamentoSimplesPesagem'

export default function SimplesPesagem() {
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Breadcrumb>
					<Breadcrumb.Item>Movimento</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/movimento/simples-pesagem">Simples Pesagem</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
			</div>
			<Divider />
			<GerenciamentoSimplesPesagem />
		</div>
	);
}