import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Drawer, Button } from 'antd';
import background from "../../../imgs/login-img.jpg";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
		<div style={{height: "100vh", backgroundImage: `url('${background}')`, backgroundSize: 'cover' }}>
			<Drawer placement="right" open={true} maskClosable={false} closable={false} mask={false} width={400}>
				<div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
					<img src="https://i.imgur.com/St9BGKw.jpeg" alt="logo" style={{width: '100%'}}></img>
				</div>
				<div style={{display: 'flex', height: '100%', width: '100%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: '-200px'}}>
					<Button type="primary" onClick={() => loginWithRedirect({prompt: "login"})} size={"large"}>Realizar Login</Button>
				</div>
			</Drawer>
		</div>
    );
};

export default LoginButton;