import React from 'react';
import { Modal, Button, Descriptions, Badge } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { formatPeso, getCustomerName, formatDateWithTime } from '../../Utils/Utils';

export default NiceModal.create(({ rec }) => {
	const modal = useModal();

	const diff = Math.abs(rec.novoValor - rec.valorAnterior);

	return (<Modal
		title="Detalhes da Transação"
		centered
		open={modal.visible}
		width="90%"
		destroyOnClose={true}
		closable={false}
		keyboard={false}
		maskClosable={false}
		afterClose={() => modal.remove()}
		footer={[
			<Button type="primary" onClick={() => modal.hide()} >
				Fechar
			</Button>
		]}>
		<Descriptions bordered>
			<Descriptions.Item label="Data">{formatDateWithTime(rec.createdDate)}</Descriptions.Item>
			<Descriptions.Item label="Motivo">{rec.motivo}</Descriptions.Item>
			<Descriptions.Item label="Status">
				{rec.isCancelado ? <Badge status="warning" text="Cancelada" /> : <Badge status="success" text="Ativa" />}
			</Descriptions.Item>
			<Descriptions.Item label="Valor Anterior">{formatPeso(rec.valorAnterior)}</Descriptions.Item>
			<Descriptions.Item label="Novo Valor">{formatPeso(rec.novoValor)}</Descriptions.Item>
			<Descriptions.Item label="Diferença">{formatPeso(diff)} ({rec.isAdicao ? 'Adicionado' : 'Subtraído'})</Descriptions.Item>
			<Descriptions.Item label="Cliente" span={3}>{getCustomerName(rec.estoque.cliente)}</Descriptions.Item>
			<Descriptions.Item label="Comentário">{rec.comentario}</Descriptions.Item>
		</Descriptions>
		{rec.isCancelado ? 
			<Descriptions bordered title={"Detalhes do Cancelamento"} style={{marginTop: '10px'}}>
				<Descriptions.Item label="Data">{formatDateWithTime(rec.parentTransacao.createdDate)}</Descriptions.Item>
				<Descriptions.Item label="Motivo">{rec.parentTransacao.motivo}</Descriptions.Item>
				<Descriptions.Item label="Status">
					{rec.parentTransacao.isCancelado ? <Badge status="warning" text="Cancelada" /> : <Badge status="success" text="Ativa" />}
				</Descriptions.Item>
				<Descriptions.Item label="Valor Anterior">{formatPeso(rec.parentTransacao.valorAnterior)}</Descriptions.Item>
				<Descriptions.Item label="Novo Valor">{formatPeso(rec.parentTransacao.novoValor)}</Descriptions.Item>
				<Descriptions.Item label="Diferença">{formatPeso(diff)} ({rec.parentTransacao.isAdicao ? 'Adicionado' : 'Subtraído'})</Descriptions.Item>
				<Descriptions.Item label="Cliente" span={3}>{getCustomerName(rec.parentTransacao.estoque.cliente)}</Descriptions.Item>
				<Descriptions.Item label="Comentário">{rec.parentTransacao.comentario}</Descriptions.Item>
			</Descriptions> : <></>}
	</Modal>)
});