import React, { useState } from 'react';
import { Modal, Button, Input, notification } from 'antd';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { formatPeso, getCustomerName } from '../../Utils/Utils';
import { cancelarTransacao } from '../../../apis/controllers/contrato';

const { TextArea } = Input;

export default NiceModal.create(({ rec }) => {
    const modal = useModal();
    const [comentario, setComentario] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    async function onClickSave() {
        setIsLoading(true);

        if (comentario.length < 5) {
            api.error({
                message: 'Erro',
                description: 'O comentário deve ser maior que 5 caracteres.',
            });

            setIsLoading(false);
            return;
        }

        try {
            await cancelarTransacao(rec.id, comentario);
            api.success({
                message: 'Sucesso',
                description: 'A transação foi cancelada.',
            });
            modal.resolve();
            modal.hide();
        } catch (e) {
            api.error({
                message: 'Erro',
                description: e,
            });
            console.error(e);
        }

        setIsLoading(false);
    }

    const diff = Math.abs(rec.novoValor - rec.valorAnterior);

    return (
        <>
            {contextHolder}
            <Modal
                title="Cancelar Transação"
                centered
                open={modal.visible}
                width="90%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button onClick={() => modal.hide()} disabled={isLoading} loading={isLoading}>
                        Cancelar
                    </Button>,
                    <Button type="primary" onClick={onClickSave} disabled={isLoading} loading={isLoading}>
                        Salvar
                    </Button>,
                ]}
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <h2 style={{ marginBottom: '20px' }}>
                            O valor de <b>{formatPeso(diff)}</b> será{' '}
                            {rec.novoValor - rec.valorAnterior > 0 ? (
                                <>
                                    <b>REMOVIDO</b> do
                                </>
                            ) : (
                                <>
                                    <b>ADICIONADO</b> ao
                                </>
                            )}{' '}
                            estoque de <b>{rec.estoque.produto.descricao}</b> do cliente <b>{getCustomerName(rec.estoque.cliente)}</b>.
                        </h2>
                        <h2 style={{ marginBottom: '20px' }}>Você tem certeza que deseja realizar essa ação?</h2>
                    </div>
                    <TextArea rows={4} value={comentario} onChange={(e) => setComentario(e.target.value)} placeholder="Digite o motivo" />
                </div>
            </Modal>
        </>
    );
});
