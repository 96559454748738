import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Modal, Descriptions, ConfigProvider } from 'antd';
import { formatPeso, getCustomerName } from '../Utils/Utils';
import ptBr from 'antd/lib/locale/pt_BR';

export default NiceModal.create(({ romaneio }) => {
    const modal = useModal();

    function cancelEdit() {
        modal.hide();
    }

    return (
        <ConfigProvider locale={ptBr}>
            <Modal
                title="Visualizar Romaneio"
                centered
                open={modal.visible}
                width="95%"
                destroyOnClose={true}
                closable={false}
                keyboard={false}
                maskClosable={false}
                afterClose={() => modal.remove()}
                footer={[
                    <Button key={1} onClick={cancelEdit}>
                        Fechar
                    </Button>,
                ]}
            >
                <Descriptions bordered column={24} size="small">
                    <Descriptions.Item label="Tipo" span={8}>
                        {romaneio.tipo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Produto" span={8}>
                        {romaneio.produto.descricao}
                    </Descriptions.Item>
                    <Descriptions.Item label="Cliente" span={8}>
                        {getCustomerName(romaneio.cliente)}
                    </Descriptions.Item>

                    <Descriptions.Item label="Placa" span={4}>
                        {romaneio.placaVeiculo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipo" span={6}>
                        {romaneio.tipoVeiculo}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nome" span={6}>
                        {romaneio.motoristaNome}
                    </Descriptions.Item>
                    <Descriptions.Item label="CPF" span={4}>
                        {romaneio.motoristaCPF}
                    </Descriptions.Item>
                    <Descriptions.Item label="Celular" span={4}>
                        {romaneio.motoristaCelular}
                    </Descriptions.Item>

                    <Descriptions.Item label="Umidade" span={4}>
                        {romaneio.umidade} %
                    </Descriptions.Item>
                    <Descriptions.Item label="Impureza" span={4}>
                        {romaneio.impureza} %
                    </Descriptions.Item>
                    <Descriptions.Item label="Avariados" span={4}>
                        {romaneio.avariados} %
                    </Descriptions.Item>
                    <Descriptions.Item label="PS" span={4}>
                        {romaneio.prestacaoServico} %
                    </Descriptions.Item>
                    <Descriptions.Item label="Transgenia" span={8}>
                        {romaneio.transgenia}
                    </Descriptions.Item>

                    <Descriptions.Item label="Peso Entrada" span={8}>
                        {formatPeso(romaneio.pesoEntrada)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Peso Saída" span={8}>
                        {formatPeso(romaneio.pesoSaida)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Peso Líquido" span={8}>
                        {formatPeso(romaneio.pesoLiquido)}
                    </Descriptions.Item>

                    <Descriptions.Item label="Operação Fiscal" span={24}>
                        {romaneio?.operacaoFiscal?.nome}
                    </Descriptions.Item>

                    <Descriptions.Item label="Observações" span={24}>
                        {romaneio.observacoes}
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </ConfigProvider>
    );
});
