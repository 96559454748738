import React, { useState } from 'react';
import { Table, Space, Tooltip, Button } from 'antd';
import { useRomaneiosCliente } from "../../../apis/controllers/cliente";
import { useModal } from '@ebay/nice-modal-react';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import RomaneioDetalhes from '../../Utils/RomaneioDetalhes';
import RomaneioPrint from '../../Utils/RomaneioPrint';
import { formatPeso, formatDate } from '../../Utils/Utils';
import EditarRomaneio from '../../Utils/EditarRomaneio';

export default function RomaneiosCliente(props) {
    const [cliente] = useState(props.cliente);
	const editarRomaneioModal = useModal(EditarRomaneio);
	const { romaneios, isLoading } = useRomaneiosCliente(cliente.id);
    const columns = [
		{
			title: 'Data',
			dataIndex: 'data',
			key: 'data',
			ellipsis: true,
			render: (_, record) => formatDate(record.createdDate),
		},
		{
			title: 'Produto',
			dataIndex: ['produto', 'descricao'],
			key: 'produto',
		},
		{
			title: 'Peso Liquido',
			render: (record) => formatPeso(record.pesoLiquido),
		},
		{
			title: 'Ações',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<Tooltip title="Visualizar">
						<Button shape="circle" icon={<EyeOutlined />} onClick={() => onClickView(record)}/>
					</Tooltip>
					<Tooltip title="Editar informações básicas">
						<Button shape="circle" icon={<EditOutlined />} onClick={() => editarRomaneioModal.show({record})}/>
					</Tooltip>
					<RomaneioPrint id={record.id} />
				</Space>
			),
		},
	];

	function onClickView(record){
		NiceModal.show(RomaneioDetalhes, { romaneio: record });
	}

	return (
		<div>
			<Table
				rowKey="id"
				columns={columns}
				dataSource={romaneios}
				loading={isLoading}
				pagination={{
					simple: false,
					hideOnSinglePage: true,
					defaultPageSize: 5,
					showSizeChanger: false
				}}
			/>
		</div>    
    )
}