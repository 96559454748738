import backendApi from '../backendAxiosClient';
import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export async function buscarRomaneiosFechados(startdate, endDate) {
    return (await backendApi.get(`balanca/fechados?startDate=${startdate}&endDate=${endDate}`)).data;
}

export function usePlacasRomaneio() {
    const { data, error, mutate } = useSWR(`balanca/placas`, fetcher);

    return {
        placas: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function usePontosDescarga() {
    const { data, error, mutate } = useSWR(`balanca/pontos-descarga`, fetcher);

    return {
        pontosDescarga: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useProdutosRomaneio() {
    const { data, error, mutate } = useSWR(`balanca/produtos`, fetcher);

    return {
        produtos: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export function useClientesRomaneio() {
    const { data, error, mutate } = useSWR(`balanca/clientes`, fetcher);

    return {
        clientes: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    };
}

export async function deletarRomaneio(romaneioId) {
    return await backendApi.delete(`balanca?id=${romaneioId}`);
}

export async function buscarRomaneio(romaneioId) {
    return (await backendApi.get(`balanca/romaneio?id=${romaneioId}`)).data;
}

export async function criarRomaneio(romaneio) {
    return (await backendApi.post('balanca', JSON.stringify(romaneio))).data;
}

export async function registrarSaidaBackend(saida) {
    return await backendApi.post(`balanca/saida`, JSON.stringify(saida));
}

export async function atualizarRomaneio(romaneio) {
    return await backendApi.patch(`balanca`, JSON.stringify(romaneio));
}

export async function atualizarRomaneioBasico(romaneio) {
    return await backendApi.patch(`balanca/basico`, JSON.stringify(romaneio));
}

export async function criarPesagem(romaneio) {
    return (await backendApi.post('balanca/pesagens', JSON.stringify(romaneio))).data;
}

export async function notificarChamada(romaneioId, notificationType, whatsapp) {
    await backendApi.post(`balanca/notificar?romaneioId=${romaneioId}&notificationType=${notificationType}&whatsapp=${whatsapp}`, null);
}

export async function deletarPesagem(romaneioId) {
    return await backendApi.delete(`balanca/pesagens?id=${romaneioId}`);
}

export async function atualizarPesagem(romaneio) {
    return await backendApi.patch(`balanca/pesagens`, JSON.stringify(romaneio));
}

export async function saidaPesagem(pesagem) {
    return await backendApi.post(`balanca/pesagens/saida`, JSON.stringify(pesagem));
}
