import React, { useEffect, useState } from 'react';
import { Breadcrumb, Divider, Spin, Row, Col, Form, notification, Button, Input, Tabs, Select } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from 'react-router-dom';
import { getDetalheCaixa } from '../../../apis/queries/graphQueries';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { MaskedInput } from 'antd-mask-input';
import TransacoesCaixa from '../TransacoesCaixa/TransacoesCaixa';
import { updateCaixa } from '../../../apis/backend';
import LancamentosRelacionados from '../LancamentosRelacionados/LancamentosRelacionados';
import { formatCurrencyTwo, tiposCaixa } from '../../Utils/Utils';

export default function DetalheCaixa(props) {
    const { data, isLoading } = useGraphQL(getDetalheCaixa, {
        caixaId: parseInt(props.id),
    });
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState('1');
    const [pageLoading, setPageLoading] = useState(false);
    const fieldsRules = [
        {
            required: true,
        },
    ];

    async function update(e) {
        setPageLoading(true);

        e.id = props.id;

        try {
            await updateCaixa(e);

            notification.success({
                message: 'Sucesso',
                description: 'Sucesso ao atualizar caixa.',
            });
        } catch (e) {
            notification.error({
                message: 'Erro',
                description: e,
            });
        }

        setPageLoading(false);
    }

    useEffect(() => {
        if (!data || !data.caixas[0]) return;

        data.caixas[0].saldoAtual = formatCurrencyTwo(data.caixas[0].saldoAtual);
        form.setFieldsValue(data.caixas[0]);
    }, [data]);

    const DetalheCaixaForm = () => {
        return (
            <>
                <Form form={form} name="caixaForm" onFinish={update}>
                    <Row justify="space-between" gutter={4}>
                        <Col span={24}>
                            <Form.Item label="Nome" name={'nome'} rules={fieldsRules}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={6}>
                            <Form.Item label="Tipo" name={'tipo'} rules={fieldsRules}>
                                <Select options={tiposCaixa} />
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item label="Descrição" name={'descricao'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={6}>
                            <Form.Item label="Titular" name={'titular'}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="CPF" name={'cpf'}>
                                <MaskedInput mask={'000.000.000-00'}></MaskedInput>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="CNPJ" name={'cnpj'}>
                                <MaskedInput mask={'00.000.000/0000-00'}></MaskedInput>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="RG" name={'rg'}>
                                <MaskedInput mask={'00.000.000-[*]'}></MaskedInput>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={7}>
                            <Form.Item label="Banco" name={'banco'}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Código Bancário" name={'codigoBancario'}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Conta Corrente" name={'contaCorrente'}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Agência" name={'agencia'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="space-between" gutter={4}>
                        <Col span={24}>
                            <Form.Item label="Saldo Atual" name={'saldoAtual'}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Divider orientation="left">Lançamentos</Divider>
                <LancamentosRelacionados id={props.id} context="caixa"></LancamentosRelacionados>
            </>
        );
    };

    const items = [
        {
            key: '1',
            label: 'Informações Gerais',
            children: <DetalheCaixaForm />,
        },
        {
            key: '2',
            label: 'Transações',
            children: <TransacoesCaixa caixaId={props.id} />,
        },
    ];

    return (
        <div>
            <Spin tip="Carregando..." spinning={isLoading || pageLoading}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Financeiro</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/financeiro/caixas/`}>Caixas</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/financeiro/caixa/${props.id}`}>{data?.caixas[0]?.nome}</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Divider />
                <ProPageHeader
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    title={`${data?.caixas[0]?.nome}`}
                    className="site-page-header"
                    onBack={() => navigate('/financeiro/caixas/')}
                    extra={[
                        <Button type="primary" form="caixaForm" key="1" htmlType="submit" disabled={activeTab !== '1'}>
                            Salvar
                        </Button>,
                    ]}
                ></ProPageHeader>
                <div>
                    <Tabs defaultActiveKey="1" onChange={setActiveTab} items={items} />
                </div>
            </Spin>
        </div>
    );
}
