import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import printJS from 'print-js';

export const listaVeiculos = [
    { label: 'LS 48.5', value: 'LS 48.5' },
    { label: 'Vanderleia 50', value: 'Vanderleia 50' },
    { label: 'Vanderleia 53', value: 'Vanderleia 53' },
    { label: 'Bitrem 57', value: 'Bitrem 57' },
    { label: 'Rodotrem 74', value: 'Rodotrem 74' },
    { label: 'Trator', value: 'Trator' },
    { label: 'Veículo Utilitário', value: 'Veículo Utilitário' },
    { label: 'Caminhão Toco', value: 'Caminhão Toco' },
    { label: 'Caminhão Trucado', value: 'Caminhão Trucado' },
    { label: 'LS Quarto Eixo 54.5', value: 'LS Quarto Eixo 54.5' },
    { label: 'LS Quarto Eixo 58.5', value: 'LS Quarto Eixo 58.5' },
    { label: 'Carreta Simples 41.5', value: 'Carreta Simples 41.5' },
];

export const tipoFretes = [
    {
        label: 'FOB',
        value: 'FOB',
    },
    {
        label: 'CIF',
        value: 'CIF',
    },
];

export const transacaoTipo = [
    {
        label: 'Crédito',
        value: 'Crédito',
    },
    {
        label: 'Débito',
        value: 'Débito',
    },
];

export const modalidadeOptions = [
    {
        label: 'Compra e venda mercadoria depositada na unidade armazenadora',
        value: 'Compra e venda mercadoria depositada na unidade armazenadora',
    },
    {
        label: 'Compra e venda com entrega futura',
        value: 'Compra e venda com entrega futura',
    },
];

export const tiposCliente = [
    {
        label: 'Pessoa Jurídica',
        value: 'Pessoa Jurídica',
    },
    {
        label: 'Pessoa Física',
        value: 'Pessoa Física',
    },
];

export const formasPagamento = [
    {
        label: 'Conta',
        value: 'Conta',
    },
    {
        label: 'Cheque',
        value: 'Cheque',
    },
    {
        label: 'Dinheiro',
        value: 'Dinheiro',
    },
    {
        label: 'Outros',
        value: 'Outros',
    },
];

export const relacionamentoCliente = [
    {
        label: 'Cliente',
        value: 'Cliente',
    },
    {
        label: 'Fornecedor',
        value: 'Fornecedor',
    },
    {
        label: 'Ambos',
        value: 'Ambos',
    },
];

export const atividadesCliente = [
    {
        label: 'Indústria PET',
        value: 'Indústria PET',
    },
    {
        label: 'Avicultura de Postura',
        value: 'Avicultura de Postura',
    },
    {
        label: 'Avicultura de Corte',
        value: 'Avicultura de Corte',
    },
    {
        label: 'Revendedor',
        value: 'Revendedor',
    },
    {
        label: 'Suinocultura',
        value: 'Suinocultura',
    },
    {
        label: 'Bovinocultura de Corte',
        value: 'Bovinocultura de Corte',
    },
    {
        label: 'Bovinocultura de Leite',
        value: 'Bovinocultura de Leite',
    },
    {
        label: 'Pequena Empresa',
        value: 'Pequena Empresa',
    },
];

export const simNao = [
    { value: 'Sim', label: 'Sim' },
    { value: 'Não', label: 'Não' },
];

export const operacoesFiscaisTipos = [
    { value: 'Entrada', label: 'Entrada' },
    { value: 'Saída', label: 'Saída' },
    { value: 'Prestação de Serviço', label: 'Prestação de Serviço' },
];

export const contratosTipos = [
    {
        label: 'Vigentes',
        value: 'vigentes',
    },
    {
        label: 'Finalizados',
        value: 'finalizados',
    },
];

export const unidadesTipos = [
    {
        label: 'KG',
        value: 'KG',
    },
    {
        label: 'Litros',
        value: 'Litros',
    },
    {
        label: 'Metros',
        value: 'Metros',
    },
];

export const moegas = [
    {
        label: 'Moega 01',
        value: 'Moega 01',
    },
    {
        label: 'Moega 02',
        value: 'Moega 02',
    },
    {
        label: 'Moega 03',
        value: 'Moega 03',
    },
    {
        label: 'Moega 04',
        value: 'Moega 04',
    },
];

export const romaneioTipos = [
    {
        label: 'Entrada',
        value: 'Entrada',
    },
    {
        label: 'Saída',
        value: 'Saída',
    },
];

export const tiposCaixa = [
    {
        label: 'Conta Bancária',
        value: 'Conta Bancária',
    },
    {
        label: 'Conta de Investimento',
        value: 'Conta de Investimento',
    },
    {
        label: 'Dinheiro',
        value: 'Dinheiro',
    },
    {
        label: 'Cheque',
        value: 'Cheque',
    },
    {
        label: 'Outros',
        value: 'Outros',
    },
];

export const movimentacoesEstoqueTipos = [
    {
        label: 'Entrada',
        value: 'Entrada',
    },
    {
        label: 'Saída',
        value: 'Saída',
    },
    {
        label: 'Prestação de Serviço',
        value: 'Prestacao de Servico',
    },
    {
        label: 'Quebra Técnica',
        value: 'Quebra Técnica',
    },
    {
        label: 'Operação',
        value: 'Operacao',
    },
    {
        label: 'Cancelamento',
        value: 'Cancelamento',
    },
];

export const lancamentoStatus = [
    { label: 'Não Pago', value: 'Não Pago' },
    { label: 'Pago', value: 'Pago' },
    { label: 'Cancelado', value: 'Cancelado' },
];

export const tiposChamada = [
    { label: 'Balança de entrada', value: 'Balança de entrada' },
    { label: 'Documentos', value: 'Documentos' },
];

export const lancamentoTipos = [
    { label: 'Débito', value: 'Débito' },
    { label: 'Crédito', value: 'Crédito' },
];

export const lancamentoCategorias = [
    { label: 'Receita com Milho', value: 'Receita com Milho', type: lancamentoTipos[1].value },
    { label: 'Receita com Soja', value: 'Receita com Soja', type: lancamentoTipos[1].value },
    { label: 'Receita com Sorgo', value: 'Receita com Sorgo', type: lancamentoTipos[1].value },
    { label: 'Receita com Resíduos', value: 'Receita com Resíduos', type: lancamentoTipos[1].value },
    { label: 'Outras Receitas', value: 'Outras Receitas', type: lancamentoTipos[1].value },
    { label: 'Outros Débitos', value: 'Outros Débitos', type: lancamentoTipos[0].value },
    { label: 'Recuperação de Clientes', value: 'Recuperação de Clientes', type: lancamentoTipos[1].value },
    { label: 'Rendimento de Aplicação Financeira', value: 'Rendimento de Aplicação Financeira', type: lancamentoTipos[1].value },
    { label: 'Entrada de Empréstimos', value: 'Entrada de Empréstimos', type: lancamentoTipos[1].value },
    { label: 'Antecipação de Compra de Grãos', value: 'Antecipação de Compra de Grãos', type: lancamentoTipos[0].value },
    { label: 'Compra de Grãos - Milho', value: 'Compra de Grãos - Milho', type: lancamentoTipos[0].value },
    { label: 'Compra de Grãos - Soja', value: 'Compra de Grãos - Soja', type: lancamentoTipos[0].value },
    { label: 'Compra de Grãos - Sorgo', value: 'Compra de Grãos - Sorgo', type: lancamentoTipos[0].value },
    { label: 'Compra de Grãos - Resíduos', value: 'Compra de Grãos - Resíduos', type: lancamentoTipos[0].value },
    { label: 'Energia Elétrica', value: 'Energia Elétrica', type: lancamentoTipos[0].value },
    { label: 'Lenha Secador', value: 'Lenha Secador', type: lancamentoTipos[0].value },
    { label: 'Manutenção de Equipamentos', value: 'Manutenção de Equipamentos', type: lancamentoTipos[0].value },
    { label: 'Veículos Industriais', value: 'Veículos Industriais', type: lancamentoTipos[0].value },
    { label: 'Logística de Entrega', value: 'Logística de Entrega', type: lancamentoTipos[0].value },
    { label: 'Impostos - Contribuição Assistencial', value: 'Impostos - Contribuição Assistencial', type: lancamentoTipos[0].value },
    { label: 'Impostos - FGTS', value: 'Impostos - FGTS', type: lancamentoTipos[0].value },
    { label: 'Impostos - IRRF0561/INSS/FUNRURAL', value: 'Impostos - IRRF0561/INSS/FUNRURAL', type: lancamentoTipos[0].value },
    { label: 'Impostos - COFINS', value: 'Impostos - COFINS', type: lancamentoTipos[0].value },
    { label: 'Impostos - CSLL', value: 'Impostos - CSLL', type: lancamentoTipos[0].value },
    { label: 'Impostos - CSRF5952', value: 'Impostos - CSRF5952', type: lancamentoTipos[0].value },
    { label: 'Impostos - IRPJ', value: 'Impostos - IRPJ', type: lancamentoTipos[0].value },
    { label: 'Impostos - IRRF1708', value: 'Impostos - IRRF1708', type: lancamentoTipos[0].value },
    { label: 'Impostos - ISS', value: 'Impostos - ISS', type: lancamentoTipos[0].value },
    { label: 'Impostos - PIS', value: 'Impostos - PIS', type: lancamentoTipos[0].value },
    { label: 'Pessoal - Salários', value: 'Pessoal - Salários', type: lancamentoTipos[0].value },
    { label: 'Pessoal - Encargos', value: 'Pessoal - Encargos', type: lancamentoTipos[0].value },
    { label: 'Pessoal - Férias e 13o', value: 'Pessoal - Férias e 13o', type: lancamentoTipos[0].value },
    { label: 'Pessoal - Benefícios', value: 'Pessoal - Benefícios', type: lancamentoTipos[0].value },
    { label: 'Pessoal - Rescisão', value: 'Pessoal - Rescisão', type: lancamentoTipos[0].value },
    { label: 'Pessoal - Exames Médicos/Epis', value: 'Pessoal - Exames Médicos/Epis', type: lancamentoTipos[0].value },
    { label: 'Pró-Labore', value: 'Pró-Labore', type: lancamentoTipos[0].value },
    { label: 'Despesas Administrativas/Produção', value: 'Despesas Administrativas/Produção', type: lancamentoTipos[0].value },
    { label: 'TI/Telecom', value: 'TI/Telecom', type: lancamentoTipos[0].value },
    { label: 'Serviços com Terceiros', value: 'Serviços com Terceiros', type: lancamentoTipos[0].value },
    { label: 'Despesas Bancárias', value: 'Despesas Bancárias', type: lancamentoTipos[0].value },
    { label: 'Marketing/Patrocínio', value: 'Marketing/Patrocínio', type: lancamentoTipos[0].value },
    { label: 'Comissão de Vendas', value: 'Comissão de Vendas', type: lancamentoTipos[0].value },
    { label: 'Viagens', value: 'Viagens', type: lancamentoTipos[0].value },
    { label: 'Impostos Sob Receita', value: 'Impostos Sob Receita', type: lancamentoTipos[0].value },
    { label: 'Seguros', value: 'Seguros', type: lancamentoTipos[0].value },
    { label: 'Investimentos', value: 'Investimentos', type: lancamentoTipos[0].value },
    { label: 'Empréstimo para Terceiros', value: 'Empréstimo para Terceiros', type: lancamentoTipos[0].value },
    { label: 'Pagamento de Empréstimo', value: 'Pagamento de Empréstimo', type: lancamentoTipos[0].value },
    { label: 'Juros Atraso de Pagamento', value: 'Juros Atraso de Pagamento', type: lancamentoTipos[0].value },
    { label: 'Cheques Devolvidos', value: 'Cheques Devolvidos', type: lancamentoTipos[1].value },
    { label: 'Transferência Entre Contas', value: 'Transferência Entre Contas', type: lancamentoTipos[0].value },
    { label: 'Financiamentos', value: 'Financiamentos', type: lancamentoTipos[0].value },
    { label: 'Aplicação Financeira', value: 'Aplicação Financeira', type: lancamentoTipos[0].value },
];

export const transgeniaOptions = [
    {
        label: 'Outro Produto',
        value: 'Outro Produto',
    },
    {
        label: 'Baixada',
        value: 'Baixada',
    },
    {
        label: 'Declarada',
        value: 'Declarada',
    },
    {
        label: 'Testada Positiva',
        value: 'Testada Positiva',
    },
    {
        label: 'Testada Negativa',
        value: 'Testada Negativa',
    },
    {
        label: 'Rec. Arm. Participante',
        value: 'Rec. Arm. Participante',
    },
    {
        label: 'Pendente',
        value: 'Pendente',
    },
];

export function formatNumber(number) {
    if (!number) number = 0;

    return number.toLocaleString('pt-BR', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    });
}

export function formatRoundedNumber(number) {
    if (!number) number = 0;

    return number.toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}

export function formatRoundedPeso(number) {
    if (!number) number = 0;

    return `${formatRoundedNumber(number)} KG`;
}

export function formatPercentage(number) {
    if (!number) number = 0;

    return number.toLocaleString('pt-BR', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });
}

export function formatPercentageConcat(number) {
    return `${formatPercentage(number)} %`;
}

export function formatPeso(number) {
    return `${formatNumber(number)} KG`;
}

export function formatSacos(number) {
    //Cada saco tem 60 KG
    number = !number ? 0 : number;

    number = number <= 0 ? 0 : number / 60;
    return number.toLocaleString('pt-BR', { maximumFractionDigits: 0, minimumFractionDigits: 0 });
}

export function formatDate(date, onlyDate = false) {
    if (date) date = date + 'Z';
    else date = new Date();

    return dayjs(date).format(onlyDate ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm');
}

export function formatDayDate(date, onlyDate = false) {
    if (!date) date = new Date();

    return dayjs(date).format(onlyDate ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm');
}

export function formatDayDateFromUTC(date, onlyDate = false) {
    return formatDayDate(dayjs(date).utc('z').local(), onlyDate);
}

export function formatDateWithTime(date, onlyDate = false) {
    if (!date) date = new Date();

    return dayjs(date).format(onlyDate ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm');
}

export function isNonMinDate(date) {
    if (!date) return false;

    return dayjs(date) > dayjs(new Date('1990-01-01'));
}

export function toUserTime(date) {
    if (date) date = date + 'Z';
    else date = new Date();

    return dayjs(date);
}

export function formatCurrency(number) {
    if (!number) number = 0;

    return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4, maximumFractionDigits: 4 });
}

export function formatCurrencyTwo(number) {
    if (!number) number = 0;

    return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getCustomerName(customer) {
    if (!customer) return '';

    return customer.tipoPessoa === 'Pessoa Física' ? customer.nome : customer.razaoSocial;
}

export function isValidCPF(cpf) {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
    cpf = cpf.split('').map((el) => +el);
    const rest = (count) => ((cpf.slice(0, count - 12).reduce((soma, el, index) => soma + el * (count - index), 0) * 10) % 11) % 10;
    return rest(10) === cpf[9] && rest(11) === cpf[10];
}

export function maskCPF(cpf) {
    if (!cpf) return '';

    return cpf
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export function getCustomerIdentification(customer) {
    if (!customer) return '';

    return customer.tipoPessoa === 'Pessoa Física' ? customer.cpf : customer.cnpj;
}

export function removeAcentos(text) {
    if (!text) return '';

    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function searchInArray(text, array) {
    if (!text) return [];

    return array.filter((record) => {
        const recordValues = Object.values(record);
        let searchValue = '';

        for (let index = 0; index < recordValues.length; index++) {
            const element = recordValues[index];

            if (element == null) continue;

            if (typeof element === 'object' && element != null) {
                searchValue = Object.values(element).toString().toLocaleLowerCase();
            } else {
                searchValue = element.toString().toLocaleLowerCase();
            }

            if (removeAcentos(searchValue).includes(removeAcentos(text.toLocaleLowerCase()))) return true;
        }

        return false;
    });
}

export function fromDateOnlyToLocal(date) {
    if (!date) return '';

    return dayjs(date).format('DD/MM/YYYY');
}

export function isClienteFisico(customer) {
    if (!customer) return '';

    return customer.tipoPessoa === 'Pessoa Física';
}

export function filterSelect(inputValue, option) {
    if (!option || !option.label) return true;

    return removeAcentos(option.label.toString().toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()));
}

export function reaisFormatter(val) {
    if (!val) return 0;
    return parseFloat(val).toLocaleString('pt-BR', { currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 4 });
}

export function reaisParser(val) {
    if (!val) return 0;
    return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, '').replace(/(\,{1})/g, '.')).toFixed(4);
}

export function printBase64Pdf(pdf, callback) {
    printJS({ printable: pdf, type: 'pdf', base64: true, onPrintDialogClose: callback });
}
