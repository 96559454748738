import React from 'react';
import './Produto.scoped.css';
import { useParams } from "react-router-dom";
import DetalheProduto from '../../../components/Produto/DetalheProduto/DetalheProduto';

export default function ProdutoView() {
	const { id } = useParams();

	return (
		<DetalheProduto id={id}></DetalheProduto>
	);
}