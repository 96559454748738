import React from 'react';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { Card, Col, Row } from 'antd';
import { getCaixas } from '../../../apis/queries/graphQueries';
import { Link } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';
import { formatCurrencyTwo } from '../../Utils/Utils';

export default function ListagemCaixas() {
    const { data, isLoading } = useGraphQL(getCaixas);

    function getBankImage(code) {
        switch (code) {
            case 'Dinheiro':
                return '/dinheiro.jpg';
            case 'Outros':
                return '/bitcoin.jpg';
            case 'Cheque':
                return '/cheque.jpg';
            case 'Conta Bancária':
                return '/banco.jpg';
            case 'Conta de Investimento':
                return '/investimentos.png';
            default:
                break;
        }
    }

    return (
        <Row gutter={[24, 24]}>
            {data?.caixas?.map((x) => (
                <Col span={6} key={x.id}>
                    <Tilt>
                        <Link to={`/financeiro/caixa/${x.id}`}>
                            <Card loading={isLoading} hoverable cover={<img alt="Banco imagem" height={'200px'} width={'200px'} src={getBankImage(x.tipo)} />}>
                                {formatCurrencyTwo(x.saldoAtual)} - {x.nome}
                            </Card>
                        </Link>
                    </Tilt>
                </Col>
            ))}
        </Row>
    );
}
