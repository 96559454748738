import React, { useState } from 'react';
import { useContatos, deletarContato, criarPortalUser, deletarPortalUser } from "../../../apis/backend";
import { Button, notification, Space, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, StopOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';
import ContatoClienteEdit from '../ContatoClienteEdit/ContatoClienteEdit';

export default function ContatosClienteActions(props) {
	const contato = props.contato;
	const { mutate } = useContatos(contato.clienteId);
	const updateContatoModal = useModal(ContatoClienteEdit);
	const [isLoading, setIsLoading] = useState(false);

	function onClickEdit() {
		updateContatoModal.show({ contato: contato }).then(async () => {
			await mutate();
		});
	}

	async function onClickDelete() {
		if (contato.isPortalEnabled) {
			notification.error({
				message: 'Erro',
				description: 'Desative o portal antes de deletar o contato.'
			});
			return;
		}
		setIsLoading(true);

		try {
			await deletarContato(contato.id);
			notification.success({
				message: 'Sucesso',
				description: 'O contato foi desativado.'
			});
		} catch (e) {
			console.error(e);
			notification.error({
				message: 'Erro',
				description: 'Falha ao deletar contato, tente novamente.'
			});
		}
		
		await mutate();
		setIsLoading(false);
	}

	async function activatePortal() {
		if (contato.id == null || contato.isAuth0Activated)
			return;

		try {
			setIsLoading(true);
			await criarPortalUser(contato.id);
			await mutate();
			notification.success({
				message: 'Sucesso',
				description: 'O usuário foi ativado.'
			});
		} catch (e) {
			console.error(e);
			notification.error({
				message: 'Erro',
				description: 'Falha ao criar acesso ao portal. Tente novamente',
			});
		}
		setIsLoading(false);
	}

	async function disablePortal() {
		if (contato.id == null || !contato.isPortalEnabled)
			return;

		try {
			setIsLoading(true);
			await deletarPortalUser(contato.id);
			await mutate();
			notification.success({
				message: 'Sucesso',
				description: 'O usuário foi desativado.'
			});
		} catch (e) {
			console.error(e);
			notification.error({
				message: 'Erro',
				description: 'Falha ao desativar acesso ao portal. Tente novamente',
			});
		}
		setIsLoading(false);
	}

	return <Space size="small">
		<Tooltip title="Deletar">
			<Button shape="circle" icon={<DeleteOutlined />} onClick={onClickDelete} loading={isLoading} />
		</Tooltip>
		<Tooltip title="Editar">
			<Button shape="circle" icon={<EditOutlined />} onClick={onClickEdit} loading={isLoading} />
		</Tooltip>
		{
			!contato.isPortalEnabled ?
				<Tooltip title="Ativar Portal">
					<Button shape="circle" icon={<CheckOutlined />} onClick={activatePortal} loading={isLoading} />
				</Tooltip>
				:
				<Tooltip title="Desativar Portal">
					<Button shape="circle" icon={<StopOutlined />} onClick={disablePortal} loading={isLoading} />
				</Tooltip>
		}
	</Space>		
}
