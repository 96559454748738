import "./DetalheOperacaoFiscal.scoped.css";
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Divider, Spin, Button, Tabs } from 'antd';
import { ProPageHeader } from '@ant-design/pro-layout';
import { Link, useNavigate } from "react-router-dom";
import { useOperacaoFiscal } from '../../../apis/backend';

import InformacoesGeraisOpFiscal from '../InformacoesGeraisOpFiscal/InformacoesGeraisOpFiscal';

export default function DetalheOperacaoFiscal(props) {
    const navigate = useNavigate();
    const { operacao, isLoading, mutate } = useOperacaoFiscal(props.id);
    const [pageLoading, setPageLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [isOpFiscalLoaded, setIsOpFiscalLoaded] = useState(false);

    useEffect(() => {
		setPageLoading(isLoading);
        setIsOpFiscalLoaded(!!operacao);

    }, [operacao, isLoading]);

    const items = [
        {
            key: '1',
            label: 'Informações Gerais',
            children: isOpFiscalLoaded ? <InformacoesGeraisOpFiscal mutate={mutate} opFiscal={operacao} isLoading={setPageLoading} /> : <></>
        }
    ];

    return (
        <div>
            <Spin tip="Carregando..." spinning={pageLoading}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Cadastros</Breadcrumb.Item>
                        <Breadcrumb.Item>
                        <Link to={`/cadastros/operacao-fiscal/`}>Operação Fiscal</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/cadastros/operacao-fiscal/${props.id}`}>{operacao?.nome}</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Divider />
				<ProPageHeader
                    style={{paddingLeft: 0, paddingRight: 0}}
                    title={operacao?.nome}
                    className="site-page-header"
                    onBack={() => navigate("/cadastros/operacao-fiscal")}
                    extra={[
                        <Button type="primary" form="opFiscalForm" key="submit" htmlType="submit" disabled={activeTab !== "1"}>Salvar</Button>,
                    ]}
                >
				</ProPageHeader>
                <div>
                    <Tabs defaultActiveKey="1" onChange={setActiveTab} items={items} />
                </div>
            </Spin>
        </div>
    )
}