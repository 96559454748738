import React from 'react';
import { Table } from 'antd';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { Link } from 'react-router-dom';
import { getLancamentosRelacionadosContrato, getLancamentosRelacionadosCliente, getLancamentosRelacionadosCaixa } from '../../../apis/queries/graphQueries';
import { formatCurrencyTwo } from '../../Utils/Utils';

export default function LancamentosRelacionados(props) {
    const query = {
        contrato: getLancamentosRelacionadosContrato,
        cliente: getLancamentosRelacionadosCliente,
        caixa: getLancamentosRelacionadosCaixa,
    };
    const { data, isLoading } = useGraphQL(query[props.context], { id: parseInt(props.id) });

    const columns = [
        {
            title: 'Descrição',
            key: 'descricao',
            ellipsis: true,
            render: (_, record) => <Link to={`/financeiro/lancamento/${record.id}`}>{record.descricao}</Link>,
        },
        {
            title: 'Categoria',
            key: 'categoria',
            dataIndex: 'categoria',
            ellipsis: true,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            ellipsis: true,
        },
        {
            title: 'Valor Total',
            key: 'valor',
            dataIndex: 'valor',
            render: (value) => formatCurrencyTwo(value),
            ellipsis: true,
        },
    ];

    if (props.context === 'caixa') {
        columns.push(
            {
                title: 'Valor a Pagar',
                key: 'missingRelatedValue',
                dataIndex: 'missingRelatedValue',
                render: (value) => formatCurrencyTwo(value),
                ellipsis: true,
            },
            {
                title: 'Valor Pago',
                key: 'relatedValue',
                dataIndex: 'relatedValue',
                render: (value) => formatCurrencyTwo(value),
                ellipsis: true,
            }
        );
    }

    return (
        <div>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data?.lancamentos}
                pagination={{
                    simple: false,
                    hideOnSinglePage: true,
                    defaultPageSize: 5,
                    showSizeChanger: false,
                }}
                loading={isLoading}
                bordered
            />
        </div>
    );
}
