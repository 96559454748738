import React from 'react';
import { Card, Typography } from 'antd';
import { useStatisticsBalanca } from '../../../../apis/backend';
import { PieChart, Pie, Legend, Cell } from 'recharts';

const { Text } = Typography;

export default function EstatisticasRomaneio(props) { 
	const { count: countMilho } = useStatisticsBalanca(1, props.type);
	const { count: countSoja } = useStatisticsBalanca(2, props.type);
	const { count: countSorgo } = useStatisticsBalanca(3, props.type);
	const RADIAN = Math.PI / 180;
	const data = [];

	if (countMilho)
		data.push({
			name: "Milho",
			value: countMilho,
			color: '#FFEC00',
		});
	
	if (countSoja)
		data.push({
			name: "Soja",
			value: countSoja,
			color: '#41A91D'
		});
	
	if (countSorgo)
		data.push({
			name: "Sorgo",
			value: countSorgo,
			color: '#A93B1D'
		});
	
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<g>
				<text fontWeight={'bold'} style={{textShadow: '0px 1px 5px rgb(0, 0, 175)', WebkitTextStroke: '1px #f0ecec'}} fontSize={23} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
					{data[index].value}
				</text>
			</g>
		);
	};

	const renderLegend = (props) => {
		const { payload } = props;
		
		return <div>
			{
			  payload.map((entry, index) => (
				<div key={`item-${index}`} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<div style={{background: entry.color, width: '50px', height: '25px', marginRight: '20px'}}/>
					<Text style={{color: entry.color, fontSize: '26px'}}>{entry.value}</Text>
				</div>
			  ))
			}
		</div>
	  }

	return (
		<Card width="100%" height="100%" title={props.type} bodyStyle={{pointerEvents: 'none'}}>
			<PieChart width={400} height={160}>
				<Legend content={renderLegend} layout="vertical" verticalAlign="middle" align="right"/>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={false}
					label={renderCustomizedLabel}
					innerRadius={30}
					outerRadius={80}
					fill="#8884d8"
					dataKey="value"
				>
					{
						data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
					}
				</Pie>
			</PieChart>
		</Card>
	);
}