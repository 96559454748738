import useSWR from 'swr';
import { fetcher } from '../backendFetcher';

export function useCodigosNcm() {
	const { data, error } = useSWR(`fiscal/NCM/codigo`, fetcher);

	return {
		ncms: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useCodigosCest() {
	const { data, error } = useSWR(`fiscal/CEST/codigo`, fetcher);

	return {
		cests: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useCodigosCfop() {
	const { data, error, mutate } = useSWR(`fiscal/CFOP/codigo`, fetcher);

	return {
		cfops: data,
		isLoading: !error && !data,
		isError: error,
		mutate
	}
}