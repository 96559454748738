import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { criarContato } from "../../../apis/backend";
import { Row, Col, Input, Button, Modal, Form } from 'antd';

export default NiceModal.create(({cliente}) => {
	const modal = useModal();
	const [formCreation] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);
	const fieldsRules = [
		{
			required: true
		}
	];

	async function createContato(e) {
		setIsLoading(true);

		await criarContato(e, cliente.id);
		formCreation.resetFields();
		setIsLoading(false);
		modal.resolve();
		modal.hide();
	}

	return <Modal
		title="Criar Contato"
		centered
		open={modal.visible}
		width="90%"
		destroyOnClose={true}
		closable={false}
		keyboard={false}
		maskClosable={false}
		afterClose={() => modal.remove()}
		footer={[
			<Button key={1} onClick={() => modal.hide()} loading={isLoading}>
				Cancelar
			</Button>,
			<Button key={2} type="primary" loading={isLoading} form="ContatoCreationForm" key="submit" htmlType="submit">
				Criar
			</Button>
		]}
	>
		<Form form={formCreation} name="ContatoCreationForm" onFinish={createContato}>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Nome"
						name={"nome"}
						rules={fieldsRules}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={8}>
					<Form.Item
						label="Celular Primário"
						name={"celularUm"}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Celular Secundário"
						name={"celularDois"}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Telefone Fixo"
						name={"telefoneFixo"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Email"
						name={"email"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	</Modal>
});