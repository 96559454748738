import React, { useState } from 'react';
import { Row, Col, Input, Button, InputNumber, Modal, Select, Form, Result } from 'antd';
import { registrarSaidaBackend, getRomaneioPdf, useProdutosRomaneio, useClientesRomaneio } from '../../../../apis/backend';
import { transgeniaOptions, printBase64Pdf } from '../../../Utils/Utils'
import { LoadingOutlined, StopOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import BalancasTooltip from '../../../Utils/BalancasTooltip';

export default NiceModal.create(({ record }) => {
	const modal = useModal();
	const [form] = Form.useForm();
	const { produtos } = useProdutosRomaneio();
	const { clientes } = useClientesRomaneio();
	const fieldsRules = [
		{
			required: true
		}
	];
	const [status, setStatus] = useState({
		inProgress: false,
		title: 'Registrando saída',
		subtitle: null,
		icon: <LoadingOutlined />,
		extra: null
	});

	async function saveSaida(formValues) {
		setStatus({ inProgress: true, title: 'Registrando saída', subtitle: null, icon: <LoadingOutlined />, extra: null });

		try {
			formValues.id = record.id;
			await registrarSaidaBackend(formValues);
		} catch (e) {
			return setStatus({
				inProgress: true,
				title: 'Falha ao registrar saída!',
				subtitle: `Ocorreu uma falha ao registrar a saída do romaneio, tente novamente. (${e})`,
				icon: <StopOutlined style={{ color: '#C41E3A' }} />,
				extra: [
					<Button onClick={() => close(true)}>Cancelar</Button>,
					<Button type="primary" onClick={() => saveSaida(formValues)}>Tentar novamente</Button>
				]
			});
		}

		try {
			setStatus({ inProgress: true, title: 'Gerando impressão', subtitle: null, icon: <LoadingOutlined />, extra: null });
			const pdf = await getRomaneioPdf(record.id);
			printBase64Pdf(pdf, onPrintClose);
		} catch (e) {
			setStatus({
				inProgress: true,
				title: 'Falha ao imprimir!',
				subtitle: `A saída foi registrada, mas houve um erro ao gerar a impressão. Imprima utilizando a guia de histórico. (${e})`,
				icon: <WarningOutlined style={{ color: '#D8BA23' }} />,
				extra: <Button type="primary" onClick={() => close(true)}>Fechar</Button>,
			});
		}
	}

	function onPrintClose() {
		setStatus({
			inProgress: true,
			title: 'Saída registrada',
			subtitle: null,
			icon: <CheckCircleOutlined style={{ color: '#5CB914' }} />,
			extra: <Button type="primary" onClick={() => close(true)}>Fechar</Button>
		});
	}

	function close(shouldMutate) {
		if (shouldMutate)
			modal.resolve();
		modal.hide();
	}

	function calculatePs(e) {
		const keys = Object.keys(e);

		if (!keys.includes("umidade") && !keys.includes("produtoId") && !keys.includes("clienteId"))
			return;

		const valueForm = { ...record, ...form.getFieldsValue() };

		if (!valueForm.produtoId || !valueForm.clienteId || !valueForm.umidade)
			return;
		
		const fullProduct = produtos.find(x => x.value === valueForm.produtoId);
		const fullClient = clientes.find(x => x.value === valueForm.clienteId);
		const umidade = valueForm.umidade;
		let prestacaoValue = 0;
		
		fullProduct.tabelaPs.forEach(x => {
			if (umidade >= x.umidadeInicial && umidade <= x.umidadeFinal) {
				prestacaoValue = x.desconto;
				return;
			}
		});

		fullClient.ps.forEach(x => {
			if (umidade >= x.umidadeInicial && umidade <= x.umidadeFinal && x.produtoId === valueForm.produtoId) {
				prestacaoValue = x.desconto;
				return;
			}
		});

		form.setFields([
			{
				name: 'prestacaoServico',
				value: prestacaoValue
			}
		]);
	}

	function onClickEscritorioSaida(e){
		form.setFields([{
			name: "pesoSaida",
			value: e.peso
		}]);
	}

	function onClickPatioSaida(e){
		form.setFields([{
			name: "pesoSaida",
			value: e.peso
		}]);
	}

	const RomaneioSaidaForm = () => {
		return <Form form={form} name="form" onFinish={saveSaida} onValuesChange={calculatePs} initialValues={{ ...record, pesoSaida: 1}}>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<BalancasTooltip onClickEscritorio={onClickEscritorioSaida} onClickPatio={onClickPatioSaida}>
						<Form.Item
							label="Peso Saída"
							name={"pesoSaida"}
							rules={fieldsRules}>
							<InputNumber min={1} addonAfter={'KG'} style={{ width: '100%' }}></InputNumber>
						</Form.Item>
					</BalancasTooltip>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Umidade"
						name={"umidade"}
						rules={fieldsRules}>
							<InputNumber min={0} max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Impureza"
						name={"impureza"}
						rules={fieldsRules}>
							<InputNumber min={0} max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Avariados"
						name={"avariados"}
						rules={fieldsRules}>
							<InputNumber min={0}  max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="PS"
						name={"prestacaoServico"}
						rules={fieldsRules}>
							<InputNumber min={0} max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Transgenia"
						name={"transgenia"}
						rules={fieldsRules}>
						<Select disabled={record.produtoId !== 2} options={transgeniaOptions} />
					</Form.Item>
				</Col>
			</Row>
			<Col span={24}>
				<Form.Item
					label="Observações"
					name={"observacoes"}>
					<Input />
				</Form.Item>
			</Col>
		</Form>
	}

	const RomaneioCreationProgress = () => {
		return <Result
			icon={status.icon}
			subTitle={status.subtitle}
			title={status.title}
			extra={status.extra}
		/>
	}

	return (<Modal
		title={`Saída - ${record.placaVeiculo}`}
		centered
		open={modal.visible}
		width="90%"
		destroyOnClose={true}
		closable={false}
		keyboard={false}
		maskClosable={false}
		afterClose={() => modal.remove()}
		footer={!status.inProgress ? [
			<Button key={1} onClick={close}>
				Cancelar
			</Button>,
			<Button key={2} type="primary" form="form" htmlType="submit">
				Salvar e Imprimir
			</Button>
		] : <></>}
	>
		{!status.inProgress ? <RomaneioSaidaForm /> : <RomaneioCreationProgress />}
	</Modal>);
});