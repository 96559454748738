import React from 'react';
import { Table, Space, Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';
import { formatPeso } from '../../Utils/Utils';
import { getContratosCliente } from '../../../apis/queries/graphQueries';
import { useGraphQL } from '../../../apis/backendGraphQL';
import { EyeOutlined } from '@ant-design/icons';
import ContratoDetalhes from '../../Utils/ContratoDetalhes';
import NiceModal from '@ebay/nice-modal-react';

export default function ContratosCliente(props) {
    const { data, isLoading } = useGraphQL(getContratosCliente, { clienteId: props.cliente?.id });
    const columns = [
        {
            title: 'Número',
            dataIndex: 'numeroDeContrato',
            key: 'numeroDeContrato',
            ellipsis: true,
            render: (_, record) => <Link to={`/cadastros/contrato/${record.id}`}>{record.numeroDeContrato}</Link>,
        },
        {
            title: 'Produto',
            dataIndex: ['produto', 'descricao'],
            key: 'ncmId',
        },
        {
            title: 'Quantidade Total',
            render: (record) => formatPeso(record.quantidadeTotal),
            key: 'quantidadeTotal',
        },
        {
            title: 'Saldo a Embarcar',
            render: (record) => formatPeso(record.saldoAEmbarcar),
            key: 'saldoAEmbarcar',
        },
        {
            title: 'Status',
            render: (record) => `${record.saldoAEmbarcar > 0 ? 'Vigente' : 'Finalizado'}`,
            key: 'status',
        },
        {
            title: 'Ações',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Visualizar">
                        <Button shape="circle" icon={<EyeOutlined />} onClick={() => onClickView(record.id)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    function onClickView(recordId) {
        NiceModal.show(ContratoDetalhes, { contratoId: recordId });
    }

    return (
        <Table
            rowKey="id"
            columns={columns}
            dataSource={data?.contratos}
            loading={isLoading}
            pagination={{
                simple: false,
                hideOnSinglePage: true,
                defaultPageSize: 5,
                showSizeChanger: false,
            }}
        />
    );
}
