import React, { useEffect, useState } from 'react';
import './Layout.scoped.css';
import { Menu, Layout, Drawer, Badge, List, Typography } from 'antd';
import {
    FileSearchOutlined,
    BellOutlined,
    AreaChartOutlined,
    ReconciliationOutlined,
    DollarCircleOutlined,
    RetweetOutlined,
    FolderViewOutlined,
    FileAddOutlined,
    ClockCircleOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    HomeOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Offline, Online } from 'react-detect-offline';
import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { useGraphQL } from '../../apis/backendGraphQL';
import { getNotifications } from '../../apis/queries/graphQueries';
import { formatDayDate } from '../Utils/Utils';

const { Header, Sider, Content } = Layout;
const { Paragraph } = Typography;

function LayoutDomain(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState('home');
    const [userPermissions, setUserPermissions] = useState([]);
    const { user, logout, getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = useState(false);
    const { data: notifications, isLoading: loadingNotifications } = useGraphQL(getNotifications);

    const pollingOptions = {
        enabled: true,
        url: 'https://ipv4.icanhazip.com',
        interval: 8000,
        timeout: 3000,
    };

    useEffect(() => {
        getDetails();

        async function getDetails() {
            const token = await getAccessTokenSilently({
                scope: 'openid email profile',
                audience: 'backoffice.limaetarifa.com.br',
            });
            const decoded = jwt_decode(token);
            setUserPermissions(decoded.permissions);
        }
    }, []);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const NotificationsDrawer = () => (
        <Drawer title="Notificações" onClose={onClose} open={open}>
            <List
                itemLayout="horizontal"
                dataSource={notifications?.notificacoes}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                        <List.Item.Meta
                            title={`${item.titulo} - ${formatDayDate(item.createdDate)}`}
                            description={<Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'mais' }}>{item.descricao}</Paragraph>}
                        />
                    </List.Item>
                )}
            />
        </Drawer>
    );

    const menuItems = [
        {
            label: <Link to="/">Home</Link>,
            key: 'home',
            icon: <HomeOutlined />,
        },
        {
            label: 'Cadastros',
            key: 'cadastros',
            icon: <FileAddOutlined />,
            children: [
                {
                    label: <Link to="/cadastros/cliente">Cliente</Link>,
                    key: 'cadastros-cliente',
                },
                {
                    label: <Link to="/cadastros/produto">Produto</Link>,
                    key: 'cadastros-produto',
                },
                {
                    label: <Link to="/cadastros/operacao-fiscal">Operações Fiscais</Link>,
                    key: 'cadastros-operacao-fiscal',
                },
                {
                    label: <Link to="/cadastros/contrato">Contratos</Link>,
                    key: 'cadastros-contrato',
                },
            ],
        },
        {
            label: 'Estoques',
            key: 'estoques',
            type: 'group',
            icon: <HomeOutlined />,
            children: [
                {
                    label: 'Expediente',
                    key: 'expediente',
                    icon: <ClockCircleOutlined />,
                    children: [
                        {
                            label: <Link to="/expediente/descarga">Descarga</Link>,
                            key: 'expediente-descarga',
                        },
                    ],
                },
                {
                    label: 'Movimento',
                    key: 'movimento',
                    icon: <RetweetOutlined />,
                    children: [
                        {
                            label: <Link to="/movimento/balanca">Balança</Link>,
                            key: 'movimento-balanca',
                        },
                        {
                            label: <Link to="/movimento/simples-pesagem">Simples Pesagem</Link>,
                            key: 'movimento-simples-pesagem',
                        },
                    ],
                },
                {
                    label: 'Gerenciamento',
                    key: 'gerenciamento',
                    icon: <FolderViewOutlined />,
                    children: [
                        {
                            label: <Link to="/gerenciamento/gestao-balanca">Gestão de Balança</Link>,
                            key: 'gerenciamento-gestao-balanca',
                        },
                        {
                            label: <Link to="/gerenciamento/operacoes">Operações</Link>,
                            key: 'gerenciamento-operacoes',
                        },
                    ],
                },
                {
                    key: 'relatorios',
                    icon: <FileSearchOutlined />,
                    label: <Link to="/relatorios">Relatórios</Link>,
                },
            ],
        },
        userPermissions.includes('financeiro:all')
            ? {
                  key: 'financeiro',
                  label: 'Financeiro',
                  type: 'group',
                  children: [
                      {
                          icon: <ReconciliationOutlined />,
                          label: <Link to="/financeiro/lancamentos">Lançamentos</Link>,
                          key: 'financeiro-lançamentos',
                      },
                      {
                          icon: <DollarCircleOutlined />,
                          label: <Link to="/financeiro/caixas">Caixas</Link>,
                          key: 'financeiro-caixas',
                      },
                      {
                          icon: <AreaChartOutlined />,
                          label: <Link to="/financeiro/dashboard">Dashboard</Link>,
                          key: 'financeiro-dashboard',
                      },
                  ],
              }
            : null,
    ];

    return (
        <Layout className="site-layout">
            <Sider
                trigger={null}
                collapsible
                width={250}
                collapsed={collapsed}
                style={{
                    left: 0,
                    bottom: 0,
                    minHeight: '100vh',
                    backgroundColor: 'white',
                }}
            >
                <Menu mode="inline" items={menuItems} selectedKeys={selectedKeys} onClick={({ keys }) => setSelectedKeys(keys)} />
            </Sider>

            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                        <div style={{ width: '50%' }}>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: () => setCollapsed(!collapsed),
                            })}
                            <Online polling={pollingOptions}>
                                <Badge status="success" text="Online" />
                            </Online>
                            <Offline polling={pollingOptions}>
                                <Badge status="error" text="Offline" />
                            </Offline>
                        </div>
                        <div style={{ width: '50%', marginRight: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                            <div>{user?.name} - </div>
                            <div style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }}>
                                <LogoutOutlined onClick={() => logout({ returnTo: window.location.origin })} />
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={showDrawer}>
                                <Badge count={notifications?.notificacoes.length} showZero style={{ fontSize: 10 }}>
                                    <BellOutlined style={{ marginRight: '3px', fontSize: 15 }} />
                                </Badge>
                            </div>
                        </div>
                    </div>
                    <NotificationsDrawer />
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {props.children}
                </Content>
            </Layout>
        </Layout>
    );
}

export default LayoutDomain;
