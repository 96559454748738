import "./UmidadeProduto.scoped.css";
import React, { useEffect, useState } from 'react';
import { useUmidades, deletarUmidade, criarUmidade, atualizarUmidade} from "../../../apis/backend";
import { Table, Modal, Button, Form, Row, Col, Space, InputNumber } from 'antd';

export default function UmidadeProduto(props) {
    const [produto] = useState(props.produto);
	const [formCreation] = Form.useForm();
    const [formEdit] = Form.useForm();
	const {umidades, mutate} = useUmidades(produto.id);
    const [editarVisible, setEditarVisible] = useState(false);
	const [loadingModalButton, setLoadingModalButton] = useState(false);
	const [visibleCriar, setVisibleCriar] = useState(false);
    const [editRecord, setEditRecord] = useState({});
	const isLoading = props.isLoading
	const columns = [
		{ title: 'Umidade (%)', dataIndex: 'umidade', key: 'umidade' },
        { title: 'Desconto (%)', dataIndex: 'desconto', key: 'desconto' },
		{
		  title: 'Ações',
		  dataIndex: '',
		  key: '',
		  render: (_, a) => AcoesUmidade(a),
		},
	];
	const fieldsRules = [
        {
            required: true
        }
    ];

	useEffect(() => {
		getUmidades();
	}, []);

	async function createUmidade(e){
		setLoadingModalButton(true);

        await criarUmidade(e, produto.id);

		setLoadingModalButton(false);
		setVisibleCriar(false);
		await getUmidades();
	}

	async function getUmidades(){
		isLoading(true);
		mutate();
		isLoading(false);
	}

	async function onClickDelete(record){
		isLoading(true);
		await deletarUmidade(record.id)
		await getUmidades();
		isLoading(false);
	}

	async function visibleEditar(record) {
		formEdit.resetFields();
		formEdit.setFieldsValue(record)
		setEditRecord(record);
        
		setTimeout(() => {
			setEditarVisible(true);
		}, 0);
    }

    async function editUmidade(e){
        setLoadingModalButton(true);
        e.id = editRecord.id;
        await atualizarUmidade(e, produto.id);
        await getUmidades();
        setLoadingModalButton(false);
        setEditarVisible(false);
    }

	const AcoesUmidade = (record) => (
        <div>
            <Button type="link" onClick={() => visibleEditar(record)}>Editar</Button>
            <Button type="link" onClick={() => onClickDelete(record)}>Deletar</Button>
            <Modal
					title="Editar Umidade"
					centered
					open={editarVisible}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
					footer={[
						<Button key={1} onClick={() => setEditarVisible(false)} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="umidadeEditForm" htmlType="submit">
							Atualizar
						</Button>
					]}
				>
					<Form form={formEdit} name="umidadeEditForm" onFinish={editUmidade} initialValues={editRecord}>
						<Row justify='space-between' gutter={4}>
							<Col span={12}>
								<Form.Item
                                label="Umidade"
                                name={"umidade"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.1" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={12}>
								<Form.Item
                                label="Desconto"
                                name={"desconto"}
                                rules={fieldsRules}>
									<InputNumber max={100} step="0.1" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
        </div>
	);

    return (
		<div>
			<Space size={"small"} direction="vertical" style={{width: "100%"}}>
				<div style={{width: "100%", display: "flex", justifyContent: 'end'}}>
				    <Button type="primary" onClick={() => setVisibleCriar(true)}>Adicionar</Button>
				</div>
			
				<Table
					rowKey={"id"}
					columns={columns}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 5,
						showSizeChanger: false
					}}
					dataSource={umidades}
				/>

				<Modal
					title="Criar Umidade"
					centered
					open={visibleCriar}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
                    afterClose={() => formCreation.resetFields()}
					footer={[
						<Button key={1} onClick={() => setVisibleCriar(false)} loading={loadingModalButton}>
							Cancelar
						</Button>,
						<Button key={2} type="primary" loading={loadingModalButton} form="umidadeCreationForm" htmlType="submit">
							Criar
						</Button>
					]}
				>
					<Form form={formCreation} name="umidadeCreationForm" onFinish={createUmidade}>
						<Row justify='space-between' gutter={4}>
							<Col span={12}>
								<Form.Item
                                label="Umidade"
                                name={"umidade"}
                                rules={fieldsRules}>
									<InputNumber  max={100} step="0.1" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
                            <Col span={12}>
								<Form.Item
                                label="Desconto"
                                name={"desconto"}
                                rules={fieldsRules}>
									<InputNumber  max={100} step="0.1" addonAfter="%" style={{width: '100%'}}/>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
			</Space>
        </div>
    )
}