export default function RelatorioPrestacoesServico() {
    return (
        <div>
            <iframe
                title="Relatório de Prestação de Serviço"
                src="https://metabase.limaetarifa.com.br/public/dashboard/34c7b3cc-c366-423d-b3bf-bc62d19238c7"
                frameborder="0"
                width="100%"
                height="990px"
                allowtransparency
            ></iframe>
        </div>
    );
}
