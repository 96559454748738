import React, { useState } from 'react';
import { Space, Tooltip, Button, notification } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { EyeOutlined, LinkOutlined, DeleteOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import TransacaoDetalhes from '../../Utils/TransacaoDetalhes';
import TransacaoRelacionamento from '../TransacaoRelacionamento/TransacaoRelacionamento';
import DeletarTransacao from '../DeletarTransacao/DeletarTransacao';
import { disponivelCheque, naoRelacionavel } from '../../../apis/backend';

export default function TransacoesActions(props) {
    const [isLoading, setIsLoading] = useState(false);

    function onClickView() {
        NiceModal.show(TransacaoDetalhes, { transacao: props.transacao });
    }

    function onClickRelacionar() {
        NiceModal.show(TransacaoRelacionamento, { transacao: props.transacao });
    }

    function onClickDelete() {
        NiceModal.show(DeletarTransacao, { transacao: props.transacao });
    }

    async function onClickDisponivel() {
        setIsLoading(true);

        try {
            await disponivelCheque(props.transacao.id);
            notification.success({
                message: 'Sucesso',
                description: 'A disponibilidade do cheque foi alterada.',
            });
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao alterar. Tente novamente.',
            });
        }

        props.mutate();

        setIsLoading(false);
    }

    async function onClickNaoRelacionavel() {
        setIsLoading(true);

        try {
            await naoRelacionavel(props.transacao.id);
            notification.success({
                message: 'Sucesso',
                description: 'Foi alterada a condição de relacionamento da transação.',
            });
        } catch (e) {
            console.error(e);
            notification.error({
                message: 'Erro',
                description: 'Falha ao alterar. Tente novamente.',
            });
        }

        props.mutate();

        setIsLoading(false);
    }

    return (
        <div>
            <Space size="small">
                <Tooltip title="Visualizar">
                    <Button shape="circle" icon={<EyeOutlined />} onClick={onClickView} size="small" />
                </Tooltip>
                <Tooltip title="Relacionar com Lançamento">
                    <Button shape="circle" icon={<LinkOutlined />} onClick={onClickRelacionar} size="small" disabled={props.transacao?.naoRelacionavel} />
                </Tooltip>
                {props.transacao?.caixa?.tipo !== 'Conta Bancária' ? (
                    <Tooltip title="Deletar">
                        <Button shape="circle" icon={<DeleteOutlined />} onClick={onClickDelete} size="small" />
                    </Tooltip>
                ) : (
                    <></>
                )}
                {props.transacao?.caixa?.tipo === 'Cheque' ? (
                    <Tooltip placement="left" title={props.transacao?.chequeDisponivel ? 'Tornar Cheque Indisponível' : 'Tornar Cheque Disponível'}>
                        <Button
                            disabled={isLoading}
                            loading={isLoading}
                            shape="circle"
                            icon={props.transacao?.chequeDisponivel ? <CloseOutlined /> : <CheckOutlined />}
                            onClick={onClickDisponivel}
                            size="small"
                        />
                    </Tooltip>
                ) : (
                    <></>
                )}
                <Tooltip placement="left" title={props.transacao?.naoRelacionavel ? 'Tornar Relacionável' : 'Tornar Não Relacionável'}>
                    <Button shape="circle" icon={props.transacao?.naoRelacionavel ? <CloseOutlined /> : <CheckOutlined />} onClick={onClickNaoRelacionavel} size="small" />
                </Tooltip>
            </Space>
        </div>
    );
}
