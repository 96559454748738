
import React, { useEffect, useState } from 'react';
import { useContas, atualizarConta, deletarConta, criarConta, tornarContaPrincipal } from "../../../apis/backend";
import { Table, Modal, Button, Form, Row, Col, Input, Space, Tooltip } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { StarFilled } from '@ant-design/icons';

export default function ContasBancariasCliente(props) {
	const [cliente] = useState(props.cliente);
	const [form] = Form.useForm();
	const [formCreation] = Form.useForm();
	const { contas, mutate } = useContas(cliente.id);
	const [loadingModalButton, setLoadingModalButton] = useState(false);
	const [visible, setVisible] = useState(false);
	const [visibleCriar, setVisibleCriar] = useState(false);
	const [currentConta, setCurrentConta] = useState({});
	const isLoading = props.isLoading
	const columns = [
		{
			title: 'Titular',
			dataIndex: 'titular',
			key: 'titular',
			render: (x, full) => {
				return <div>{x}
					{
						full.principal ?
							<Tooltip title="Conta principal">
								<StarFilled style={{ color: '#FFD700', fontSize: '17px' }} />
							</Tooltip> : <></>
					}
				</div>
			}
		},
		{ title: 'Banco', dataIndex: 'banco', key: 'banco' },
		{ title: 'Conta Corrente', dataIndex: 'contaCorrente', key: 'contaCorrente' },
		{
			title: 'Ações',
			dataIndex: '',
			key: '',
			render: (_, a) => AcoesConta(a),
		},
	];
	const fieldsRules = [
		{
			required: true
		}
	];

	useEffect(() => {
		isLoading(true);
		getContas();
	}, []);

	async function updateConta(e) {
		setLoadingModalButton(true);
		e.id = currentConta.id;

		await atualizarConta(e, cliente.id);

		setLoadingModalButton(false);
		setVisible(false);
		await getContas();
	}

	async function createConta(e) {
		setLoadingModalButton(true);

		await criarConta(e, cliente.id);

		setLoadingModalButton(false);
		setVisibleCriar(false);
		formCreation.resetFields();
		await getContas();
	}

	async function getContas() {
		isLoading(true);
		mutate();
		isLoading(false);
	}

	function onClickEdit(record) {
		form.resetFields();
		form.setFieldsValue(record)
		setCurrentConta(record);

		setTimeout(() => {
			setVisible(true);
		}, 0);
	}

	async function onClickPrincipal(record) {
		isLoading(true);
		await tornarContaPrincipal(record.id);
		getContas();
	}

	async function onClickDelete(record) {
		isLoading(true);
		await deletarConta(record.id)
		await getContas();
		isLoading(false);
	}

	const ExpandedConta = (record) => (
		<div>C.c: {record.contaCorrente} | Ag: {record.agencia} | CNPJ: {record.cnpj} | CPF: {record.cpf} | RG: {record.rg}</div>
	);

	const AcoesConta = (record) => (
		<>
			<div>
				<Button type="link" onClick={() => onClickEdit(record)}>
					Editar
				</Button>
				<Button type="link" disabled={record.principal} onClick={() => onClickPrincipal(record)}>
					Tornar Principal
				</Button>
				<Button type="link" onClick={() => onClickDelete(record)}>
					Deletar
				</Button>
			</div>
			<Modal
				title="Editar Conta"
				centered
				open={visible}
				width="90%"
				destroyOnClose={true}
				closable={false}
				keyboard={false}
				maskClosable={false}
				footer={[
					<Button key={1}
						onClick={() => setVisible(false)}
						loading={loadingModalButton}
					>
						Cancelar
					</Button>,
					<Button key={2}
						type="primary"
						loading={loadingModalButton}
						form="ContaForm"
						htmlType="submit"
					>
						Atualizar
					</Button>,
				]}
			>
				<Form
					form={form}
					name="ContaForm"
					onFinish={updateConta}
					initialValues={currentConta}
				>
					<Row justify="space-between" gutter={4}>
						<Col span={6}>
							<Form.Item label="Titular" name={"titular"} rules={fieldsRules}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="CPF" name={"cpf"}>
								<MaskedInput mask={"000.000.000-00"}></MaskedInput>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="CNPJ" name={"cnpj"}>
								<MaskedInput mask={"00.000.000/0000-00"}></MaskedInput>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="RG" name={"rg"}>
								<MaskedInput mask={"00.000.000-[*]"}></MaskedInput>
							</Form.Item>
						</Col>
					</Row>
					<Row justify="space-between" gutter={4}>
						<Col span={8}>
							<Form.Item label="Banco" name={"banco"}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Agência" name={"agencia"}>
								<Input />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Conta Corrente" name={"contaCorrente"}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);

	return (
		<div>
			<Space size={"small"} direction="vertical" style={{ width: "100%" }}>
				<div
					style={{ width: "100%", display: "flex", justifyContent: "end" }}
				>
					<Button type="primary" onClick={() => setVisibleCriar(true)}>
						Adicionar
					</Button>
				</div>

				<Table
					rowKey={"id"}
					columns={columns}
					expandable={{
						expandedRowRender: (record) => ExpandedConta(record),
						rowExpandable: () => true,
					}}
					pagination={{
						simple: false,
						hideOnSinglePage: true,
						defaultPageSize: 5,
						showSizeChanger: false,
					}}
					dataSource={contas}
				/>
				<Modal
					title="Criar Conta Bancária"
					centered
					open={visibleCriar}
					width="90%"
					destroyOnClose={true}
					closable={false}
					keyboard={false}
					maskClosable={false}
					footer={[
						<Button
							key={1}
							onClick={() => setVisibleCriar(false)}
							loading={loadingModalButton}
						>
							Cancelar
						</Button>,
						<Button
							key={2}
							type="primary"
							loading={loadingModalButton}
							form="ContaCreationForm"
							htmlType="submit"
						>
							Criar
						</Button>,
					]}
				>
					<Form
						form={formCreation}
						name="ContaCreationForm"
						onFinish={createConta}
					>
						<Row justify="space-between" gutter={4}>
							<Col span={6}>
								<Form.Item
									label="Titular"
									name={"titular"}
									rules={fieldsRules}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label="CPF" name={"cpf"}>
									<MaskedInput mask={"000.000.000-00"}></MaskedInput>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label="CNPJ" name={"cnpj"}>
									<MaskedInput mask={"00.000.000/0000-00"}></MaskedInput>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item label="RG" name={"rg"}>
									<MaskedInput mask={"00.000.000-[*]"}></MaskedInput>
								</Form.Item>
							</Col>
						</Row>
						<Row justify="space-between" gutter={4}>
							<Col span={8}>
								<Form.Item label="Banco" name={"banco"}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Agência" name={"agencia"}>
									<Input />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item label="Conta Corrente" name={"contaCorrente"}>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Modal>
			</Space>
		</div>
	);
}