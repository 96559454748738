import React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { Link } from 'react-router-dom';

import ListagemLancamentos from '../../../components/Financeiro/ListagemLancamentos/ListagemLancamentos';
import CriarLancamento from '../../../components/Financeiro/CriarLancamento/CriarLancamento';

export default function Lancamentos() {
	return (
		<div >
			<div style={{display: 'flex', justifyContent: 'space-between'}}>
				<Breadcrumb>
					<Breadcrumb.Item>Financeiro</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to="/financeiro/lancamentos">Lançamentos</Link>
					</Breadcrumb.Item>
				</Breadcrumb>
				<CriarLancamento></CriarLancamento>
			</div>
			<Divider />
			<ListagemLancamentos></ListagemLancamentos>
		</div>
	);
}