import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, InputNumber, Modal, Select, Form, AutoComplete, Result } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { usePlacasRomaneio, useProdutosRomaneio, useClientesRomaneio, atualizarRomaneio } from '../../../../apis/backend';
import { listaVeiculos, isValidCPF, transgeniaOptions, romaneioTipos, filterSelect } from '../../../Utils/Utils';
import { LoadingOutlined, StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import BalancasTooltip from '../../../Utils/BalancasTooltip';

export default NiceModal.create(({ record }) => {
	const modal = useModal();
	const [form] = Form.useForm();
	const [updateStatus, setUpdateStatus] = useState({
		inProgress: false,
		title: 'Atualizando romaneio',
		subtitle: null,
		icon: <LoadingOutlined />,
		extra: null
	});
	const [placaOptionsFiltered, setPlacaOptionsFiltered] = useState([]);
	const { produtos, isLoading: loadingProdutos } = useProdutosRomaneio();
	const { placas, isLoading: loadingPlacas } = usePlacasRomaneio();
	const { clientes, isLoading: loadingClientes } = useClientesRomaneio();
	const [disabledTrans, setDisabledTrans] = useState(true);
	const fieldsRules = [
		{
			required: true
		}
	];

	useEffect(() => {
		form.setFieldsValue(record);
		setDisabledTrans(record.produtoId !== 2)
	}, []);

	useEffect(() => {
		if (placas) {
			setPlacaOptionsFiltered(placas);
		}
	}, [placas, loadingPlacas]);

	async function update(payload) {
		payload.id = record.id;

		if (!isValidCPF(payload.motoristaCPF)) {
			return form.setFields([{
				name: 'motoristaCPF',
				errors: ["Insira um CPF válido"]
			}]);
		}

		setUpdateStatus({ inProgress: true, title: 'Atualizando romaneio', subtitle: null, icon: <LoadingOutlined />, extra: null });

		try {
			await atualizarRomaneio(payload);
			setUpdateStatus({
				inProgress: true,
				title: 'Romaneio atualizado',
				subtitle: null,
				icon: <CheckCircleOutlined style={{ color: '#5CB914' }} />,
				extra: <Button type="primary" onClick={() => closeEdit(true)}>Fechar</Button>
			});
		} catch (e) {
			console.error(e);
			return setUpdateStatus({
				inProgress: true,
				title: 'Falha ao atualizar romaneio!',
				subtitle: `Ocorreu uma falha ao atualizar o romaneio. (${e})`,
				icon: <StopOutlined style={{ color: '#C41E3A' }} />,
				extra: [
					<Button onClick={() => closeEdit(true)}>Cancelar</Button>,
					<Button type="primary" onClick={() => update(payload)}>Tentar novamente</Button>
				]
			});
		}
	}

	function onChangeForm(e) {
		calculatePs(e);
		calculateTrans(e);
	}

	function onSelectPlaca(placa) {
		const placaFull = placas.find(x => x.value === placa).fullData;

		Object.keys(placaFull).forEach(x => {
			form.setFields([
				{
					name: x,
					value: placaFull[x]
				}
			])
		});
	}

	function calculateTrans() {
		setDisabledTrans(form.getFieldValue("produtoId") !== 2)
	}

	function calculatePs(e) {
		const keys = Object.keys(e);

		if (!keys.includes("umidade") && !keys.includes("produtoId") && !keys.includes("clienteId"))
			return;

		const valueForm = form.getFieldsValue();

		if (!valueForm.produtoId || !valueForm.clienteId || !valueForm.umidade)
			return;

		const fullProduct = produtos.find(x => x.value === valueForm.produtoId);
		const fullClient = clientes.find(x => x.value === valueForm.clienteId);
		const umidade = valueForm.umidade;
		let prestacaoValue = 0;

		fullProduct.tabelaPs.forEach(x => {
			if (umidade >= x.umidadeInicial && umidade <= x.umidadeFinal) {
				prestacaoValue = x.desconto;
				return;
			}
		});

		fullClient.ps.forEach(x => {
			if (umidade >= x.umidadeInicial && umidade <= x.umidadeFinal && x.produtoId === valueForm.produtoId) {
				prestacaoValue = x.desconto;
				return;
			}
		});

		form.setFields([
			{
				name: 'prestacaoServico',
				value: prestacaoValue
			}
		]);
	}

	function closeEdit(shouldMutate) {
		if (shouldMutate)
			modal.resolve();
		modal.hide();
	}

	function onClickEscritorioEntrada(e){
		form.setFields([{
			name: "pesoEntrada",
			value: e.peso
		}]);
	}

	function onClickPatioEntrada(e){
		form.setFields([{
			name: "pesoEntrada",
			value: e.peso
		}]);
	}

	function onClickEscritorioSaida(e){
		form.setFields([{
			name: "pesoSaida",
			value: e.peso
		}]);
	}

	function onClickPatioSaida(e){
		form.setFields([{
			name: "pesoSaida",
			value: e.peso
		}]);
	}

	const RomaneioUpdateForm = () => {
		return <Form form={form} name="romaneioForm" onFinish={update} onValuesChange={onChangeForm} >
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Tipo"
						name={"tipo"}
						rules={fieldsRules}>
						<Select options={romaneioTipos} />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={12}>
					<Form.Item
						label="Produto"
						name={"produtoId"}
						rules={fieldsRules}>
						<Select
							allowClear
							showSearch
							loading={loadingProdutos}
							filterOption={filterSelect}
							options={produtos}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="Cliente"
						name={"clienteId"}
						rules={fieldsRules}>
						<Select
							allowClear
							showSearch
							loading={loadingClientes}
							filterOption={filterSelect}
							options={clientes}
						/>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={4}>
					<Form.Item
						label="Placa"
						name={"placaVeiculo"}
						rules={fieldsRules}>
						<AutoComplete
							options={placaOptionsFiltered}
							filterOption={filterSelect}
							onSelect={onSelectPlaca}
						/>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Tipo Veículo"
						name={"tipoVeiculo"}
						rules={fieldsRules}>
						<Select options={listaVeiculos} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Nome"
						name={"motoristaNome"}
						rules={fieldsRules}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="CPF"
						name={"motoristaCPF"}
						rules={fieldsRules}>
						<MaskedInput mask={'000.000.000-00'}></MaskedInput>
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Celular"
						name={"motoristaCelular"}>
						<MaskedInput mask={'(00) 000000000'}></MaskedInput>
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={4}>
					<Form.Item
						label="Umidade"
						name={"umidade"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Impureza"
						name={"impureza"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="Avariados"
						name={"avariados"}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={4}>
					<Form.Item
						label="PS"
						name={"prestacaoServico"}
						rules={fieldsRules}>
						<InputNumber max={100} step="0.0001" addonAfter="%" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						label="Transgenia"
						name={"transgenia"}
					>
						<Select options={transgeniaOptions} disabled={disabledTrans} />
					</Form.Item>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={12}>
					<BalancasTooltip onClickEscritorio={onClickEscritorioEntrada} onClickPatio={onClickPatioEntrada}>
						<Form.Item
							label="Peso Entrada"
							name={"pesoEntrada"}
							rules={fieldsRules}
						>
							<InputNumber addonAfter={'KG'} style={{ width: '100%' }}></InputNumber>
						</Form.Item>
					</BalancasTooltip>
				</Col>
				<Col span={12}>
					<BalancasTooltip onClickEscritorio={onClickEscritorioSaida} onClickPatio={onClickPatioSaida}>
						<Form.Item
							label="Peso Saída"
							name={"pesoSaida"}
							rules={fieldsRules}
						>
							<InputNumber addonAfter={'KG'} style={{ width: '100%' }}></InputNumber>
						</Form.Item>
					</BalancasTooltip>
				</Col>
			</Row>
			<Row justify='space-between' gutter={4}>
				<Col span={24}>
					<Form.Item
						label="Observações"
						name={"observacoes"}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	}

	const RomaneioUpdateProgress = () => {
		return <Result
			icon={updateStatus.icon}
			subTitle={updateStatus.subtitle}
			title={updateStatus.title}
			extra={updateStatus.extra}
		/>
	}

	return (
		<Modal
			title="Editar Romaneio"
			centered
			open={modal.visible}
			width="90%"
			destroyOnClose={true}
			closable={false}
			keyboard={false}
			maskClosable={false}
			afterClose={() => modal.remove()}
			footer={!updateStatus.inProgress ? [
				<Button key={1} onClick={closeEdit}>
					Cancelar
				</Button>,
				<Button key={2} type="primary" form="romaneioForm" htmlType="submit">
					Salvar
				</Button>
			] : <></>}>
				{!updateStatus.inProgress ? <RomaneioUpdateForm /> : <RomaneioUpdateProgress />}
		</Modal>
	);
});